import ActionTypes from "../constants/ActionTypes";
import BASE_URL from "../constants/Api";
import { authHeader } from "../helpers";
import axiosInstance from "../helpers/refreshTokenMiddleware";

// Action Creators
export const requestAccessToken = () => ({
  type: ActionTypes.REQUEST_ACCESS_TOKEN
});

export const receiveAccessTokenSuccess = (accessToken, refreshToken) => {
  localStorage.setItem('accessToken', accessToken);
  localStorage.setItem('refreshToken', refreshToken);
  return {
    type: ActionTypes.RECEIVE_ACCESS_TOKEN_SUCCESS,
    payload: { accessToken, refreshToken }
  };
};

export const receiveAccessTokenFailure = error => ({
  type: ActionTypes.RECEIVE_ACCESS_TOKEN_FAILURE,
  payload: error,
  error: true 
});

export const doingMaintenance = (maintenance, screen_message) => {
  localStorage.setItem('maintenance', maintenance);
  localStorage.setItem('screen_message', screen_message);
  return {
    type: ActionTypes.DOING_MAINTENANCE,
    payload: { maintenance, screen_message }
  };
}

// Action creator for receiving OTP token
export const receiveOtpToken = otpToken => ({
  type: ActionTypes.RECEIVE_OTP_TOKEN,
  payload: otpToken,
});

export const fetchAccessToken = credentials => async dispatch => {
  dispatch(requestAccessToken());
  try {
    // メインテナンスをクリア
    localStorage.removeItem('maintenance');
    localStorage.removeItem('screen_message');

    const response = await fetch(BASE_URL + '/users/access-token', {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify(credentials),
    });
    
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Failed to fetch access token');
    }

    if (data.respons && data.respons.otp_token && data.respons.maintenance !== 1) { 
      dispatch(receiveOtpToken(data.respons.otp_token));
    } else if (data.respons && data.respons.access_token && data.respons.refresh_token  && data.respons.maintenance !== 1) { 
      dispatch(receiveAccessTokenSuccess(data.respons.access_token, data.respons.refresh_token));
    } else {
      if (data.respons.maintenance === 1) {
        dispatch(doingMaintenance(data.respons.maintenance, data.respons.screen_message));
      };
      throw new Error('Invalid response structure');
    }

  } catch (error) {
    dispatch(receiveAccessTokenFailure(error.message));
    throw error;
  }
};

export const verifyOtpRequest = () => ({
  type: ActionTypes.VERIFY_OTP_REQUEST
});

export const verifyOtpSuccess = (accessToken, refreshToken) => {
  localStorage.setItem('accessToken', accessToken);
  localStorage.setItem('refreshToken', refreshToken);
  return {
    type: ActionTypes.VERIFY_OTP_SUCCESS,
    payload: { accessToken, refreshToken }
  };
};

export const verifyOtpFailure = (message, code) => ({
  type: ActionTypes.VERIFY_OTP_FAILURE,
  payload: {message, code},
  error: true 
});

export const verifyOtp = (otpCode, otpToken) => async dispatch => {
  dispatch(verifyOtpRequest());
  try {
    const response = await fetch(BASE_URL + '/users/otp-token', {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({ otp_code: otpCode, otp_token: otpToken })
    });

    const data = await response.json();
    console.log('api', data)
    if (data.status !== 200) {
      // throw new Error(data.respons.message || 'Failed to fetch access token');
      dispatch(verifyOtpFailure(data.respons.message || 'Failed to fetch access token', data.respons.error_code || ""));
    } else {
      if (data.respons.maintenance === 1) {
        dispatch(doingMaintenance(data.respons.maintenance, data.respons.screen_message));
      } else {
        dispatch(verifyOtpSuccess(data.respons.access_token, data.respons.refresh_token));
      }
    }
  } catch (error) {
    dispatch(verifyOtpFailure(error.message, ""));
  }
};

// Action Creators
export const resendOtpRequest = () => ({
  type: ActionTypes.RESEND_OTP_REQUEST
});

export const resendOtpSuccess = (otpToken) => ({
  type: ActionTypes.RESEND_OTP_SUCCESS,
  payload: otpToken
});

export const resendOtpFailure = (error) => ({
  type: ActionTypes.RESEND_OTP_FAILURE,
  payload: error
});

export const resendOtp = () => async (dispatch, getState) => {
  dispatch(resendOtpRequest());
  try {
    const response = await fetch(BASE_URL + '/users/resend-otp', {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({ otp_token: getState().login.otpToken }) 
    });

    const data = await response.json();
    if (data.status !== 200) {
      throw new Error(data.respons.message || 'Failed to resend OTP.');
    }
    if (data.respons && data.respons.otp_token) {
      dispatch(resendOtpSuccess(data.respons.otp_token)); 
    } else {
      throw new Error('Invalid response structure');
    }
    // alert('OTP has been resent successfully.');
  } catch (error) {
    dispatch(resendOtpFailure(error.toString()));
  }
};

export const resetOtpAttemptCount = () => ({
  type: ActionTypes.RESET_OTP_ATTEMPT_COUNT
});

// Action creators in your actions file
export const refreshAccessTokenRequest = () => ({
  type: ActionTypes.REFRESH_ACCESS_TOKEN_REQUEST
});

export const refreshAccessTokenSuccess = (token) => ({
  type: ActionTypes.REFRESH_ACCESS_TOKEN_SUCCESS,
  payload: token
});

export const refreshAccessTokenFailure = (error) => ({
  type: ActionTypes.REFRESH_ACCESS_TOKEN_FAILURE,
  payload: error,
  error: true
});

export const refreshAccessToken = (refreshToken) => async (dispatch) => {
  dispatch(refreshAccessTokenRequest());
  try {
    const response = await fetch(BASE_URL + '/users/refresh-token', {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({ refresh_token: refreshToken })
    });

    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.message || 'Failed to refresh access token');
    }

    const newAccessToken = data.newAccessToken;
    dispatch(refreshAccessTokenSuccess(newAccessToken));
    return newAccessToken;
  } catch (error) {
    dispatch(refreshAccessTokenFailure(error.message));
    throw error; 
  }
};

export const clearError = () => {
  return {
      type: ActionTypes.CLEAR_ERROR
  };
};

// Action creators
export const fetchUserSuccess = (user) => ({
  type: ActionTypes.FETCH_USER_SUCCESS,
  payload: user.respons
});

export const fetchUserFailure = (error) => ({
  type: ActionTypes.FETCH_USER_FAILURE,
  payload: error,
  error: true
});

export const fetchUserData = () => async (dispatch) => {
  try {
    const accessToken = localStorage.getItem('accessToken');

    if (!accessToken) {
      window.location.href = '/login';
      return;
    }

    const response = await axiosInstance.get(BASE_URL + '/users/me', {
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    });

    dispatch(fetchUserSuccess(response.data));
  } catch (error) {
    dispatch(fetchUserFailure(error.message));
  }
};

export const logoutRequest = () => ({
  type: ActionTypes.LOGOUT_REQUEST,
});

export const logoutSuccess = () => ({
  type: ActionTypes.LOGOUT_SUCCESS,
});

export const logoutFailure = (error) => ({
  type: ActionTypes.LOGOUT_FAILURE,
  payload: error,
  error: true,
});

export const logout = () => async (dispatch) => {
  dispatch(logoutRequest());

  try {
    const accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');

    // ローカルストレージのトークン・データを削除
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('activeItem');
    localStorage.removeItem('activeSet');

    if (!refreshToken) {
      throw new Error('No refresh token found.');
    }

    const body = {
      refresh_token: refreshToken,
    };

    const response = await axiosInstance.post(BASE_URL + '/users/logout', body, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    if (response.data?.respons.logout === true) {
      dispatch(logoutSuccess());
      window.location.href = '/login';
    } else {
      throw new Error('Unexpected server response.');
    }
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message || 'Failed to logout.';
    dispatch(logoutFailure(errorMessage));
    window.location.href = '/login';
    console.error('Logout failed:', errorMessage);
  }
};
