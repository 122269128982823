import ActionTypes from '../constants/ActionTypes';

const initialState = {
    selectedCamera: null,
};

const batteryGraphListReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SET_SELECTED_CAMERA:
            return {
                ...state,
                selectedCamera: action.payload,
            }
        default:
            return state;
    }

};

export default batteryGraphListReducer;
