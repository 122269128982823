import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ComparisonGraphList from '../components/ComparisonGraphList';
import { fetchSearchlist, fetchComparisonGraphList } from '../actions/comparisonGraphList';

const mapStateToProps = (state) => {

  const comparisonGraphListData = state.comparisonGraphList;
  console.log("comparisonGraphListData", comparisonGraphListData);
  const user = state.login.user;

  return {
    user,
    facilities: comparisonGraphListData.facilities,
    cameras: comparisonGraphListData.cameras,
    gauges: comparisonGraphListData.gauges,
    measureUnit: comparisonGraphListData.measureUnit,
    listData: comparisonGraphListData.listData,
    isSearchListFetched: comparisonGraphListData.isSearchListFetched || false,
    isCameraListFetched: comparisonGraphListData.isCameraListFetched || false,
    isLoading: comparisonGraphListData.isLoading || false,
  };

};
  
const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchSearchlist,
    fetchComparisonGraphList,
}, dispatch);
  
export default connect(mapStateToProps, mapDispatchToProps)(ComparisonGraphList);