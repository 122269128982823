import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, Navigate} from 'react-router-dom';
import PrivateRoute from '../components/PrivateRoute';
import LoginContainer from '../containers/LoginContainer';
import ScreenViewContainer from '../containers/ScreenViewContainer';
import BatteryGraphListContainer from '../containers/BatteryGraphListContainer';
import MenuContainer from '../containers/MenuContainer';
import DataManageContainer from '../containers/DataManageContainer';
import BatteryGraphContainer from '../containers/BatteryGraphContainer';
import ComparisonGraphListContainer from '../containers/ComparisonGraphListContainer';
import ComparisonGraphContainer from '../containers/ComparisonGraphContainer';
import TransitionGraphListContainer from '../containers/TransitionGraphListContainer';
import TransitionGraphContainer from '../containers/TransitionGraphContainer';
import CompanyListContainer  from '../containers/CompanyListContainer';
import FacilityNewContainer from '../containers/FacilityNewContainer';
import FacilityListContainer from '../containers/FacilityListContainer';
import FacilityEditContainer from '../containers/FacilityEditContainer';
import CameraNewContainer from '../containers/CameraNewContainer';
import CameraEditContainer from '../containers/CameraEditContainer';
import GaugeEditContainer from '../containers/GaugeEditContainer';
import MeasureNewContainer from '../containers/MeasureNewContainer';
import MeasureEditContainer from '../containers/MeasureEditContainer';
import MailSendSettingContainer from '../containers/MailSendSettingContainer';
import ConfirmFirstMonthContainer from '../containers/ConfirmFirstMonthContainer';
import ConfirmCameraCountContainer from '../containers/ConfirmCameraCountContainer';
import UserNameChangeContainer from '../containers/UserNameChangeContainer';
import IdPasswordChangeContainer from '../containers/IdPasswordChangeContainer';
import Setting2FAContainer from '../containers/Setting2FAContainer';
import CameraChangeContainer from '../containers/CameraChangeContainer';
import LoginHistoryContainer from '../containers/LoginHistoryContainer';
import MaintenanceContainer from '../containers/MaintenanceContainer';
import MobileRedirect from '../helpers/MobileRedirect';
import "../App/App.css"

const App = () => {
  const location = useLocation();
  const hideMenu = ((location.pathname === '/login') || (location.pathname === '/maintenance'));
  const restrictedPaths = [
    '/companyList', 
    '/facilityNew', 
    '/facilityList', 
    '/facilityEdit', 
    '/cameraNew', 
    '/cameraEdit', 
    '/gaugeEdit', 
    '/measureNew', 
    '/measureEdit',
    '/batterygraphlist',
    '/batterygraph',
    '/comparisongraphlist',
    '/comparisongraph',
    '/transitiongraphlist',
    '/transitiongraph',
    '/mailSendSetting',
    '/confirmFirstMonth',
    '/confirmCameraCount',
    '/userNameChange',
    '/idPasswordChange',
    '/setting2FA',
    '/cameraChange',
    '/loginHistory',

  ];

  return (
    <div className="main-container">
      {!hideMenu && <MenuContainer/>}
      <MobileRedirect restrictedPaths={restrictedPaths} />
      <Routes>
        <Route path="/" element={
          <PrivateRoute>
            <ScreenViewContainer />
          </PrivateRoute>
        } />
        <Route path="/batterygraphlist" element={
          <PrivateRoute>
            <BatteryGraphListContainer />
          </PrivateRoute>
        } />
        <Route path="/batterygraph" element={
          <PrivateRoute>
            <BatteryGraphContainer />
          </PrivateRoute>
        } />
        <Route path="/comparisongraphlist" element={
          <PrivateRoute>
            <ComparisonGraphListContainer />
          </PrivateRoute>
        } />
        <Route path="/comparisongraph" element={
          <PrivateRoute>
            <ComparisonGraphContainer />
          </PrivateRoute>
        } />
        <Route path="/transitiongraphlist" element={
          <PrivateRoute>
            <TransitionGraphListContainer />
          </PrivateRoute>
        } />
        <Route path="/transitiongraph" element={
          <PrivateRoute>
            <TransitionGraphContainer />
          </PrivateRoute>
        } />
        <Route path="/login" element={<LoginContainer />} />
        <Route path="/data-manage" element={
          <PrivateRoute>
            <DataManageContainer/>
          </PrivateRoute>
        } />

        <Route path="/companyList" element={
          <PrivateRoute disallowedAuthority={0}
                        allowedAuthority={9} 
                        requiredCompanyId={1}>
            <CompanyListContainer/>
          </PrivateRoute>
        } />

        <Route path="/facilityNew" element={
          <PrivateRoute disallowedAuthority={0}>
            <FacilityNewContainer/>
          </PrivateRoute>
        } />

        <Route path="/facilityList" element={
          <PrivateRoute disallowedAuthority={0}>
            <FacilityListContainer/>
          </PrivateRoute>
        } />

        <Route path="/facilityEdit" element={
          <PrivateRoute disallowedAuthority={0}>
            <FacilityEditContainer/>
          </PrivateRoute>
        } />

        <Route path="/cameraNew" element={
          <PrivateRoute disallowedAuthority={0}>
            <CameraNewContainer/>
          </PrivateRoute>
        } />

        <Route path="/cameraEdit" element={
          <PrivateRoute disallowedAuthority={0}>
            <CameraEditContainer/>
          </PrivateRoute>
        } />

        <Route path="/gaugeEdit" element={
          <PrivateRoute disallowedAuthority={0}>
            <GaugeEditContainer/>
          </PrivateRoute>
        } />

        <Route path="/measureNew" element={
          <PrivateRoute disallowedAuthority={0}>
            <MeasureNewContainer/>
          </PrivateRoute>
        } />

        <Route path="/measureEdit" element={
          <PrivateRoute disallowedAuthority={0}>
            <MeasureEditContainer/>
          </PrivateRoute>
        } />

        <Route path="/mailSendSetting" element={
          <PrivateRoute>
            <MailSendSettingContainer/>
          </PrivateRoute>
        } />

        <Route path="/confirmFirstMonth" element={
          <PrivateRoute>
            <ConfirmFirstMonthContainer/>
          </PrivateRoute>
        } />

        <Route path="/confirmCameraCount" element={
          <PrivateRoute>
            <ConfirmCameraCountContainer/>
          </PrivateRoute>
        } />

        <Route path="/userNameChange" element={
          <PrivateRoute>
            <UserNameChangeContainer/>
          </PrivateRoute>
        } />

        <Route path="/idPasswordChange" element={
          <PrivateRoute>
            <IdPasswordChangeContainer/>
          </PrivateRoute>
        } />

        <Route path="/setting2FA" element={
          <PrivateRoute>
            <Setting2FAContainer/>
          </PrivateRoute>
        } />

        <Route path="/cameraChange" element={
          <PrivateRoute>
            <CameraChangeContainer/>
          </PrivateRoute>
        } />

        <Route path="/loginHistory" element={
          <PrivateRoute allowedAuthority={9}>
            <LoginHistoryContainer />
          </PrivateRoute>
        } />

        {/* <Route path="/maintenance" element={
          <PrivateRoute>
            <MaintenanceContainer/>
          </PrivateRoute>
        } /> */}
        <Route path="/maintenance" element={<MaintenanceContainer />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      
    </div>
  );
};

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
