import ActionTypes from "../constants/ActionTypes";
import BASE_URL from "../constants/Api";
import axiosInstance from "../helpers/refreshTokenMiddleware";

export const fetchCompanyDataSuccess = (company) => ({
    type: ActionTypes.FETCH_COMPANY_SUCCESS,
    payload: company.respons
});

export const fetchCompanyData = () => async (dispatch) => {
    try {
        console.log("fetchCompanyData");
        const accessToken = localStorage.getItem('accessToken');
        const response = await axiosInstance.get(BASE_URL + '/top/sidemenu', {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        });
        
        dispatch(fetchCompanyDataSuccess(response.data));
        
    } catch (error) {
        console.log(error);
    }

};

export const setSelectedCamera = (cameraInfo) => ({
    type: ActionTypes.SET_SELECTED_CAMERA,
    payload: cameraInfo,
});

export const fetchBatteryGraphListRequest = () => ({
    type: ActionTypes.FETCH_BATTERY_GRAPH_LIST_REQUEST,
});

export const fetchBatteryGraphListSuccess = (data) => ({
    type: ActionTypes.FETCH_BATTERY_GRAPH_LIST_SUCCESS,
    payload: data,
});

export const fetchBatteryGraphListFailure = (error) => ({
    type: ActionTypes.FETCH_BATTERY_GRAPH_LIST_FAILURE,
    payload: error,
});

export const fetchBatteryGraphList = () => async (dispatch) => {
    dispatch(fetchBatteryGraphListRequest);
    try {
        const response = await axiosInstance.post(BASE_URL + '/graph/battery-graph-list', {});
        dispatch(fetchBatteryGraphListSuccess(response.data.respons));
    } catch (error) {
        const errorMessage = error.response && error.response.status === 504 
            ? '504 ゲートウェイタイムアウト。\n後でもう一度お試しください。' 
            : error.message;
        dispatch(fetchBatteryGraphListFailure(errorMessage));
    }

};