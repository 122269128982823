import ActionTypes from '../constants/ActionTypes';

const initialState = {
    isSearchListFetched: false,
    isCameraListFetched: false,
    isLoading: false,
    facilities: [],
    cameras: [],
    gauges: [],
    measureUnit: [],
    listData: [],
};

const transitionGraphListReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.FETCH_SEARCHLIST_REQUEST:
            return {
                ...state,
                isSearchListFetched: false,
                isCameraListFetched: false,
                isLoading: true,
            };
        case ActionTypes.FETCH_SEARCHLIST_SUCCESS:
            return {
                ...state,
                isSearchListFetched: true,
                isCameraListFetched: false,
                isLoading: false,
                facilities: action.payload.facility,
                cameras: action.payload.camera,
                gauges: action.payload.gauge,
                measureUnit: action.payload.measure,
            };
        case ActionTypes.FETCH_SEARCHLIST_FAILURE:
            return {
                ...state,
                isSearchListFetched: false,
                isCameraListFetched: false,
                isLoading: false,
            };
        case ActionTypes.FETCH_TRANSITION_GRAPH_LIST_REQUEST:
            return {
                ...state,
                isSearchListFetched: true,
                isCameraListFetched: false,
                isLoading: true,
            };
        case ActionTypes.FETCH_TRANSITION_GRAPH_LIST_SUCCESS:
            return {
                ...state,
                isSearchListFetched: true,
                isCameraListFetched: true,
                isLoading: false,
                listData: action.payload,
            };
        case ActionTypes.FETCH_TRANSITION_GRAPH_LIST_FAILURE:
            return {
                ...state,
                isSearchListFetched: true,
                isCameraListFetched: false,
                isLoading: false,
            };
        default:
            return state;
    }
};

export default transitionGraphListReducer;