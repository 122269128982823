import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from '../Modal/Modal';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './CameraChange.css';
import Breadcrumb from '../Breadcrumb';

const CameraChange = ({ user, fetchCameraChange, fetchGetGaugeData, sendCameraChange, data = {}, dataCount, loading, error }) => {
    const navigate = useNavigate ();
    const location = useLocation ();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [selectedDevice, setSelectedDevice] = useState(null); 
    const [cameraName, setCameraName] = useState(''); 
    const [newCameraName, setNewCameraName] = useState(''); 
    const [newFacilityName, setNewFacilityName] = useState('');
    const [isMeterCountDisabled, setIsMeterCountDisabled] = useState(false);
    const [showConfirmLabels, setShowConfirmLabels] = useState(false);
    const [meterCount, setMeterCount] = useState("0");
    const [cameraObject, setCameraObject] = useState("風景");
    const [newCameraObject, setNewCameraObject] = useState("風景");
    const [isSent, setIsSent] = useState(false);
    const [showUserName, setUserName] = useState('');
    const screenType = useSelector((state) => state.device.screenType);
    const [selectedPhotoType, setSelectedPhotoType] = useState('photo_type_meter');

    useEffect(() => {
        setSelectedPhotoType('photo_type_meter');
        setIsMeterCountDisabled(false);
    }, []);
 
    useEffect(() => {
        fetchCameraChange(); 
    }, [fetchCameraChange, location]);

    useEffect(() => {
        if (user) {
          setUserName(user.user_name);
        };
    }, [user]);

    const mailToList = data?.mail_data?.mail_to ? data.mail_data.mail_to.split(',') : [];
    const mailCcList = data?.mail_data?.mail_cc ? data.mail_data.mail_cc.split(',') : [];
    const mailBccList = data?.mail_data?.mail_bcc ? data.mail_data.mail_bcc.split(',') : [];

    const handleDeviceSelect = async (event) => {
        const deviceId = event.target.value;
        const device = data.camera_data.find(d => d.device_id === deviceId);
        setSelectedDevice(device);
        setCameraName(device ? device.display_camera_name : '');
        setNewCameraName(device ? device.display_camera_name : '');

        // Call fetchGetGaugeData with deviceId to get the gauge data count
        try {
            const response = await fetchGetGaugeData({ camera_id: deviceId });
            const gaugeDataCount = response?.respons?.gauge_data_count || 0;

            // Update cameraObject based on gaugeDataCount
            if (gaugeDataCount > 0) {
                setCameraObject(`計器 （${gaugeDataCount} 台）`);
            } else {
                setCameraObject("風景");
            }
        } catch (error) {
            console.error("Failed to fetch gauge data:", error);
            setCameraObject("風景");
        }
    };

    const handleNewCameraNameChange = (event) => {
        setNewCameraName(event.target.value);
    };

    const handleNewFacilityNameChange = (event) => {
        setNewFacilityName(event.target.value);
    };
    
    const handlePhotoTypeChange = (event) => {
        const selectedType = event.target.id;
        setSelectedPhotoType(selectedType);

        const isPhotoTypeView = selectedType === "photo_type_view";
        setIsMeterCountDisabled(isPhotoTypeView);
    };

    const handleMeterCountChange = (event) => {
        setMeterCount(event.target.value);
    };

    const handleConfirmClick = () => {
        if (!selectedDevice) {
            setModalMessage("シリアル番号を選択してください。"); 
            setIsModalOpen(true);
            return;
        }

        if (!newCameraName.trim()) {
            setModalMessage("新カメラ名を入力してください。"); 
            setIsModalOpen(true);
            return;
        }
        if (newCameraName.length > 100) {
            setModalMessage("新カメラ名は１００文字まで入力してください。"); 
            setIsModalOpen(true);
            return;
        }

        if (!newFacilityName.trim()) {
            setModalMessage("移動後の撮影場所を入力してください。"); 
            setIsModalOpen(true);
            return;
        }
        if (newFacilityName.length > 100) {
            setModalMessage("移動後の撮影場所は１００文字まで入力してください。"); 
            setIsModalOpen(true);
            return;
        }

        if (!isMeterCountDisabled && meterCount === "0") {
            setModalMessage("計器台数を選択してください。"); 
            setIsModalOpen(true);
            return;
        }

        if (isMeterCountDisabled) {
            setNewCameraObject("風景");
        } else {
            setNewCameraObject(`計器 （${meterCount} 台）`);
        }

        setShowConfirmLabels(true); 
    };

    const handleBackClick = () => {
        setShowConfirmLabels(false);
    };

    const handleSendRequest = async () => {
        if (isSent) {
            setModalMessage("すでに同一内容で依頼済みです。"); 
            setIsModalOpen(true);
            return;
        }

        const params = {
            mail_to: mailToList,
            mail_cc: mailCcList,
            mail_bcc: mailBccList,
            facility_id: selectedDevice?.facility_id || '',
            facility_name: selectedDevice?.facility_name || '',
            camera_id: selectedDevice?.device_id || '',
            camera_object: cameraObject,
            new_camera_object: newCameraObject,
            camera_index: data.camera_data.findIndex(d => d.device_id === selectedDevice?.device_id) + 1,
            camera_name: cameraName,
            new_camera_name: newCameraName,
            new_facility_name: newFacilityName,
        };

        try {
            const response = await sendCameraChange(
                params.mail_to, 
                params.mail_cc, 
                params.mail_bcc, 
                params.facility_id, 
                params.facility_name, 
                params.camera_id, 
                params.camera_object, 
                params.new_camera_object, 
                params.camera_index, 
                params.camera_name, 
                params.new_camera_name, 
                params.new_facility_name
            );

            setModalMessage(response?.data?.respons?.msg || "依頼が完了しました。"); 
            setIsModalOpen(true);
            setIsSent(true); 
            document.getElementById('camera-change-input').style.display = 'none';
            document.getElementById('camera-change-btn').style.display = 'none';
            document.getElementById('finish-message').style.display = 'block';

        } catch (error) {
            console.error("Failed to send camera change request:", error);
            setModalMessage("送信に失敗しました。再試行してください。"); 
            setIsModalOpen(true);
        }
    };

    const handleTopClick = () => {
        navigate('/');
    };

    return (
        <div id='Main' className={`main ${screenType}`}>
            <div id='Contents'>
                <Breadcrumb
                    userName = {showUserName}
                    pageName = "ユーザ情報"
                    onTopClick = {handleTopClick}
                />
                {isModalOpen && (
                    <Modal
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    message={modalMessage}
                />
                )}
                <div id="ajax-view-disp">
                    <div className="display-main">
                        <div className={`lay-master-set ${screenType}`}>
                            <div className="lay-master-set-wrap">
                                <div className="lay-camera-set__title">
                                    <span>カメラ移動連絡</span>
                                </div>
                                <div className="lay-master-set__form" id='camera-change-input'>
                                    <form id="cameraChange">
                                        <div className="wrapper">
                                            <div className="mail-send__set">
                                                {mailToList.map((email, index) => (
                                                    <input 
                                                        key={`mail_to_${index}`} 
                                                        type="hidden" 
                                                        id={`mail_to${index}`} 
                                                        name="mail_to[]" 
                                                        value={email.trim()} 
                                                    />
                                                ))}
                                                {mailCcList.map((email, index) => (
                                                    <input 
                                                        key={`mail_cc_${index}`} 
                                                        type="hidden" 
                                                        id={`mail_cc${index}`} 
                                                        name="mail_cc[]" 
                                                        value={email.trim()} 
                                                    />
                                                ))}
                                                {mailBccList.map((email, index) => (
                                                    <input 
                                                        key={`mail_bcc_${index}`} 
                                                        type="hidden" 
                                                        id={`mail_bcc${index}`} 
                                                        name="mail_bcc[]" 
                                                        value={email.trim()} 
                                                    />
                                                ))}
                                                <div className="">
                                                    <div className="mod-form__set camera-change" id="mail_to_head">
                                                        <dl>
                                                            <dt>シリアル番号</dt>
                                                            <dd>
                                                                <div className="mod-form__select camera_change_input" style={{ display: showConfirmLabels ? 'none' : 'block' }}>
                                                                    <input type="hidden" id="company_id" name="company_id" value={data.mail_data?.company_id || ''} />
                                                                    <input type="hidden" id="company_name" name="company_name" value={selectedDevice?.company_name || ''} />
                                                                    <input type="hidden" id="facility_id" name="facility_id" value={selectedDevice?.facility_id || ''} />
                                                                    <input type="hidden" id="facility_name" name="facility_name" value={selectedDevice?.facility_name || ''} />
                                                                    <input type="hidden" id="camera_id" name="camera_id" value={selectedDevice?.device_id || ''} />
                                                                    <input type="hidden" id="camera_object" name="camera_object" value={cameraObject} />
                                                                    <input type="hidden" id="new_camera_object" name="new_camera_object" value={newCameraObject} /> 
                                                                    <select 
                                                                        id="camera_index" 
                                                                        name="camera_index" 
                                                                        onChange={handleDeviceSelect}
                                                                    >
                                                                        <option value="0"></option>
                                                                        {data.camera_data && data.camera_data.map((device, index) => (
                                                                            <option key={index} value={device.device_id}>
                                                                                {device.device_id}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                                <div className="camera_change_label" style={{ display: showConfirmLabels ? 'block' : 'none' }}>
                                                                    <span id="label_camera_id">{selectedDevice?.device_id || ''}</span>
                                                                </div>
                                                            </dd>
                                                        </dl>
                                                    </div>
                                                    <div className="mod-form__set camera-change">
                                                        <dl>
                                                            <dt>カメラ名</dt>
                                                            <dd>
                                                                <div className="mod-form__text camera_change_input" style={{ display: showConfirmLabels ? 'none' : 'block' }}>
                                                                    <input 
                                                                        type="text" 
                                                                        id="camera_name" 
                                                                        name="camera_name" 
                                                                        value={cameraName} 
                                                                        readOnly 
                                                                    />
                                                                </div>
                                                                <div className="camera_change_label" style={{ display: showConfirmLabels ? 'block' : 'none' }}>
                                                                    <span id="label_camera_name">{cameraName}</span>
                                                                </div>
                                                            </dd>
                                                        </dl>
                                                    </div>
                                                    <div className="mod-form__set camera-change">
                                                        <dl>
                                                            <dt>新カメラ名</dt>
                                                            <dd>
                                                                <div className="mod-form__text camera_change_input" style={{ display: showConfirmLabels ? 'none' : 'block' }}>
                                                                    <input 
                                                                        type="text" 
                                                                        id="new_camera_name" 
                                                                        name="new_camera_name" 
                                                                        value={newCameraName}
                                                                        onChange={handleNewCameraNameChange}
                                                                    />
                                                                </div>
                                                                <div className="camera_change_label camera_change_label_display" style={{ display: showConfirmLabels ? 'block' : 'none' }}>
                                                                    <span id="label_new_camera_name">{newCameraName}</span>
                                                                </div>
                                                            </dd>
                                                        </dl>
                                                    </div>
                                                    <div className="mod-form__set camera-change">
                                                        <dl>
                                                            <dt>移動後の撮影場所</dt>
                                                            <dd>
                                                                <div className="mod-form__text camera_change_input" style={{ display: showConfirmLabels ? 'none' : 'block' }}>
                                                                    <input 
                                                                        type="text" 
                                                                        id="new_facility_name" 
                                                                        name="new_facility_name" 
                                                                        value={newFacilityName}
                                                                        onChange={handleNewFacilityNameChange}
                                                                    />
                                                                </div>
                                                                <div className="camera_change_label camera_change_label_display" style={{ display: showConfirmLabels ? 'block' : 'none' }}>
                                                                    <span id="label_facility_name">{newFacilityName}</span>
                                                                </div>
                                                            </dd>
                                                        </dl>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="mod-form__set camera-change">
                                                        <dl>
                                                            <dt>変更後の撮影物</dt>
                                                        </dl>
                                                    </div>
                                                    <div className="checkbox-frame" style={{ marginBottom: '10px' }}>
                                                        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                                            <div>
                                                                <div>
                                                                    <label>
                                                                        <input 
                                                                            type="radio" 
                                                                            id="photo_type_meter" 
                                                                            value="photo_type_meter" 
                                                                            name="photo_type" 
                                                                            className="radio-input"
                                                                            onChange={handlePhotoTypeChange}
                                                                            disabled={showConfirmLabels}
                                                                            checked={selectedPhotoType === 'photo_type_meter'}
                                                                        />
                                                                        <span className="radio-parts">　計器</span>
                                                                    </label>
                                                                </div>
                                                                <div className="mod-form__select camera_change_input" style={{display: showConfirmLabels ? 'none' : 'inline' }}>
                                                                    <select 
                                                                        id="meter_count" 
                                                                        name="meter_count" 
                                                                        disabled={isMeterCountDisabled}
                                                                        onChange={handleMeterCountChange}
                                                                        value={meterCount}
                                                                    >
                                                                        <option value="0"> </option>
                                                                        <option value="1">1</option>
                                                                        <option value="2">2</option>
                                                                        <option value="3">3</option>
                                                                        <option value="4">4</option>
                                                                        <option value="5">5</option>
                                                                    </select>
                                                                    <span style={{paddingLeft: '5px'}}>台</span>
                                                                </div>
                                                                <div 
                                                                    className="camera_change_label" 
                                                                    id="meter_count_div" 
                                                                    style={{ display: showConfirmLabels && meterCount !== "0" ? 'block' : 'none' }}
                                                                >
                                                                    <span id="meter_count_label">{meterCount}</span>
                                                                    <span>　台</span>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div>
                                                                    <label>
                                                                        <input 
                                                                            type="radio" 
                                                                            id="photo_type_view" 
                                                                            value="photo_type_view" 
                                                                            name="photo_type" 
                                                                            className="radio-input"
                                                                            onChange={handlePhotoTypeChange}
                                                                            disabled={showConfirmLabels}
                                                                        />
                                                                        <span className="radio-parts">　場所</span>
                                                                    </label>
                                                                </div>
                                                                <div className="mod-form__select" style={{ display: 'inline' }}>
                                                                    <span>（風景）</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                                    <div id="confirm_message">
                                                        <span>
                                                            上記内容で計器または場所の登録を依頼します。
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div id="camera-change-btn" className="mail-btn" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                    <div className="camera_change_input" style={{ display: showConfirmLabels ? 'none' : 'block' }}>
                                        <button 
                                            type="button" 
                                            id="btn_change_confirm" 
                                            className="mod-btn__round small" 
                                            style={{ width: '200px' }} 
                                            onClick={handleConfirmClick}
                                        >
                                            確認
                                        </button>
                                    </div>
                                    <div className="camera_change_label" id="button_div" style={{ display: showConfirmLabels ? 'block' : 'none' }}>
                                        <button 
                                            type="button" 
                                            id="btn_back" 
                                            value="" 
                                            className="mod-btn__round small" 
                                            style={{ width: '200px' }} 
                                            onClick={handleBackClick}
                                        >
                                            前の画面に戻る
                                        </button>
                                    </div>
                                    <div className="camera_change_label" id="button_div" style={{ display: showConfirmLabels ? 'block' : 'none' }}>
                                        <button 
                                            type="button" 
                                            id="btn_change_request" 
                                            value="cameraChangeMailSend" 
                                            className="mod-btn__round small" 
                                            style={{ width: '200px' }}
                                            onClick={handleSendRequest}
                                        >
                                            変更依頼する
                                        </button>
                                    </div>
                                </div>
                                <div id="finish-message" style={{display: 'none'}}>
                                    <span>
                                        連絡内容を送信しました。<br/>変更完了までに、2営業日以上かかることがあります。<br/>予めご了承ください。
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`overlay ${loading ? 'active' : ''}`}>
                <div className={`spinner ${loading ? 'active' : ''}`}></div>
            </div>
        </div>
    );
};

CameraChange.propTypes = {
    data: PropTypes.object.isRequired,
    dataCount: PropTypes.number,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    fetchCameraChange: PropTypes.func.isRequired,
    fetchGetGaugeData: PropTypes.func.isRequired,
    sendCameraChange: PropTypes.func.isRequired,
};

export default React.memo(CameraChange);
