import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MailSendSetting from '../components/MailSendSetting';
import { fetchMailSend, testMailSend, saveMailSend} from '../actions/mailsendsetting';

const mapStateToProps = (state) => {
    const { mailSendSetting } = state;
    const user = state.login.user;
    return {
        user,
        data: mailSendSetting.data, 
        loading: mailSendSetting.loading,
        error: mailSendSetting.error,
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchMailSend,
    testMailSend,
    saveMailSend
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MailSendSetting);