import ActionTypes from "../constants/ActionTypes";
import BASE_URL from "../constants/Api";
import axiosInstance from "../helpers/refreshTokenMiddleware";

export const fetchConfirmCameraCountRequest = () => ({
    type: ActionTypes.FETCH_CONFIRM_CAMERA_COUNT_REQUEST,
});

export const fetchConfirmCameraCountSuccess = (data) => ({
    type: ActionTypes.FETCH_CONFIRM_CAMERA_COUNT_SUCCESS,
    payload: data,
});

export const fetchConfirmCameraCountFailure = (error) => ({
    type: ActionTypes.FETCH_CONFIRM_CAMERA_COUNT_FAILURE,
    payload: error,
});

// Thunk to fetch data from API
export const fetchConfirmCameraCount = () => {
    return async (dispatch) => {
        dispatch(fetchConfirmCameraCountRequest());
        try {
            const accessToken = localStorage.getItem('accessToken');
            const response = await axiosInstance.post(
                BASE_URL + '/setting/confirm-camera-count', 
                {},
                {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                }
            );
            
            const data = response.data.respons;
            dispatch(fetchConfirmCameraCountSuccess(data)); 
            
        } catch (error) {
            dispatch(fetchConfirmCameraCountFailure(error.message));
        }
    };
};
