import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import IdPasswordChange from '../components/IdPasswordChange';
import { updateIdPassword } from '../actions/idpasswordchange';
import { fetchUserData } from '../actions/user';

const mapStateToProps = (state) => ({
    loading: state.idPassword.loading,
    error: state.idPassword.error,
    loginId: state.idPassword.loginId,
    user: state.login.user,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateIdPassword,
    fetchUserData
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(IdPasswordChange);