import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TransitionGraphList from '../components/TransitionGraphList';
import { fetchSearchlist, fetchTransitionGraphList } from '../actions/transitionGraphList';

const mapStateToProps = (state) => {
  const transitionGraphListData = state.transitionGraphList;
  console.log("transitionGraphListData", transitionGraphListData);
  const user = state.login.user;

  return {
    user,
    facilities: transitionGraphListData.facilities,
    cameras: transitionGraphListData.cameras,
    gauges: transitionGraphListData.gauges,
    measureUnit: transitionGraphListData.measureUnit,
    listData: transitionGraphListData.listData,
    isSearchListFetched: transitionGraphListData.isSearchListFetched || false,
    isCameraListFetched: transitionGraphListData.isCameraListFetched || false,
    isLoading: transitionGraphListData.isLoading || false,
  };

};
  
const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchSearchlist,
    fetchTransitionGraphList,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TransitionGraphList);
