import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import DataEdit from './DataEdit';
import DataRegister from './DataRegister'; 
import Modal from '../Modal/Modal';
import Draggable from 'react-draggable';

const DataManagePopup = ({
    item,
    relativeIndex,
    currentPage = 1,
    itemsPerPage,
    getGaugeData,
    checkboxStates,
    handleCheckboxChange,
    comments,
    fontSizes,
    handleCommentChange,
    handleFontSizeChange,
    fnEditData,
    imageListData,
    setIsPopupOpen,
    fetchImageList,
    setLoading,
    selectedItem,
    startDate,
    endDate,
    selectedFacility,
    selectedCamera,
    selectedGauge,
    setComments,
    setFontSizes,
    isSearching,
    displayedData,
    formatValue,
    formatDiffVal, 
    screenType,
    setRelativeActive
}) => {
    const [currentItem, setCurrentItem] = useState(item);
    const [currentIndex, setCurrentIndex] = useState(relativeIndex);
    const [currentGaugeDataList, setCurrentGaugeDataList] = useState(getGaugeData(item));
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [isDragging, setIsDragging] = useState(false);

    const previousItemRef = useRef(item);

    useEffect(() => {
        if (previousItemRef.current !== item) {
            setCurrentItem(item);
            setCurrentIndex(relativeIndex);
            setCurrentGaugeDataList(getGaugeData(item));
            previousItemRef.current = item;
            // setTempComments(prev => ({
            //     ...prev,
            //     [relativeIndex]: comments[relativeIndex] || item.comment || ''
            // }));
        }
    }, [item, relativeIndex, getGaugeData]);

    const [tempComments, setTempComments] = useState(comments);
    const handleTempCommentChange = (index, event) => {
        const newComment = event.target.value;
        setTempComments(prev => ({
            ...prev,
            [index]: newComment
        }));
    };

    const updateComments = (index) => {
        setComments(prev => ({
            ...prev,
            [index]: tempComments[index]
        }));
    };

    const [checkboxPopup, setCheckboxPopup] = useState(checkboxStates);
    const handlePopupCheckbox = (currentItem, checked) => {
        setCheckboxPopup(prevState => ({
            ...prevState,
            [currentItem]: checked,
        }));
    };  
    const closePopup = () => {
        if (!isDragging && !isModalOpen) {
            setIsPopupOpen(false);
        }
    };

    const handleNext = () => {
        if (currentIndex < imageListData.length - 1) {
            const nextIndex = currentIndex + 1;
            const nextItem = imageListData[nextIndex];
            
            setCurrentItem(nextItem);
            setCurrentIndex(nextIndex);
            setCurrentGaugeDataList(getGaugeData(nextItem));
        }
    };

    const handlePrev = () => {
        if (currentIndex > 0) {
            const prevIndex = currentIndex - 1;
            const prevItem = imageListData[prevIndex];
            setCurrentItem(prevItem);
            setCurrentIndex(prevIndex);
            setCurrentGaugeDataList(getGaugeData(prevItem));
        }
    };
    
    const hasRegisteredData = currentGaugeDataList.some(gauge => gauge.measureVal);

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const pageData = displayedData.slice(startIndex, endIndex);

    const totCount = pageData.length;

    //Handle drag popup
    const [justDragged, setJustDragged] = useState(false);
    const [dragStartPosition, setDragStartPosition] = useState({ x: 0, y: 0 });

    const handleStart = (e) => {
      setIsDragging(false);
      setDragStartPosition({ x: e.clientX, y: e.clientY });
    };
  
    const handleDrag = (e) => {
      const distance = Math.sqrt(
        Math.pow(e.clientX - dragStartPosition.x, 2) +
        Math.pow(e.clientY - dragStartPosition.y, 2)
      );
  
      if (distance > 5) {
        setIsDragging(true);
      }
    };

    const handleStop = () => {
        if (isDragging) {
          setJustDragged(true);
          setTimeout(() => setJustDragged(false), 200); 
        }
        setIsDragging(false);
    };
    
    const handleLinkClick = (e) => {
        if (isDragging || justDragged) {
            e.preventDefault();
        } else {
            console.log('Click without dragging');
        }
    };

    const validateInput = (inputValue) => {
        const intNumLimit = 10;
        const decNumLimit = 5;
    
        const isNumeric = /^[+-]?\d*\.?\d*$/.test(inputValue);
        const dotCount = (inputValue.match(/\./g) || []).length;
        const hyphenCount = (inputValue.match(/-/g) || []).length;
    
        if (
            !isNumeric ||
            dotCount > 1 ||
            hyphenCount > 1 ||
            (hyphenCount === 1 && inputValue.indexOf('-') !== 0)
        ) {
            setModalMessage('数値のみ入力可能です。');
            setIsModalOpen(true);
            return false;
        }
    
        const [intPart, decPart] = inputValue.split('.');
    
        if (intPart.replace('-', '').length > intNumLimit) {
            setModalMessage(`整数の数値は、${intNumLimit}桁までの入力です。`);
            setIsModalOpen(true);
            return false;
        }
    
        if (dotCount === 1 && decPart && decPart.length > decNumLimit) {
            setModalMessage(`小数の数値は、${decNumLimit}桁までの入力です。`);
            setIsModalOpen(true);
            return false;
        }
    
        return true;
    };
    
    const handleMeasureChange = (event, gauge, index, currentIndex) => {
        let inputValue = event.target.value.trim();
    
        if (inputValue === "") {
            event.target.value = ""; 
            updateDiffValue("--", currentIndex, index, "black"); 
            return;
        }

        if (!validateInput(inputValue)) {
            event.target.value = ""; 
            updateDiffValue("--", currentIndex, index, "black");
            return;
        }
    
        const decimalValue = gauge.decimal_value;
        let newMeasureVal;
    
        if (decimalValue === -1) {
            newMeasureVal = inputValue;
        } else if (decimalValue === 0) {
            newMeasureVal = Math.trunc(parseFloat(inputValue));
        } else if (decimalValue >= 1 && decimalValue <= 5) {
            const factor = Math.pow(10, decimalValue);
            newMeasureVal = Math.trunc(parseFloat(inputValue) * factor) / factor;
            newMeasureVal = newMeasureVal.toFixed(decimalValue);
        }
    
        event.target.value = newMeasureVal;
    
        if (gauge.prev_measure_val !== null && gauge.prev_measure_val !== undefined) {
            const calculatedDiff = parseFloat(newMeasureVal) - gauge.prev_measure_val;
            const roundedDiff = Math.round(calculatedDiff * Math.pow(10, 5)) / Math.pow(10, 5);
    
            let formattedDiff;
            if (calculatedDiff < 0 || isNaN(roundedDiff)) {
                formattedDiff = '--';
            } else if (roundedDiff === 0) {
                formattedDiff = '0';
            } else {
                formattedDiff = decimalValue >= 0
                    ? roundedDiff.toFixed(decimalValue)
                    : roundedDiff.toString().replace(/\.?0+$/, '');
            }
    
            const diffElement = document.getElementById(`dvpop_${currentIndex}_${index}`);
            if (diffElement) {
                diffElement.textContent = formattedDiff;
                diffElement.style.color = getValueColor(
                    gauge.diffFlg,
                    gauge.threshold_min_flg,
                    gauge.threshold_max_flg,
                    gauge.diff_threshold_min_flg,
                    gauge.diff_threshold_max_flg
                );
            }
        } else {
            updateDiffValue('--', currentIndex, index, 'black');
        }
    };
    
    const updateDiffValue = (value, currentIndex, index, color) => {
        const diffElement = document.getElementById(`dvpop_${currentIndex}_${index}`);
        if (diffElement) {
            diffElement.textContent = value;
            diffElement.style.color = color;
        }
    };
    
    const getValueColor = (diffFlg, thresholdMinFlg, thresholdMaxFlg, diffThresholdMinFlg, diffThresholdMaxFlg) => {
        const isMeasureAbnormal = thresholdMinFlg || thresholdMaxFlg;
        const isMeasureAbnormalDiff = diffThresholdMinFlg || diffThresholdMaxFlg;
        const isAbnormal = diffFlg ? isMeasureAbnormalDiff : isMeasureAbnormal;
        return isAbnormal ? "red" : "black";
    };

    return (
        <div className="popup-wrapper" id="dataManage_popup_wrapper" onClick={closePopup}>
            <Draggable
                handle=".popup"
                positionOffset={{ x: '-50%', y: '-50%' }}
                onStart={handleStart}
                onDrag={handleDrag}
                onStop={handleStop}
                cancel="textarea, input, .no-drag, button, img"
                disabled={isModalOpen}
            >
                <div className="popup" id="dataManage_popup" style={{ maxWidth: '900px'}} onClick={(e) => e.stopPropagation()}>
                    <div 
                        className={`popup-content ${screenType} ${(currentItem.status_class === 2 && currentItem.disuse_flg !== 1) ? 'lay-list__horizontal-error' : ''}`} 
                        style={{
                            background: (currentItem.status_class === 2 && currentItem.disuse_flg !== 1) ? '' : 'white'
                        }}
                    >
                        <div id="success-message"></div>
                        {currentIndex > 0 && (
                            <button className="prev" onClick={handlePrev} onTouchStart={handlePrev} aria-label="Previous">❮</button>
                        )}
                        {currentIndex < imageListData.length - 1 && (
                            <button className="next" onClick={handleNext} onTouchStart={handleNext} aria-label="Next">❯</button>
                        )}
                        <form id="dataformpopup0" onSubmit={(e) => e.preventDefault()} key={currentIndex}>
                            <div style={{ float: 'left' }}>
                                <div className="lay-list__horizontal__date date_popup">
                                    <div style={{ width: '75%', paddingLeft: '60px' }}>
                                        {currentItem.disp_datetime}<br />
                                        {currentItem.display_camera_name}<br />
                                        {currentItem.display_gauge_name}
                                    </div>
                                    <div className="lay-list__popup__checkbox" style={{ alignSelf: 'flex-end' }}>
                                        <label>
                                            <input 
                                                type="checkbox" 
                                                name="disuse_flg" 
                                                className="checkbox-input"
                                                checked={checkboxPopup[currentIndex] !== undefined ? checkboxPopup[currentIndex] : currentItem.disuse_flg === 1}
                                                onChange={(e) => handlePopupCheckbox(currentIndex, e.target.checked)}
                                            />
                                            <span className="checkbox-parts"></span>
                                            <span style={{wordBreak: 'keep-all'}}>不使用</span>
                                        </label>
                                    </div>
                                </div>

                                <div className="lay-list__horizontal__pct">
                                    <a href={currentItem.image_url} target="_blank" rel="noopener noreferrer" onClick={handleLinkClick}>
                                        <img src={currentItem.image_url} alt="" style={{ width: '400px', height: 'auto' }} onMouseDown={(e) => e.preventDefault()}/>
                                    </a>
                                </div>
                            </div>

                            <div style={{ width: '300px', float: 'left', marginLeft: '20px', marginTop: '25px' }}>
                                <div className="lay-list__horizontal__para">
                                    {currentGaugeDataList.map((gauge, index) => (
                                        <React.Fragment key={index}>
                                            {gauge.unit && (
                                                <>
                                                    {`計測データ${index + 1}`}
                                                    <div className="mod-form__num">
                                                        <input 
                                                            type="text" 
                                                            id={`mv_${currentIndex}_${index}`} 
                                                            name={`measure_val${index}`} 
                                                            defaultValue={isNaN(gauge.measureVal) ? '' : gauge.measureVal}
                                                            inputMode="decimal"
                                                            pattern="[0-9]*\.?[0-9]+" 
                                                            onKeyDown={(e) => {
                                                                if (
                                                                    !(
                                                                        (e.key >= '0' && e.key <= '9') ||
                                                                        e.key === '.' ||
                                                                        e.key === '-' ||
                                                                        e.key === 'Backspace' ||
                                                                        e.key === 'Tab' ||
                                                                        e.key === 'Delete' ||
                                                                        e.key === 'ArrowLeft' ||
                                                                        e.key === 'ArrowRight'
                                                                    )
                                                                ) {
                                                                    if (e.key === 'Enter') {
                                                                        e.preventDefault(); 
                                                                        handleMeasureChange(e, gauge, index, currentIndex); 
                                                                    } else {
                                                                        e.preventDefault();
                                                                    }
                                                                }
                                                            }}
                                                            onBlur={(e) => handleMeasureChange(e, gauge, index, currentIndex)} 
                                                        />
                                                        <span className="mod-form__num-unit">
                                                            {gauge.unit}
                                                        </span>
                                                        <input type="hidden" name={`diff_flg${index}`} value={gauge.diffFlg || ''} />
                                                        <input type="hidden" name={`measure_id${index}`} value={gauge.measure_id || ''} />
                                                        <input type="hidden" name={`decimal_value${index}`} value={gauge.decimal_value || ''} />
                                                        <input type="hidden" name={`min_limit${index}`} value={gauge.min_limit || ''} />
                                                        <input type="hidden" name={`min_limit_flg${index}`} value={gauge.min_limit_flg || ''} />
                                                        <input type="hidden" name={`max_limit${index}`} value={gauge.max_limit || ''} />
                                                        <input type="hidden" name={`max_limit_flg${index}`} value={gauge.max_limit_flg || ''} />
                                                        <input type="hidden" name={`diff_type${index}`} value={gauge.diff_type || ''} />
                                                        <input type="hidden" name={`diff_val${index}`} id={`newdvpop_0_${index}`} value={gauge.diff_val || ''} />
                                                        <input type="hidden" name={`diff_min_limit${index}`} value={gauge.diff_min_limit || ''} />
                                                        <input type="hidden" name={`diff_min_limit_flg${index}`} value={gauge.diff_min_limit_flg || ''} />
                                                        <input type="hidden" name={`diff_max_limit${index}`} value={gauge.diff_max_limit || ''} />
                                                        <input type="hidden" name={`diff_max_limit_flg${index}`} value={gauge.diff_max_limit_flg || ''} />
                                                    </div>
                                                    <div className="lay-list__horizontal__para__num diff" style={{
                                                        display: currentItem.disuse_flg === 0 && gauge.diffFlg === 1 ? 'block' : 'none',
                                                        color: getValueColor(
                                                            gauge.diffFlg,
                                                            gauge.threshold_min_flg,
                                                            gauge.threshold_max_flg,
                                                            gauge.diff_threshold_min_flg,
                                                            gauge.diff_threshold_max_flg
                                                        )
                                                    }}>
                                                        <span className="lay-list__horizontal__para__text">差分値：</span>
                                                        <span id={`dvpop_${currentIndex}_${index}`}>{formatDiffVal(gauge.diffVal, gauge.decimal_value) || gauge.displayDiffVal || '--'}</span>
                                                        <span className="lay-list__horizontal__para__num-unit">{gauge.unit}</span>
                                                    </div>
                                                </>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </div>

                                <div className="mod-form__textarea">
                                    <label>コメント</label>
                                    <textarea 
                                        name="comment" 
                                        style={{ fontSize: `${fontSizes[currentIndex] || currentItem.comment_font_size}pt` }}
                                        value={tempComments[currentIndex] !== undefined ? tempComments[currentIndex] : currentItem.comment || ''} 
                                        onChange={(e) => handleTempCommentChange(currentIndex, e)}
                                    />
                                </div>

                                <div className="lay-list__horizontal__btn">
                                    <button 
                                        type="button" 
                                        className="mod-btn__roundborder small" 
                                        style={{width: '45%', float: 'left'}}
                                        onClick={closePopup}>
                                        キャンセル
                                    </button>

                                    {(hasRegisteredData || currentItem.status_class === 1) ? (
                                        <DataEdit
                                            relativeIndex={relativeIndex}
                                            fetchImageList={fetchImageList}
                                            selectedItem={item}
                                            startDate={startDate}
                                            endDate={endDate}
                                            selectedFacility={selectedFacility}
                                            selectedCamera={selectedCamera}
                                            selectedGauge={selectedGauge}
                                            comments={comments}
                                            fontSizes={fontSizes}
                                            setComments={setComments}
                                            setFontSizes={setFontSizes}
                                            setLoading={setLoading}
                                            isPopup={true}
                                            setIsEditModalOpen={setIsModalOpen}
                                            setModalMessage={setModalMessage} 
                                            updateComments={updateComments}
                                            setRelativeActive={setRelativeActive}
                                        />
                                    ) : (
                                        <DataRegister
                                            relativeIndex={currentIndex}
                                            fetchImageList={fetchImageList}
                                            selectedItem={selectedItem}
                                            startDate={startDate}
                                            endDate={endDate}
                                            selectedFacility={selectedFacility}
                                            selectedCamera={selectedCamera}
                                            selectedGauge={selectedGauge}
                                            comments={comments}
                                            setComments={setComments}
                                            setLoading={setLoading}
                                            isPopup={true}
                                            setIsRegisterModalOpen={setIsModalOpen}
                                            setModalMessage={setModalMessage}
                                            updateComments={updateComments}
                                            setRelativeActive={setRelativeActive}
                                        />
                                    )}
                                    {isModalOpen && (
                                        <Modal
                                            isOpen={isModalOpen}
                                            onClose={() => {
                                                setIsModalOpen(false);
                                            }}
                                            message={modalMessage}
                                            showCancelButton={false}
                                        />
                                    )}
                                </div>
                            </div>
                            <input type="hidden" name="company_id" value={currentItem.company_id || ''} />
                            <input type="hidden" name="gauge_id" value={currentItem.gauge_id || ''} />
                            <input type="hidden" name="device_id" value={currentItem.device_id || ''} />
                            <input type="hidden" name="get_datetime" value={currentItem.get_datetime || ''} />
                            <input type="hidden" name="this_count" value={currentIndex || ''} />
                            <input type="hidden" name="tot_count" value={totCount || ''} />
                            <input type="hidden" name="page_cnt" value={currentPage || ''} />
                            <input type="hidden" name="cnt" value={currentGaugeDataList.length || ''} />
                        </form>
                    </div>
                </div>
            </Draggable>
        </div>
    );
};

DataManagePopup.propTypes = {
    item: PropTypes.object.isRequired,
    relativeIndex: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    itemsPerPage: PropTypes.number.isRequired,
    getGaugeData: PropTypes.func.isRequired,
    displayedData: PropTypes.array.isRequired,
    checkboxStates: PropTypes.object.isRequired,
    handleCheckboxChange: PropTypes.func.isRequired,
    comments: PropTypes.object.isRequired,
    fontSizes: PropTypes.object.isRequired,
    handleCommentChange: PropTypes.func.isRequired,
    handleFontSizeChange: PropTypes.func.isRequired,
    fnEditData: PropTypes.func.isRequired,
    imageListData: PropTypes.array.isRequired,
    setIsPopupOpen: PropTypes.func.isRequired,
    fetchImageList: PropTypes.func.isRequired,
    setLoading: PropTypes.func.isRequired,
    selectedItem: PropTypes.object.isRequired,
    startDate: PropTypes.instanceOf(Date).isRequired,
    endDate: PropTypes.instanceOf(Date).isRequired,
    selectedFacility: PropTypes.string.isRequired,
    selectedCamera: PropTypes.string.isRequired,
    selectedGauge: PropTypes.string.isRequired,
    setComments: PropTypes.func.isRequired,
    setFontSizes: PropTypes.func.isRequired,
};

export default DataManagePopup;
