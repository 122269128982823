import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Modal from '../Modal/Modal';
import './IdPasswordChange.css';
import Breadcrumb from '../Breadcrumb';

const IdPasswordChange = ({error, updateIdPassword, fetchUserData, loginId, user }) => {
    const navigate = useNavigate ();
    const location = useLocation ();
    const [newLoginId, setNewLoginId] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [errorMessages, setErrorMessages] = useState({}); // To store errors for each field
    const [showUserName, setUserName] = useState('');
    const [loading, setLoading] = useState(false);
    const screenType = useSelector((state) => state.device.screenType);

    // Fetch user data when the component mounts
    useEffect(() => {
        const loadData = async () => {
            setLoading(true);
            await fetchUserData(); 
            setLoading(false);
        };
        loadData();
        setErrorMessages('');
    }, [fetchUserData, location]);

    // Set newLoginId based on loginId from props
    useEffect(() => {
        if (loginId) {
            setNewLoginId(loginId);
        }
    }, [loginId, location]);

    useEffect(() => {
        if (user) {
          setUserName(user.user_name);
        };
    }, [user]);

    const validateInput = () => {
        const errors = {};
        const minLength = 8;
        const validLoginIdRegex = /^[0-9a-z_.-]+$/;
        const normalizedLoginId = newLoginId.toLowerCase();

        if (!newLoginId.trim()) {
            errors.newLoginId = "入力をしてください。";
        } else if (!/^[\x21-\x7E]+$/.test(newLoginId)) {
            errors.newLoginId = "半角英数字記号で入力してください。";
        } else if (newLoginId.length < minLength || newLoginId.length > 16) {
            errors.newLoginId = "8桁～16桁で入力してください。";
        } else if (!validLoginIdRegex.test(normalizedLoginId)) {
            errors.newLoginId = "アルファベット (a-z)、数字 (0-9)、ハイフン (-)、アンダースコア (_)、およびピリオド (.) のみが使用できます。";
        }

        if (!currentPassword.trim()) {
            errors.currentPassword = "入力をしてください。";
        } else if (!/^[\x21-\x7E]+$/.test(currentPassword)) {
            errors.currentPassword = "半角英数字記号で入力してください。";
        }

        if (!newPassword.trim()) {
            errors.newPassword = "入力をしてください。";
        } else if (!/^[\x21-\x7E]+$/.test(newPassword)) {
            errors.newPassword = "半角英数字記号で入力してください。";
        } else if (newPassword.length < minLength || newPassword.length > 32) {
            errors.newPassword = "8桁～32桁で入力してください。";
        } else if (currentPassword === newPassword) {
            errors.newPassword = "現在のパスワードと新しいパスワードが同じです。";
        }

        if (!confirmPassword.trim()) {
            errors.confirmPassword = "入力をしてください。";
        } else if (!/^[\x21-\x7E]+$/.test(confirmPassword)) {
            errors.confirmPassword = "半角英数字記号で入力してください。";
        } else if (confirmPassword.length < minLength || confirmPassword.length > 32) {
            errors.confirmPassword = "8桁～32桁で入力してください。";
        } else if (newPassword !== confirmPassword) {
            errors.confirmPassword = "新しいパスワードの入力と一致していません。";
        }

        setErrorMessages(errors);
        return Object.keys(errors).length === 0;
    };

    const handleUpdateIdPassword = async () => {
        if (!validateInput()) {
            return;
        }

        try {
            setLoading(true);
            const response = await updateIdPassword(currentPassword, newPassword, confirmPassword, newLoginId);
            // Call fetchUserData again to refresh user information
            fetchUserData();
            const message = response.data?.respons?.msg || "ID/PW updated successfully!";
            setModalMessage(message);
            setIsModalOpen(true);

            // Clear input fields after a successful update
            setCurrentPassword('');
            setNewPassword('');
            setConfirmPassword('');
            setErrorMessages('');
        } catch (error) {
            setModalMessage(error.message || "Failed to update ID/PW.");
            setIsModalOpen(true);
        } finally {
            setLoading(false);
        }
    };

    const handleTopClick = () => {
        navigate('/');
    };

    return (
        <div id='Main' className={`main ${screenType}`}>
            <div id='Contents'>
                <Breadcrumb
                    userName = {showUserName}
                    pageName = "ユーザ情報"
                    onTopClick = {handleTopClick}
                />
                {isModalOpen && (
                    <Modal
                        isOpen={isModalOpen}
                        onClose={() => setIsModalOpen(false)}
                        message={modalMessage}
                    />
                )}
                <div id="ajax-view-disp">
                    <div className="display-main">
                        <div className={`lay-master-set ${screenType}`}>
                            <div className="lay-master-set-wrap">
                                <div className="lay-master-set__title">
                                    <span>ID/PWの変更</span>
                                </div>
                                <div className="lay-master-set__form">
                                    <form id="userData">
                                        <div className="wrapper">
                                            <div className="user_input">
                                                <div>
                                                    <span id="current_login_id">現在のログインID : {loginId}</span>
                                                </div>
                                            </div>
                                            <div className="user_input">
                                                <div>
                                                    <span>新しいログインID : </span>
                                                </div>
                                                <div>
                                                    <input
                                                        type="text"
                                                        id="login_id"
                                                        name="login_id"
                                                        value={newLoginId}
                                                        onChange={(e) => setNewLoginId(e.target.value.toLowerCase())}
                                                        minLength="8"
                                                        maxLength="16"
                                                    />
                                                    <div className="error_msg" style={{width: '80%'}}>
                                                        {errorMessages.newLoginId && <span style={{ color: 'red' }}>{errorMessages.newLoginId}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="user_input" style={{ marginTop: '50px' }}>
                                                <div>
                                                    <span>現在のパスワード : </span>
                                                </div>
                                                <div>
                                                    <input
                                                        type="password"
                                                        id="current_password"
                                                        name="current_password"
                                                        value={currentPassword}
                                                        onChange={(e) => setCurrentPassword(e.target.value)}
                                                        maxLength="32"
                                                    />
                                                    <div className="error_msg">
                                                        {errorMessages.currentPassword && <span style={{ color: 'red' }}>{errorMessages.currentPassword}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="user_input">
                                                <div>
                                                    <span>新しいパスワード : </span>
                                                </div>
                                                <div>
                                                    <input
                                                        type="password"
                                                        id="new_password"
                                                        name="new_password"
                                                        value={newPassword}
                                                        onChange={(e) => setNewPassword(e.target.value)}
                                                        maxLength="32"
                                                    />
                                                    <div className="error_msg">
                                                        {errorMessages.newPassword && <span style={{ color: 'red' }}>{errorMessages.newPassword}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="user_input" style={{ marginBottom: '20px' }}>
                                                <div>
                                                    <span>新しいパスワード(確認) : </span>
                                                </div>
                                                <div>
                                                    <input
                                                        type="password"
                                                        id="confirm_password"
                                                        name="confirm_password"
                                                        value={confirmPassword}
                                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                                        maxLength="32"
                                                    />
                                                    <div className="error_msg">
                                                        {errorMessages.confirmPassword && <span style={{ color: 'red' }}>{errorMessages.confirmPassword}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="user-btn">
                                    <button type="button" className="master_long_btn" onClick={handleUpdateIdPassword}>
                                        登録
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`overlay ${loading ? 'active' : ''}`}>
                <div className={`spinner ${loading ? 'active' : ''}`}></div>
            </div>
        </div>
    );
};

IdPasswordChange.propTypes = {
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    updateIdPassword: PropTypes.func.isRequired,
    fetchUserData: PropTypes.func.isRequired,
    loginId: PropTypes.string,
};

export default React.memo(IdPasswordChange);
