import ActionTypes from '../constants/ActionTypes'; 

const initialState = {
    cameraList: [],
    loading: false,
    error: null
};

const cameraReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.REGISTER_CAMERA_REQUEST:
            return { ...state, loading: true, error: null };
        case ActionTypes.REGISTER_CAMERA_SUCCESS:
            return { ...state, loading: false, message: action.payload };
        case ActionTypes.REGISTER_CAMERA_FAILURE:
            return { ...state, loading: false, error: action.payload };

        case ActionTypes.FETCH_CAMERA_LIST_REQUEST:
            return { ...state, loading: true, error: null };
        case ActionTypes.FETCH_CAMERA_LIST_SUCCESS:
            return { ...state, loading: false, cameraList: action.payload };
        case ActionTypes.FETCH_CAMERA_LIST_FAILURE:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};

export default cameraReducer;
