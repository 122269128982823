import ActionTypes from "../constants/ActionTypes";
import BASE_URL from "../constants/Api";
import axiosInstance from "../helpers/refreshTokenMiddleware";

export const fetchCompaniesRequest = () => ({
    type: ActionTypes.FETCH_COMPANIES_REQUEST,
});

export const fetchCompaniesSuccess = (companies) => ({
    type: ActionTypes.FETCH_COMPANIES_SUCCESS,
    payload: companies,
});

export const fetchCompaniesFailure = (error) => ({
    type: ActionTypes.FETCH_COMPANIES_FAILURE,
    payload: error,
});

// Thunk to fetch data from API
export const fetchCompanies = () => {
    return async (dispatch) => {
        dispatch(fetchCompaniesRequest());
        try {
            const accessToken = localStorage.getItem('accessToken');
            const response = await axiosInstance.get(BASE_URL + '/top/companies-list', {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            // Check response here
            const data = response.data.respons || [];
            // Ensure response.data contains the array of companies
            console.log('API Response:', data);
            dispatch(fetchCompaniesSuccess(data)); // Send data to reducer
            
        } catch (error) {
            dispatch(fetchCompaniesFailure(error.message));
        }
    };
};

// Action for fetching management unit data
export const fetchManagementUnitRequest = () => ({
    type: ActionTypes.FETCH_MANAGEMENT_UNIT_REQUEST,
});

export const fetchManagementUnitSuccess = (companies) => ({
    type: ActionTypes.FETCH_MANAGEMENT_UNIT_SUCCESS,
    payload: companies,
});

export const fetchManagementUnitFailure = (error) => ({
    type: ActionTypes.FETCH_MANAGEMENT_UNIT_FAILURE,
    payload: error,
});

// Thunk action to fetch management unit data
export const fetchManagementUnitData = () => {
    return async (dispatch) => {
        dispatch(fetchManagementUnitRequest());
        try {
            const accessToken = localStorage.getItem('accessToken');
            const response = await axiosInstance.get(BASE_URL + '/top/get-management-unit-data', {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            const data = response.data.respons || [];
            dispatch(fetchManagementUnitSuccess(data));
        } catch (error) {
            dispatch(fetchManagementUnitFailure(error.message));
        }
    };
};
export const registerCompanyRequest = () => ({
    type: ActionTypes.REGISTER_COMPANY_REQUEST,
});

export const registerCompanySuccess = (message) => ({
    type: ActionTypes.REGISTER_COMPANY_SUCCESS,
    payload: message,
});

export const registerCompanyFailure = (error) => ({
    type: ActionTypes.REGISTER_COMPANY_FAILURE,
    payload: error,
});

export const replaceCompaniesRequest = () => ({
    type: ActionTypes.REPLACE_COMPANY_REQUEST,
});

export const replaceCompaniesSuccess = (message) => ({
    type: ActionTypes.REPLACE_COMPANY_SUCCESS,
    payload: message,
});

export const replaceCompaniesFailure = (error) => ({
    type: ActionTypes.REPLACE_COMPANY_FAILURE,
    payload: error,
});

export const registerCompany = (adminId, companyId, companyCode, companyName) => {
    return async (dispatch) => {
        dispatch(registerCompanyRequest());
        try {
            const accessToken = localStorage.getItem('accessToken');
            const response = await axiosInstance.post(BASE_URL + '/top/edit-company-data', {
                admin_id: adminId,
                company_id: companyId,
                company_code: companyCode,
                company_name: companyName
            }, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            dispatch(registerCompanySuccess(response.data.respons.msg));
            return response;
        } catch (error) {
            dispatch(registerCompanyFailure(error.message));
        }
    };
};

export const replaceCompanies = (companyIds) => {
    return async (dispatch) => {
        dispatch(replaceCompaniesRequest());
        try {
            const accessToken = localStorage.getItem('accessToken');
            const body = {
                company_ids: companyIds
            };

            const response = await axiosInstance.post(BASE_URL + '/top/replace-company-data', body, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            dispatch(replaceCompaniesSuccess(response.data.respons.msg));
            return response;
        } catch (error) {
            dispatch(replaceCompaniesFailure(error.message));
        }
    };
};
