import React, { useState } from 'react';
import JSZip from 'jszip';
import saveAs from 'save-as';
import { format } from 'date-fns';
import axios from "axios";
import BASE_URL from "../../constants/Api";
// import MessageBox from './MessageBox';
import ConfirmPopup from './ConfirmPopup';
import Modal from '../Modal/Modal';

const PhotoDownload = ({ getSearchConditions, setIsDownloading}) => {
    const DOWNLOAD_LIMIT = 500;
    const MASK_MSG_CLOSE = 0
    const MASK_MSG_OPEN_500ITEMS_OV = 1
    const MASK_MSG_OPEN_0ITEMS = 2
    const MASK_MSG_OPEN_NOPC = 4
    const MASK_YESNO_BTN = 0
    const MASK_OK_BTN = 1
    const [msgOpen, setMsgOpen] = useState(MASK_MSG_CLOSE);

    let zip = null; // instance of JSZip
    const processDataInBatches = async (data, batchSize) => {
        const totalBatches = Math.ceil(data.length / batchSize);
        const tasks = [];
        
        for (let i = 0; i < totalBatches; i++) {
            const batch = data.slice(i * batchSize, (i + 1) * batchSize);
            const task = processBatch(batch); // バッチずつを処理
            tasks.push(task);
        }
        
        await Promise.all(tasks); // 全てのタスクを実行
    }

    const processBatch = async (images) => {
        // Add Images to the zip file
        for (var i = 0; i < images.length; i++) {
            for (let index = 0; index < 3; index++) {
                try {
                    let response = null;
                    if (index === 0) {
                        response = await fetch(images[i].presigned_image_url);
                    } else if (index === 1) {
                        response = await fetch(images[i].image_url.replace("https://", "http://"));
                    } else {
                        response = await fetch(images[i].image_url);
                    }
                    const blob = await response.blob();
                    // console.log(blob);
                    zip.file(images[i].filename, blob);
                    break;
                } catch (error) {
                    console.error(error);
                }
            }
        }
    }

    function fetchAllImages() {
        const baseUrl = BASE_URL + '/top/photo-download';
        let accessToken = localStorage.getItem('accessToken');
        const fetchPage = async (page, images = []) => {
            const {
                companyId,
                startDate,
                endDate,
                facilityId,
                deviceId,
                gaugeId,
                failureId,
                status,
                searchComment,
                threshold
            } = getSearchConditions();

            const requestBody = {
                company_id: Number(companyId),
                failure_id: failureId,
                start_date: startDate,
                end_date: endDate,
                page_group_number: page
            };
            const statusIndex = parseInt(status, 10);
            const searchCommentIndex = parseInt(searchComment, 10);
            const thresholdIndex = parseInt(threshold, 10);

            if (facilityId) requestBody.facility_id = Number(facilityId);
            if (deviceId) requestBody.device_id = deviceId;
            if (gaugeId) requestBody.gauge_id = gaugeId;
            if (isNaN(statusIndex) === false && status !== '') requestBody.status = statusIndex;
            if (isNaN(searchCommentIndex) === false && searchComment !== '') requestBody.search_comment = searchCommentIndex;
            if (isNaN(thresholdIndex) === false && threshold !== '') requestBody.threshold = thresholdIndex;
            
            const config = {
                headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
                }
            };
        
            try {
                // debugger
                const response = await axios.post(baseUrl, requestBody, config);
                const ret = response.data.respons.images;
        
                if ( (ret.length === 0) || (images.length + ret.length >= DOWNLOAD_LIMIT) ) {
                    const results = images.concat(ret); // return images;
                    if (results.length > DOWNLOAD_LIMIT) {
                        return results.slice(0, DOWNLOAD_LIMIT);
                    }
                    return results;
                } else {
                    return fetchPage(page + 1, images.concat(ret));
                }
            } catch (error) {
                console.error('Error fetching images:', error);
                return images;
            }
        };
    
        return fetchPage(1); // １ページから開始
    }

    const fetchImages = async () => {
        zip = new JSZip();
        setMsgOpen(MASK_MSG_CLOSE);
        setIsDownloading(true);
        fetchAllImages().then( async images => {
            if (images.length === 0) {
                setMsgOpen(MASK_MSG_OPEN_0ITEMS);
                setIsDownloading(false);
                return;
            }

            // バッチサイズ
            const batchSize = Math.ceil(images.length / 50);
            processDataInBatches(images, batchSize).then(async () => {
                console.log('All batches processed');
                // Generate the zip file
                zip.generateAsync({type: "blob"}).then(function(content) {
                    saveAs(content, format(new Date(), 'yyyyMMddhhmmss')+'.zip');
                    zip = null;
                    setIsDownloading(false);
                });
            });
        });
    }

    const handleDownload = async () => {
        const {searchCount, deviceType} = getSearchConditions();
        if (deviceType !== 'desktop') {
            setMsgOpen(MASK_MSG_OPEN_NOPC);
            return;
        }
        if (searchCount > 0) {
            if (searchCount > DOWNLOAD_LIMIT) {
                setMsgOpen(MASK_MSG_OPEN_500ITEMS_OV);
            } else {
                fetchImages();
            }
        } else {
            setMsgOpen(MASK_MSG_OPEN_0ITEMS);
        }
    }

    return (
        <>
            <label className="mod-btn__round" id="photo_btn" onClick={handleDownload}>撮影データダウンロード</label>
            {/* <MessageBox
                title="撮影データダウンロード"
                message={`検索結果が${DOWNLOAD_LIMIT}件を超えています。<br>最新の${DOWNLOAD_LIMIT}件の画像データをダウンロードします。`}
                isOpen={(msgOpen & MASK_MSG_OPEN_500ITEMS_OV) === MASK_MSG_OPEN_500ITEMS_OV}
                onOK={fetchImages}
            />
            <MessageBox
                title="撮影データダウンロード"
                message="データ検索をしてください。"
                isOpen={(msgOpen & MASK_MSG_OPEN_0ITEMS) === MASK_MSG_OPEN_0ITEMS}
                onOK={()=> {setMsgOpen(MASK_MSG_CLOSE);}}
            /> */}
            {((msgOpen & MASK_MSG_OPEN_500ITEMS_OV) === MASK_MSG_OPEN_500ITEMS_OV) && (
                <ConfirmPopup   
                    handlePhotoShoot={fetchImages}                                     
                    onClose={() => setMsgOpen(MASK_MSG_CLOSE)}   
                    message={`検索結果が${DOWNLOAD_LIMIT}件を超えています。<br>最新の${DOWNLOAD_LIMIT}件の画像データをダウンロードします。`}
                    confirmButtons={MASK_YESNO_BTN}
                />
            )}
            {((msgOpen & MASK_MSG_OPEN_0ITEMS) === MASK_MSG_OPEN_0ITEMS) && (
                <ConfirmPopup   
                    handlePhotoShoot={() => setMsgOpen(MASK_MSG_CLOSE)}
                    onClose={() => setMsgOpen(MASK_MSG_CLOSE)}
                    message="データ検索をしてください。"
                    confirmButtons={MASK_OK_BTN}
                />
            )}
            {/* {((msgOpen & MASK_MSG_OPEN_NOPC) === MASK_MSG_OPEN_NOPC) && (
                <ConfirmPopup   
                    handlePhotoShoot={() => setMsgOpen(MASK_MSG_CLOSE)}
                    onClose={() => setMsgOpen(MASK_MSG_CLOSE)}
                    message="撮影データダウンロードはPCからご利用ください。"
                    confirmButtons={MASK_OK_BTN}
                />
            )} */}
            <Modal 
                isOpen={((msgOpen & MASK_MSG_OPEN_NOPC) === MASK_MSG_OPEN_NOPC)} 
                onClose={() => setMsgOpen(MASK_MSG_CLOSE)} 
                message="撮影データダウンロードはPCからご利用ください。" 
            />
        </>
    );
}

export default React.memo(PhotoDownload);