import ActionTypes from "../constants/ActionTypes";
import BASE_URL from "../constants/Api";
import axiosInstance from "../helpers/refreshTokenMiddleware";

export const fetchFacilityEditRequest = () => ({
    type: ActionTypes.FACILITY_EDIT_REQUEST,
});

export const fetchFacilityEditSuccess = (devices) => ({
    type: ActionTypes.FACILITY_EDIT_SUCCESS,
    payload: devices,
});

export const fetchFacilityEditFailure = (error) => ({
    type: ActionTypes.FACILITY_EDIT_FAILURE,
    payload: error,
});

// Thunk to fetch data from API  
export const fetchFacilityEdit = (company_id, facility_id) => {
    return async (dispatch) => {
        dispatch(fetchFacilityEditRequest());
        try {
            const accessToken = localStorage.getItem('accessToken');
            const response = await axiosInstance.post(BASE_URL + '/top/facility-edit', {
                company_id: parseInt(company_id, 10), 
                facility_id: facility_id
            }, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            // Check response here
            const data = response.data.respons || {};
            dispatch(fetchFacilityEditSuccess(data));

        } catch (error) {
            dispatch(fetchFacilityEditFailure(error.message));
        }
    };
};

// Actions creators for editing facility
export const updateFacilityRequest = () => ({
    type: ActionTypes.FACILITY_UPDATE_REQUEST,
});

export const updateFacilitySuccess = (message) => ({
    type: ActionTypes.FACILITY_UPDATE_SUCCESS,
    payload: message,
});

export const updateFacilityFailure = (error) => ({
    type: ActionTypes.FACILITY_UPDATE_FAILURE,
    payload: error,
});

// Thunk action to send updated facility data
export const updateFacility = (facility_name, company_id, facility_id, deviceIds, displayNameIds, is_delete = undefined) => {
    return async (dispatch) => {
        dispatch(updateFacilityRequest());
        try {
            const accessToken = localStorage.getItem('accessToken');
            const body = {
                company_id: company_id,
                facility_id: facility_id,
                facility_name: facility_name,
                device_id: deviceIds,           
                display_name_id: displayNameIds
            };

            if (is_delete) {
                body.is_delete = is_delete; 
            }

            const response = await axiosInstance.post(BASE_URL + '/top/edit-facility-data', body, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            dispatch(updateFacilitySuccess(response.data.respons.msg));
            return response;
        } catch (error) {
            dispatch(updateFacilityFailure(error.message));
        }
    };
};

