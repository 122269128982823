import React, { useRef, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";

const ImageSlider = ({ images, mainImage, onImageClick, setCurrentPage, getPageFromMainImage, currentPage, isSliderAction, setSliderAction, screenType }) => {
  const swiperRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [isDragging, setIsDragging] = useState(false); // Flag to differentiate between drag and click

  useEffect(() => {
    if (swiperRef.current && mainImage && !isDragging) {
      const mainImageIndex = images.findIndex((image) => image.url === mainImage.image_url);

      if (mainImageIndex !== -1 && mainImageIndex !== currentIndex) {
        swiperRef.current.slideTo(mainImageIndex, 0); // Update to mainImage
        setCurrentIndex(mainImageIndex); 
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainImage, images, currentIndex]);
  
  const handleImageClick = (image) => {
    if (!isDragging) {
      console.log("Image clicked:", image);
      onImageClick(image); // Call the click handler only if not dragging
    }
  };

  const handleSliderMove = (swiper) => {
    setIsDragging(true);
    const progress = swiper.progress; // Swiper progress from 0 to 1
    const totalSlides = images.length; // Total number of slides
    const centerIndex = Math.round(progress * (totalSlides - 1)); // Calculate the center index

    if (
      images[centerIndex] &&
      (!mainImage || images[centerIndex].url !== mainImage.image_url)
    ) {
      isSliderAction.current = true;

      onImageClick(images[centerIndex]); // Update the main image in real time
      setCurrentIndex(centerIndex); // Update the current index state
      const page = getPageFromMainImage(images[centerIndex].info);
        if (page !== currentPage) {
            setCurrentPage(page); 
        }
    }
  };

  const resetHoverState = () => {
    const divElements = document.querySelectorAll(".pager .pagination li div");
    divElements.forEach((div) => {
      const parent = div.parentNode;
      const sibling = div.nextSibling;
  
      parent.removeChild(div);
  
      if (sibling) {
        parent.insertBefore(div, sibling);
      } else {
        parent.appendChild(div);
      }
    });
  };

  const handleSliderStop = () => {
    setIsDragging(false); 
    isSliderAction.current = false;
    resetHoverState(); 
  };

  return (
    <div className={`image-slider ${screenType}`}>
      {images && images.length > 0 ? (
        <Swiper
          modules={[Navigation]}
          spaceBetween={3}
          slidesPerView={25}
          slidesPerGroup={1}
          loop={false}
          threshold={20}
          centeredSlides={true} 
          preventClicks={false}
          preventClicksPropagation={false}
          resistanceRatio={1}
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
          onSliderMove={(swiper) => handleSliderMove(swiper)}
          onTouchStart={() => setIsDragging(false)} 
          onTouchMove={() => setIsDragging(true)} 
          onTouchEnd={() => setIsDragging(false)}
          onTransitionEnd={() => handleSliderStop()} 
        >
          {images.map((image, index) => (
            <SwiperSlide key={index}>
              <div
                className={`slider-image-wrapper ${screenType} ${
                  mainImage && mainImage.image_url === image.url ? "active" : ""
                }`}
                onClick={(e) => {
                  e.stopPropagation(); 
                  handleImageClick(image); 
                }}
              >
                <img
                  src={image.url}
                  alt={`Thumbnail ${index + 1}`}
                  style={{
                    width: "100%",
                    height: "28px",
                  }}
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <p style={{ textAlign: "center", color: "#888" }}>
          No images available
        </p>
      )}
    </div>
  );
};

export default ImageSlider;
