import ActionTypes from '../constants/ActionTypes'; 

const initialState = {
    loading: false,
    data: {},
    error: null,
};

const mailSendReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.GET_MAIL_SEND_SETTING_REQUEST:
            return { ...state, loading: true };
        case ActionTypes.GET_MAIL_SEND_SETTING_SUCCESS:
            return { ...state, loading: false, data: action.payload};
        case ActionTypes.GET_MAIL_SEND_SETTING_FAILURE:
            return { ...state, loading: false, error: action.payload };
        case ActionTypes.TEST_MAIL_SEND_REQUEST:
            return { ...state, loading: true };
        case ActionTypes.TEST_MAIL_SEND_SUCCESS:
            return { ...state, loading: false};  
        case ActionTypes.TEST_MAIL_SEND_FAILURE:
            return { ...state, loading: false, error: action.payload };
        case ActionTypes.SAVE_MAIL_SEND_REQUEST:
            return { ...state, loading: true };
        case ActionTypes.SAVE_MAIL_SEND_SUCCESS:
            return { ...state, loading: false};  
        case ActionTypes.SAVE_MAIL_SEND_FAILURE:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};

export default mailSendReducer;
