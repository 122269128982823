import ActionTypes from "../constants/ActionTypes";
import BASE_URL from "../constants/Api";
import axiosInstance from "../helpers/refreshTokenMiddleware";

export const fetchGaugeEditRequest = () => ({
    type: ActionTypes.GAUGE_EDIT_REQUEST,
});

export const fetchGaugeEditSuccess = (measures) => ({
    type: ActionTypes.GAUGE_EDIT_SUCCESS,
    payload: measures,
});

export const fetchGaugeEditFailure = (error) => ({
    type: ActionTypes.GAUGE_EDIT_FAILURE,
    payload: error,
});

// Thunk to fetch data from API  
export const fetchGaugeEdit = (company_id, facility_id, device_id, gauge_id) => {
    return async (dispatch) => {
        dispatch(fetchGaugeEditRequest());
        try {
            const accessToken = localStorage.getItem('accessToken');
            const response = await axiosInstance.post(BASE_URL + '/top/gauge-edit', {
                company_id: company_id, 
                facility_id: facility_id,
                device_id: device_id,
                gauge_id: gauge_id
            }, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            // Check response here
            const data = response.data.respons || {};
            dispatch(fetchGaugeEditSuccess(data));

        } catch (error) {
            dispatch(fetchGaugeEditFailure(error.message));
        }
    };
};

export const checkMeasureDataRequest = () => ({
    type: ActionTypes.CHECK_MEASURE_DATA_REQUEST,
});

export const checkMeasureDataSuccess = (data) => ({
    type: ActionTypes.CHECK_MEASURE_DATA_SUCCESS,
    payload: data,
});

export const checkMeasureDataFailure = (error) => ({
    type: ActionTypes.CHECK_MEASURE_DATA_FAILURE,
    payload: error,
});

// Thunk action to check gauge list measure data
export const checkMeasureData = (gauge_name, company_id, device_id, gauge_id, measure_id, is_delete = undefined, cnt) => {
    return async (dispatch) => {
        dispatch(checkMeasureDataRequest());
        try {
            const accessToken = localStorage.getItem('accessToken');
            const body = {
                gauge_name,
                company_id,
                device_id,
                gauge_id,
                measure_id,
                cnt,   
            };

            if (is_delete.length) {
                body.is_delete = is_delete;
            }

            const response = await axiosInstance.post(BASE_URL + '/top/check-gauge-list-measure-data', body, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            dispatch(checkMeasureDataSuccess(response.data.respons.data));
            return response.data.respons.data; 
        } catch (error) {
            dispatch(checkMeasureDataFailure(error.message)); // Dispatch failure action
        }
    };
};

export const editGaugeDataRequest = () => ({
    type: ActionTypes.REPLACE_FACILITY_REQUEST,
});

export const editGaugeDataSuccess = (message) => ({
    type: ActionTypes.REPLACE_FACILITY_SUCCESS,
    payload: message,
});

export const editGaugeDataFailure = (error) => ({
    type: ActionTypes.REPLACE_FACILITY_FAILURE,
    payload: error,
});

export const editGaugeData = (gauge_name, company_id, device_id, gauge_id, measure_id, is_delete = undefined, cnt) => {
    return async (dispatch) => {
        dispatch(editGaugeDataRequest());
        try {
            const accessToken = localStorage.getItem('accessToken');
            const body = {
                gauge_name,
                company_id,
                device_id,
                gauge_id,
                measure_id, 
                cnt,      
            };

            if (is_delete) {
                body.is_delete = is_delete;
            }

            const response = await axiosInstance.post(BASE_URL + '/top/edit-gauge-data', body, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            dispatch(editGaugeDataSuccess(response.data.respons.msg));
            return response;
        } catch (error) {
            dispatch(editGaugeDataFailure(error.message));
        }
    };
};