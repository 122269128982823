import React from 'react'
import './CameraInfo.css'

const CameraInfo = React.memo(
    ({cameraIndex, display_facility_name, display_camera_name, battery_per, onShowGraphClick}) => {
        return (
            <div className='lay-search_terms_camera_info'>
                <div className='lay-search_terms-wrap_camera_info'>
                    <div className='lay-search_terms__list_camera_info'>
                        <div className='cameraIndex'>カメラ{cameraIndex}</div>
                        <div className='lay-search_terms__list__text_camera_info'>
                        {display_facility_name}／{display_camera_name}
                        </div>
                        <div className='lay-list__table__comment_camera_info'>
                            <div className='lay-list__table__comment_batt'>
                            {battery_per > 20 ? (
                                <div className='battery'>
                                    <div className='battery-level' style={{ height: "100%", 
                                        width: battery_per +"%"}}>
                                    </div>
                                </div>
                            ) : (
                                <div className='battery'>
                                    <div className='battery-level-alert' style={{ height: "100%", 
                                        width: battery_per +"%"}}>
                                    </div>
                                </div>
                            )}                                                                              
                                <span className='battery_text'>({battery_per}％)</span>
                            </div>
                            <a className='mod-btn__roundborder_camera_info moresmall' onClick={onShowGraphClick}>グラフ表示
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
);

export default CameraInfo;