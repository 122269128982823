import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from '../Modal/Modal';
import { subMonths, addDays, format, isBefore} from 'date-fns';
import DatePicker from 'react-datepicker';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './LoginHistory.css';
import Breadcrumb from '../Breadcrumb';

const ITEMS_PER_PAGE = 50;
const PAGES_PER_GROUP = 10;

const LoginHistory = ({ user, fetchLoginHistory, fetchGetLoginHistory, data = {}, loading, error }) => {
    const navigate = useNavigate ();
    const location = useLocation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [endDate, setEndDate] = useState(new Date());
    const [startDate, setStartDate] = useState(addDays(subMonths(new Date(), 1), 1));
    const [companyId, setCompanyId] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentGroup, setCurrentGroup] = useState(1);

    const [loginHistoryData, setLoginHistoryData] = useState([]); 
    const [totalItems, setTotalItems] = useState(0);
    const [totalPages, setTotalPages] = useState(1); 
    const [isDataFetched, setIsDataFetched] = useState(false);
    const [showUserName, setUserName] = useState('');
    const screenType = useSelector((state) => state.device.screenType);

    // useEffect to call fetchLoginHistory when the component mounts
    useEffect(() => {
        const fetchData = async () => {
            try {
                // Reset dates to default when fetching new data
                setStartDate(addDays(subMonths(new Date(), 1), 1)); 
                setEndDate(new Date()); 
    
                const formattedStartDate = format(addDays(subMonths(new Date(), 1), 1), 'yyyy-MM-dd');
                const formattedEndDate = format(new Date(), 'yyyy-MM-dd');
    
                await fetchLoginHistory(formattedStartDate, formattedEndDate);
            } catch (error) {
                console.error("Error fetching login history on load:", error);
            } finally {
                setIsDataFetched(true);
            }
        };
    
        fetchData();
        setCompanyId("0");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchLoginHistory, location]);
    
    
    useEffect(() => {
        if (data.login_history) {
            const fetchedData = data.login_history || [];

            setLoginHistoryData(fetchedData);
            setTotalItems(fetchedData.length);
            setTotalPages(Math.ceil(fetchedData.length / ITEMS_PER_PAGE));
            setCurrentPage(1);
            setCurrentGroup(1);
        }
    }, [data.login_history]);    

    useEffect(() => {
        if (user) {
          setUserName(user.user_name);
        };
    }, [user]);

    const highlightWeekends = (date) => {
        const day = date.getDay();
        return day === 0 ? "highlight-sunday" : day === 6 ? "highlight-saturday" : "";
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };
    
    const handleStartDateChange = (date) => {
        setStartDate(date);
    };   
    
    const handleTopClick = () => {
        navigate('/');
    };

    const getAuthorityClassLabel = (authorityClass) => {
        const authority = Number(authorityClass);
    
        switch (authority) {
            case 9:
                return "管理者（システム）"; 
            case 1:
                return "管理者（一般）"; 
            default:
                return "一般"; 
        }
    };    
    
    const getLoginStatusLabel = (loginStatus) => {
        switch (loginStatus) {
            case 0: return "成功（通常）";
            case 1: return "成功（二段階認証）";
            case 2: return "失敗（コード期限切れ）";
            case 3: return "失敗（認証エラー）";
            case 4: return "ロック（認証超過）";
            case 5: return "ロック（手動）";
            case 6: return "メンテナンス";
            default: return "未知の状態";
        }
    };

    const getCurrentPageData = () => {
        const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
        const endIndex = startIndex + ITEMS_PER_PAGE;
        return loginHistoryData.slice(startIndex, endIndex);
    };    

    const changePage = (page) => {
        setCurrentPage(page);
    };

    const changeGroup = (group) => {
        setCurrentGroup(group);
    };
    
    const renderPagination = () => {
        const startPage = (currentGroup - 1) * PAGES_PER_GROUP + 1;
        const endPage = Math.min(startPage + PAGES_PER_GROUP - 1, totalPages);
    
        const pages = [];
        if (currentGroup > 1) {
            pages.push(
                <li key="pagepre" className="pagepre" onClick={() => changeGroup(currentGroup - 1)}>
                    <div><span>前の10件</span></div>
                </li>
            );
        }
    
        for (let page = startPage; page <= endPage; page++) {
            pages.push(
                <li key={page} className={`pagegroup ${currentGroup}`} onClick={() => changePage(page)}>
                    <div className={`${page === currentPage ? 'active' : ''}`}><span>{page}</span></div>
                </li>
            );
        }
    
        if (currentGroup * PAGES_PER_GROUP < totalPages) {
            pages.push(
                <li key="pagenext" className="pagenext" onClick={() => changeGroup(currentGroup + 1)}>
                    <div><span>次の10件</span></div>
                </li>
            );
        }
    
        return pages;
    };
    
    const downloadCSV = () => {
        const currentTime = format(new Date(), 'yyyyMMddHHmmss');
        const fileName = `ログイン履歴_${currentTime}.csv`;
    
        const selectedCompany = document.getElementById("company_id").selectedOptions[0].text;
        const startDateValue = format(startDate, 'yyyy/MM/dd');
        const endDateValue = format(endDate, 'yyyy/MM/dd');

        if (!startDateValue || !endDateValue) {
            setModalMessage("期間を入力してください");  
            setIsModalOpen(true);
            return;
        }

        if (isBefore(endDate, startDate)) {
            setModalMessage("正しい期間を入力してください"); 
            setIsModalOpen(true);
            return;
        }

        const maxEndDate = addDays(subMonths(startDate, -6), -1);  
        if (isBefore(maxEndDate, endDate)) {
            setModalMessage("検索期間は半年以内で入力してください");  
            setIsModalOpen(true);
            return;
        }
    
        const summary = [
            [`${fileName}`],
            [`${selectedCompany}`],
            [`検索期間: ${startDateValue}〜${endDateValue}`],
            []
        ];
    
        const headers = ["№", "ログイン日時", "ログイン組織", "ログインユーザー", "設定権限", "状態"];
    
        const rows = loginHistoryData.map((entry, index) => [
            index + 1,
            entry.fmt_datetime,
            entry.company_name,
            entry.user_name,
            getAuthorityClassLabel(entry.authority_class),
            getLoginStatusLabel(entry.login_status)
        ]);
    
        const csvContent = [...summary, headers, ...rows].map(e => e.join(",")).join("\n");
    
        const bom = "\uFEFF";
        const blob = new Blob([bom + csvContent], { type: 'text/csv;charset=utf-8;' });
    
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleCompanyChange = (event) => {
        const selectedValue = event.target.value;
        setCompanyId(selectedValue === "全表示" ? 0 : Number(selectedValue)); 
    };

    const handleSearch = async () => {
        if (!startDate || !endDate) {
            setModalMessage("期間を入力してください");
            setIsModalOpen(true);
            return;
        }
        if (isBefore(endDate, startDate)) {
            setModalMessage("正しい期間を入力してください");
            setIsModalOpen(true);
            return;
        }

        const maxEndDate = addDays(subMonths(startDate, -6), -1); 
        if (isBefore(maxEndDate, endDate)) {
            setModalMessage("検索期間は半年以内で入力してください"); 
            setIsModalOpen(true);
            return;
        }
    
        const formattedStartDate = format(startDate, 'yyyy-MM-dd');
        const formattedEndDate = format(endDate, 'yyyy-MM-dd');
    
        try {
            const result = await fetchGetLoginHistory(formattedStartDate, formattedEndDate, companyId);
    
            setLoginHistoryData(result.login_history || []);
            setTotalItems(result.login_history?.length || 0);
            setTotalPages(Math.ceil((result.login_history?.length || 0) / ITEMS_PER_PAGE));
    
            setCurrentPage(1);
            setCurrentGroup(1);
        } catch (error) {
            setModalMessage(error.message || "Failed to search.");
            setIsModalOpen(true);
        }
    };
    
    return (
        <div id='Main' className={`main ${screenType}`}>
            <div id='Contents'>
                <Breadcrumb
                    userName = {showUserName}
                    pageName = "ログイン履歴"
                    onTopClick = {handleTopClick}
                />
                {isModalOpen && (
                    <Modal
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    message={modalMessage}
                />
                )}
                <div id="ajax-view-disp">
                    <div className="display-main">
                        <div className="lay-master-set">
                            <div className="lay-master-set-wrap">
                                <div className="lay-login-set__title">
                                    <span>ログイン履歴</span>
                                </div>
                                <form id="loginHistory">
                                    <div className="mod-form__select " style={{width: '250px', marginBottom: '20px'}}>
                                        <select id="company_id" name="company_id" value={companyId} onChange={handleCompanyChange}>
                                            <option value="0">全表示</option>
                                            {data.company_data && data.company_data.map((company, index) => (
                                                <option key={index} value={company.company_id}>
                                                    {company.company_name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    
                                    <div className="lay-search__form__col1">
                                        <div className="mod-form__set">
                                            <dl>
                                                <dd>
                                                <div className="mod-form__text calendar">
                                                    <DatePicker
                                                        selected={startDate}
                                                        onChange={handleStartDateChange}
                                                        dayClassName={(date) => {
                                                            const weekendClass = highlightWeekends(date);
                                                            const isCurrentMonth = date.getMonth() === startDate.getMonth();
                                                            return isCurrentMonth ? weekendClass : "hide-day";
                                                        }}
                                                        locale="ja"
                                                        dateFormat="yyyy/MM/dd"
                                                        className="custom-datepicker"
                                                        calendarStartDay={1}
                                                        renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
                                                            <div className="custom-header">
                                                                <button style={{float: 'left'}} onClick={(e) => {
                                                                    e.preventDefault();
                                                                    decreaseMonth();
                                                                }}>{'<'}</button>
                                                                
                                                                <span>{format(date, 'yyyy年')}</span>
                                                                <span>{format(date, 'MM')}月</span>
                                                                
                                                                <button style={{float: 'right'}} onClick={(e) => {
                                                                    e.preventDefault(); 
                                                                    increaseMonth();
                                                                }}>{'>'}</button>
                                                            </div>
                                                        )}
                                                        onKeyDown={(e) => e.preventDefault()}
                                                    />
                                                </div>
                                                </dd>
                                            </dl>
                                        </div>
                                        <div className="mod-form__set-between">〜</div>
                                        <div className="mod-form__set">
                                            <dl>
                                                <dd>
                                                    <div className="mod-form__text second-calendar">
                                                        <DatePicker
                                                            selected={endDate}
                                                            onChange={handleEndDateChange}
                                                            dayClassName={(date) => {
                                                                const weekendClass = highlightWeekends(date);
                                                                const isCurrentMonth = date.getMonth() === startDate.getMonth();
                                                                return isCurrentMonth ? weekendClass : "hide-day";
                                                            }}
                                                            locale="ja"
                                                            dateFormat="yyyy/MM/dd"
                                                            className="custom-datepicker"
                                                            calendarStartDay={1}
                                                            renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
                                                                <div className="custom-header">
                                                                    <button style={{float: 'left'}} onClick={(e) => {
                                                                        e.preventDefault();
                                                                        decreaseMonth();
                                                                    }}>{'<'}</button>
                                                                    
                                                                    <span>{format(date, 'yyyy年')}</span>
                                                                    <span>{format(date, 'MM')}月</span>
                                                                    
                                                                    <button style={{float: 'right'}} onClick={(e) => {
                                                                        e.preventDefault(); 
                                                                        increaseMonth();
                                                                    }}>{'>'}</button>
                                                                </div>
                                                            )}
                                                            onKeyDown={(e) => e.preventDefault()}
                                                        />
                                                    </div>
                                                </dd>
                                            </dl>
                                        </div>
                                    </div>
                                    <div className="lay-search__btn">
                                        <button type="button" className="mod-btn__round" onClick={handleSearch}>
                                            検索
                                        </button>
                                    </div>
                                </form>
                            </div>
                            <div className="lay-tab__content" style={{ display: isDataFetched && loginHistoryData.length > 0 ? 'block' : 'none' }}>
                                <ul>
                                    <li>
                                    <div className="outer-div">
                                            <div className="pager-frame">
                                                <div className="result_num">
                                                    <span>検索件数：</span>
                                                    <span id="data-count">{totalItems}</span>
                                                </div>
                                                <div className="pager"> 
                                                    <ul className="pagination">
                                                        {renderPagination()}
                                                    </ul>
                                                </div>
                                            </div>
                                                <button type="button" className="mod-btn__round" id="csv_btn" onClick={downloadCSV}>
                                                    CSVダウンロード
                                                </button>
                                        </div>
                                        
                                            
                                    </li>
                                </ul>
                            </div>
                            <div
                                className="mod-no-data"
                                style={{ display: isDataFetched && loginHistoryData.length === 0 ? 'block' : 'none', }}
                            >
                                表示データが存在しません。
                            </div>

                            <div className="login-log">
                                <div className="selection page-list1 lay-list__table"  style={{ display: isDataFetched && loginHistoryData.length > 0 ? 'block' : 'none' }}>
                                    <table className="loginInfoTbl" style={{fontSize: '14px'}}>
                                        <tbody>
                                            <tr className="login-log-header">
                                                <th className="colNo">№</th>
                                                <th className="colLoginTime">ログイン日時</th>
                                                <th className="colLoginUser">ログインユーザー</th>
                                                <th className="colAuthenClass">設定権限</th>
                                                <th className="colStatus">状態</th>
                                            </tr>
                                            {getCurrentPageData().map((entry, index) => (
                                                <tr key={index}>
                                                    <td className="colNo">{(currentPage - 1) * ITEMS_PER_PAGE + index + 1}</td>
                                                    <td className="colLoginTime">{entry.fmt_datetime.replace(/(\d{4} 年 \d{2} 月 \d{2} 日)\s+(\d{1,2} 時.*)/, '$1　$2')}</td>
                                                    <td className="colLoginUser" style={{ textAlign: 'left' }}>
                                                        {entry.user_name}（{entry.company_name}）
                                                    </td>
                                                    <td className="colAuthenClass">
                                                        {getAuthorityClassLabel(entry.authority_class)}
                                                    </td>
                                                    <td 
                                                        className={`colStatus ${entry.login_status > 1 ? 'status_err' : ''}`}
                                                    >
                                                        {getLoginStatusLabel(entry.login_status)}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`overlay ${loading ? 'active' : ''}`}>
                <div className={`spinner ${loading ? 'active' : ''}`}></div>
            </div>
        </div>
    );
};

LoginHistory.propTypes = {
    data: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    fetchLoginHistory: PropTypes.func.isRequired,
    fetchGetLoginHistory: PropTypes.func.isRequired
};

export default React.memo(LoginHistory);