import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const MobileRedirect = ({ restrictedPaths = [] }) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!restrictedPaths.includes(location.pathname)) return;

    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android|iphone|ipod|blackberry|iemobile|opera mini/i.test(userAgent.toLowerCase())) {
      alert('このページはモバイルデバイスでは利用できません。');
      navigate('/');
      return;
    }
  }, [navigate, location.pathname, restrictedPaths]);

  return null;
};

export default MobileRedirect;
