import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Menu from "../components/Menu";
import { refreshAccessToken, logout } from "../actions/user";
import { fetchUserData } from '../actions/user';

const mapStateToProps = (state) => {  
  const user = state.login.user;

  return {
      user,

  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logout,
      refreshAccessToken,
      fetchUserData
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
