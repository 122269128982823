import React from 'react';
import PropTypes from 'prop-types';

const DataList = ({
    pagesInCurrentGroup,
    displayedData,
    currentPage,
    buttonsPerGroup,
    itemsPerPage,
    gaugeData,
    getGaugeData,
    fetchImageList,
    selectedItem,
    startDate,
    endDate,
    selectedFacility,
    selectedCamera,
    selectedGauge,
    comments,
    setComments,
    fontSizes,
    setFontSizes,
    setLoading,
    openPopup,
    failure_id,
    formatValue,
    formatDiffVal
}) => {

    return (
        <div className="lay-list">
            {pagesInCurrentGroup.map((page) => {
                const startIndex = ((page - 1) % buttonsPerGroup) * itemsPerPage;
                const endIndex = startIndex + itemsPerPage;
                const pageData = displayedData.slice(startIndex, endIndex);

                const hasDiffFlg = pageData.some(item => {
                    const gaugeDataList = getGaugeData(item, gaugeData);
                    return gaugeDataList.some(gauge => gauge.diffFlg === 1);
                });

                return (
                    <div
                        key={page}
                        className={`selection page-list${page}`}
                        style={{ display: currentPage === page ? 'block' : 'none' }}
                    >
                        {pageData.length === 0 ? (
                            <div className="mod-no-data">表示データが存在しません。</div>
                        ) : (
                            <div className="lay-list__table">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>記録日</th>
                                            <th>記録者</th>
                                            <th>施設名</th>
                                            <th>カメラ名</th>
                                            <th>計器名</th>
                                            <th>計測</th>
                                            <th style={{ display: hasDiffFlg ? 'table-cell' : 'none' }}>差分値</th>
                                            <th>状態</th>
                                            <th>コメント</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {pageData.map((item, itemIdx) => {
                                            const gaugeDataList = getGaugeData(item, gaugeData);

                                            return (
                                                <tr key={itemIdx}>
                                                    <td>{item.disp_datetime}</td>
                                                    <td>{item.user_name}</td>
                                                    <td>{item.facility_name}</td>
                                                    <td>{item.display_camera_name}</td>
                                                    <td>{item.display_gauge_name}</td>
                                                    <td>
                                                        {gaugeDataList.map((gauge, index) => (
                                                            <div key={index}>
                                                                {isNaN(gauge.measureVal) ? '' : gauge.measureVal} {gauge.unit}
                                                            </div>
                                                        ))}
                                                    </td>
                                                    <td style={{ display: hasDiffFlg ? 'table-cell' : 'none' }}>
                                                        {gaugeDataList.map((gauge, index) => (
                                                            <div key={index} style={{ display: gauge.diffFlg === 1 ? 'block' : 'none' }}>
                                                                {formatDiffVal(gauge.diffVal, gauge.decimal_value) || gauge.displayDiffVal ||  '--'} {gauge.unit}
                                                            </div>
                                                        ))}
                                                    </td>
                                                    <td>{item.status}</td>
                                                    <td>
                                                        <div className="lay-list__table__comment">
                                                            {item.comment}
                                                        </div>
                                                    </td>
                                                    <td className="lay-list__table__btn">
                                                        <div style={{ width: "60px" }}>
                                                            {failure_id !== 1 && (
                                                                item.status_class === 0 ? (
                                                                    <button
                                                                        type="button"
                                                                        id={`registerbtn${startIndex + itemIdx}`}
                                                                        className="mod-btn__round moresmall"
                                                                        onClick={() => openPopup(item, itemIdx, page)}
                                                                    >
                                                                        入力
                                                                    </button>
                                                                ) : (
                                                                    <button
                                                                        type="button"
                                                                        className="mod-btn__roundborder moresmall"
                                                                        onClick={() => openPopup(item, itemIdx, page)}
                                                                    >
                                                                        編集
                                                                    </button>
                                                                )
                                                            )}
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                );
            })}
        </div>
    );
};

DataList.propTypes = {
    pagesInCurrentGroup: PropTypes.array.isRequired,
    displayedData: PropTypes.array.isRequired,
    currentPage: PropTypes.number.isRequired,
    buttonsPerGroup: PropTypes.number.isRequired,
    itemsPerPage: PropTypes.number.isRequired,
    gaugeData: PropTypes.object.isRequired,
    getGaugeData: PropTypes.func.isRequired,
    fetchImageList: PropTypes.func.isRequired,
    selectedItem: PropTypes.object.isRequired,
    startDate: PropTypes.instanceOf(Date).isRequired,
    endDate: PropTypes.instanceOf(Date).isRequired,
    selectedFacility: PropTypes.string.isRequired,
    selectedCamera: PropTypes.string.isRequired,
    selectedGauge: PropTypes.string.isRequired,
    comments: PropTypes.object.isRequired,
    setComments: PropTypes.func.isRequired,
    fontSizes: PropTypes.object.isRequired,
    setFontSizes: PropTypes.func.isRequired,
    setLoading: PropTypes.func.isRequired,
    openPopup: PropTypes.func.isRequired,
};

export default DataList;
