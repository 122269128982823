import ActionTypes from '../constants/ActionTypes';
import axiosInstance from '../helpers/refreshTokenMiddleware';
import BASE_URL from '../constants/Api';

export const updateUserNameRequest = () => ({
    type: ActionTypes.UPDATE_USER_NAME_REQUEST,
});

export const updateUserNameSuccess = (message) => ({
    type: ActionTypes.UPDATE_USER_NAME_SUCCESS,
    payload: message,
});

export const updateUserNameFailure = (error) => ({
    type: ActionTypes.UPDATE_USER_NAME_FAILURE,
    payload: error,
});

export const updateUserName = (userId, userName) => {
    return async (dispatch) => {
        dispatch(updateUserNameRequest());
        try {
            const accessToken = localStorage.getItem('accessToken');
            const response = await axiosInstance.post(BASE_URL + '/setting/update-user-name', {
                user_id: userId,
                user_name: userName,
            }, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            dispatch(updateUserNameSuccess(response.data.respons.msg));
            return response;
        } catch (error) {
            dispatch(updateUserNameFailure(error.message));
        }
    };
};
