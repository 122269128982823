import ActionTypes from "../constants/ActionTypes";
import BASE_URL from "../constants/Api";
import axiosInstance from "../helpers/refreshTokenMiddleware";
import { format } from 'date-fns';

export const fetchLoginHistoryRequest = () => ({
  type: ActionTypes.FETCH_LOGIN_HISTORY_REQUEST,
});

export const fetchLoginHistorySuccess = (data) => ({
    type: ActionTypes.FETCH_LOGIN_HISTORY_SUCCESS,
    payload: data,
});

export const fetchLoginHistoryFailure = (error) => ({
    type: ActionTypes.FETCH_LOGIN_HISTORY_FAILURE,
    payload: error,
});

// Thunk to fetch data from API  
export const fetchLoginHistory = (startDate, endDate) => {
    return async (dispatch) => {
        dispatch(fetchLoginHistoryRequest());
        try {
            const accessToken = localStorage.getItem('accessToken');
            const body = {
                start_date: format(startDate, 'yyyy-MM-dd'),
                end_date: format(endDate, 'yyyy-MM-dd'),
            };
            const response = await axiosInstance.post(BASE_URL + '/setting/login-history', body, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                }
            });
  
            const data = response.data.respons || {};
            dispatch(fetchLoginHistorySuccess(data));
        } catch (error) {
            // Dispatch failure action if the API call fails
            dispatch(fetchLoginHistoryFailure(error.message));
        }
    };
};

export const fetchGetLoginHistoryRequest = () => ({
    type: ActionTypes.FETCH_GET_LOGIN_HISTORY_REQUEST,
  });
  
  export const fetchGetLoginHistorySuccess = (dataLogin) => ({
      type: ActionTypes.FETCH_GET_LOGIN_HISTORY_SUCCESS,
      payload: dataLogin,
  });
  
  export const fetchGetLoginHistoryFailure = (error) => ({
      type: ActionTypes.FETCH_GET_LOGIN_HISTORY_FAILURE,
      payload: error,
  });
  
  // Thunk to fetch data from API  
  export const fetchGetLoginHistory = (startDate, endDate, company_id) => {
      return async (dispatch) => {
          dispatch(fetchGetLoginHistoryRequest());
          try {
              const accessToken = localStorage.getItem('accessToken');
              const body = {
                  start_date: format(startDate, 'yyyy-MM-dd'),
                  end_date: format(endDate, 'yyyy-MM-dd'),
                  company_id
              };
              const response = await axiosInstance.post(BASE_URL + '/setting/get-login-history', body, {
                  headers: {
                      'Authorization': `Bearer ${accessToken}`,
                  }
              });
    
              const dataLogin = response.data.respons ||{};
              dispatch(fetchGetLoginHistorySuccess(dataLogin));
              return dataLogin;
          } catch (error) {
              // Dispatch failure action if the API call fails
              dispatch(fetchGetLoginHistoryFailure(error.message));
          }
      };
  };