import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import './DataSearch.css'

const DataSearch = React.memo(
    ({facilities, cameras, gauges, measureUnit,
      initStartDate,   
      initEndDate,   
      initFacility,   
      initCamera,   
      initGauge,   
      initMeasure,   
      initMinimumValue,   
      initMaximumValue,   
      initStepSizeValue,   
      initDecimalValue,   
      initViewType,   
      onStartDateChange,
      onEndDateChange,
      onFacilityChange,
      onCameraChange,
      onGaugeChange,
      onMeasureChange,
      onMinimumValueChange, 
      onMaximumValueChange, 
      onStepSizeValueChange,
      onDecimalValueChange,
      onViewTypeChange,
      onSearchClick
    }) => {
        console.log("initStartDate:", initStartDate);
        const [startDate, setStartDate] = useState(initStartDate);
        const [endDate, setEndDate] = useState(initEndDate);
        const [selectedFacility, setSelectedFacility] = useState(initFacility);
        const [selectedCamera, setSelectedCamera] = useState(initCamera);
        const [selectedGauge, setSelectedGauge] = useState(initGauge);
        const [selectedMeasure, setSelectedMeasure] = useState(initMeasure);
        const [filteredGauges, setFilteredGauges] = useState(gauges);
        const [filteredCamera, setFilteredCamera] = useState(cameras);
        const [minimumValue, setMinimumValue] = useState(initMinimumValue);
        const [maximumValue, setMaximumValue] = useState(initMaximumValue);
        const [decimalValue, setDecimalValue] = useState(initDecimalValue);
        const [stepSizeValue, setStepSizeValue] = useState(initStepSizeValue);
        const [viewType, setViewType] = useState(initViewType);
        const [shouldDisableFocus, setShouldDisableFocus] = useState(false);

        useEffect(() => {
            const checkMediaQueries = () => {
                const condition1 = window.matchMedia('(min-width: 577px) and (max-width: 767px)').matches;
                const condition2 = window.matchMedia('(max-width: 576px)').matches;
                const condition3 = window.matchMedia('(max-height: 576px) and (orientation: landscape)').matches;
                setShouldDisableFocus(condition1 || condition2 || condition3);
            };
    
            checkMediaQueries();
    
            window.addEventListener('resize', checkMediaQueries);
    
            return () => {
                window.removeEventListener('resize', checkMediaQueries);
            };
        }, []);
   
        const handleEndDateChange = (date) => {
            const newDate = formatDate(date);
            setEndDate(newDate);
            onEndDateChange(newDate);
            
        };
    
        const handleStartDateChange = (date) => {
            const newDate = formatDate(date);

            setStartDate(newDate);
            onStartDateChange(newDate);
        };

        const handleFacilityChange = (event) => {
            const facilityId = event.target.value;
            
            if (facilityId === '') {
                if (selectedMeasure === '') {
                    setFilteredGauges(gauges);
                    setFilteredCamera(cameras);
                } else {
                    setFilteredGauges([]);
                    setFilteredCamera([]);
                };
                
            } else {
                setSelectedMeasure('');
                onMeasureChange('');
                setFilteredCamera(filterCamera(facilityId));
                setFilteredGauges(filterGauges(facilityId, ''));
            };
            setSelectedCamera('');
            setSelectedGauge('');
            setSelectedFacility(facilityId);
            onCameraChange('');
            onGaugeChange('');
            onFacilityChange(facilityId);
        };

        const handleCameraChange = (event) => {
            const cameraId = event.target.value;

            if (cameraId === '') {
                setFilteredCamera(filterCamera(selectedFacility));
                setFilteredGauges(filterGauges(selectedFacility, ''));
            } else {
                const facility = facilities.find(facility =>
                    cameras.some(camera => camera.device_id === cameraId && camera.facility_id === facility.facility_id)
                );
                if (facility) {
                    setSelectedFacility(facility.facility_id);
                    onFacilityChange(facility.facility_id);
                    setFilteredCamera(filterCamera(facility.facility_id));
                    setFilteredGauges(filterGauges(facility.facility_id, cameraId));

                };
            };

            setSelectedGauge('');
            setSelectedCamera(cameraId);
            onGaugeChange('');
            onCameraChange(cameraId);
        };

        const handleGaugeChange = (event) => {
            const gaugeId = event.target.value;

            setSelectedGauge(gaugeId);
            onGaugeChange(gaugeId);

            const camera = cameras.find(camera =>
                gauges.some(gauge => gauge.gauge_id === gaugeId && gauge.device_id === camera.device_id)
            );
            if (camera) {
                setSelectedCamera(camera.device_id);
                onCameraChange(camera.device_id);
                const facility = facilities.find(facility => facility.facility_id === camera.facility_id);
                if (facility) {
                    setSelectedFacility(facility.facility_id);
                    onFacilityChange(facility.facility_id);
                    setFilteredCamera(filterCamera(facility.facility_id));
                    setFilteredGauges(filterGauges(facility.facility_id, camera.device_id));
                };
            };
    
        };

        const handleMeasureChange = (event) => {
            const measureId = event.target.value;
            setSelectedMeasure(measureId);
            onMeasureChange(measureId);
            setSelectedFacility('');
            onFacilityChange('');
            setSelectedCamera('');
            onCameraChange('');
            setSelectedGauge('');
            onGaugeChange('');
            setFilteredGauges([]);
            setFilteredCamera([]);
        };

        const handleMinimumValChange = (event) => {
            let newMinVal = event.target.value;

            if (/^[0-9.]*$/.test(newMinVal)) {
                if (newMinVal === ".") newMinVal = "";
            };

            if (/^\d*\.?\d*$/.test(newMinVal)) {
                setMinimumValue(newMinVal);
                onMinimumValueChange(newMinVal);     
            };
            
        };

        const handleMaximumValChange = (event) => {
            let newMaxVal = event.target.value;

            if (/^[0-9.]*$/.test(newMaxVal)) {
                if (newMaxVal === ".") newMaxVal = "";
            };

            if (/^\d*\.?\d*$/.test(newMaxVal)) {
                setMaximumValue(newMaxVal);
                onMaximumValueChange(newMaxVal);          
            };

        };

        const handleDecimalValueChange = (event) => {
            const newDecimalValue = event.target.value;
            setDecimalValue(newDecimalValue);
            onDecimalValueChange(newDecimalValue);
        };

        const handleStepSizeValChange = (event) => {
            let newStepSizeVal = event.target.value;

            if (/^[0-9.]*$/.test(newStepSizeVal)) {
                if (newStepSizeVal === ".") newStepSizeVal = "";
            };

            if (/^\d*\.?\d*$/.test(newStepSizeVal)) {
                setStepSizeValue(newStepSizeVal);
                onStepSizeValueChange(newStepSizeVal);            
            };
            
        };

        const handleViewTypeChange = (event) => {
            const newViewType = event.target.value;
            setViewType(newViewType);
            onViewTypeChange(newViewType); 
            var tmpEndYear = new Date(endDate).getFullYear();         
            var tmpEndMonth = new Date(endDate).getMonth();         
            var tmpEndDay = new Date(tmpEndYear, tmpEndMonth + 1, 0).getDate();
            var tmpEndDate = new Date(tmpEndYear, tmpEndMonth, tmpEndDay);
            var tmpStartYear = new Date(startDate).getFullYear();         
            var tmpStartMonth = new Date(startDate).getMonth();         
            var tmpStartDay = new Date(tmpStartYear, tmpStartMonth, 1).getDate();
            var tmpStartDate = new Date(tmpStartYear, tmpStartMonth, tmpStartDay);

            if (parseInt(newViewType) === 2) {
                handleEndDateChange(tmpEndDate);
                handleStartDateChange(tmpStartDate);
            };
        };

        const filterCamera = (facilityId) => {
            return cameras.filter(camera => String(camera.facility_id) === String(facilityId));
        };

        const filterGauges = (facilityId, cameraId) => {
            if (cameraId === '') {
                return gauges.filter(gauge => String(gauge.facility_id) === String(facilityId));
            } else {
                return gauges.filter(gauge => String(gauge.facility_id) === String(facilityId) && String(gauge.device_id) === String(cameraId));
            };
        };
        
        const highlightWeekends = (date) => {
            const day = date.getDay();
            if (day === 0) {
                return "highlight-sunday";
            } else if (day === 6) {
                return "highlight-saturday";
            }
            return "";
        };

        const handleCalendarKeyDown = (event) => {
            event.preventDefault();
        };

        const handleNumberKeyDown = (event) => {
            const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab', '.'];

            if (!/^[0-9]$/.test(event.key) && !allowedKeys.includes(event.key)) {
                event.preventDefault();
            };

        };

        const handleSearchClick = () => {
            if (minimumValue !== '') setMinimumValue(parseFloat(minimumValue));
            if (maximumValue !== '') setMaximumValue(parseFloat(maximumValue));
            if (stepSizeValue !== '') setStepSizeValue(parseFloat(stepSizeValue));

            onSearchClick();
        };

        const formatDate = (date) => (date ? format(date, viewType === 2 ? 'yyyy/MM' : 'yyyy/MM/dd') : '');

        return (
            <div className='lay-search-graph'>
                <div className='lay-search-graph-wrap'>
                    <div className='lay-search-graph__title'>
                        <span>データ検索</span>
                    </div>
                    <div className='lay-search-graph__lead'>
                        それぞれ条件を入力し「検索」ボタンをクリックしてください。
                    </div>
                    <form id='searchNewData-graph'>
                        <div className='lay-search-graph__form1'>
                            <div className='lay-search-graph__form__col1'>
                                <ul>
                                    <li>
                                        <div className='mod-form-graph__set'>
                                            <dl>
                                                <dt>期間</dt>
                                                <dd>
                                                    <div className='mod-form-graph__text calendar'>
                                                        <DatePicker
                                                            selected={startDate}
                                                            onChange={handleStartDateChange}
                                                            dayClassName={highlightWeekends}
                                                            locale="ja"
                                                            dateFormat={parseInt(viewType)===2 ? "yyyy/MM" : "yyyy/MM/dd"}
                                                            className="custom-datepicker"
                                                            calendarStartDay={1}
                                                            onFocus={shouldDisableFocus ? (e) => e.target.blur() : undefined}
                                                            renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
                                                                <div className="custom-header">
                                                                    <button style={{float: 'left'}} onClick={(e) => {
                                                                        e.preventDefault();
                                                                        decreaseMonth();
                                                                    }}>{'<'}</button>
                                                                    
                                                                    <span>{format(date, 'yyyy年')}</span>
                                                                    <span>{format(date, 'MM')}月</span>
                                                                    
                                                                    <button style={{float: 'right'}} onClick={(e) => {
                                                                        e.preventDefault(); 
                                                                        increaseMonth();
                                                                    }}>{'>'}</button>
                                                                </div>
                                                            )}
                                                            style={{ width: '315px' }}
                                                            showFullMonthYearPicker={parseInt(viewType) === 1}
                                                            showMonthYearPicker={parseInt(viewType) === 2}
                                                            onKeyDown={handleCalendarKeyDown}
                                                        />
                                                    </div>
                                                </dd>
                                            </dl>
                                        </div>
                                    </li>
                                    <div className="mod-form-graph__set-between">〜</div>
                                    <li>
                                        <div className='mod-form-graph__set'>
                                            <dl>
                                                <dd>
                                                    <div className='mod-form-graph__text second-calendar'>
                                                        <DatePicker
                                                            selected={endDate}
                                                            onChange={handleEndDateChange}
                                                            dayClassName={highlightWeekends}
                                                            locale="ja"
                                                            dateFormat={parseInt(viewType)===2 ? "yyyy/MM" : "yyyy/MM/dd"}
                                                            className="custom-datepicker"
                                                            calendarStartDay={1}
                                                            onFocus={shouldDisableFocus ? (e) => e.target.blur() : undefined}
                                                            renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
                                                                <div className="custom-header">
                                                                    <button style={{float: 'left'}} onClick={(e) => {
                                                                        e.preventDefault();
                                                                        decreaseMonth();
                                                                    }}>{'<'}</button>
                                                                    
                                                                    <span>{format(date, 'yyyy年')}</span>
                                                                    <span>{format(date, 'MM')}月</span>
                                                                    
                                                                    <button style={{float: 'right'}} onClick={(e) => {
                                                                        e.preventDefault(); 
                                                                        increaseMonth();
                                                                    }}>{'>'}</button>
                                                                </div>
                                                            )}
                                                            style={{ width: '315px' }}
                                                            showFullMonthYearPicker={parseInt(viewType) === 1}
                                                            showMonthYearPicker={parseInt(viewType) === 2}
                                                            onKeyDown={handleCalendarKeyDown}
                                                        />
                                                    </div>
                                                </dd>
                                            </dl>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className='lay-search-graph__form__col2'>
                                <ul>
                                    <li>
                                        <div className='mod-form-graph__set'>
                                            <dl>
                                                <dt>施設名</dt>
                                                <dd>
                                                    <div className='mod-form-graph__select'>
                                                        <select id='facility' name="facility_id" value={selectedFacility} onChange={handleFacilityChange}>
                                                        <option value=""></option>
                                                        {facilities.map((facility) => (
                                                            <option key={facility.facility_id} value={facility.facility_id}>
                                                                {facility.facility_name}
                                                            </option>
                                                        ))}
                                                        </select>
                                                    </div>
                                                </dd>
                                            </dl>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='mod-form-graph__set'>
                                            <dl>
                                                <dt>カメラ名</dt>
                                                <dd>
                                                    <div className='mod-form-graph__select'>
                                                        <select id='device_id' name="device_id" value={selectedCamera} onChange={handleCameraChange}>
                                                        <option value=""></option>
                                                        {filteredCamera.map((camera) => (
                                                            <option key={camera.device_id} value={camera.device_id}>
                                                                {camera.display_camera_name}
                                                            </option>
                                                        ))}
                                                        </select>
                                                    </div>
                                                </dd>
                                            </dl>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className='lay-search-graph__form__col3'>
                                <ul>
                                    <li>
                                        <div className='mod-form-graph__set'>
                                            <dl>
                                                <dt>計器名</dt>
                                                <dd>
                                                    <div className='mod-form-graph__select'>
                                                        <select id='gauge_id' name="gauge_id" value={selectedGauge} onChange={handleGaugeChange}>
                                                            <option value=""></option>
                                                            {(!selectedCamera) ? 
                                                                filteredGauges.map((gauge) => (
                                                                    <option key={gauge.gauge_id} value={gauge.gauge_id}>
                                                                        {gauge.display_gauge_name}
                                                                    </option>
                                                                )) 
                                                                : 
                                                                filteredGauges.map((gauge) => (
                                                                    <option key={gauge.gauge_id} value={gauge.gauge_id}>
                                                                        {gauge.display_gauge_name}
                                                                    </option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                </dd>
                                            </dl>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='mod-form-graph__set'>
                                            <dl>
                                                <dt>計測項目</dt>
                                                <dd>
                                                    <div className='mod-form-graph__select'>
                                                        <select id='measure' name="measure_id" value={selectedMeasure} onChange={handleMeasureChange}>
                                                        <option value=""></option>
                                                        {measureUnit.map((measure) => (
                                                            <option key={measure.measure_id} value={measure.measure_id}>
                                                                {measure.display_measure_name}
                                                            </option>
                                                        ))}
                                                        </select>
                                                    </div>
                                                </dd>
                                            </dl>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className='lay-search-graph__form2'>
                            <div className="lay-search-option__title">
                                <span>表示設定</span>
                            </div>
                            <div className='lay-search-graph__form__col2'>
                                <ul>
                                    <li>
                                        <div className='mod-form-graph__set'>
                                            <dl>
                                                <dt>最小値</dt>
                                                <dd>
                                                    <div className='mod-form-graph__text'>
                                                        <input type="text" 
                                                               id="minimum_value" 
                                                               name="minimum_value" 
                                                               value={minimumValue} 
                                                               onKeyDown={handleNumberKeyDown} 
                                                               onChange={handleMinimumValChange} 
                                                               inputMode="decimal"
                                                               pattern="^-?[0-9]*[.,]?[0-9]+$">
                                                        </input>
                                                    </div>
                                                </dd>
                                            </dl>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='mod-form-graph__set'>
                                            <dl>
                                                <dt>最大値</dt>
                                                <dd>
                                                    <div className='mod-form-graph__text'>
                                                        <input type="text" 
                                                               id="maximum_value" 
                                                               name="maximum_value" 
                                                               value={maximumValue} 
                                                               onKeyDown={handleNumberKeyDown} 
                                                               onChange={handleMaximumValChange} 
                                                               pattern="^-?[0-9]*[.,]?[0-9]+$">
                                                        </input>
                                                    </div>
                                                </dd>
                                            </dl>
                                        </div>
                                    </li>
                                </ul>

                            </div>
                            <div className='lay-search-graph__form__col2'>
                                <ul>
                                    <li>
                                        <div className='mod-form-graph__set'>
                                            <dl>
                                                <dt>小数点桁数</dt>
                                                <dd>
                                                    <div className='mod-form-graph__select'>
                                                        <select id='decimal_value' name="decimal_value" value={decimalValue} onChange={handleDecimalValueChange}>
                                                            <option key="99" value="auto">自動</option>
						    					            <option key="0" value="0">0</option>
						    					            <option key="1" value="1">1</option>
						    					            <option key="2" value="2">2</option>
						    					            <option key="3" value="3">3</option>
						    					            <option key="4" value="4">4</option>
						    					            <option key="5" value="5">5</option>
                                                        </select>
                                                    </div>
                                                </dd>
                                            </dl>
                                        </div>
    
                                    </li>

                                    <li>
                                        <div className='mod-form-graph__set'>
                                            <dl>
                                                <dt>目盛り間隔</dt>
                                                <dd>
                                                    <div className='mod-form-graph__text'>
                                                        <input type="text" 
                                                               id="step_size_value" 
                                                               name="step_size_value" 
                                                               value={stepSizeValue} 
                                                               onKeyDown={handleNumberKeyDown} 
                                                               onChange={handleStepSizeValChange}
                                                               pattern="^-?[0-9]*[.,]?[0-9]+$">
                                                        </input>
                                                    </div>
                                                </dd>
                                            </dl>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className='lay-search-graph__form__col2'>
                                <ul>
                                    <li>
                                        <div className='mod-form-graph__set'>
                                            <dl>
                                                <dt>グラフ表示</dt>
                                                <dd>
                                                    <div className='tabs'>
                                                        <input type='radio' id="view_type1" name="view_type" value={1} checked={parseInt(viewType) === 1} onChange={handleViewTypeChange}></input>
                                                        <label className='tab_item' htmlFor='view_type1'>1日単位</label>
                                                        <input type='radio' id="view_type2" name="view_type" value={2} checked={parseInt(viewType) === 2} onChange={handleViewTypeChange}></input>
                                                        <label className='tab_item' htmlFor='view_type2'>月単位</label>
                                                    </div>
                                                </dd>
                                            </dl>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="lay-search-graph__lead"> 
                                最小値・最大値・目盛り間隔は空白にすると該当部分を自動で調整します。
			                </div>
                        </div>
                        
                    </form>
                    <div className='lay-search-graph__btn'>
                        <button type='button' className="mod-btn-graph__round" id='search' onClick={handleSearchClick}>検索</button>
                    </div>
                </div>
            </div>
            
        );

    }

);

export default DataSearch;


