import ActionTypes from '../constants/ActionTypes';
import axiosInstance from '../helpers/refreshTokenMiddleware';
import BASE_URL from '../constants/Api';

export const fetchTransitionGraphDataRequest = () => ({
    type: ActionTypes.FETCH_TRANSITION_GRAPH_REQUEST,
});

export const fetchTransitionGraphDataSuccess = (data, isEmpty) => ({
    type: ActionTypes.FETCH_TRANSITION_GRAPH_SUCCESS,
    payload: { data, isEmpty },
});

export const fetchTransitionGraphDataFailure = (error) => ({
    type: ActionTypes.FETCH_TRANSITION_GRAPH_FAILURE,
    payload: error,
});

export const fetchTransitionGraphData = (items, start_date, end_date, view_type, past_view_data) => async (dispatch) => {
    dispatch(fetchTransitionGraphDataRequest());
    try {
        var inputdata = [];
        const count = items.length;
        console.log("items:", items);

        inputdata = {
            graphCount: count,
            graph_facility_id1: items.facility_id,
            graph_device_id1: items.device_id,
            graph_gauge_id1: items.gauge_id,
            graph_measure_id1: items.measure_id,
            start_date: start_date,
            end_date: end_date,
            view_type: view_type,
            past_data_view: past_view_data
        };
        
        const response = await axiosInstance.post(BASE_URL + '/graph/transition-graph-data', inputdata);
        const data = response.data.respons || [];
        const isEmpty = data.length === 0;
        dispatch(fetchTransitionGraphDataSuccess(data, isEmpty));  
    
    } catch (error) {
    dispatch(fetchTransitionGraphDataFailure(error.message));       
    }

};