import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FacilityList from '../components/FacilityList';
import { checkGaugeData, fetchFacilities, replaceFacilities } from '../actions/facilitylist';

const mapStateToProps = (state) => {
    const { facilityList } = state;
    const user = state.login.user;
    return {
        user,
        facilities: facilityList.facilities,
        loading: facilityList.loading,
        error: facilityList.error,
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchFacilities,
    replaceFacilities,
    checkGaugeData
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FacilityList);