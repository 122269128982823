import ActionTypes from "../constants/ActionTypes";
import BASE_URL from "../constants/Api";
import axiosInstance from "../helpers/refreshTokenMiddleware";

export const fetchConfirmFirstMonthRequest = () => ({
    type: ActionTypes.FETCH_CONFIRM_FIRST_MONTH_REQUEST,
});

export const fetchConfirmFirstMonthSuccess = (data) => ({
    type: ActionTypes.FETCH_CONFIRM_FIRST_MONTH_SUCCESS,
    payload: data,
});

export const fetchConfirmFirstMonthFailure = (error) => ({
    type: ActionTypes.FETCH_CONFIRM_FIRST_MONTH_FAILURE,
    payload: error,
});

// Thunk to fetch data from API
export const fetchConfirmFirstMonth = () => {
    return async (dispatch) => {
        dispatch(fetchConfirmFirstMonthRequest());
        try {
            const accessToken = localStorage.getItem('accessToken');
            const response = await axiosInstance.get(BASE_URL + '/setting/confirm-first-month', {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            
            const data = response.data.respons;
            dispatch(fetchConfirmFirstMonthSuccess(data)); 
            
        } catch (error) {
            dispatch(fetchConfirmFirstMonthFailure(error.message));
        }
    };
};