import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import LoginHistory from '../components/LoginHistory';
import { fetchLoginHistory, fetchGetLoginHistory} from '../actions/loginhistory';

const mapStateToProps = (state) => {
    const { loginHistory } = state;
    const user = state.login.user;
    return {
        user,
        data: loginHistory.data, 
        loading: loginHistory.loading,
        error: loginHistory.error,
        dataLogin: loginHistory.dataLogin
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchLoginHistory,
    fetchGetLoginHistory
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LoginHistory);