import React from 'react';
import './Breadcrumb.css';

const Breadcrumb = React.memo(
    ({
        isTopPage = false, // Default value for isTopPage
        userName,
        pageName,
        onTopClick
    }) => {
        return (
            <div className='lay-breadcrumb'>
                {(isTopPage === true)  ? (
                    <div className='item'></div>
                ) : (
                    <div className='item'>
                        <ul>
                            <li>
                                <button className='bread_btn' id="bread_top_btn" onClick={onTopClick}>
                                    トップ
                                </button>
                            </li>
                            <li>{pageName}</li>
                        </ul>
                    </div>
                )}

                <div className='option' id='option'>
                    <span>ログイン：</span>
                    <span id='login_user_name'>{userName}</span>
                </div>
            </div>
        );
    }
);

export default Breadcrumb;
