import React from 'react';
import './Maintenance.css';

const Maintenance = React.memo(
    ({maintenance, screen_message}) => {
        const subMessage = localStorage.getItem("screen_message");

        const handleGoBack = (event)  => {
            window.location.href = '/';
        };

        return (
            <div className='lay-notice'>
                <img src="img/common/navi/logo.png" alt="巡回点検楽スルー"/>
                <div className='main_message'>
                    <p></p>
                </div>
                <div className='sub_message'>
                    <p>{subMessage}</p>
                </div>
                <div className='button'>
                    <button type='button' className='mod-btn__round moresmall redirect_btn' onClick={handleGoBack}>ログイン画面に戻る</button>

                </div>
            </div>

        );

    }
);

export default Maintenance;

