import ActionTypes from '../constants/ActionTypes'; 

const initialState = {
    loading: false,
    error: null,
    loginId: '', 
};

const idPasswordReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.UPDATE_ID_PASSWORD_REQUEST:
            return { ...state, loading: true, error: null };
        case ActionTypes.UPDATE_ID_PASSWORD_SUCCESS:
            return { ...state, loading: false, message: action.payload, error: null };
        case ActionTypes.UPDATE_ID_PASSWORD_FAILURE:
            return { ...state, loading: false, error: action.payload };
        case ActionTypes.FETCH_USER_SUCCESS: 
            return { ...state, loginId: action.payload.login_id, error: null };
        case ActionTypes.FETCH_USER_FAILURE:
            return { ...state, error: action.payload };
        default:
            return state;
    }
};

export default idPasswordReducer;
