// index.js in the 'src' directory

import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import {store } from './helpers/store'; // Make sure you have a store.js or equivalent
import App from './App/App'; // Adjust the path as needed
import './index.css'; // If you have global styles

// Get the root element from your HTML
const rootElement = document.getElementById('root');

// Create a root
const root = createRoot(rootElement);

// Render your app using the root.render method
root.render(
    <Provider store={store}>
      <App />
    </Provider>
);