import ActionTypes from '../constants/ActionTypes';
import axiosInstance from '../helpers/refreshTokenMiddleware';
import BASE_URL from '../constants/Api';
// import { format } from 'date-fns';

export const fetchSearchlistRequest = () => ({
    type: ActionTypes.FETCH_SEARCHLIST_REQUEST,
});

export const fetchSearchlistSuccess = (data) => ({
    type: ActionTypes.FETCH_SEARCHLIST_SUCCESS,
    payload: data,
});

export const fetchSearchlistFailure = (error) => ({
    type: ActionTypes.FETCH_SEARCHLIST_FAILURE,
    payload: error,
});

export const fetchComparisonGraphListRequest = () => ({
    type: ActionTypes.FETCH_COMPARISON_GRAPH_LIST_REQUEST,
});

export const fetchComparisonGraphListSuccess = (data) => ({
    type: ActionTypes.FETCH_COMPARISON_GRAPH_LIST_SUCCESS,
    payload: data,
});

export const fetchComparisonGraphListFailure = (error) => ({
    type: ActionTypes.FETCH_COMPARISON_GRAPH_LIST_FAILURE,
    payload: error,
});

export const fetchSearchlist = () => async (dispatch) => {
    dispatch(fetchSearchlistRequest());
    try {
        const response = await axiosInstance.post(BASE_URL + '/top/searchlist', {failure_id: 0});
        dispatch(fetchSearchlistSuccess(response.data.respons));
    } catch (error) {
        const errorMessage = error.response && error.response.status === 504 
            ? '504 ゲートウェイタイムアウト。\n後でもう一度お試しください。' 
            : error.message;
        dispatch(fetchSearchlistFailure(errorMessage));
    }
};

export const fetchComparisonGraphList = (startDate, endDate, facilityId, measureId, viewType) => async (dispatch) => {
    dispatch(fetchComparisonGraphListRequest());
    try {
        const response = await axiosInstance.post(BASE_URL + '/graph/comparison-graph-list', {
            graphCount: 1,
            start_date: startDate,
            end_date: endDate,
            facility_id: facilityId,
            measure_id: measureId,
            view_type: viewType});
        dispatch(fetchComparisonGraphListSuccess(response.data.respons));
    } catch (error) {
        const errorMessage = error.response && error.response.status === 504 
            ? '504 ゲートウェイタイムアウト。\n後でもう一度お試しください。' 
            : error.message;
        dispatch(fetchComparisonGraphListFailure(errorMessage));
    }

};