import ActionTypes from '../constants/ActionTypes';

const initialState = {
    standard_time: null,
    diff_flg: null,
    diff_type: null,
    graph_data: null,
    gauge_id: null,
    measure_id: null,
    is_loaded: false,
    start_date: null,
    end_date: null,
    start_date_real: null,
    end_date_real: null,
    view_type: null,
    unit: null,
    max_limit: null,
    min_limit: null,
    error: null,
    isFetched: false,
    isLoading: false,
};

const transitionGraphReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.FETCH_TRANSITION_GRAPH_FAILURE:
            return {
                ...state,
                isFetched: false,
                isLoading: false,
            };
        case ActionTypes.FETCH_TRANSITION_GRAPH_REQUEST:
            return {
                ...state,
                isFetched: false,
                isLoading: true,
            };
        case ActionTypes.FETCH_TRANSITION_GRAPH_SUCCESS:
            return {
                ...state,
                standard_time: action.payload.data.standard_time,
                diff_flg: action.payload.data.diff_flg,
                diff_type: action.payload.data.diff_type,
                graph_data: action.payload.data.data,
                gauge_id: action.payload.data.gauge_id,
                measure_id: action.payload.data.measure_id,
                is_loaded: action.payload.data.is_loaded,
                start_date: action.payload.data.start_date,
                end_date: action.payload.data.end_date,
                start_date_real: action.payload.data.start_date_real,
                end_date_real: action.payload.data.end_date_real,
                view_type: action.payload.data.view_type,
                unit: action.payload.data.unit,
                max_limit: action.payload.data.max_limit,
                min_limit: action.payload.data.min_limit,
                error: action.payload.data.error,
                isFetched: true,
                isLoading: false,
            };
        default:
            return state;
    }

};

export default transitionGraphReducer;