import React from 'react'
import './ComparisonGraphCameraInfo.css'

const ComparisonGraphCameraInfo = React.memo(
    ({id, display_facility_name, display_camera_name, display_gauge_name, measure_name, unit, onChangeCheckbox}) => {
        const handleChangeCheckbox = (event) => {
            const { tabIndex, checked } = event.target;
            onChangeCheckbox(tabIndex, checked);
        };
        return (
            <div className="lay-search_terms-compgraph">
                <div className="lay-search_terms-compgraph-wrap">
                    <div className="lay-search_terms-compgraph__list">
                        <div className="lay-search_terms-compgraph__list__title">
                            <img src="img/common/icon-terms-result.png" alt="検索結果" />
                        </div>
                        <div className="lay-search_terms-compgraph__list__text" id="searchtext">
                        {display_facility_name} ／ {display_camera_name} ／ {display_gauge_name} ／ {measure_name}[{unit}]
                            <div className="lay-list-compgraph__table__comment">
                                <label>
                                <input type="checkbox" 
                                       className="checkbox-input" 
                                       name="graph_check_count" 
                                       id={`graph_check_count${id}`} 
                                       tabIndex={id}
                                       onChange={handleChangeCheckbox}
                                />
                                <span className="checkbox-parts"></span>
                                </label>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
);

export default ComparisonGraphCameraInfo;