import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import ImageSlider from './ImageSlider';
import DataEdit from './DataEdit';
import DataDelete from './DataDelete';
import DataRegister from './DataRegister';

const LandscapeView = ({
    mainImage,
    setMainImage,
    images,
    currentPage,
    fontSizes,
    comments,
    handleCommentChange,
    handleFontSizeChange,
    handleImageClick,
    fetchImageList,
    selectedItem,
    startDate,
    endDate,
    selectedFacility,
    selectedCamera,
    selectedGauge,
    setLoading,
    setComments,
    setFontSizes,
    fnEditData,
    failure_id,
    setCurrentPage,
    getPageFromMainImage,
    isSliderAction,
    actionType,
    formatDiffVal, 
    screenType, 
    setModalMessage,
    setIsModalOpen,
    setMeasureValues,
    editStatus,
    setEditStatus,
    setRelativeActive
}) => {
    const [inputValues, setInputValues] = useState({});

    useEffect(() => {
        const slider = document.querySelector('.image-slider');
        const mainImage = document.querySelector('.main-image-info');
        if (slider && mainImage) {
            const sliderHeight = slider.offsetHeight;
            mainImage.style.paddingBottom = `${sliderHeight}px`;
        }
    }, []);

    useEffect(() => {
        setInputValues({
            ...mainImage.gaugeDataList?.reduce((acc, gauge, index) => {
            acc[`input_${index}`] = gauge.measureVal || '';
            return acc;
            }, {}),
        });

        setComments({
            [mainImage.relativeIndex]: mainImage.comment || '',
        });
    }, [mainImage, setComments]);

    const validateInput = (inputValue) => {
        const intNumLimit = 10;
        const decNumLimit = 5;
    
        const isNumeric = /^[+-]?\d*\.?\d*$/.test(inputValue);
        const dotCount = (inputValue.match(/\./g) || []).length;
        const hyphenCount = (inputValue.match(/-/g) || []).length;
    
        if (
            !isNumeric ||
            dotCount > 1 ||
            hyphenCount > 1 ||
            (hyphenCount === 1 && inputValue.indexOf('-') !== 0)
        ) {
            setModalMessage('数値のみ入力可能です。');
            setIsModalOpen(true);
            return false;
        }
    
        const [intPart, decPart] = inputValue.split('.');
    
        if (intPart.replace('-', '').length > intNumLimit) {
            setModalMessage(`整数の数値は、${intNumLimit}桁までの入力です。`);
            setIsModalOpen(true);
            return false;
        }
    
        if (dotCount === 1 && decPart && decPart.length > decNumLimit) {
            setModalMessage(`小数の数値は、${decNumLimit}桁までの入力です。`);
            setIsModalOpen(true);
            return false;
        }
    
        return true;
    };
    
    const handleMeasureChange = (event, gauge, index) => {
        let inputValue = event.target.value.trim();
        inputValue = inputValue.replace(',', '.');
        if (inputValue === "") {
            updateDiffValue("--", mainImage.relativeIndex, index, "black");
            setInputValues((prevValues) => ({
                ...prevValues,
                [`input_${index}`]: "",
            }));
            return;
        }
    
        if (!validateInput(inputValue)) {
            updateDiffValue("--", mainImage.relativeIndex, index, "black");
            setInputValues((prevValues) => ({
                ...prevValues,
                [`input_${index}`]: "",
            }));
            return;
        }
    
        const decimalValue = gauge.decimal_value;
        let formattedValue;
    
        if (decimalValue === -1) {
            formattedValue = inputValue;
        } else if (decimalValue === 0) {
            formattedValue = Math.trunc(parseFloat(inputValue)).toString();
        } else if (decimalValue >= 1 && decimalValue <= 5) {
            const factor = Math.pow(10, decimalValue);
            formattedValue = (Math.trunc(parseFloat(inputValue) * factor) / factor).toFixed(decimalValue);
        }
    
        event.target.value = formattedValue;
        setInputValues((prevValues) => ({
            ...prevValues,
            [`input_${index}`]: formattedValue,
        }));
    
        if (gauge.prev_measure_val !== null && gauge.prev_measure_val !== undefined) {
            const calculatedDiff = parseFloat(formattedValue) - gauge.prev_measure_val;
            const roundedDiff = Math.round(calculatedDiff * Math.pow(10, 5)) / Math.pow(10, 5);
    
            let formattedDiff;
            if (calculatedDiff < 0 || isNaN(roundedDiff)) {
                formattedDiff = '--';
            } else if (roundedDiff === 0) {
                formattedDiff = '0';
            } else {
                formattedDiff = decimalValue >= 0
                    ? roundedDiff.toFixed(decimalValue)
                    : roundedDiff.toString().replace(/\.?0+$/, '');
            }
    
            const diffElement = document.getElementById(`dv_${mainImage.relativeIndex}_${index}`);
            if (diffElement) {
                diffElement.textContent = formattedDiff;
                diffElement.style.color = getValueColor(
                    gauge.diffFlg,
                    gauge.threshold_min_flg,
                    gauge.threshold_max_flg,
                    gauge.diff_threshold_min_flg,
                    gauge.diff_threshold_max_flg
                );
            }
        } else {
            updateDiffValue('--', mainImage.relativeIndex, index, 'black');
        }
    };     

    const updateDiffValue = (value, relativeIndex, index, color) => {
        const diffElement = document.getElementById(`dv_${relativeIndex}_${index}`);
        if (diffElement) {
            diffElement.textContent = value;
            diffElement.style.color = color;
        }
    };
    
    const getValueColor = (
        diffFlg,
        thresholdMinFlg,
        thresholdMaxFlg,
        diffThresholdMinFlg,
        diffThresholdMaxFlg
    ) => {
        const isMeasureAbnormal = thresholdMinFlg || thresholdMaxFlg;
        const isMeasureAbnormalDiff = diffThresholdMinFlg || diffThresholdMaxFlg;
        const isAbnormal = diffFlg ? isMeasureAbnormalDiff : isMeasureAbnormal;
        return isAbnormal ? "red" : "black";
    };    
    
    return (
        <div className="image-list-container" style={{position: 'relative', paddingBottom: '20px', maxHeight: '360px'}}>
            {mainImage && (
                <div className={`main-image-info ${screenType} ${
                    mainImage.status_class === 2 && mainImage.disuse_flg !== 1
                        ? 'lay-list__horizontal-error'
                        : ''
                }`}>
                    <form id={`dataform${mainImage.relativeIndex}`} onSubmit={(e) => e.preventDefault()}>
                        <div className="image-info">
                            <div className={`lay-list__horizontal__info ${screenType}`}>
                                <div className={`lay-list__horizontal__date ${screenType}`}>
                                    {mainImage.disp_datetime}
                                </div>
                                <div className={`lay-list__horizontal__name ${screenType}`}>
                                    {mainImage.display_camera_name}<br />
                                    {mainImage.display_gauge_name}
                                </div>
                            </div>
                        </div>
                        <div className={`lay-list__horizontal__img ${screenType}`}>
                            <div className={`lay-list__horizontal__pct ${screenType}`}>
                                <a href={mainImage.image_url} target="_blank" rel="noopener noreferrer">
                                    <img src={mainImage.image_url} alt="" style={{width: '260px', height: '260px', objectFit: 'cover' }}/>
                                </a>
                                
                            </div>
                        </div>
                        
                        <div className={`lay-list__horizontal__data ${screenType}`}>
                            {mainImage.gaugeDataList.length > 0 && (
                                <div className={`lay-list__horizontal__para ${screenType}`}>
                                    {mainImage.gaugeDataList.map((gauge, index) => (
                                        <React.Fragment key={index}>
                                            {gauge.unit && (
                                                <>
                                                    {gauge.measureVal !== undefined || mainImage.status_class !== 0 ? (
                                                        <>
                                                            <div
                                                                className={`lay-list__horizontal__para__num ${screenType} edit_data_lbl${mainImage.relativeIndex}`}
                                                                style={{ display: 'block' }}
                                                            >
                                                                <span
                                                                    style={{
                                                                        color:
                                                                            mainImage.disuse_flg === 0 &&
                                                                            gauge.abnormal_flg === 1
                                                                                ? '#f00'
                                                                                : 'inherit',
                                                                    }}
                                                                >
                                                                    {isNaN(gauge.measureVal) ? '' : gauge.measureVal}
                                                                </span>
                                                                <span className={`lay-list__horizontal__para__num-unit ${screenType}`}>
                                                                    {gauge.unit}
                                                                </span>
                                                            </div>
                                                            <div
                                                                className={`mod-form__num ${screenType} edit_data${mainImage.relativeIndex}`}
                                                                style={{ display: 'none' }}
                                                            >
                                                                <input
                                                                    type="text"
                                                                    id={`mv_${mainImage.relativeIndex}_${index}`}
                                                                    name={`measure_val${index}`}
                                                                    value={inputValues[`input_${index}`] || ''}
                                                                    onChange={(e) => {
                                                                        const value = e.target.value.replace(',', '.'); 
                                                                        setInputValues((prev) => ({
                                                                            ...prev,
                                                                            [`input_${index}`]: value, 
                                                                        }));
                                                                    }}
                                                                    inputMode="decimal"
                                                                    pattern="^-?[0-9]*[.,]?[0-9]+$"
                                                                    onKeyDown={(e) => {
                                                                        if (e.key === 'Enter') {
                                                                            e.preventDefault(); 
                                                                            handleMeasureChange(e, gauge, index, mainImage.relativeIndex); 
                                                                            }
                                                                        }}
                                                                    onBlur={(e) => handleMeasureChange(e, gauge, index)}
                                                                />
                                                                <span className={`mod-form__num-unit ${screenType}`}>{gauge.unit}</span>
                                                                <input type="hidden" name={`diff_flg${index}`} value={gauge.diffFlg || ''} />
                                                                <input type="hidden" name={`measure_id${index}`} value={gauge.measure_id || ''} />
                                                                <input type="hidden" name={`decimal_value${index}`} value={gauge.decimal_value || ''} />
                                                                <input type="hidden" name={`min_limit${index}`} value={gauge.min_limit || ''} />
                                                                <input type="hidden" name={`min_limit_flg${index}`} value={gauge.min_limit_flg || ''} />
                                                                <input type="hidden" name={`max_limit${index}`} value={gauge.max_limit || ''} />
                                                                <input type="hidden" name={`max_limit_flg${index}`} value={gauge.max_limit_flg || ''} />
                                                                <input type="hidden" name={`diff_type${index}`} value={gauge.diff_type || ''} />
                                                                <input type="hidden" name={`diff_val${index}`} id={`newdv_${mainImage.relativeIndex}_${index}`} value={gauge.diff_val || ''} />
                                                                <input type="hidden" name={`diff_min_limit${index}`} value={gauge.diff_min_limit || ''} />
                                                                <input type="hidden" name={`diff_min_limit_flg${index}`} value={gauge.diff_min_limit_flg || ''} />
                                                                <input type="hidden" name={`diff_max_limit${index}`} value={gauge.diff_max_limit || ''} />
                                                                <input type="hidden" name={`diff_max_limit_flg${index}`} value={gauge.diff_max_limit_flg || ''} />
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div
                                                                className={`lay-list__horizontal__para__num ${screenType} edit_data_lbl${mainImage.relativeIndex}`}
                                                                style={{ display: 'none' }}
                                                            >
                                                                {isNaN(gauge.measureVal) ? '' : gauge.measureVal}
                                                                <span className={`lay-list__horizontal__para__num-unit ${screenType}`}>{gauge.unit}</span>
                                                            </div>
                                                            <div
                                                                className={`mod-form__num ${screenType} edit_data${mainImage.relativeIndex}`}
                                                                style={{ display: 'block' }}
                                                            >
                                                                <input
                                                                    type="text"
                                                                    id={`mv_${mainImage.relativeIndex}_${index}`}
                                                                    name={`measure_val${index}`}
                                                                    value={inputValues[`input_${index}`] || ''}
                                                                    onChange={(e) => {
                                                                        const value = e.target.value.replace(',', '.'); 
                                                                        setInputValues((prev) => ({
                                                                            ...prev,
                                                                            [`input_${index}`]: value,
                                                                        }));
                                                                    }}
                                                                    inputMode="decimal"
                                                                    pattern="^-?[0-9]*[.,]?[0-9]+$"
                                                                    onKeyDown={(e) => {
                                                                        if (e.key === 'Enter') {
                                                                            e.preventDefault(); 
                                                                            handleMeasureChange(e, gauge, index, mainImage.relativeIndex); 
                                                                            }
                                                                        }}
                                                                    onBlur={(e) => handleMeasureChange(e, gauge, index)}
                                                                />
                                                                <span className={`mod-form__num-unit ${screenType}`}>{gauge.unit}</span>
                                                                <input type="hidden" name={`diff_flg${index}`} value={gauge.diffFlg || ''} />
                                                                <input type="hidden" name={`measure_id${index}`} value={gauge.measure_id || ''} />
                                                                <input type="hidden" name={`decimal_value${index}`} value={gauge.decimal_value || ''} />
                                                                <input type="hidden" name={`min_limit${index}`} value={gauge.min_limit || ''} />
                                                                <input type="hidden" name={`min_limit_flg${index}`} value={gauge.min_limit_flg || ''} />
                                                                <input type="hidden" name={`max_limit${index}`} value={gauge.max_limit || ''} />
                                                                <input type="hidden" name={`max_limit_flg${index}`} value={gauge.max_limit_flg || ''} />
                                                                <input type="hidden" name={`diff_type${index}`} value={gauge.diff_type || ''} />
                                                                <input type="hidden" name={`diff_val${index}`} id={`newdv_${mainImage.relativeIndex}_${index}`} value={gauge.diff_val || ''} />
                                                                <input type="hidden" name={`diff_min_limit${index}`} value={gauge.diff_min_limit || ''} />
                                                                <input type="hidden" name={`diff_min_limit_flg${index}`} value={gauge.diff_min_limit_flg || ''} />
                                                                <input type="hidden" name={`diff_max_limit${index}`} value={gauge.diff_max_limit || ''} />
                                                                <input type="hidden" name={`diff_max_limit_flg${index}`} value={gauge.diff_max_limit_flg || ''} />
                                                            </div>
                                                        </>
                                                    )}
                                                    <div
                                                        className={`lay-list__horizontal__para__num ${screenType} diff`}
                                                        style={{
                                                            display:
                                                                mainImage.disuse_flg === 0 && gauge.diffFlg === 1
                                                                    ? 'block'
                                                                    : 'none',
                                                            color: getValueColor(
                                                                gauge.diffFlg,
                                                                gauge.threshold_min_flg,
                                                                gauge.threshold_max_flg,
                                                                gauge.diff_threshold_min_flg,
                                                                gauge.diff_threshold_max_flg
                                                            ),
                                                        }}
                                                    >
                                                        <span className={`lay-list__horizontal__para__text ${screenType}`}>差分値：</span>
                                                        <span
                                                            id={`dv_${mainImage.relativeIndex}_${index}`}
                                                            style={{
                                                                color: getValueColor(
                                                                    gauge.diffFlg,
                                                                    gauge.threshold_min_flg,
                                                                    gauge.threshold_max_flg,
                                                                    gauge.diff_threshold_min_flg,
                                                                    gauge.diff_threshold_max_flg
                                                                ),
                                                            }}
                                                        >
                                                            {formatDiffVal(gauge.diffVal, gauge.decimal_value) ||
                                                                gauge.displayDiffVal ||
                                                                '--'}
                                                        </span>
                                                        <span className={`lay-list__horizontal__para__num-unit ${screenType}`}>{gauge.unit}</span>
                                                    </div>
                                                </>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </div>
                            )}

                            <div className={`lay-list__horizontal__comment ${screenType}`}>
                            <div className={`lay-list__horizontal__comment__title ${screenType}`}>コメント入力欄</div>
                            <div className={`mod-form__textarea ${screenType}`}>
                                {(mainImage.gaugeDataList.some(gauge => gauge.measureVal) || 
                                    mainImage.status_class === 1 || failure_id === 1) ? (
                                    <>
                                        <textarea
                                            id={`edit_comment_lbl${mainImage.relativeIndex}`}
                                            name="comment"
                                            style={{
                                                fontSize: `${fontSizes[mainImage.relativeIndex] || mainImage.comment_font_size}pt`,
                                                display: 'block',
                                            }}
                                            value={
                                                comments[mainImage.relativeIndex] !== undefined
                                                    ? comments[mainImage.relativeIndex]
                                                    : mainImage.comment || ''
                                            }
                                            onChange={(e) =>
                                                handleCommentChange(mainImage.relativeIndex, e)
                                            }
                                            readOnly={true}
                                        ></textarea>
                                        <textarea
                                            id={`edit_comment${mainImage.relativeIndex}`}
                                            name="comment"
                                            style={{
                                                fontSize: `${fontSizes[mainImage.relativeIndex] || mainImage.comment_font_size}pt`,
                                                display: 'none',
                                            }}
                                            value={
                                                comments[mainImage.relativeIndex] !== undefined
                                                    ? comments[mainImage.relativeIndex]
                                                    : mainImage.comment || ''
                                            }
                                            onChange={(e) =>
                                                handleCommentChange(mainImage.relativeIndex, e)
                                            }
                                        ></textarea>
                                    </>
                                ) : (
                                    <>
                                        <textarea
                                            id={`edit_comment_lbl${mainImage.relativeIndex}`}
                                            name="comment"
                                            style={{
                                                fontSize: `${fontSizes[mainImage.relativeIndex] || mainImage.comment_font_size}pt`,
                                                display: 'none',
                                            }}
                                            value={
                                                comments[mainImage.relativeIndex] !== undefined
                                                    ? comments[mainImage.relativeIndex]
                                                    : mainImage.comment || ''
                                            }
                                            onChange={(e) =>
                                                handleCommentChange(mainImage.relativeIndex, e)
                                            }
                                            readOnly={true}
                                        ></textarea>
                                        <textarea
                                            id={`edit_comment${mainImage.relativeIndex}`}
                                            name="comment"
                                            style={{
                                                fontSize: `${fontSizes[mainImage.relativeIndex] || mainImage.comment_font_size}pt`,
                                                display: 'block',
                                            }}
                                            value={
                                                comments[mainImage.relativeIndex] !== undefined
                                                    ? comments[mainImage.relativeIndex]
                                                    : mainImage.comment || ''
                                            }
                                            onChange={(e) =>
                                                handleCommentChange(mainImage.relativeIndex, e)
                                            }
                                        ></textarea>
                                    </>
                                )}
                            </div>
                            </div>
                            {(mainImage.gaugeDataList.some(gauge => gauge.measureVal) || mainImage.status_class === 1) && (
                            <div className={`lay-list__horizontal_slider ${screenType}`}>
                                <label>
                                    <span
                                        id={`leftLabel${mainImage.relativeIndex}`}
                                        style={{ fontSize: '12pt', display: 'none' }}
                                    >
                                        A
                                    </span>
                                    <input
                                        type="range"
                                        className="slider"
                                        id={`size_edit${mainImage.relativeIndex}`}
                                        name="comment_font_size"
                                        min="6"
                                        max="18"
                                        style={{ display: 'none' }}
                                        value={fontSizes[mainImage.relativeIndex] || mainImage.comment_font_size || ''}
                                        step="1"
                                        onChange={(e) =>
                                            handleFontSizeChange(mainImage.relativeIndex, e.target.value)
                                        }
                                    />
                                    <span
                                        id={`rightLabel${mainImage.relativeIndex}`}
                                        style={{ fontSize: '18pt', display: 'none' }}
                                    >
                                        A
                                    </span>
                                </label>
                            </div>
                            )}
                            {failure_id !== 1 && (
                                (mainImage.gaugeDataList.some(gauge => gauge.measureVal) || mainImage.status_class === 1) ? (
                                    <div className={`lay-list__horizontal__btn ${screenType}`}>
                                        <button
                                            type="button"
                                            className="mod-btn__roundborder small"
                                            id={`edit_btn${mainImage.relativeIndex}`}
                                            onClick={() =>
                                                fnEditData(mainImage.relativeIndex, mainImage, mainImage.gaugeDataList)
                                            }
                                        >
                                            編集
                                        </button>
                                        <DataEdit
                                            relativeIndex={mainImage.relativeIndex}
                                            fetchImageList={fetchImageList}
                                            selectedItem={selectedItem}
                                            startDate={startDate}
                                            endDate={endDate}
                                            selectedFacility={selectedFacility}
                                            selectedCamera={selectedCamera}
                                            selectedGauge={selectedGauge}
                                            comments={comments}
                                            fontSizes={fontSizes}
                                            setComments={setComments}
                                            setFontSizes={setFontSizes}
                                            setLoading={setLoading}
                                            isPopup={false}
                                            screenType={screenType}
                                            editStatus={editStatus}
                                            setEditStatus={setEditStatus}
                                            setRelativeActive={setRelativeActive}
                                        />
                                        <DataDelete
                                            relativeIndex={mainImage.relativeIndex}
                                            fetchImageList={fetchImageList}
                                            selectedItem={selectedItem}
                                            startDate={startDate}
                                            endDate={endDate}
                                            selectedFacility={selectedFacility}
                                            selectedCamera={selectedCamera}
                                            selectedGauge={selectedGauge}
                                            comments={comments}
                                            setComments={setComments}
                                            setLoading={setLoading}
                                            screenType={screenType}
                                            setMeasureValues={setMeasureValues}
                                            editStatus={editStatus}
                                            setEditStatus={setEditStatus}
                                            setRelativeActive={setRelativeActive}
                                        />
                                    </div>
                                ) : (
                                    <div className={`lay-list__horizontal__btn ${screenType}`}>
                                        <DataRegister
                                            relativeIndex={mainImage.relativeIndex}
                                            fetchImageList={fetchImageList}
                                            selectedItem={selectedItem}
                                            startDate={startDate}
                                            endDate={endDate}
                                            selectedFacility={selectedFacility}
                                            selectedCamera={selectedCamera}
                                            selectedGauge={selectedGauge}
                                            comments={comments}
                                            setComments={setComments}
                                            setLoading={setLoading}
                                            isPopup={false}
                                            screenType={screenType}
                                            editStatus={editStatus}
                                            setEditStatus={setEditStatus}
                                            setRelativeActive={setRelativeActive}
                                        />
                                    </div>
                                )
                            )}
                        </div>

                        {/* Input hidden */}
                        <input type="hidden" name="company_id" value={mainImage.company_id || ''} />
                        <input type="hidden" name="gauge_id" value={mainImage.gauge_id || ''} />
                        <input type="hidden" name="device_id" value={mainImage.device_id || ''} />
                        <input type="hidden" name="get_datetime" value={mainImage.get_datetime || ''} />
                        <input type="hidden" name="this_count" value={mainImage.relativeIndex || ''} />
                        <input type="hidden" name="tot_count" value={images.length || ''} />
                        <input type="hidden" name="page_cnt" value={currentPage || ''} />
                        <input type="hidden" name="cnt" value={mainImage.gaugeDataList.length || ''} />

                    </form>

                </div>
            )}
            <ImageSlider
                images={images}
                onImageClick={(image) =>
                    handleImageClick(image, image.info.relativeIndex)
                }
                mainImage={mainImage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                getPageFromMainImage={getPageFromMainImage}
                isSliderAction={isSliderAction}
                // setSliderAction={() => { isSliderAction.current = true; }}
                actionType={actionType}
                screenType={screenType}
            />
        </div>
    );
};

LandscapeView.propTypes = {
    mainImage: PropTypes.object,
    images: PropTypes.array.isRequired,
    currentPage: PropTypes.number.isRequired,
    fontSizes: PropTypes.object.isRequired,
    comments: PropTypes.object.isRequired,
    handleCommentChange: PropTypes.func.isRequired,
    handleFontSizeChange: PropTypes.func.isRequired,
    handleImageClick: PropTypes.func.isRequired,
    fetchImageList: PropTypes.func.isRequired,
    selectedItem: PropTypes.object.isRequired,
    startDate: PropTypes.instanceOf(Date).isRequired,
    endDate: PropTypes.instanceOf(Date).isRequired,
    selectedFacility: PropTypes.string.isRequired,
    selectedCamera: PropTypes.string.isRequired,
    selectedGauge: PropTypes.string.isRequired,
    setLoading: PropTypes.func.isRequired,
    setComments: PropTypes.func.isRequired,
    setFontSizes: PropTypes.func.isRequired,
    fnEditData: PropTypes.func.isRequired,
    failure_id: PropTypes.number.isRequired,
};

export default LandscapeView;
