import ActionTypes from '../constants/ActionTypes'; 

const initialState = {
    loading: false,
    error: null,
    userName: '', 
    userId: null, 
};

const userNameReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.UPDATE_USER_NAME_REQUEST:
            return { ...state, loading: true, error: null };
        case ActionTypes.UPDATE_USER_NAME_SUCCESS:
            return { ...state, loading: false, message: action.payload, error: null };
        case ActionTypes.UPDATE_USER_NAME_FAILURE:
            return { ...state, loading: false, error: action.payload };
        case ActionTypes.FETCH_USER_SUCCESS: 
            return { ...state, userName: action.payload.user_name, userId: action.payload.user_id, error: null };
        case ActionTypes.FETCH_USER_FAILURE:
            return { ...state, error: action.payload };
        default:
            return state;
    }
};

export default userNameReducer;
