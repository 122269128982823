import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Modal from '../Modal/Modal';
import './Setting2FA.css';
import Breadcrumb from '../Breadcrumb';

const Setting2FA = ({error, updateSetting2FA, fetchUserData, mail2FA, using2FAflg, user }) => {
    const navigate = useNavigate ();
    const location = useLocation ();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [email, setEmail] = useState('');
    const [is2FAEnabled, setIs2FAEnabled] = useState(false);
    const [errorEmail, setErrorEmail] = useState('');
    const [showUserName, setUserName] = useState('');
    const [loading, setLoading] = useState(false);
    const screenType = useSelector((state) => state.device.screenType);

    // Fetch user data when the component mounts
    useEffect(() => {
        const loadData = async () => {
            setLoading(true);
            await fetchUserData();
            setLoading(false);
        };
        loadData();
    }, [fetchUserData, location]);

    // Set initial values for email and checkbox based on props from fetchUserData
    useEffect(() => {
        setEmail(mail2FA || '');
        setIs2FAEnabled(using2FAflg === 1);
    }, [mail2FA, using2FAflg]);

    useEffect(() => {
        if (user) {
          setUserName(user.user_name);
        };
    }, [user]);

    const handleCheckboxChange = (e) => {
        const checked = e.target.checked;
        setIs2FAEnabled(checked);

        if (!checked) {
            setEmail(mail2FA || '');
            setErrorEmail(''); 
        }
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    // Validate email format
    const isEmailValid = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const handleUpdateSetting2FA = async () => {
        setErrorEmail(''); // Clear previous email error
    
        // Check if 2FA is enabled and email is empty
        if (is2FAEnabled && !email.trim()) {
            setErrorEmail('メールアドレスを入力してください。'); // "Please enter an email address."
            return;
        }
    
        // Check if email format is valid
        if (is2FAEnabled && !isEmailValid(email)) {
            setErrorEmail('メールアドレスが正しくありません。'); // "The email address is incorrect."
            return;
        }
    
        // Check if there are any changes
        if (email === mail2FA && is2FAEnabled === (using2FAflg === 1)) {
            setModalMessage("保存しました。");
            setIsModalOpen(true);
            return;
        }
    
        const is2FAEnabledValue = is2FAEnabled ? 1 : 0;

        // Attempt to update settings via API
        try {
            const response = await updateSetting2FA(is2FAEnabledValue, email);
            fetchUserData(); // Refresh user data after updating settings
            const message = response.data?.respons?.msg || "Settings updated successfully!";
            setModalMessage(message);
            setIsModalOpen(true);
        } catch (error) {
            // Display server error if available
            const errorMessage = error.response?.data?.respons?.msg || "Failed to update settings.";
            setModalMessage(errorMessage);
            setIsModalOpen(true);
        }
    };

    const handleTopClick = () => {
        navigate('/');
    };
    
    return (
        <div id='Main' className={`main ${screenType}`}>
            <div id='Contents'>
                <Breadcrumb
                    userName = {showUserName}
                    pageName = "ユーザ情報"
                    onTopClick = {handleTopClick}
                />
                {isModalOpen && (
                    <Modal
                        isOpen={isModalOpen}
                        onClose={() => setIsModalOpen(false)}
                        message={modalMessage}
                    />
                )}
                <div id="ajax-view-disp">
                    <div className="display-main">
                        <div className={`lay-master-set ${screenType}`}>
                            <div className="lay-master-set-wrap">
                                <div className="lay-master-set__title">
                                    <span>二段階認証設定</span>
                                </div>

                                <div className="lay-master-set__form">
                                    <form id="setting2fa">
                                        <div className="wrapper" style={{textAlign: 'left'}}>
                                            <div className="mail-send__set">
                                                <div className="checkbox-frame" style={{marginLeft: '10px'}}>
                                                    <div style={{display: 'flex'}}>
                                                        <div>
                                                            <label style={{fontWeight: 'bold', fontSize: '20px'}}>
                                                                <input 
                                                                    type="checkbox" 
                                                                    name="using_2fa_flg" 
                                                                    className="checkbox-input"
                                                                    checked={is2FAEnabled}
                                                                    onChange={handleCheckboxChange}
                                                                />
                                                                <span className="checkbox-parts"></span>
                                                                二段階認証を使用する
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mail-input">
                                                    <div style={{width: '80%', marginTop: '20px', fontSize: '18px'}}>
                                                        <div>
                                                            <span>認証コード受信用メールアドレス</span>
                                                        </div>
                                                    </div>
                                                    <div className="mod-form__set" id="mail_to_head">
                                                        <dl style={{marginTop: '10px'}}>
                                                            <dt>宛先</dt>
                                                            <dd style={{width: '70%'}}>
                                                                <div className="mod-form__text">
                                                                    <input 
                                                                        type="text" 
                                                                        id="mail_2fa" 
                                                                        name="mail_2fa" 
                                                                        value={email} 
                                                                        onChange={handleEmailChange} 
                                                                        readOnly={!is2FAEnabled}
                                                                        style={{
                                                                            background: is2FAEnabled ? '#fff' : 'rgb(238, 238, 238)'
                                                                        }}
                                                                    />
                                                                </div>
                                                                {errorEmail && (
                                                                    <div className="error_mail_2fa" style={{color: 'red'}}>
                                                                        {errorEmail}
                                                                    </div>
                                                                )}
                                                            </dd>
                                                        </dl>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="mail-btn" style={{display: 'flex', justifyContent: 'center'}}>
                                    <button type="button" className="master_long_btn" onClick={handleUpdateSetting2FA} style={{width: '200px'}}>
                                        登録
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`overlay ${loading ? 'active' : ''}`}>
                <div className={`spinner ${loading ? 'active' : ''}`}></div>
            </div>
        </div>
    );
};

Setting2FA.propTypes = {
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    updateSetting2FA: PropTypes.func.isRequired,
    fetchUserData: PropTypes.func.isRequired,
    mail2FA: PropTypes.string,
    using2FAflg: PropTypes.number,
};

export default React.memo(Setting2FA);
