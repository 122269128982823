import React, { useState } from 'react';
import { format } from 'date-fns';
import axios from "axios";
import BASE_URL from "../../constants/Api";
// import MessageBox from './MessageBox';
import ConfirmPopup from './ConfirmPopup';
import Modal from '../Modal/Modal';

const CsvDownload = ({ getSearchConditions, setIsDownloading }) => {
    const DOWNLOAD_LIMIT = 500;
    const MASK_MSG_CLOSE = 0
    const MASK_MSG_OPEN_500ITEMS_OV = 1
    const MASK_MSG_OPEN_0ITEMS = 2
    const MASK_MSG_OPEN_NOPC = 4
    const MASK_YESNO_BTN = 0
    const MASK_OK_BTN = 1
    const [msgOpen, setMsgOpen] = useState(MASK_MSG_CLOSE);

    function fetchAllDataManages() {
        const baseUrl = BASE_URL + '/top/datamanage';
        let accessToken = localStorage.getItem('accessToken');

        const fetchPage = async (page, dataManages = []) => {
            const {
                companyId,
                startDate,
                endDate,
                facilityId,
                deviceId,
                gaugeId,
                failureId,
                status,
                searchComment,
                threshold
            } = getSearchConditions();

            const requestBody = {
                company_id: Number(companyId),
                failure_id: failureId,
                start_date: startDate,
                end_date: endDate,
                page_group_number: page
            };
            const statusIndex = parseInt(status, 10);
            const searchCommentIndex = parseInt(searchComment, 10);
            const thresholdIndex = parseInt(threshold, 10);

            if (facilityId) requestBody.facility_id = Number(facilityId);
            if (deviceId) requestBody.device_id = deviceId;
            if (gaugeId) requestBody.gauge_id = gaugeId;
            if (isNaN(statusIndex) === false && status !== '') requestBody.status = statusIndex;
            if (isNaN(searchCommentIndex) === false && searchComment !== '') requestBody.search_comment = searchCommentIndex;
            if (isNaN(thresholdIndex) === false && threshold !== '') requestBody.threshold = thresholdIndex;

            const config = {
                headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
                }
            };
        
            try {
                // debugger
                const response = await axios.post(baseUrl, requestBody, config);
                const ret = response.data.respons.data;
                ret.forEach(item => {
                    const gauge_key = `${item.device_id}${item.gauge_id}${item.get_datetime}`;
                    console.log(response.data.respons.gauge_data[gauge_key]);
                    
                    item["measure_cnt"] = response.data.respons.gauge_data[gauge_key].length;
                    item["measure_arr"] = response.data.respons.gauge_data[gauge_key];
                    
                    // const gauge_data = response.data.respons.gauge_data[gauge_key]
                    // const measure_unit = gauge_data[0] && gauge_data[0].unit ? gauge_data[0].unit : "";
                    // const measure_val = gauge_data[0] && gauge_data[0].measure_val ? 
                    //                 `${gauge_data[0].measure_val} ${gauge_data[0].unit}` : measure_unit;
                    // item["measure_val"] = measure_val;

                    // const diff_unit = gauge_data[0] && gauge_data[0].unit ? `-- ${gauge_data[0].unit}` : "";
                    // const diff_val = gauge_data[0] && gauge_data[0].diff_val ? 
                    //                 `${gauge_data[0].diff_val} ${measure_unit}` : diff_unit;
                    // item["diff_val"] = diff_val;
                });
                // for (let index = 0; index < ret.length; index++) {
                //     const gauge_key = `${ret[index].device_id}${ret[index].gauge_id}${ret[index].get_datetime}`;
                //     console.log(response.data.respons.gauge_data[gauge_key][0]);
                //     const gauge_data = response.data.respons.gauge_data[gauge_key]
                //     const measure_val = gauge_data[0] && gauge_data[0].measure_val ? gauge_data[0].measure_val : "";
                //     ret[index]["measure_val"] = measure_val;
                // }
        
                if ( (ret.length === 0) || (dataManages.length + ret.length >= DOWNLOAD_LIMIT) ) {
                    const results = dataManages.concat(ret);
                    if (results.length > DOWNLOAD_LIMIT) {
                        return results.slice(0, DOWNLOAD_LIMIT);
                    }
                    return results;
                } else {
                    return fetchPage(page + 1, dataManages.concat(ret));
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                return dataManages;
            }
        };
    
        return fetchPage(1); // １ページから開始
    }

    function formatNumber(value, decimal_value) {
        if (decimal_value < 0) {
            // decimal_value = 0;
            return value.replace(/\.?0+$/, '');
        }
        return new Intl.NumberFormat('en-US', {
            minimumFractionDigits: decimal_value,
            maximumFractionDigits: decimal_value,
        }).format(value);
    }

    function checkNull(value) {
        if ((value === 0) || (value === "0")) {
            return false;
        } else if (value) {
            return false;
        }
        return true;
    }

    const fetchCsv = async () => {
        setMsgOpen(MASK_MSG_CLOSE);
        setIsDownloading(true);
        fetchAllDataManages().then( async dataManages => {
            if (dataManages.length === 0) {
                setMsgOpen(MASK_MSG_OPEN_0ITEMS);
                setIsDownloading(false);
                return;
            }

            // バッチサイズ
            const dtNow = new Date()
            const csvRows = [];
            csvRows.push(format(dtNow, '"yyyy/MM/dd HH:mm:ss"'));
            csvRows.push(format(dtNow, '"yyyyMMddHHmmss')+'.csv"');
            // csvRows.push('"日時","記録者","施設名","カメラ名","計器名","計測値","単位","状態","コメント"');
            let csv_header = '"日時","記録者","施設名","カメラ名","計器名"';
            let diff_flg_column = {};
            let measure_cnt = 0;
            dataManages.forEach(item => {
                measure_cnt = Math.max(measure_cnt, item.measure_cnt);
                for (let index = 0; index < item.measure_arr.length; index++) {
                    diff_flg_column[index] = diff_flg_column[index] | (item.measure_arr[index].diff_flg === 1);
                }
            });

            if (measure_cnt > 1){  //２以上の計測項目の場合
                for(let i=0; i < measure_cnt; i++) {
                  csv_header += `,"計測値${i+1}"`; // ,"計測値'.($i+1).'"';
                  if (diff_flg_column[i]) csv_header += `,"差分値${i+1}"`; // ',"差分値'.($i+1).'"';
                  csv_header += `,"単位${i+1}"`; // $csv_header.= ',"単位'.($i+1).'"';
                }
            } else if (measure_cnt === 1) { //計測項目が１つしかない
                csv_header += ',"計測値"';
                if (diff_flg_column[0]) csv_header += ',"差分値"';
                csv_header += ',"単位"';
            } else {  //計測項目なし
                measure_cnt = 1;
                csv_header += ',"計測値","単位"';
            }
            csv_header += ',"状態","コメント"';
            csvRows.push(csv_header);
              
            dataManages.forEach(item => {
                const row = [
                    item.disp_datetime,
                    item.user_name ? item.user_name : "",
                    item.facility_name,
                    item.display_camera_name,
                    item.display_gauge_name ? item.display_gauge_name : "",
                    // item.measure_val,
                    // item.diff_val,
                    // item.status,
                    // item.comment
                ];

                let index = 0;
                for (index = 0; index < item.measure_arr.length; index++) {
                    row.push(checkNull(item.measure_arr[index].measure_val) !== true ? formatNumber(item.measure_arr[index].measure_val, item.measure_arr[index].decimal_value) : "");
                    if (diff_flg_column[index]) {
                        row.push(checkNull(item.measure_arr[index].diff_val) !== true ? formatNumber(item.measure_arr[index].diff_val, item.measure_arr[index].decimal_value) : "--");
                    }
                    row.push(item.measure_arr[index].unit !== undefined ? item.measure_arr[index].unit : "");
                }

                if(index < measure_cnt) {
                    let temp_cnt = index;
                    for (let n=0; n < measure_cnt - temp_cnt ; n++) {
                      row.push('');
                      if (diff_flg_column[index]) row.push('--'); // ($diff_group[$n] == 1) ? ',"--"' : ',""';
                      row.push('');
                      index++;
                    }
                  }

                // item.measure_arr.forEach(measure => {
                //     row.push(measure.measure_val ? measure.measure_val : "");
                //     if (measure.diff_flg === 1) {
                //         row.push(measure.diff_val ? measure.diff_val : "");
                //     }
                //     row.push(measure.unit);
                // });
                row.push(item.status);
                row.push(item.comment);
                console.log(row);
                csvRows.push(row.map(v => `"${v.toString().replace(/"/g, '""')}"`).join(','));
            });
            const csvData = csvRows.join('\n');
            const BOM = '\uFEFF'; // BOM cho UTF-8
            const blob = new Blob([BOM + csvData], { type: 'text/csv;charset=utf-8;' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.setAttribute('href', url);
            link.setAttribute('download', format(dtNow, 'yyyyMMddHHmmss')+'.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            setIsDownloading(false);
        });
    }

    const handleDownload = async () => {
        const {searchCount, deviceType} = getSearchConditions();
        if (deviceType !== 'desktop') {
            setMsgOpen(MASK_MSG_OPEN_NOPC);
            return;
        }
        if (searchCount > 0) {
            if (searchCount > DOWNLOAD_LIMIT) {
                setMsgOpen(MASK_MSG_OPEN_500ITEMS_OV);
            } else {
                fetchCsv();
            }
        } else {
            setMsgOpen(MASK_MSG_OPEN_0ITEMS);
        }
    }

    return (
        <>
            <label className="mod-btn__round" id="csv_btn" onClick={handleDownload}>CSVダウンロード</label>
            {/* <MessageBox
                title="CSVダウンロード"
                message="検索結果が500件を超えています。<br>最新の500件のCSVデータをダウンロードします。"
                isOpen={(msgOpen & MASK_MSG_OPEN_500ITEMS_OV) === MASK_MSG_OPEN_500ITEMS_OV}
                onOK={fetchCsv}
            />
            <MessageBox
                title="撮影データダウンロード"
                message="データ検索をしてください。"
                isOpen={(msgOpen & MASK_MSG_OPEN_0ITEMS) === MASK_MSG_OPEN_0ITEMS}
                onOK={()=> {setMsgOpen(MASK_MSG_CLOSE);}}
            /> */}
            {((msgOpen & MASK_MSG_OPEN_500ITEMS_OV) === MASK_MSG_OPEN_500ITEMS_OV) && (
                <ConfirmPopup   
                    handlePhotoShoot={fetchCsv}
                    onClose={() => setMsgOpen(MASK_MSG_CLOSE)}
                    message={`検索結果が${DOWNLOAD_LIMIT}件を超えています。<br>最新の${DOWNLOAD_LIMIT}件のCSVデータをダウンロードします。`}
                    confirmButtons={MASK_YESNO_BTN}
                />
            )}
            {((msgOpen & MASK_MSG_OPEN_0ITEMS) === MASK_MSG_OPEN_0ITEMS) && (
                <ConfirmPopup   
                    handlePhotoShoot={() => setMsgOpen(MASK_MSG_CLOSE)}
                    onClose={() => setMsgOpen(MASK_MSG_CLOSE)}
                    message="データ検索をしてください。"
                    confirmButtons={MASK_OK_BTN}
                />
            )}
            {/* {((msgOpen & MASK_MSG_OPEN_NOPC) === MASK_MSG_OPEN_NOPC) && (
                <ConfirmPopup   
                    handlePhotoShoot={() => setMsgOpen(MASK_MSG_CLOSE)}
                    onClose={() => setMsgOpen(MASK_MSG_CLOSE)}
                    message="CSVダウンロードはPCからご利用ください。"
                    confirmButtons={MASK_OK_BTN}
                />
            )} */}
            <Modal 
                isOpen={((msgOpen & MASK_MSG_OPEN_NOPC) === MASK_MSG_OPEN_NOPC)} 
                onClose={() => setMsgOpen(MASK_MSG_CLOSE)} 
                message="CSVダウンロードはPCからご利用ください。" 
            />
        </>
    );
}

export default React.memo(CsvDownload);