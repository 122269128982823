/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { addDays, addMonths, format } from 'date-fns';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import './ComparisonGraphList.css';
import DataSearch from '../DataSearch/DataSearch';
import Modal from '../Modal/Modal';
import ComparisonGraphCameraInfo from '../ComparisonGraphCameraInfo/ComparisonGraphCameraInfo';
import Breadcrumb from '../Breadcrumb';

const ComparisonGraphList = React.memo(
    ({user, facilities, cameras, gauges, measureUnit, listData, isSearchListFetched, isCameraListFetched, isLoading, fetchSearchlist, fetchComparisonGraphList}) => {

      const [startDate, setStartDate] = useState('');
      const [endDate, setEndDate] = useState('');
      const [selectedFacility, setSelectedFacility] = useState('');
      const [selectedCamera, setSelectedCamera] = useState('');
      const [selectedGauge, setSelectedGauge] = useState('');
      const [selectedMeasure, setSelectedMeasure] = useState('');
      const [minimumValue, setMinimumValue] = useState('');
      const [maximumValue, setMaximumValue] = useState('');
      const [decimalValue, setDecimalValue] = useState('auto');
      const [stepSizeValue, setStepSizeValue] = useState('');
      const [viewType, setViewType] = useState("1");
      const [modalMessage, setModalMessage] = useState('');
      const [isModalOpen, setIsModalOpen] = useState(false);
      const [searchParams, setSearchParams] = useSearchParams();
      const [showListData, setShowListData] = useState([]);
      const [selectedId, setSelectedId] = useState([]);
      const [searchConditionSet, setSearchConditionSet] = useState(false);
      const [showUserName, setUserName] = useState('');
      let isShowGraph = searchParams.get('isShowGraph');
      let isSearching = false;
      const navigate = useNavigate();
      const location = useLocation();
      const screenType = useSelector((state) => state.device.screenType);

      useEffect(() => {

          const fetchData = async () => {
            fetchSearchlist();
          };

          if ((isSearchListFetched === false) ||
              (isSearchListFetched === true && searchConditionSet === false) ||
              (isSearchListFetched === true && location.search === "")) {
            fetchData();
          };

          // eslint-disable-next-line react-hooks/exhaustive-deps
  
      }, [location]);

      useEffect(() => {
        var tmpStartDate = searchParams.get('startDate');
        var tmpEndDate = searchParams.get('endDate');
        var tmpFacility = searchParams.get('facility');
        var tmpCamera = searchParams.get('camera');
        var tmpGauge = searchParams.get('gauge');
        var tmpMeasure = searchParams.get('measure');
        var tmpMinimumValue = searchParams.get('minimumValue');
        var tmpMaximumValue = searchParams.get('maximumValue');
        var tmpDecimalValue = searchParams.get('decimalValue');
        var tmpStepSizeValue = searchParams.get('stepSizeValue');
        var tmpViewType = searchParams.get('viewType');

        if (tmpViewType) {
          // setViewType(parseInt(tmpViewType,10));
          setViewType(tmpViewType);
        } else {
          setViewType("1");
        };

        if (tmpStartDate) {
          setStartDate(formatDate(tmpStartDate));
        } else {
          var tmpDate = addMonths(new Date(), -1);
          tmpDate = addDays(tmpDate, 1);
          setStartDate(formatDate(tmpDate));
        };

        if (tmpEndDate) {
          setEndDate(formatDate(tmpEndDate));
        } else {
          setEndDate(formatDate(new Date()));
        };

        if (tmpFacility) {
          setSelectedFacility(tmpFacility);
        } else {
          setSelectedFacility('');
        };

        if (tmpCamera) {
          setSelectedCamera(tmpCamera);
        } else {
          setSelectedCamera('');
        };

        if (tmpGauge) {
          setSelectedGauge(tmpGauge);
        } else {
          setSelectedGauge('');
        };

        if (tmpMeasure) {
          setSelectedMeasure(tmpMeasure);
        } else {
          setSelectedMeasure('');
        };

        if (tmpMinimumValue) {
          setMinimumValue(tmpMinimumValue);
        } else {
          setMinimumValue('');
        };

        if (tmpMaximumValue) {
          setMaximumValue(tmpMaximumValue);
        } else {
          setMaximumValue('');
        };

        if (tmpDecimalValue) {
          setDecimalValue(tmpDecimalValue);
        } else {
          setDecimalValue('auto');
        };

        if (tmpStepSizeValue) {
          setStepSizeValue(tmpStepSizeValue);
        } else {
          setStepSizeValue('');
        };

        if (searchConditionSet === false) setSearchConditionSet(true);

      },[isSearchListFetched]);

      useEffect(() => {
        if ((location.search !== "" && isSearchListFetched === true && searchConditionSet === true && isSearching === false)) {
          searchProcess();
        };
      },[searchConditionSet, isSearchListFetched])

      useEffect(() => {
        setShowListData(listData);
      },[listData, isCameraListFetched]);

      useEffect(() => {
        console.log(showListData);
      },[showListData]);

      useEffect(() => {
        if (user) {
            setUserName(user.user_name);
        };
      }, [user]);

      const handleStartDateChange = (value) => {
        setStartDate(value);
      };

      const handleEndDateChange = (value) => {
        setEndDate(value);
      };

      const handleFacilityChange = (value) => {
        setSelectedFacility(value);
      };

      const handleCameraChange = (value) => {
        setSelectedCamera(value);
      };

      const handleGaugeChange = (value) => {
        setSelectedGauge(value);
      };

      const handleMeasureChange = (value) => {
        setSelectedMeasure(value);
      };

      const handleMinimumValueChange = (value) => {
        setMinimumValue(value);
      };

      const handleMaximumValueChange = (value) => {
        setMaximumValue(value);
      };

      const handleDecimalValueChange = (value) => {
        setDecimalValue(value);
      };

      const handleStepSizeValueChange = (value) => {
        setStepSizeValue(value);
      };

      const handleViewTypeChange = (value) => {
        setViewType(value);
      }; 

      const handleSearch = () => {
        isShowGraph = false;
        searchProcess();
        isSearching = false;
      };

      const handleCheckboxChange = (tabIndex, checked) => {

        console.log("tabIndex:", tabIndex);

        var tmpSelectedId = selectedId;
        console.log("tmpSelectedId_org:", tmpSelectedId);
        
        if (checked) {
          tmpSelectedId.push(tabIndex);
          console.log("tmpSelectedId_checked:", tmpSelectedId);
        } else {
          tmpSelectedId = tmpSelectedId.filter(item => item !== tabIndex);
          console.log("tmpSelectedId_unchecked:", tmpSelectedId);
        };
        setSelectedId(tmpSelectedId);

      };

      const handleShowGraph = () => {
        console.log("selectedId.length:", selectedId.length);
        if (minimumValue > maximumValue) {
          setModalMessage("検索範囲のデータ最大値より大きい値が設定<br>されています。設定値を確認してください。");
          setIsModalOpen(true);
        };
        if (selectedId.length < 1) {
          setModalMessage("比較データのチェックしてください。");
          setIsModalOpen(true);
        } else if (selectedId.length === 1) {
          setModalMessage("比較対象がチェックされていません。");
          setIsModalOpen(true);
        } else if (selectedId.length > 3) {
          setModalMessage("比較データ数は、最大３件までです。");
          setIsModalOpen(true);
        } else {
          isShowGraph = true;
          console.log("isShowGraph:", isShowGraph);
            
          const tmpSelectedItem = [];

          selectedId.sort();
          selectedId.map(id => tmpSelectedItem.push(showListData[id]));

          const tmpGetDataInfo = {
            graphCount: selectedId.length,
            startDate: startDate,
            endDate: endDate,
            selectedItem: tmpSelectedItem,
            maximumValue: maximumValue,
            minimumValue: minimumValue,
            decimalValue: decimalValue,
            stepSizeValue: stepSizeValue,
            viewType: viewType,
            slideIndex: 0,
            graphType: 1
          };
          console.log("tmpGetDataInfo:", tmpGetDataInfo);
          const queryParam = new URLSearchParams({data_info: JSON.stringify(tmpGetDataInfo)}).toString();
          navigate(`/comparisongraph?${queryParam}`);
          setModalMessage("");
          setIsModalOpen(false);
        };

      };

      const handleTopClick = () => {
        navigate('/');
      }

      const formatDate = (date) => (date ? format(date, viewType === 2 ? 'yyyy/MM' : 'yyyy/MM/dd') : '');

      const checkSearchCondition = () => {
        
        if ((!startDate) || (!endDate)) {
          setModalMessage("期間を入力してください。");
          return false;
        };

        const tmpStartDate = new Date(startDate);
        const tmpEndDate = new Date(endDate);

        const endDateCheck = new Date(tmpStartDate.getFullYear(), tmpStartDate.getMonth(), tmpStartDate.getDate());
        endDateCheck.setFullYear(endDateCheck.getFullYear() + 2);
        endDateCheck.setDate(endDateCheck.getDate() - 1);

        if (tmpStartDate > tmpEndDate) {
          setModalMessage("正しい期間を入力してください。");
          return false;
        };

        if (tmpEndDate > endDateCheck) {
          setModalMessage("2年以内で期間を選択してください。");
          return false;
        };

        if (!viewType) {
          setModalMessage("表示単位は必須です。");
          return false;
        };

        if (!selectedMeasure) {
          setModalMessage("計測項目選択は必須です。");
          return false;
        };    
        
        if ((maximumValue) && (minimumValue)) {
          if (parseFloat(minimumValue) > parseFloat(maximumValue)) {
            setModalMessage("最小値が最大値より大きい値が設定されています。\n設定値を確認してください。");
            return false;
          };
          if (parseFloat(minimumValue) === parseFloat(maximumValue)) {
            setModalMessage("最小値、最大値を確認してください。");
            return false;
          };
        };

        if ((maximumValue) && (minimumValue) && (stepSizeValue)) {
          const STEPNUM = 30;
          const tmpStepSizeValue = (maximumValue - minimumValue)/STEPNUM;
          if (stepSizeValue < tmpStepSizeValue) {
            setModalMessage("グラフの縦軸目盛りが" + STEPNUM + "本以下になるよう、\n目盛り間隔を調整してください。\n目盛り間隔を空白にすると、自動で調整します。");
            return false;
          };
        };

        return true;

      };

      const searchProcess = () => {
        if (checkSearchCondition() === false) {
          setIsModalOpen(true);
        } else {
          isSearching = true;
          setModalMessage('');
          setIsModalOpen(false);
          setSelectedId([]);
          fetchComparisonGraphList(startDate, endDate, selectedFacility, selectedMeasure, viewType);
          setSearchParams({ startDate: formatDate(startDate), 
            endDate: formatDate(endDate),
            facility: selectedFacility,
            camera: selectedCamera,
            gauge: selectedGauge,
            measure: selectedMeasure,
            minimumValue: minimumValue,
            maximumValue: maximumValue,
            decimalValue: decimalValue,
            stepSizeValue: stepSizeValue,
            viewType: viewType,
            isShowGraph: isShowGraph }, { replace: true }); 
        };
      };
      
      return (
        <div id='Main' className={`main ${screenType}`}>
          <div id='Contents'>
            <Breadcrumb
              userName = {showUserName}
              pageName = "データ比較"
              onTopClick = {handleTopClick}
            />
            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} message={modalMessage} />
            {(isSearchListFetched === true)?(
              <div className='searchCondition'>
              <DataSearch
              facilities = {facilities}
              cameras = {cameras}
              gauges = {gauges}
              measureUnit = {measureUnit}
              initStartDate = {startDate}   
              initEndDate = {endDate}
              initFacility = {selectedFacility}   
              initCamera = {selectedCamera}   
              initGauge = {selectedGauge}   
              initMeasure = {selectedMeasure}   
              initMinimumValue = {minimumValue}   
              initMaximumValue = {maximumValue}   
              initStepSizeValue = {stepSizeValue}   
              initDecimalValue = {decimalValue}   
              initViewType = {viewType}   
              onStartDateChange = {handleStartDateChange}
              onEndDateChange = {handleEndDateChange}
              onFacilityChange = {handleFacilityChange}
              onCameraChange = {handleCameraChange}
              onGaugeChange = {handleGaugeChange}
              onMeasureChange = {handleMeasureChange}
              onMinimumValueChange = {handleMinimumValueChange}
              onMaximumValueChange = {handleMaximumValueChange}
              onStepSizeValueChange = {handleStepSizeValueChange}
              onDecimalValueChange = {handleDecimalValueChange}
              onViewTypeChange = {handleViewTypeChange}
              onSearchClick = {handleSearch}
              />
              </div>
            ) : (
              <div className='searchCondition' style={{display: 'none'}}></div>
            )}
            {((listData.length > 0) && (isCameraListFetched === true)) ? (
              <div className='list'>
                <button className='mod-btn-compgraph__roundborder moresmall' id='graph_disp' onClick={handleShowGraph}>グラフ表示</button>
                {showListData.map((listInfo, key) => (
                  <div key={key}>
                    <ComparisonGraphCameraInfo
                      id = {key}
                      display_facility_name = {listInfo.facility_name}
                      display_camera_name = {listInfo.display_camera_name}
                      display_gauge_name = {listInfo.display_gauge_name}
                      measure_name = {listInfo.measure_name}
                      unit = {listInfo.unit}
                      onChangeCheckbox = {handleCheckboxChange}
                    />
                  </div>
                ))}

              </div>
              
            ) : (
              <div className="mod-no-data" style={{display: isCameraListFetched ? '' : 'none'}}>表示データが存在しません。</div>                      
            )}

          </div>
          <div className={`overlay ${isLoading ? 'active' : ''}`}>
            <div className={`spinner ${isLoading ? 'active' : ''}`}>
            </div>
          </div>
        </div>
      );
    }

);

ComparisonGraphList.propTypes = {
  facilities: PropTypes.array,
  cameras: PropTypes.array,
  gauges: PropTypes.array, 
  measureUnit: PropTypes.array, 
  listData: PropTypes.array, 
  isSearchListFetched: PropTypes.bool,
  isCameraListFetched: PropTypes.bool, 
  fetchSearchlist: PropTypes.func.isRequired,
  fetchComparisonGraphList: PropTypes.func.isRequired,
};

export default ComparisonGraphList;
