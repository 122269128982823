import React, {useState, useEffect, useRef } from 'react'
import './ComparisonGraph.css'
import { Chart, LineElement, CategoryScale, LinearScale, PointElement, Title, Tooltip, Legend } from 'chart.js/auto';
import { addDays, addMonths } from 'date-fns';
import html2canvas from 'html2canvas';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Modal from '../Modal/Modal';
import Breadcrumb from '../Breadcrumb';

Chart.register(LineElement, CategoryScale, LinearScale, PointElement, Title, Tooltip, Legend);

const ComparisonGraph = React.memo(
    ({user,
      standard_time,
      diff_flg,
      diff_type,
      graph_data = [],
      gauge_id,
      measure_id,
      is_loaded,
      start_date,
      end_date,
      start_date_real,
      end_date_real,
      view_type,
      unit,
      max_limit,
      min_limit,
      error,
      isFetched,
      isLoading,
      fetchComparisonGraphData
    }) => {
        console.log("----------------ComparisonGraph.jsx初期化--------------------");

        const canvasRef = useRef(null);
        const chartInstance = useRef(null);
        const navigate = useNavigate ();
        const location = useLocation();
        const queryParams = new URLSearchParams(location.search);
        let dataInfo = JSON.parse(queryParams.get('data_info'));
        const [comparedItemInfo, setComparedItemInfo] = useState('');
        const [graphDate, setGraphDate]= useState([]);
        const [showData, setShowData] = useState([]); 
        const [slideIndex, setSlideIndex] = useState(dataInfo.slideIndex);
        const [graphType, setGraphType] = useState(dataInfo.graphType);
        const [isModalOpen, setIsModalOpen] = useState(false);
        const [modalMessage, setModalMessage] = useState('');
        const viewType = dataInfo.viewType;
        const [showUserName, setUserName] = useState('');
        const [deviceType, setDeviceType] = useState("desktop");
        const screenType = useSelector((state) => state.device.screenType);
        var maximumValue = dataInfo.maximumValue;
        var minimumValue = dataInfo.minimumValue;
        // var decimalValue = dataInfo.decimalValue;
        // var stepSizeValue = dataInfo.stepSizeValue;
        let facility_name = '';
        let display_camera_name = '';
        let display_gauge_name = '';
        let measure_name = '';
        var clickTime = 0;
        
        const fnGetDateFormat = (date) => {
            if (parseInt(viewType) === 2) {
                return date.getFullYear()+"/"+("0" + (date.getMonth()+1)).slice(-2);
            } else {
                return date.getFullYear()+"/"+("0" + (date.getMonth()+1)).slice(-2)+"/"+("0"+date.getDate()).slice(-2);
            };
        };

        function fnGetDateFormatJp(date) {
            return date.getFullYear()+"年"+("0" + (date.getMonth()+1)).slice(-2)+"月"+("0"+date.getDate()).slice(-2)+"日";
        };

        const handleContextMenu = (event) => {
          event.preventDefault();
        };

        const handleGraphTypeChange = (event) => {
            setGraphType(event.target.value);
            dataInfo.graphType = event.target.value;
            queryParams.set('data_info', JSON.stringify(dataInfo));
            let newUrl = window.location.pathname + '?' + queryParams.toString();
            window.history.replaceState({}, '', newUrl);
        };

        const handleChangeSlide = (key, dateInfo) => {
            changeSlideIndex(key);
        };

        const handlePrevMove = (event) => {
            var tmpIndex;
            if (parseInt(slideIndex) !== 0) {
                tmpIndex = slideIndex - 1;
                changeSlideIndex(tmpIndex);
            };
        };

        const handleGoBack = (event)  => {
            navigate(-1);
        };

        const handleNextMove = (event) => {
            var tmpIndex;
            if (parseInt(slideIndex) !== (graphDate.length - 1)) {
                tmpIndex = slideIndex + 1;
                changeSlideIndex(tmpIndex);
            };        
        };

        const changeSlideIndex = (key) => {
            if (graphDate.length > 0) {
                for (let index = 0; index < graphDate.length; index++) {
                    graphDate[index].className = "compdot";               
                }
                graphDate[key].className = "compdot active";               
                setSlideIndex(key);
                dataInfo.slideIndex = key;
                queryParams.set('data_info', JSON.stringify(dataInfo));
                let newUrl = window.location.pathname + '?' + queryParams.toString();
                window.history.replaceState({}, '', newUrl);
            };
        };

        const handleDownloadImage = (event) => {
            if (deviceType !== 'desktop') {
              setModalMessage('グラフ画像ダウンロードはPCからご利用ください。');
              setIsModalOpen(true);
            } else {
  
              if (!clickTime) {
                clickTime = new Date();
              } else {
                //ダブルクリック防止
                var diffTime = new Date() - clickTime;
                clickTime = new Date();
                if (diffTime < 900) {
                  return false;
                }
              }
  
              var element = document.getElementById('screenshot');
              var imgWidth = element.offsetWidth + 25;
              var imgHeight = element.offsetHeight + 25;
        
              html2canvas(element, {scrollY: -window.scrollY, scrollX: -window.scrollX, width: imgWidth, height: imgHeight}).then(function (canvas) {
                // ファイル名作成開始
                const tmpItems = dataInfo.selectedItem;
                console.log("tmpItems[0].unit:", tmpItems[0].unit);
                var graph_date = graphDate[slideIndex].date.replace(/\//g,'');
                var regSpecChar = RegExp(/["<>:|\\*?]/g);

                facility_name = tmpItems[0].facility_name;
                display_camera_name = tmpItems[0].display_camera_name;
                display_gauge_name = tmpItems[0].display_gauge_name;
                measure_name = tmpItems[0].measure_name + "[" + tmpItems[0].unit + "]";
                if (tmpItems[0].facility_name != null) {
                  facility_name = tmpItems[0].facility_name;
                } else {
                  facility_name = '';
                };

                if (tmpItems[0].display_camera_name != null) {
                  display_camera_name = tmpItems[0].display_camera_name;
                } else {
                  display_camera_name = '';
                };

                if (tmpItems[0].display_gauge_name != null) {
                  display_gauge_name = tmpItems[0].display_gauge_name;
                } else {
                  display_gauge_name = '';
                };

                if (tmpItems[0].measure_name != null) {
                  measure_name = tmpItems[0].measure_name + "[" + tmpItems[0].unit + "]";
                } else {
                  measure_name = '';
                };
                
                //施設名チェック
                facility_name = facility_name.replace(regSpecChar, '');
                if (facility_name.length > 50) {
                  facility_name = facility_name.slice(0, 50) + "...";
                };
                //カメラ名チェック
                display_camera_name = display_camera_name.replace(regSpecChar, '');
                if (display_camera_name.length > 50) {
                    display_camera_name = display_camera_name.slice(0, 50) + "...";
                };
                //計器名チェック
                display_gauge_name = display_gauge_name.replace(regSpecChar, '');
                if (display_gauge_name.length > 50) {
                  display_gauge_name = display_gauge_name.slice(0, 50) + "...";
                };
                //計測項目名チェック
                measure_name = measure_name.replace(regSpecChar, '');
                if (measure_name.length > 50) {
                  measure_name = measure_name.slice(0, 50) + "...";
                };
                var fileName = graph_date + facility_name + display_camera_name + display_gauge_name + measure_name + ".png";
                // ファイル名作成終了
                
                if (canvas.msToBlob) { // IEブラウザ
                  window.navigator.msSaveBlob(canvas.msToBlob(), fileName);
                } else { // 他のブラウザ
                  var imgageData = canvas.toDataURL("image/png");
                  var newData = imgageData.replace(/^data:image\/png/, "data:application/octet-stream");
    
                  var link = document.createElement('a');
                  link.href = newData;
                  link.download = fileName;
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                }
              }).catch(function(err){
                console.log(err);
              });
  
              setModalMessage('');
              setIsModalOpen(false);
    
            };
            
        };

        useEffect(() => {
            const detectDevice = () => {
                const userAgent = navigator.userAgent || navigator.vendor || window.opera;
                const isTouchDevice = "ontouchstart" in window || navigator.maxTouchPoints > 0;
    
                if (/iPhone|Android.+Mobile/.test(userAgent)) {
                    setDeviceType("mobile");
        
                } else if (
                    /iPad|Android/.test(userAgent) || // iPad and Android tablets
                    (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)  || // iPad Pro in desktop mode
                    (isTouchDevice)            // Handle simulation in Chrome DevTools
                ) {
                    setDeviceType("tablet");
                 } else {
                    setDeviceType("desktop");        
                }
            };
    
            detectDevice();
            window.addEventListener('resize', detectDevice);
    
            return () => {
                window.removeEventListener('resize', detectDevice);
            };
        }, []);

        useEffect(() => {
            const tmpItems = dataInfo.selectedItem;
            var tmpStartDate = new Date(dataInfo.startDate);
            var tmpEndDate = new Date(dataInfo.endDate);
            var tmpShowStartDate, tmpShowEndDate;
            const tmpGraphDate = [];

            if (parseInt(viewType) === 2) {
                tmpShowStartDate = fnGetDateFormat(tmpStartDate);
                tmpShowEndDate = fnGetDateFormat(tmpEndDate);
            } else {
                tmpShowStartDate = dataInfo.startDate;
                tmpShowEndDate = dataInfo.endDate;
            };
    
            var tmpString = "期間：" + tmpShowStartDate + " ～ " + tmpShowEndDate;
            for (let index = 0; index < tmpItems.length; index++) {
                tmpString += "\n"
                if (tmpItems[index].facility_name != null) tmpString += "施設名" + (index + 1) + "：" + tmpItems[index].facility_name;
                if (tmpItems[index].display_camera_name != null) tmpString += "　カメラ名" + (index + 1) + "：" + tmpItems[index].display_camera_name;
                if (tmpItems[index].display_gauge_name != null) tmpString += "　計器名" + (index + 1) + "：" + tmpItems[index].display_gauge_name;
                if (tmpItems[index].measure_name != null) tmpString += "　計測項目" + (index + 1) + "：" + tmpItems[index].measure_name;
                if (tmpItems[index].unit != null) tmpString += "[" + tmpItems[index].unit + "]";

            };

            setComparedItemInfo(tmpString);

            if (parseInt(viewType) === 2) {
                const yearDifference = tmpEndDate.getFullYear() - tmpStartDate.getFullYear();
                const monthDifference = tmpEndDate.getMonth() - tmpStartDate.getMonth();
                const diffInMonths = yearDifference * 12 + monthDifference;

                for (let index = 0; index <= diffInMonths; index++) {
                    if (parseInt(index) === slideIndex) {
                        tmpGraphDate.push({className: "compdot active", date:fnGetDateFormat(addMonths(tmpStartDate, index))});
                    } else {
                        tmpGraphDate.push({className: "compdot", date:fnGetDateFormat(addMonths(tmpStartDate, index))});
                    };
                }
            } else {
                const diffInMs = Math.abs(tmpEndDate - tmpStartDate);
                const diffInDays  = Math.ceil(diffInMs / (1000 * 60 * 60 * 24));
    
                for (let index = 0; index <= diffInDays; index++) {
                    if (parseInt(index) === slideIndex) {
                        tmpGraphDate.push({className: "compdot active", date:fnGetDateFormat(addDays(tmpStartDate, index))});
                    } else {
                        tmpGraphDate.push({className: "compdot", date:fnGetDateFormat(addDays(tmpStartDate, index))});
                    };
    
                };
            };

            setGraphDate(tmpGraphDate);

        // eslint-disable-next-line react-hooks/exhaustive-deps
        },[]);

        useEffect(() => {

            console.log("----------------fetchData--------------------");
            
            const fetchData = async () => {
                if (graphDate.length > 0) {
                    fetchComparisonGraphData(dataInfo.selectedItem, graphDate[slideIndex].date, graphDate[slideIndex].date, parseInt(dataInfo.viewType));
                };
            };

            fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [graphDate, slideIndex]);

        useEffect(() => {
            console.log("----------------setShowData--------------------");

            let selectedDate = '';

            if (graph_data) {
                
                if (graphDate.length > 0) {
                    selectedDate = graphDate[slideIndex].date;
                    var tmpShowData = [];
        
                    Object.values(graph_data).forEach(item => {
                        tmpShowData.push(item.filter(data => fnGetDateFormat(new Date(data.measure_date)) === selectedDate));
                    });
        
                    setShowData(tmpShowData);
                };
    
            };
            
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [graph_data]);

        useEffect(() => {
            if (user) {
              setUserName(user.user_name);
            };
        }, [user]);

        useEffect(() => {
            console.log("----------------setGraph--------------------");
            let toolValue = [];
            let toolTitle = [];
            let toolCurDate = [];
            let toolCurHour = [];
            let toolPrevDate = [];
            let toolPrevHour = [];
            let toolPrevValue = [];
            let toolDiffValue = [];
            let toolDiffFlg = [];
            let comment = [];
            let unit = [];
            let toolMin = [];
            let toolMax = [];
            const limitUnderColor = '#f6aa00';
            const limitOverColor = '#ff1300';
            const bar1Color = '#00dff3';
            const bar2Color = '#068eaa';
            const bar3Color = '#005965';
            let tooltipBorder = [];
            let DataList1 = [];
            let DataList2 = [];
            let DataList3 = [];
            let filteredDataList1 = [];
            let filteredDataList2 = [];
            let filteredDataList3 = [];
            let BackGroundColorList1 = [];
            let BackGroundColorList2 = [];
            let BackGroundColorList3 = [];
            let hasErrorData1 = false;		//1日異常データがあるか
            let hasErrorData2 = false;		//1日異常データがあるか
            let hasErrorData3 = false;		//1日異常データがあるか
            let datasetArray = [];
            let toolIdx = "";
            let tmpTime = "";
            let tmpObj = [];
            let minFlg,maxFlg;
            let minValue = 0;
            let maxValue = 0;
            let stepSize = 0;
            let arrDiffFlg = [];
            let arrDiffType = [];
            let arrStandardTime = [];
            let digitFloor;
            let baseValue;
            let minTemp;
            let maxTemp;
            let minData = 0;
            let maxData = 0;
            const STEPNUM = 10;            
            let stepCount = 0;
            let isMaxNull = false;	//最大値は空白で検索
            let isMinNull = false;	//最小値は空白で検索
            let isStepNull = false;	//目盛り間隔は空白で検索
            let isAuto = false;
            let maxLimit = max_limit;
            let clipValue = 0;
            var clipTopValue = 0;
            var clipBottomValue = 0;
            let stepSizeValue = dataInfo.stepSizeValue;
            let decimalValue = dataInfo.decimalValue;

            tooltipBorder.push(bar1Color);
            tooltipBorder.push(bar2Color);
            tooltipBorder.push(bar3Color);

            const customTooltip = (context) => {
                const { tooltip } = context;
                var status_class;
                var tooltopDate = tooltip.dataPoints[0].label;
                var tooltopIndex = tooltip.dataPoints[0].datasetIndex;
                var toolMinFlg = toolMin[tooltopIndex+"_"+tooltopDate];
                var toolMaxFlg = toolMax[tooltopIndex+"_"+tooltopDate];
                var tool_diff_flg = arrDiffFlg[tooltopIndex+1];

                if ( parseInt(toolMinFlg) === 1 || parseInt(toolMaxFlg) === 1 ) {
                    status_class = 2;
                };

                let tooltipEl = document.getElementById('chartjs-tooltip');

                if (!tooltipEl) {
                    tooltipEl = document.createElement('div');
                    tooltipEl.id = 'chartjs-tooltip';
                    tooltipEl.classList.add('comp_modal_view');
                    tooltipEl.innerHTML = '<div class="comp_text_view" id="comp_text_view1"></div>';
                    document.getElementById('cchatArea').appendChild(tooltipEl);
                };
                console.log("tooltopIndex:", tooltopIndex);

                if (parseInt(status_class) === 2) {
                    tooltipEl.style.backgroundImage = 'url("/img/common/icon-caution.png")';
                    tooltipEl.style.border = `solid 3px ${limitOverColor}`;     
                } else {
                    tooltipEl.style.backgroundImage = 'none';
                    tooltipEl.style.border = `solid 3px ${tooltipBorder[tooltopIndex]}`;     
                };

                if (tooltip.opacity === 0) {
                    tooltipEl.style.opacity = 0;
                    document.getElementById('chartjs-tooltip').style.display = 'none';
                    return;
                };

                if (tooltip.body) {
                    const bodyLines = tooltip.body.map(b => b.lines);
                    let innerHtml = '';
                    var tooltip_height = 220;
                    var tooltip_width = (tool_diff_flg ? 600 : 400);
                    var comp_modal_view = document.getElementsByClassName('comp_modal_view');

                    if (bodyLines.length > 1) {
                        tooltip_height += (bodyLines.length - 1) * (tool_diff_flg ? 100 : 50);
                    };

                    for (let k = 0; k < comp_modal_view.length; k++) {
                        comp_modal_view[k].style.height = tooltip_height + 'px';
                        comp_modal_view[k].style.width = tooltip_width + 'px';
                    };

                    console.log("bodyLines:", bodyLines);
                    bodyLines.forEach(function(body, i) {
                        if (graphType && bodyLines.length > 1) {
                            tooltopIndex = tooltip.dataPoints[i].datasetIndex;
                            tool_diff_flg = toolDiffFlg[tooltopIndex + "_" + tooltopDate];
                        };

                        var toolIndex = tooltopIndex + "_" + tooltopDate;
                        var maxLenNoDiff = 55;
                        var maxLenDiff = 7;
                        var maxLenOverLapNoDiff = 28;
                        var maxLen = maxLenNoDiff;
                        var dispComment = '';

                        if (graphType) {
                            maxLen = tool_diff_flg ? maxLenDiff : maxLenOverLapNoDiff;
                        } else {
                            maxLen = tool_diff_flg ? maxLenDiff : maxLenNoDiff;
                        };

                        if (comment[toolIndex]) {
                            dispComment = comment[toolIndex];
                        };
                
                        if (dispComment.length > maxLen) {
                          dispComment = dispComment.substring(0, maxLen) + "..."
                        };

                        console.log("toolPrevValue:", toolPrevValue);
                        if (tool_diff_flg === true) {	//差分値ポップアップ
                            innerHtml += '<div class="comp_diff_view">'
                              +'<div class="comp_measure_view">'
                              +'<p>' + toolCurDate[toolIndex] + '<br>'
                              + toolCurHour[toolIndex] + '<br>'
                              + parseFloat(toolValue[toolIndex]) +" "+unit[toolIndex]+
                              '</p></div>';
                            innerHtml += '<div>－</div>';
                            innerHtml += '<div class="comp_measure_view">'
                              +'<p>' + toolPrevDate[toolIndex] + '<br>'
                              + toolPrevHour[toolIndex] + '<br>'
                              + parseFloat(toolPrevValue[toolIndex]) +" "+unit[toolIndex]+
                              '</p></div>';
                            innerHtml += '<div>=</div>';
                            innerHtml += '<div class="comp_measure_view" style="border: solid red 3px;">'
                              +'<p>' + toolCurDate[toolIndex] + '<br>'
                              + toolCurHour[toolIndex] + '<br>'
                              + parseFloat(toolDiffValue[toolIndex]) +" "+unit[toolIndex]+'<br>' 
                              + dispComment + 
                              '</p></div>';
                            innerHtml += '</div>';
                        } else {	//計測値ポップアップ
                            var style = 'margin-top:15px;';
                            if (bodyLines.length > 1) style += ' border:solid 1px;';
                            innerHtml += '<p style="' + style + '">' + toolTitle[toolIndex] + '<br>'
                              + "計測値：" + parseFloat(toolValue[toolIndex]) +"["+unit[toolIndex]+']<br>' 
                              + dispComment + 
                              '</p>';
                        };
                    });

                    document.getElementById('comp_text_view1').innerHTML = innerHtml;

                };
                tooltipEl.style.opacity = 1;
                tooltipEl.style.display = 'block';
            };

            var countDecimals = function (value) {
                if (!value) return 0;
                if(Math.floor(value) === value) return 0;
                return value.toString().split(".")[1].length || 0; 
            };

            var truncateDecimal = function (value) {
                return Math.round(parseFloat((value * Math.pow(10, 5)).toFixed(5))) / Math.pow(10, 5);
            };

            if (minimumValue === "") {
                isMinNull = true;
            } else {
                minValue = parseFloat(minimumValue);
            };

            if (maximumValue === "") {
                isMaxNull = true;
            } else {
                maxValue = parseFloat(maximumValue);
            };

            if (stepSizeValue === "") {
                isStepNull = true;
            } else {
                stepSize = parseFloat(stepSizeValue);
                stepSizeValue = stepSize;
                if (stepSizeValue === 0) {
                    stepSizeValue = 1;
                }
            };

            if (decimalValue === "auto") {
                isAuto = true;
            } else {
                decimalValue = parseFloat(decimalValue);
            };

            if ((isFetched === true) && (showData.length > 0)) {
                const ctx = canvasRef.current.getContext('2d');
                const xLabels = [];
                canvasRef.current.style.display = 'none';
                canvasRef.current.addEventListener('contextmenu', handleContextMenu);

                let chartStatus = Chart.getChart("cchart");
                if (chartStatus !== undefined) {
                    chartStatus.destroy();
                };

                let nameLen = (parseInt(dataInfo.selectedItem.length) === 3) ? ((parseInt(dataInfo.viewType) === 2) ? 12 : 10) : 20;
                let meterName = [];
                let tmpMeterName = [];
                let minLoopIndex;
                let maxLoopIndex;


                for (var index = 0; index < dataInfo.selectedItem.length; index++) {
                    tmpMeterName = dataInfo.selectedItem[index].display_gauge_name;
                    if (tmpMeterName == null) tmpMeterName = '';
                    if (tmpMeterName.length > nameLen) tmpMeterName = tmpMeterName.slice(0, nameLen) + '...';
                    tmpMeterName += '(' + graphDate[slideIndex].date + ')';
                    meterName.push(tmpMeterName);
                };

                if (parseInt(viewType) === 2) {
                    let calYear = graphDate[slideIndex].date.slice(0,4);
                    let calMonth = graphDate[slideIndex].date.slice(-2);

                    minLoopIndex = 1;
                    maxLoopIndex = new Date(calYear, calMonth, 0).getDate();
                } else {
                    minLoopIndex = 0
                    maxLoopIndex = 23;
                };
                
                for (var t = minLoopIndex; t <= maxLoopIndex; t++) {
                    if (parseInt(viewType) === 2) {
                        var xMonth = parseInt(graphDate[slideIndex].date.split("/")[1]).toString();
                        xLabels.push(xMonth+"月" + t +"日");
                    } else {
                        xLabels.push(("0" + (t)).slice(-2)+"時");
                    };

                    for (var i = 1; i <= dataInfo.selectedItem.length; i++) {
                        arrDiffFlg[i] = diff_flg[i];
                        arrDiffType[i] = diff_type[i];
                        arrStandardTime[i] = standard_time;
                        tmpObj = [];
                        let tmpData;

                        if (i === 1) {
                            if (parseInt(viewType) === 2) {
                                tmpTime = graphDate[slideIndex].date + "/" + ("0" + (t)).slice(-2) + "_" + ("0" + (standard_time[i])).slice(-2);
                            } else {
                                tmpTime = graphDate[slideIndex].date + "_" + ("0" + (t)).slice(-2);
                            };

                            // eslint-disable-next-line no-loop-func
                            showData[0].forEach(item => {
                                if (item.measure_date_time === tmpTime) {
                                    tmpObj = item;
                                };
                            });

                            if (tmpObj) {
                                if (diff_flg[i] === true) {
                                    if (tmpObj.diff_val) {
                                        tmpData = parseFloat(tmpObj.diff_val);
                                    } else {
                                        tmpData = tmpObj.diff_val;
                                    };
                                    
                                } else {
                                    if (tmpObj.measure_val) {
                                        tmpData = parseFloat(tmpObj.measure_val);
                                    } else {
                                        tmpData = tmpObj.measure_val;
                                    };
                                    
                                };
                                DataList1.push(tmpData);

                                if (diff_flg[i] === true) {
                                    // DataList1.push(tmpObj.diff_val);
                                    minFlg = tmpObj.diff_threshold_min_flg;
                                    maxFlg = tmpObj.diff_threshold_max_flg;
                                } else {
                                    // DataList1.push(tmpObj.measure_val);
                                    minFlg = tmpObj.threshold_min_flg;
                                    maxFlg = tmpObj.threshold_max_flg;
                                };

                                if (diff_flg[i] === true && diff_type[i]) {
                                    if (parseInt(maxFlg) === 1) {
                                        BackGroundColorList1.push(limitOverColor);
                                        hasErrorData1 = true;
                                    } else if (parseInt(minFlg) === 1) {
                                        BackGroundColorList1.push(limitUnderColor);
                                        hasErrorData1 = true;
                                    } else {
                                        BackGroundColorList1.push(bar1Color);
                                    };
                                } else {
                                    BackGroundColorList1.push(bar1Color);
                                };

                                if (parseInt(viewType) === 2) {
                                    toolIdx = "0_"+ parseInt(graphDate[slideIndex].date.split("/")[1]).toString() +"月" + t +"日";

                                } else {
                                    toolIdx = "0_"+ ("0" + (t)).slice(-2) + "時";
                                };
                                toolValue[toolIdx] = tmpObj.measure_val;
                                toolMin[toolIdx] = minFlg;
                                toolMax[toolIdx] = maxFlg;
                                toolTitle[toolIdx] = tmpObj.get_datetime;
                                toolCurHour[toolIdx] = ('0' + new Date(tmpObj.get_datetime).getHours()).slice(-2) + '時';
                                toolCurDate[toolIdx] = fnGetDateFormatJp(new Date(tmpObj.get_datetime));
                                toolPrevHour[toolIdx] = ('0' + new Date(tmpObj.prev_datetime).getHours()).slice(-2) + '時';
                                if (new Date(tmpObj.prev_datetime).getMinutes() !== 0) {
                                    toolPrevHour[toolIdx] += ('0' + new Date(tmpObj.prev_datetime).getMinutes()).slice(-2) + '分';
                                }; 
                                toolPrevDate[toolIdx] = fnGetDateFormatJp(new Date(tmpObj.prev_datetime));
                                toolPrevValue[toolIdx] = tmpObj.prev_measure_val;
                                toolDiffValue[toolIdx] = tmpObj.diff_val;
                                toolDiffFlg[toolIdx] = diff_flg[i];
                                comment[toolIdx] = tmpObj.comment;
                                unit[toolIdx] = tmpObj.unit;
                                
                            } else {
                                DataList1.push(null);
                                BackGroundColorList1.push(bar1Color);
                            };

                        };

                        if (i === 2) {
                            if (parseInt(viewType) === 2) {
                                tmpTime = graphDate[slideIndex].date + "/" + ("0" + (t)).slice(-2) + "_" + ("0" + (standard_time[i])).slice(-2);
                            } else {
                                tmpTime = graphDate[slideIndex].date + "_" + ("0" + (t)).slice(-2);
                            };

                            // eslint-disable-next-line no-loop-func
                            showData[1].forEach(item => {
                                if (item.measure_date_time === tmpTime) {
                                    tmpObj = item;
                                };
                            });

                            if (tmpObj) {
                                console.log("---set datalist2----");
                                if (diff_flg[i] === true) {
                                    if (tmpObj.diff_val) {
                                        tmpData = parseFloat(tmpObj.diff_val);
                                    } else {
                                        tmpData = tmpObj.diff_val;
                                    };
                                    
                                } else {
                                    if (tmpObj.measure_val) {
                                        tmpData = parseFloat(tmpObj.measure_val);
                                    } else {
                                        tmpData = tmpObj.measure_val;
                                    };
                                    
                                };
                                
                                DataList2.push(tmpData);
                                if (diff_flg[i] === true) {
                                    console.log("---set datalist2 (diff)----");
                                    // DataList2.push(tmpObj.diff_val);
                                    minFlg = tmpObj.diff_threshold_min_flg;
                                    maxFlg = tmpObj.diff_threshold_max_flg;
                                } else {
                                    console.log("---set datalist2 (measure)----");
                                    // DataList2.push(tmpObj.measure_val);
                                    minFlg = tmpObj.threshold_min_flg;
                                    maxFlg = tmpObj.threshold_max_flg;
                                };

                                if (diff_flg[i] === true && diff_type[i]) {
                                    if (parseInt(maxFlg) === 1) {
                                        BackGroundColorList2.push(limitOverColor);
                                        hasErrorData2 = true;
                                    } else if (parseInt(minFlg) === 1) {
                                        BackGroundColorList2.push(limitUnderColor);
                                        hasErrorData2 = true;
                                    } else {
                                        BackGroundColorList2.push(bar2Color);
                                    };
                                } else {
                                    BackGroundColorList2.push(bar2Color);
                                };

                                if (parseInt(viewType) === 2) {
                                    toolIdx = "1_"+ parseInt(graphDate[slideIndex].date.split("/")[1]).toString() +"月" + t +"日";
                                } else {
                                    toolIdx = "1_"+ ("0" + (t)).slice(-2) + "時";
                                };
                                toolValue[toolIdx] = tmpObj.measure_val;
                                toolMin[toolIdx] = minFlg;
                                toolMax[toolIdx] = maxFlg;
                                toolTitle[toolIdx] = tmpObj.get_datetime;
                                toolCurHour[toolIdx] = ('0' + new Date(tmpObj.get_datetime).getHours()).slice(-2) + '時';
                                toolCurDate[toolIdx] = fnGetDateFormatJp(new Date(tmpObj.get_datetime));
                                toolPrevHour[toolIdx] = ('0' + new Date(tmpObj.prev_datetime).getHours()).slice(-2) + '時';
                                if (new Date(tmpObj.prev_datetime).getMinutes() !== 0) {
                                    toolPrevHour[toolIdx] += ('0' + new Date(tmpObj.prev_datetime).getMinutes()).slice(-2) + '分';
                                }; 
                                toolPrevDate[toolIdx] = fnGetDateFormatJp(new Date(tmpObj.prev_datetime));
                                toolPrevValue[toolIdx] = tmpObj.prev_measure_val;
                                toolDiffValue[toolIdx] = tmpObj.diff_val;
                                toolDiffFlg[toolIdx] = diff_flg[i];
                                comment[toolIdx] = tmpObj.comment;
                                unit[toolIdx] = tmpObj.unit;

                            } else {
                                DataList2.push(null);
                                BackGroundColorList2.push(bar2Color);
                            };

                        };

                        if (i === 3) {
                            if (parseInt(viewType) === 2) {
                                tmpTime = graphDate[slideIndex].date + "/" + ("0" + (t)).slice(-2) + "_" + ("0" + (standard_time[i])).slice(-2);
                            } else {
                                tmpTime = graphDate[slideIndex].date + "_" + ("0" + (t)).slice(-2);
                            };
                            // eslint-disable-next-line no-loop-func
                            showData[2].forEach(item => {
                                if (item.measure_date_time === tmpTime) {
                                    tmpObj = item;
                                };
                            });

                            if (tmpObj) {
                                if (diff_flg[i] === true) {
                                    if (tmpObj.diff_val) {
                                        tmpData = parseFloat(tmpObj.diff_val);
                                    } else {
                                        tmpData = tmpObj.diff_val;
                                    };
                                    
                                } else {
                                    if (tmpObj.measure_val) {
                                        tmpData = parseFloat(tmpObj.measure_val);
                                    } else {
                                        tmpData = tmpObj.measure_val;
                                    };
                                    
                                };
                                
                                DataList3.push(tmpData);
                                
                                if (diff_flg[i] === true) {
                                    // DataList3.push(tmpObj.diff_val);
                                    minFlg = tmpObj.diff_threshold_min_flg;
                                    maxFlg = tmpObj.diff_threshold_max_flg;
                                } else {
                                    // DataList3.push(tmpObj.measure_val);
                                    minFlg = tmpObj.threshold_min_flg;
                                    maxFlg = tmpObj.threshold_max_flg;
                                };

                                if (diff_flg[i] === true && diff_type[i]) {
                                    if (parseInt(maxFlg) === 1) {
                                        BackGroundColorList3.push(limitOverColor);
                                        hasErrorData3 = true;
                                    } else if (parseInt(minFlg) === 1) {
                                        BackGroundColorList3.push(limitUnderColor);
                                        hasErrorData3 = true;
                                    } else {
                                        BackGroundColorList3.push(bar3Color);
                                    };
                                } else {
                                    BackGroundColorList3.push(bar3Color);
                                };

                                if (parseInt(viewType) === 2) {
                                    toolIdx = "2_"+ parseInt(graphDate[slideIndex].date.split("/")[1]).toString() +"月" + t +"日";
                                } else {
                                    toolIdx = "2_"+ ("0" + (t)).slice(-2) + "時";
                                };
                                toolValue[toolIdx] = tmpObj.measure_val;
                                toolMin[toolIdx] = minFlg;
                                toolMax[toolIdx] = maxFlg;
                                toolTitle[toolIdx] = tmpObj.get_datetime;
                                toolCurHour[toolIdx] = ('0' + new Date(tmpObj.get_datetime).getHours()).slice(-2) + '時';
                                toolCurDate[toolIdx] = fnGetDateFormatJp(new Date(tmpObj.get_datetime));
                                toolPrevHour[toolIdx] = ('0' + new Date(tmpObj.prev_datetime).getHours()).slice(-2) + '時';
                                if (new Date(tmpObj.prev_datetime).getMinutes() !== 0) {
                                    toolPrevHour[toolIdx] += ('0' + new Date(tmpObj.prev_datetime).getMinutes()).slice(-2) + '分';
                                }; 
                                toolPrevDate[toolIdx] = fnGetDateFormatJp(new Date(tmpObj.prev_datetime));
                                toolPrevValue[toolIdx] = tmpObj.prev_measure_val;
                                toolDiffValue[toolIdx] = tmpObj.diff_val;
                                toolDiffFlg[toolIdx] = diff_flg[i];
                                comment[toolIdx] = tmpObj.comment;
                                unit[toolIdx] = tmpObj.unit;

                            } else {
                                DataList3.push(null);
                                BackGroundColorList3.push(bar3Color);
                            };

                        };
                    };
                };

                filteredDataList1 = DataList1.filter(num => num != null);
                filteredDataList2 = DataList2.filter(num => num != null);
                filteredDataList3 = DataList3.filter(num => num != null);

                maxData = Math.max(...filteredDataList1, ...filteredDataList2, ...filteredDataList3);
                minData = Math.min(...filteredDataList1, ...filteredDataList2, ...filteredDataList3);

                //最大値設定
                if (isMaxNull) {
                    if (maxData > 0) {
                        maxTemp = truncateDecimal(maxData * 12 / 10);
                        digitFloor = Math.floor(Math.log10(maxTemp)) - 1;
                        baseValue = Math.pow(10, digitFloor);
                        maxTemp = Math.ceil(maxTemp / baseValue) * baseValue;
                        stepSize = truncateDecimal(maxTemp / STEPNUM);
                    } else {
                        maxTemp = 0;
                    };
                
                    maxValue = truncateDecimal(maxTemp);
                    if (isStepNull) {
                        stepSizeValue = stepSize;
                    };
                };

                //検索の最小値は空白の場合、最小値を計算するを処理 
                if (isMinNull) {
                    if (minData >= 0) {
                        minTemp = 0;
                    } else {
                        stepSize = truncateDecimal((maxData - minData) / 10);
              
                        if (maxData > 0) {
                            maxTemp = 0;
                            while (maxTemp < maxData) {
                                maxTemp += stepSize;
                                stepCount ++;
                            };
                            maxValue = truncateDecimal(maxTemp + stepSize);
                            if (isStepNull) {
                                stepSizeValue = stepSize;
                            };
                            minTemp = 0;
                            while (minTemp >= minData) {
                                minTemp -= stepSize;
                                stepCount ++;
                            };
                            minTemp -= stepSize;
                            stepCount += 2;
                        } else {
                            if (isStepNull) {
                                stepSizeValue = stepSize;
                            };
                            minTemp = 0;
                            while (minTemp >= minData) {
                                minTemp -= stepSize;
                                stepCount ++;
                            };
                            minTemp -= stepSize;
                            stepCount += 2;			
                        };
                    }
                    minValue = truncateDecimal(minTemp);
                };

                //目盛り間隔は空白場合
                if (isStepNull) {
                    if (stepCount === 0) stepCount = STEPNUM;
                  
                    if (isMaxNull && !isMinNull) {
                        maxTemp = truncateDecimal(maxData * 12 / 10);
                        digitFloor = Math.floor(Math.log10(maxTemp)) - 1;
                        baseValue = Math.pow(10, digitFloor);
                        maxValue = truncateDecimal(Math.ceil(maxTemp / baseValue) * baseValue);		  
                        stepSizeValue = truncateDecimal((maxData - minValue) / stepCount);
              
                    } else if (!isMaxNull && isMinNull) {
                        stepSizeValue = truncateDecimal((maxValue - minValue) / STEPNUM);
                    } else if (!isMaxNull && !isMinNull) {
                        stepSizeValue = truncateDecimal((maxValue - minValue) / STEPNUM);
                    }; 
                } else {	//目盛り間隔入力した場合
                    //ﾒﾓﾘ間隔により、最大値を調整
                    if (isMaxNull) {
                        maxValue = truncateDecimal(minValue + stepSizeValue * STEPNUM);
                    };
                    if (isMinNull) {
                        if ((maxValue - minValue) / stepSizeValue > STEPNUM) {
                            minValue = truncateDecimal(maxValue - stepSizeValue * STEPNUM);		
                        } else {
                            minValue = 0;
                        };
                    };
                };

                if (isAuto) {
                    var decimalArr = [countDecimals(maxValue), countDecimals(minValue), countDecimals(stepSizeValue)];
                    decimalValue = Math.max.apply(null, decimalArr);
                    if (decimalValue > 5) decimalValue = 5;
                };
                  
                if(parseInt(maxLimit) === 0) {
                    maxLimit = maxValue + 1;
                };

                if ((isMinNull === true && isMaxNull === true && isStepNull === true) && ((parseInt(maxData) === 0 && parseInt(minData) === 0) || !(isFinite(maxData) && isFinite(minData)))) {
                    maxValue = 10;
                    minValue = 0;
                    stepSizeValue = 1;
                    decimalValue = 0;
                };

                if (parseInt(graphType) === 1) {
                    if (isMinNull === false) {
                        clipBottomValue = -minValue;
                    } else {
                        if (isStepNull === false) {
                            clipValue = 0;
                            clipBottomValue = -minValue;
                        } else {
                            clipValue = 0;
                        };
                    };
                    if (isMaxNull === false) {
                        clipTopValue = -maxValue;
                    } else {
                        if (isStepNull === false) {
                            clipValue = 0;
                            clipTopValue = -maxValue;
                        } else {
                            clipValue = 0;
                        };
                    };
                } else {
                    clipValue = 5;
                    clipTopValue = 5;
                    clipBottomValue = 5;
                };

                console.log("DataList1-before:", DataList1);
                console.log("DataList2-before:", DataList2);
                console.log("DataList3-before:", DataList3);

                for (let i = 0; i < DataList1.length; i++) {
                    if (DataList1[i] !== null) {
                        if (DataList1[i] > maxValue) {
                            DataList1[i] = maxValue;
                        };
    
                        if (DataList1[i] < minValue) {
                            DataList1[i] = minValue;
                        };
                    };
                    
                };

                for (let j = 0; j < DataList2.length; j++) {
                    if (DataList2[j] !== null) {
                        if (DataList2[j] > maxValue) {
                            DataList2[j] = maxValue;
                        };
    
                        if (DataList2[j] < minValue) {
                            DataList2[j] = minValue;
                        };
                    };
                    
                };

                for (let k = 0; k < DataList3.length; k++) {
                    if (DataList3[k] !== null) {
                        if (DataList3[k] > maxValue) {
                            DataList3[k] = maxValue;
                        };
    
                        if (DataList3[k] < minValue) {
                            DataList3[k] = minValue;
                        };
                    };
                    
                };

                console.log("DataList1-after:", DataList1);
                console.log("DataList2-after:", DataList2);
                console.log("DataList3-after:", DataList3);


                if (parseInt(dataInfo.selectedItem.length) === 1) {
                    datasetArray = [{
                        label: meterName[0] ? meterName[0] : '',
                        data: DataList1,
                        backgroundColor: BackGroundColorList1,
                        priorDisplay: hasErrorData1,
                        clip: {left: clipValue, right: clipValue, top: maxValue + clipTopValue, bottom: minValue + clipBottomValue}
                    }];

                } else if (parseInt(dataInfo.selectedItem.length) === 2) {
                    datasetArray = [{
                        label: meterName[0] ? meterName[0] : '',
                        data: DataList1,
                        backgroundColor: BackGroundColorList1,
                        priorDisplay: hasErrorData1,
                        clip: {left: clipValue, right: clipValue, top: maxValue + clipTopValue, bottom: minValue + clipBottomValue}
                    },
                    {
                        label: meterName[1] ? meterName[1] : '',
                        data: DataList2,
                        backgroundColor: BackGroundColorList2,
                        priorDisplay: hasErrorData2,
                        clip: {left: clipValue, right: clipValue, top: maxValue + clipTopValue, bottom: minValue + clipBottomValue}
                    }];
                } else if (parseInt(dataInfo.selectedItem.length) === 3) {
                    datasetArray = [{
                        label: meterName[0] ? meterName[0] : '',
                        data: DataList1,
                        backgroundColor: BackGroundColorList1,
                        priorDisplay: hasErrorData1,
                        clip: {left: clipValue, right: clipValue, top: maxValue + clipTopValue, bottom: minValue + clipBottomValue}
                    },
                    {
                        label: meterName[1] ? meterName[1] : '',
                        data: DataList2,
                        backgroundColor: BackGroundColorList2,
                        priorDisplay: hasErrorData2,
                        clip: {left: clipValue, right: clipValue, top: maxValue + clipTopValue, bottom: minValue + clipBottomValue}
                    },
                    {
                        label: meterName[2] ? meterName[2] : '',
                        data: DataList3,
                        backgroundColor: BackGroundColorList3,
                        priorDisplay: hasErrorData3,
                        clip: {left: clipValue, right: clipValue, top: maxValue + clipTopValue, bottom: minValue + clipBottomValue}
                    }];
                };


                // eslint-disable-next-line array-callback-return
                datasetArray.map(function(arr) {
                  arr.lineTension = 0;
                  arr.showLine = true;
                  arr.fill = false;
                  arr.pointRadius = 5;
                  arr.pointHoverRadius = 8;
                  arr.priorColor = limitOverColor;
                  arr.spanGaps = true;
                });

                chartInstance.current = new Chart(ctx, {
                    type: parseInt(graphType) === 1 ? 'bar' : 'line',
                    data: {
                        labels: xLabels,
                        datasets: datasetArray
                    },

                    options: {
                        responsive: true,
                        elements: {
                            line: {
                              borderWidth: 1.5,
                              borderColor: '#000'
                            }
                        },
                        hover: {
                            mode: 'nearest'	//複数点
                        },
                        layout: {
                            padding: {
                              top: 0,
                              bottom: 0,
                              left: 0,
                              right: 0
                            }
                        },
                        scales: {
                            y: {
                              beginAtZero: true,
                              min: minValue,
                              max: minValue > maxValue ? undefined : maxValue,
                              ticks: {
                                stepSize: stepSizeValue,
                                callback: function(value) {
                                    return value.toFixed(decimalValue);
                                }
                              },
                              grid: {
                                color: '#CCC',
                                lineWidth: 1,
                              },
                              
                            },
                            x: {
                              ticks: {
                                maxRotation: 90,
                                minRotation: 90,
                              }
                            }
                        },
                        plugins: {
                            legend: {
                              display: true,
                              labels: {
                                generateLabels: (chart) => {
                                    const datasets = chart.data.datasets;
                                    return datasets.map((dataset, i) => ({
                                      text: dataset.label,
                                      fillStyle: tooltipBorder[i],
                                      strokeStyle: '#FFF',
                                      lineWidth: 2,
                                      hidden: !chart.isDatasetVisible(i),
                                      datasetIndex: i,
                                    }));
                                }

                              },
                            },
                            title: {
                              display: false 
                            },
                            tooltip: {
                              enabled: false,
                              external: customTooltip,
                            },    
                        },
                    },

                });

                canvasRef.current.style.display = '';

                return () => {

                    if (canvasRef.current) {
                      // eslint-disable-next-line react-hooks/exhaustive-deps
                      canvasRef.current.removeEventListener('contextmenu', handleContextMenu);
                    };
      
                    if (chartInstance.current) {
                      chartInstance.current.destroy();
                    };
        
                };

            };


        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [showData, graphType]);

        const handleTopClick = () => {
            navigate('/');
        };

        return (
            <div id='Main' className={`main ${screenType}`}>
                <div id='Contents'>
                    <Breadcrumb
                        userName = {showUserName}
                        pageName = "データ比較"
                        onTopClick = {handleTopClick}
                    />
                    {(comparedItemInfo) ? (
                        <div className='lay-search_terms_comparisonGraph' id="dispCameraInfo">
                            <div className='lay-search_terms_comparisonGraph-wrap'>
                                <div className='lay-search_terms_comparisonGraph__list'>
                                    <div className='lay-search_terms_comparisonGraph__list__title'>
                                        <img src='img/common/icon-terms.png' alt="検索条件" />
                                    </div>
                                    <div className='lay-search_terms_comparisonGraph__list__text'>
                                        {comparedItemInfo.split('\n').map((line, index) => (
                                            <React.Fragment key={index}>
                                                {line}
                                                <br/>
                                            </React.Fragment>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className='lay-search_terms_comparisonGraph' id="dispCameraInfo" style={{display: 'none'}}></div>
                    )}
                    <div className='comptabs'>
                        <input type='radio' id='comp_graph_type1' name='comp_graph_type' value={1} checked={parseInt(graphType) === 1} onChange={handleGraphTypeChange}></input>
                        <label className='comp_graph_item' htmlFor='comp_graph_type1'>棒型表示</label>
                        <input type='radio' id='comp_graph_type2' name='comp_graph_type' value={2} checked={parseInt(graphType) === 2} onChange={handleGraphTypeChange}></input>
                        <label className='comp_graph_item' htmlFor='comp_graph_type2'>折れ線表示</label>
                    </div>
                    <div className='lay-comparisongraph'>
                        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} message={modalMessage} />
                        <div className='lay-comparisongraph__fig'>
                            <div className='comp_slideshow-container' id='screenshot'>
                                <div className='comp_measure_unit'>
                                    <span>[{unit}]</span>
                                </div>
                                <div className='comp_chart_area' id='cchatArea'>
                                    <span></span>
                                    <canvas id='cchart' height="500" width="830" ref={canvasRef}></canvas>
                                    <span></span>
                                </div>
                                <div className='comp_graph_prev' style={{display: parseInt(slideIndex) === 0 ? 'none' : ''}} onClick={handlePrevMove}>&#10094;</div>
                                <div className='comp_graph_next' style={{display: parseInt(slideIndex) === (graphDate.length - 1) ? 'none' : ''}} onClick={handleNextMove}>&#10095;</div>

                            </div>
                            <div className='compdots'>
                                {graphDate.map((dateInfo, key) => (
                                    <span className={dateInfo.className} key={key} id={`currentSlide${key}`} value={dateInfo.date} onClick={() => handleChangeSlide(key, dateInfo)}></span>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className='lay-comp_graph__btn'>
                        <ul>
                            <li id='lbl-back'>
                                <label className='mod-btn__roundborder' onClick={handleGoBack}>戻る</label>
                            </li>
                            <li>
                                <button type='button' className='mod-btn__round' id='graphImageDownload' onClick={handleDownloadImage}>グラフ画像ダウンロード</button>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={`overlay ${isLoading ? 'active' : ''}`}>
                  <div className={`spinner ${isLoading ? 'active' : ''}`}>
                  </div>
                </div>
            </div>

        );


    }

);

export default ComparisonGraph;
