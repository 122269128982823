/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, useCallback  } from 'react'
import axiosInstance from '../../helpers/refreshTokenMiddleware'; 
import PropTypes from 'prop-types';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import BASE_URL from '../../constants/Api';
import CameraInfo from '../CameraInfo/CameraInfo';
import './BatteryGraphList.css'
import Breadcrumb from '../Breadcrumb';

const BatteryGraphList = React.memo(
    ({user,refreshAccessToken, setSelectedCamera}) => {
        const [companyData, setCompanyData] = useState([]);
        const [companyList, setCompanyList] = useState([]);
        const [cameraInfoList, setCameraInfoList] = useState([]);
        const [showCameraList, setShowCameraList] = useState([]);
        const [cameraCount, setCameraCount] = useState();
        const [selectedCompany, setSelectedCompany] = useState(0);
        const [searchParams, setSearchParams] = useSearchParams();
        const [loading, setLoading] = useState(false);
        const [showUserName, setUserName] = useState('');
        const screenType = useSelector((state) => state.device.screenType);

        let pageCount = 0;
        let maxItem = 10;
        const navigate = useNavigate();
        const location = useLocation();

        const handleCompanyChange = (event) => {
            setSelectedCompany(event.target.value);
            setCameraList(event.target.value, 0); 
            setSearchParams({ value: event.target.value, pageIndex:0 }, { replace: true });                 
        };

        const handleShowGraph = useCallback((company_id, display_facility_name, display_camera_name, device_id) => {

            navigate(`/batterygraph?company_id=${company_id}&facility=${display_facility_name}&camera=${display_camera_name}&device_id=${device_id}`);
        }, []);

        const handlePageChange = (event) => {
            showCameraListSetting(cameraInfoList, event.target.id);
            setSearchParams({ value: selectedCompany, pageIndex: event.target.id }, { replace: true });                 
        };

        const handleTopClick = () => {
            navigate('/');
        }

        useEffect (() => {
            const fetchData = async () => {
                setLoading(true);
                
                try {
                    const accessToken = localStorage.getItem('accessToken');

                    // Fetch battery graph list
                    const response01 = await axiosInstance.post(BASE_URL + '/graph/battery-graph-list', {});

                    // Fetch company list 
                    const response = await axiosInstance.get(BASE_URL + '/graph/companies-list', {
                        headers: {
                        'Authorization': `Bearer ${accessToken}`
                        }
                    });

                    const tmpBattData = response01.data.respons
                    const tmpData = response.data.respons;

                    const tmpCameraData = [];
                    const tmpCompanyList = [];
                    let index = 0;
                    let tmpCameraBattery = 0;

                    tmpData.forEach(companyItem => {
                        tmpCompanyList.push ({
                            company_id: companyItem.company_id,
                            company_name: companyItem.company_name,
                            is_delete: companyItem.is_delete,
                        });
                    })
                    setCompanyList(tmpCompanyList);
    
                    tmpBattData.forEach(battData => {                    
                        index += 1;
                        if ((battData.battery_percentage < 0) || (battData.battery_percentage === null)) {
                            tmpCameraBattery = 0;
                        } else if (battData.battery_percentage > 100) {
                            tmpCameraBattery = 100;
                        } else {
                            tmpCameraBattery = battData.battery_percentage;
                        };
                        tmpCameraData.push({
                            index: index,
                            company_id: battData.company_id,
                            company_name: battData.company_name,
                            is_delete: battData.is_delete,
                            facility_name: battData.display_facility_name,
                            device_id: battData.device_id,
                            display_camera_name: battData.display_camera_name,
                            battery_percentage: tmpCameraBattery,
                            disused_flg: battData.disused_flg,
                        });
                    });

                     // console.log("tmpCameraData:", tmpCameraData);
                    setCameraCount(tmpCameraData.length);
                    setCompanyData(tmpCameraData);
                } catch (error) {
                    console.error('Error fetching battery-graph-list:', error);                    
                } finally {
                    setLoading(false); 
                }
            };

            fetchData();

            if (companyData.length > 0) cameraListSetting();

        },[refreshAccessToken, location]);

        useEffect(() => {
            if (companyData.length > 0) cameraListSetting();
        }, [companyData]);

        useEffect(() => {
            if (user) {
                setUserName(user.user_name);
            };
        }, [user]);

        const cameraListSetting = () => {
            // console.log("---------cameraListSetting----------");
            var valueParam = searchParams.get('value')*1;
            // console.log("valueParam:", valueParam);
            var pageIndex = searchParams.get('pageIndex')*1;
            // console.log("pageIndex:", pageIndex);
            if (valueParam == null) valueParam = 0;
            if (!pageIndex == null) pageIndex = 0;
            setSelectedCompany(valueParam);
            setCameraList(valueParam, pageIndex);
        };

        const getCameraListPage = (cameraList) => {
            // console.log("-------------getCameraListPage--------------");
            // console.log("cameraList:", cameraList);
            const tmpCameraListPage = [];
            let tmpPageData = [];
            let pageInd = 0;
            let prePageInd = 0;
            // console.log("-------------setPageCount--------------");
            if (cameraList.length === 0) {
                return tmpCameraListPage;
            }
            pageCount = Math.ceil(cameraList.length/maxItem);
            // console.log("-------------tmpCameraListPage create--------------");
            // console.log("pageCount:", pageCount);
            for (let index = 1; index <= pageCount; index++) {
                tmpCameraListPage.push({});
            };
            // console.log("tmpCameraListPage:", tmpCameraListPage);
            // console.log("tmpPageData:", tmpPageData);
            // console.log("-------------tmpCameraListPage set--------------");
            for (let index = 0; index < cameraList.length; index++) {
                pageInd = Math.floor(index/maxItem);

                if (parseInt(pageInd) === parseInt(prePageInd)) {                
                    tmpPageData.push(cameraList[index]);                    
                } else {
                    tmpCameraListPage[prePageInd].className = "dot"; 
                    tmpCameraListPage[prePageInd].data = tmpPageData; 
                    tmpPageData = [];
                    prePageInd = pageInd;
                    tmpPageData.push(cameraList[index]);                   
                }

            };
            tmpCameraListPage[pageInd].className = "dot"; 
            tmpCameraListPage[pageInd].data = tmpPageData;
            // console.log("tmpCompanyListPage:", tmpCameraListPage);

            return tmpCameraListPage;
        };

        const showCameraListSetting = (cameraList, pageIndex) => {

            // console.log("-------------showCameraListSetting--------------:", pageIndex);
            // console.log("showCameraListSetting - cameraInfoList.length:", cameraList.length);
            if (cameraList.length > 0) {
                for (let index = 0; index < cameraList.length; index++) {
                    cameraList[index].className = "dot";               
                }
                cameraList[pageIndex].className = "dot active";               
                setShowCameraList(cameraList[pageIndex].data);
            };
            

        };

        const setCameraList = (companyValue, pageInd) => {
            // console.log("-------------setCameraList--------------:", companyValue);
            let tmpCameraList = [];
            let tmpCameraListPage = [];
            let cameraInd = 0;

            if (String(companyValue) === "0") {   
                tmpCameraList = companyData;
            } else {
                companyData.forEach(data => {
                    if (String(data.company_id) === String(companyValue)) {
                        cameraInd += 1;
                        tmpCameraList.push({
                            index : cameraInd,
                            company_id: data.company_id,
                            company_name: data.company_name,
                            is_delete: data.is_delete,
                            facility_name: data.facility_name,
                            device_id: data.device_id,
                            display_camera_name: data.display_camera_name,
                            battery_percentage: data.battery_percentage,
                        });
                    };
                });
            };
            setCameraCount(tmpCameraList.length);
            tmpCameraListPage = getCameraListPage(tmpCameraList);                
            setCameraInfoList(tmpCameraListPage);
            if (tmpCameraListPage.length > 0) {
                setShowCameraList(tmpCameraListPage[0].data);                
            } else {
                setShowCameraList([]);                
            };
            showCameraListSetting(tmpCameraListPage, pageInd);
        };

        return (
            <div id='Main' className={`main ${screenType}`}>
                <div id='Contents'>
                    <Breadcrumb
                        userName = {showUserName}
                        pageName = "カメラバッテリー残量"
                        onTopClick = {handleTopClick}
                    />
                    <div className='display-main-battery'>
                        <div className='lay-master-set-battery'>
                            <div className='lay-master-set-wrap-battery'>
                                <div className='lay-master-user__title-battery'>
                                <span>カメラリスト</span>
                                </div>
            
                                {(companyList.length > 1) ? (  
                                <div className='mod-form__select-battery'>
                                    <select value={selectedCompany} id="company_list" name="company_list" onChange={handleCompanyChange}>
                                    <option key="0" value={0}>全表示</option>
                                    {companyList.map((company) => (
                                        <option key={company.company_id} value={company.company_id}>{company.company_name}</option>
                                    ))}
                                    </select>
                                </div>) : (<div className='mod-form__select-battery hidden'></div> )
                                }
            
                                <div className="confirm_camera-battery">
                                    <div className="cell-battery">
                                        <div>
                                        <span id="camera-count">カメラ数：{cameraCount}台</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
    
                        {(showCameraList.length > 0)?(
                            <div className='cameralist'>
                                {showCameraList.map((cameraInfo) => (  
                                    <div key={cameraInfo.index}>                      
                                    <CameraInfo 
                                        componentkey = {cameraInfo.index}
                                        cameraIndex = {cameraInfo.index}
                                        display_facility_name = {cameraInfo.facility_name} 
                                        display_camera_name = {cameraInfo.display_camera_name} 
                                        battery_per = {cameraInfo.battery_percentage} 
                                        onShowGraphClick = {() => handleShowGraph(cameraInfo.company_id, cameraInfo.facility_name, cameraInfo.display_camera_name, cameraInfo.device_id)}
                                    />
                                    </div>
                                ))}             
                                <div className='pagination'>
                                    {cameraInfoList.map((pagedata, index) => (
                                        <span className={pagedata.className} key={index} id = {index} onClick={handlePageChange}></span>                             
                                    ))}                                      
                                </div>
                            </div>
                        ) : (<div className='cameralist hidden'></div>)
                        }
                    </div>  
                </div>
                <div className={`overlay ${loading ? 'active' : ''}`}>
                    <div className={`spinner ${loading ? 'active' : ''}`}>
                    </div>
                </div>
            </div>                     
        );
    }
);

BatteryGraphList.propTypes = {
    refreshAccessToken: PropTypes.func.isRequired
};

export default BatteryGraphList;
