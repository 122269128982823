import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { dataDelete, fetchImageList } from '../../actions/datamanage';
import Modal from '../Modal/Modal';

const DataDelete = ({
    relativeIndex,
    dataDelete,
    fetchImageList,
    selectedItem,
    startDate,
    endDate,
    selectedFacility,
    selectedCamera,
    selectedGauge,
    comments,
    gaugeData,
    setComments,
    setLoading,
    setMeasureValues, 
    screenType,
    editStatus,
    setEditStatus,
    setRelativeActive
}) => {
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [deleteData, setDeleteData] = useState(null);
    const [modalMessage, setModalMessage] = useState('');

    const dataDeleteHandler = () => {
        const form = document.getElementById(`dataform${relativeIndex}`);
        const inputs = form.getElementsByTagName('input');
        let data = {
            company_id: selectedItem.company_id,
            gauge_id: selectedItem.gauge_id,
            device_id: selectedItem.device_id,
            get_datetime: selectedItem.get_datetime,
        };

        for (let input of inputs) {
            if (input.type === 'hidden' || input.type === 'text') {
                if (input.value !== '') {
                    if (['comment', 'gauge_id', 'device_id', 'get_datetime'].includes(input.name)) {
                        data[input.name] = input.value;
                    } else {
                        const parsedValue = parseFloat(input.value);
                        data[input.name] = isNaN(parsedValue) ? '' : parsedValue;
                    }
                }
            }
        }

        const checkbox = form.querySelector('input[name="disuse_flg"]');
        const isCheckboxChecked = checkbox && checkbox.checked;
        if (isCheckboxChecked) {
            data['disuse_flg'] = 'on';
        }

        data.relativeIndex = relativeIndex;

        setDeleteData(data);
        setIsDeleteModalOpen(true);
        setModalMessage('データを削除して、\n未登録に戻しますか？');
    };

    const confirmDelete = async () => {
        try {
            setLoading(true);
            const deletePayload = {
                company_id: deleteData.company_id,
                gauge_id: deleteData.gauge_id,
                device_id: deleteData.device_id,
                get_datetime: deleteData.get_datetime,
            };

            await dataDelete(deletePayload);
            setRelativeActive(relativeIndex);
            
            setEditStatus(() => {
                const newStatus = {};
                Object.keys(editStatus).forEach((key) => {
                    newStatus[key] = false;
                });
                return newStatus;
            });

            Object.keys(editStatus).forEach((key) => {
                const editBtn = document.getElementById(`edit_btn${key}`);
                const editUpdateBtn = document.getElementById(`edit_update_btn${key}`);
        
                if (editBtn) {
                    editBtn.style.display = "block";
                }
                if (editUpdateBtn) {
                    editUpdateBtn.style.display = "none";
                }
                document.querySelectorAll(`.edit_data_lbl${key}`).forEach(element => {
                    element.style.display = "block";
                });
                document.querySelectorAll(`.edit_data${key}`).forEach(element => {
                    element.style.display = "none";
                });
                document.querySelectorAll(`#edit_comment_lbl${key}`).forEach(element => {
                    element.style.display = "block";
                });
                document.querySelectorAll(`#edit_comment${key}`).forEach(element => {
                    element.style.display = "none";
                });
                const editCommentLbl = document.getElementById(`edit_comment_lbl${key}`);
                const editComment = document.getElementById(`edit_comment${key}`);
                const sizeEdit = document.getElementById(`size_edit${key}`);
                const leftLabel = document.getElementById(`leftLabel${key}`);
                const rightLabel = document.getElementById(`rightLabel${key}`);
        
                if (editCommentLbl) {
                    editCommentLbl.style.display = "block";
                }
                if (editComment) {
                    editComment.style.display = "none";
                }
                if (sizeEdit) {
                    sizeEdit.style.display = "none";
                }
                if (leftLabel) {
                    leftLabel.style.display = "none";
                }
                if (rightLabel) {
                    rightLabel.style.display = "none";
                }
    
                const checkbox = document.getElementById(`disuse_flg${key}`);
                if (checkbox) {
                    checkbox.disabled = true;
                }
            });
    
            setComments(prevComments => {
                const updatedComments = { ...prevComments };
                updatedComments[deleteData.relativeIndex] = '';
                return updatedComments;
            });

            setMeasureValues(prevValues => ({
                ...prevValues,
                [deleteData.relativeIndex]: {},
            }));

            const form = document.getElementById(`dataform${deleteData.relativeIndex}`);
            if (form) {
                const inputs = form.getElementsByTagName('input');
                const textarea = form.querySelector('textarea[name="comment"]');

                for (let input of inputs) {
                    if (input.type === 'text') {
                        input.value = '';
                    }
                }

                if (textarea) {
                    textarea.value = '';
                }

                // const checkbox = form.querySelector('input[name="disuse_flg"]');
                // // if (checkbox) {
                // //     checkbox.checked = false;
                // // }

                // if (!checkbox.checked && deleteData.diff_flg === 1) {
                //     document.querySelectorAll(`#li${deleteData.relativeIndex} .lay-list__horizontal__para__num.diff`).forEach(element => {
                //         element.style.display = 'block';
                //     });
                // }
            }

        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
            setIsDeleteModalOpen(false);
        }
    };

    return (
        <>
            <button
                type="button"
                className={`mod-btn__roundborder small ${screenType}`}
                id={`delete_btn${relativeIndex}`}
                style={{ marginTop: '10px', color: 'red', display: 'block' }}
                onClick={dataDeleteHandler}
            >
                削除
            </button>
            {isDeleteModalOpen && (
                <Modal
                    isOpen={isDeleteModalOpen}
                    onClose={() => setIsDeleteModalOpen(false)}
                    message={modalMessage}
                    onConfirm={confirmDelete}
                    showCancelButton={true}
                />
            )}
        </>
    );
};

DataDelete.propTypes = {
    relativeIndex: PropTypes.number.isRequired,
    fetchImageList: PropTypes.func.isRequired,
    dataDelete: PropTypes.func.isRequired,
    selectedItem: PropTypes.object.isRequired,
    startDate: PropTypes.instanceOf(Date).isRequired,
    endDate: PropTypes.instanceOf(Date).isRequired,
    selectedFacility: PropTypes.string.isRequired,
    selectedCamera: PropTypes.string.isRequired,
    selectedGauge: PropTypes.string.isRequired,
    comments: PropTypes.object.isRequired,
    setComments: PropTypes.func.isRequired,
    setLoading: PropTypes.func.isRequired
};

const mapDispatchToProps = {
    dataDelete,
    fetchImageList
};

export default connect(null, mapDispatchToProps)(DataDelete);
