import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ComparisonGraph from '../components/ComparisonGraph';
import { fetchComparisonGraphData} from "../actions/comparisonGraph";

const mapStateToProps = (state) => {
    const comparisonGraphData = state.comparisonGraph;
    const user = state.login.user;

    return {
        user,
        standard_time: comparisonGraphData.standard_time,
        diff_flg: comparisonGraphData.diff_flg,
        diff_type: comparisonGraphData.diff_type,
        graph_data: comparisonGraphData.graph_data,
        gauge_id: comparisonGraphData.gauge_id,
        measure_id: comparisonGraphData.measure_id,
        is_loaded: comparisonGraphData.is_loaded,
        start_date: comparisonGraphData.start_date,
        end_date: comparisonGraphData.end_date,
        start_date_real: comparisonGraphData.start_date_real,
        end_date_real: comparisonGraphData.end_date_real,
        view_type: comparisonGraphData.view_type,
        unit: comparisonGraphData.unit,
        max_limit: comparisonGraphData.max_limit,
        min_limit: comparisonGraphData.min_limit,
        error: comparisonGraphData.error,
        isFetched: comparisonGraphData.isFetched,
        isLoading: comparisonGraphData.isLoading,
    };

};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchComparisonGraphData,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ComparisonGraph);
