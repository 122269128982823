import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import { addDays, differenceInDays, format, isBefore } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css'; 
import './DataManage.css';
import Modal from '../Modal/Modal';
import PhotoDownload from './PhotoDownload';
import CsvDownload from './CsvDownload';
import SearchForm from './SearchForm';
import ImageList from './ImageList';
import DataList from './DataList';
import DataManagePopup from './DataManagePopup';
import CameraInfo from './CameraInfo';
import PhotoShoot from './PhotoShoot';
import ConfirmPopup from './ConfirmPopup';
import PhotoShootDenyPopup from './PhotoShootDenyPopup';
import Breadcrumb from '../Breadcrumb';
import { AUTHORITY_CLASS } from '../../constants/Enums';

const isGetCameraInfo = (selectedItem, failure_id, user) => {
    return (
        selectedItem?.camera_id &&
        failure_id !== 1 &&     
        user?.authority_class > AUTHORITY_CLASS.GENERAL.value 
    );
};

const isDisplayCameraInfo = (user, cameraInfo, deviceType) => {
    return (
        user &&
        user.authority_class > AUTHORITY_CLASS.GENERAL.value &&  
        cameraInfo &&                                            
        deviceType !== "mobile"                                  
    );
};

const DataManage = ({
    user,
    isFetching,
    facilities = [],
    cameras = [],
    cameraInfo,
    gauges = [],
    statuses = [],
    imageListData = [],
    searchCount = 0,
    selectedItem = null,
    error = '',
    fetchFacilities,
    fetchImageList,
    fetchCount,    
    setSelectedItem,
    fetchCameraInfo,
    clearCameraInfo,
    onPhotoShoot,
    fetchPhotoShootStatus,
    onPhotoShootUpdate,
    searchTerms,
    gaugeData = {},
    failure_id,
    is_null, 
    isFetched,
    relativeActive,
    setRelativeActive,
}) => {

    console.log('DataManage Render !');
     
    // State management
    const [startDate, setStartDate] = useState(addDays(new Date(), -1));
    const [endDate, setEndDate] = useState(new Date());
    const [selectedFacility, setSelectedFacility] = useState('');
    const [selectedCamera, setSelectedCamera] = useState('');   
    const [selectedGauge, setSelectedGauge] = useState('');
    const [filteredGauges, setFilteredGauges] = useState(gauges);
    const [filteredCamera, setFilteredCamera] = useState(cameras);
    const [searchPerformed, setSearchPerformed] = useState(true);
    const [photoDisplay, setPhotoDisplay] = useState(true);
    const [isDownloading, setIsDownloading] = useState(false);
    const [searchConditions, setSearchConditions] = useState({
        facilityName: '',
        cameraName: '',
        gaugeName: '',
        status: '',
        searchComment: '',
        threshold: ''
    });
    const [isSearching, setIsSearching] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentGroup, setCurrentGroup] = useState(1);
    const [selectedPage, setSelectedPage] = useState(1); 
    const previousGroupRef = useRef(1);
    const itemsPerPage = 10;
    const buttonsPerGroup = 10;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [displayedData, setDisplayedData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isFetchingCount, setIsFetchingCount] = useState(false);
    const navigate = useNavigate();
    const apiCalled = useRef(false);
    const [isCountVisible, setIsCountVisible] = useState(false);
    const [isPaginationVisible, setIsPaginationVisible] = useState(false);
    const [apiCallId, setApiCallId] = useState(0);
    const [latestApiCallId, setLatestApiCallId] = useState(0);
    const [isConfirmPopup, setIsConfirmPopup] = useState(false);
    const [isPhotoShootDenyPopup, setIsPhotoShootDenyPopup] = useState(false);
    const [showUserName, setUserName] = useState('');       
    const [deviceType, setDeviceType] = useState("desktop");
    const [measureValues, setMeasureValues] = useState({});
    const screenType = useSelector((state) => state.device.screenType);
    let imageListFinished = false;
    let countExceedsLimit = false;
    const newStartDate = useSelector((state) => state.dataManage?.new_start_date);

    // detect device for responsive 
    useEffect(() => {
        const detectDevice = () => {
            const userAgent = navigator.userAgent || navigator.vendor || window.opera;
            const isTouchDevice = "ontouchstart" in window || navigator.maxTouchPoints > 0;

            if (/iPhone|Android.+Mobile/.test(userAgent)) {
                setDeviceType("mobile");
            } else if (
                /iPad|Android/.test(userAgent) || // iPad and Android tablets
                (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)  || // iPad Pro in desktop mode
                (isTouchDevice)            // Handle simulation in Chrome DevTools
            ) {
                setDeviceType("tablet");
            } else {
                setDeviceType("desktop");
            }
        };

        detectDevice();
        window.addEventListener('resize', detectDevice);

        return () => {
            window.removeEventListener('resize', detectDevice);
        };
    }, []);   

          
    // Load active item from localStorage
    // useEffect(() => {
    //     const activeItem = JSON.parse(localStorage.getItem('activeItem'));
    //     if (activeItem) {
    //         setSelectedItem(activeItem);
            
    //         // Process gaugeName to remove the "［初回名称］" prefix, if present
    //         const processedGaugeName = activeItem.gauge_name
    //             ? activeItem.gauge_name.replace(/^［初回名称］\s*/, '')
    //             : '';
    //         setSearchConditions({
    //             facilityName: activeItem.facility_name || '',
    //             cameraName: activeItem.camera_name || '',
    //             gaugeName: processedGaugeName,  
    //             status: '',
    //             searchComment: '',
    //             threshold: ''
    //         });
    //     }
    // }, [setSelectedItem]);    

    useEffect(() => {
        const selectedFacilityName = facilities.find(facility => facility.facility_id === parseInt(selectedFacility))?.facility_name || '';
        const selectedCameraName = cameras.find(camera => camera.device_id === selectedCamera)?.display_camera_name || '';
        const selectedGaugeName = gauges.find(gauge => gauge.gauge_id === selectedGauge)?.display_gauge_name || '';
        const processedGaugeName = selectedGaugeName.replace(/^［初回名称］\s*/, '');
        const selectedComment = document.getElementById('search_comment').value || '';
        const selectedThreshold = document.getElementById('threshold').value || '';
        const selectedStatusIndex = document.getElementById('status').value;
        const selectedStatus = statuses[selectedStatusIndex] || '';
        setSearchConditions({
            facilityName: selectedFacilityName,
            cameraName: selectedCameraName,
            gaugeName: processedGaugeName,
            status: selectedStatus,
            searchComment: selectedComment,
            threshold: selectedThreshold
    });
    }, [selectedItem, facilities, cameras, gauges]);    
    
    // Fetch data for the selected item and initialize search conditions
    const fetchDataForSelectedItem = async () => {
        if (selectedItem && !apiCalled.current) {  
            const currentApiCallId = apiCallId + 1; 
            setApiCallId(currentApiCallId); 
    
            setIsCountVisible(false);
            setIsPaginationVisible(false);
            apiCalled.current = true;
            const failureId = selectedItem.isHistory ? 1 : 0;
            const today = new Date();
            const newStartDate = addDays(today, -1);
            const newEndDate = today;
    
            clearCameraInfo();

            setStartDate(newStartDate);
            setEndDate(newEndDate);

            setLoading(true);
            setIsFetchingCount(true);
    
            try {
                const facilitiesPromise = fetchFacilities(selectedItem.company_id, failureId);
    
                const imageListPromise = fetchImageList(
                    selectedItem.company_id,
                    selectedItem.company_name,
                    newStartDate,
                    newEndDate,
                    1,
                    selectedItem.facility_id,
                    selectedItem.camera_id,
                    selectedItem.gauge_id,
                    null,
                    null,
                    null,
                    failureId,
                    null
                ).then(() => {
                    imageListFinished = true;
                    setLoading(false);
                    setLatestApiCallId(currentApiCallId);
                });
    
                const countPromise = fetchCount(
                    selectedItem.company_id,
                    selectedItem.company_name,
                    newStartDate,
                    newEndDate,
                    selectedItem.facility_id,
                    selectedItem.camera_id,
                    selectedItem.gauge_id,
                    null,
                    null,
                    null,
                    failureId
                ).then((count) => {
                    if (!imageListFinished) {
                        setLoading(true);
                    }
                    setIsFetchingCount(false);
                    setIsCountVisible(true);
                    setIsPaginationVisible(true);
    
                    if (count > 10000) {
                        countExceedsLimit = true;
                    }
                });

                if (isGetCameraInfo(selectedItem, failure_id, user)) {
                    const cameraInfoPromise = fetchCameraInfo(
                        selectedItem.company_id,
                        selectedItem.camera_id
                    ).then(() => {
                        // setLoading(false); 
                    });
        
                    await facilitiesPromise;
                    await Promise.all([imageListPromise, cameraInfoPromise, countPromise]);
                }
                else {                    
                    await facilitiesPromise;
                    await Promise.all([imageListPromise, countPromise]);
                }
                    
                if (currentApiCallId === latestApiCallId) { 
                    setSelectedFacility(selectedItem.facility_id || '');
                    setSelectedCamera(selectedItem.camera_id || '');
                    setFilteredGauges(filterGaugesByCamera(selectedItem.camera_id || ''));
                    setSelectedGauge(selectedItem.gauge_id || '');
                }
                
                setLatestApiCallId(currentApiCallId);
            } catch (error) {
                console.error("Error during API call:", error);
                setLoading(false); 
            } finally {
                apiCalled.current = false;
            }
    
            if (countExceedsLimit) {
                setModalMessage('検索件数が10000件を超えました。\n最新の10000件を表示します。');
                setIsModalOpen(true);
            }
    
            setCurrentGroup(1);
            setCurrentPage(1);
            setSelectedPage(1);
            previousGroupRef.current = 1;
        }
    };
    
    // Handle fetching data when a new item is selected or search is initiated
    useEffect(() => {
        if (selectedItem && !isSearching) {
            fetchDataForSelectedItem();
            document.getElementById('status').value = '';
            document.getElementById('search_comment').value = '';
            document.getElementById('threshold').value = '';
            setSelectedFacility(selectedItem.facility_id || '');
            setSelectedCamera(selectedItem.camera_id || '');
            setSelectedGauge(selectedItem.gauge_id || '');

            const newSearchConditions = { ...searchConditions };
            if (selectedItem.facility_name) {
                newSearchConditions.facilityName = selectedItem.facility_name;
            } else {
                delete newSearchConditions.facilityName;
            }

            if (selectedItem.camera_name) {
                newSearchConditions.cameraName = selectedItem.camera_name;
            } else {
                delete newSearchConditions.cameraName;
            }

            if (selectedItem.gauge_name) {
                newSearchConditions.gaugeName = selectedItem.gauge_name.replace(/^［初回名称］\s*/, '');
            } else {
                delete newSearchConditions.gaugeName;
            }

            newSearchConditions.status = '';
            newSearchConditions.searchComment = '';
            newSearchConditions.threshold = '';

            setSearchConditions(newSearchConditions);
            setPhotoDisplay(true);
        }
        setIsSearching(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedItem, fetchFacilities, fetchImageList, fetchCount]);

    // Fetch data with search parameters
    const fetchDataWithSearch = async () => {
        const currentApiCallId = apiCallId + 1; 
        setApiCallId(currentApiCallId);
        setLoading(true);
        setIsCountVisible(false);
        setIsPaginationVisible(false);
        const selectedStatusIndex = parseInt(document.getElementById('status').value, 10);
        const selectedComment = parseInt(document.getElementById('search_comment').value, 10);
        const selectedThreshold = parseInt(document.getElementById('threshold').value, 10);
        const failureId = selectedItem.isHistory ? 1 : 0;
        const facilityId = selectedFacility !== '' ? selectedFacility : null;
        const cameraId = selectedCamera !== '' ? selectedCamera : null;
        const gaugeId = selectedGauge !== '' ? selectedGauge : null;
    
        setIsFetchingCount(true);
        try {
            // Fetch image list and count separately
            const imageListPromise = fetchImageList(
                selectedItem.company_id,
                selectedItem.company_name,
                startDate,
                endDate,
                1,
                facilityId,
                cameraId,
                gaugeId,
                isNaN(selectedStatusIndex) ? null : selectedStatusIndex,
                isNaN(selectedComment) ? null : selectedComment,
                isNaN(selectedThreshold) ? null : selectedThreshold,
                failureId,
                null,
            ).then(() => {
                imageListFinished = true;
                setLoading(false);
                setLatestApiCallId(currentApiCallId);
            });
        
            const countPromise = fetchCount(
                selectedItem.company_id,
                selectedItem.company_name,
                startDate,
                endDate,
                facilityId,
                cameraId,
                gaugeId,
                isNaN(selectedStatusIndex) ? null : selectedStatusIndex,
                isNaN(selectedComment) ? null : selectedComment,
                isNaN(selectedThreshold) ? null : selectedThreshold,
                failureId
            ).then((count) => {
                if (!imageListFinished) {
                    setLoading(true);
                }
                setIsFetchingCount(false);
                setIsCountVisible(true);
                setIsPaginationVisible(true);

                if (count > 10000) {
                    countExceedsLimit = true; 
                }
            });

            if (isGetCameraInfo(selectedItem, failure_id, user)) {
                const cameraInfoPromise = fetchCameraInfo(
                    selectedItem.company_id,
                    selectedItem.camera_id                
                ).then(() => {
                    // setLoading(false); 
                });

                await Promise.all([imageListPromise, cameraInfoPromise, countPromise]);
            }
            else{
                await Promise.all([imageListPromise, countPromise]);
            }
            setLatestApiCallId(currentApiCallId); 

        } catch (error) {
            console.error("Error during search:", error);
            setLoading(false);
        } finally {
            apiCalled.current = false;
        }

        if (countExceedsLimit) {
            setModalMessage('検索件数が10000件を超えました。\n最新の10000件を表示します。');
            setIsModalOpen(true);
        }

        setCurrentGroup(1);
        setCurrentPage(1);
        setSelectedPage(1);
    };

    // Display modal on error
    useEffect(() => {
        if (error) {
            setModalMessage(error);
            setIsModalOpen(true);
        }
    }, [error]);

    useEffect(() => {
        if (user) {
          setUserName(user.user_name);
        };
    }, [user]);

    // Filter gauges based on selected camera
    const filterGaugesByCamera = (cameraId) => {
        return gauges.filter(gauge => gauge.device_id === cameraId);
    };

    // Format date to string
    const formatDate = (date) => (date ? format(date, 'yyyy/MM/dd') : '');

    // Get search parameters
    const getSearchParameters = () => {
        const selectedStatusIndex = document.getElementById('status').value;
        const selectedStatus = statuses[selectedStatusIndex] || '';
        return {
            selectedStatusIndex,
            selectedStatus
        };
    };

    // Pagination pages and groups
    const totalPages = Math.ceil(Math.min(searchCount, 10000) / itemsPerPage);
    const totalGroups = Math.ceil(totalPages / buttonsPerGroup);
    const [mainImage, setMainImage] = useState(); 

    const resetMainImage = (page) => {
        const startIndex = ((page - 1) % buttonsPerGroup) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        const pageData = displayedData.slice(startIndex, endIndex);
    
        if (pageData.length > 0) {
            setMainImage({
                ...pageData[0],
                gaugeDataList: getGaugeData(pageData[0], gaugeData),
                comment_font_size: pageData[0].comment_font_size || 12,
                relativeIndex: displayedData.findIndex(item => item === pageData[0]),
            });
        }
    };
    
    const goToPage = async (page) => {
        const newGroup = Math.ceil(page / buttonsPerGroup);
    
        const selectedStatusIndex = parseInt(document.getElementById('status').value, 10);
        const selectedComment = parseInt(document.getElementById('search_comment').value, 10);
        const selectedThreshold = parseInt(document.getElementById('threshold').value, 10);
        const failureId = selectedItem.isHistory ? 1 : 0; 
    
        setLoading(true);
    
        if (newGroup !== previousGroupRef.current) {
            await fetchImageList(
                selectedItem.company_id,
                selectedItem.company_name,
                startDate,
                endDate,
                newGroup,
                selectedItem.facility_id,
                selectedItem.camera_id,
                selectedItem.gauge_id,
                isNaN(selectedStatusIndex) ? null : selectedStatusIndex,
                isNaN(selectedComment) ? null : selectedComment,
                isNaN(selectedThreshold) ? null : selectedThreshold,
                failureId,
                newGroup > 1 ? newStartDate : null
            );
            previousGroupRef.current = newGroup;
        }
    
        setCurrentPage(page);
        setSelectedPage(page);
        setCurrentGroup(newGroup);
        resetMainImage(page);
        setIsModalOpen(false);
        setLoading(false);
        setRelativeActive(null);
    };

    const goToGroup = (group) => {
        setCurrentGroup(group);
        setCurrentPage(selectedPage);
    };

    const currentPageGroup = Math.ceil(currentPage / buttonsPerGroup);
    const pagesInCurrentGroup = Array.from({ length: totalPages }, (_, i) => i + 1)
        .filter(page => Math.ceil(page / buttonsPerGroup) === currentPageGroup);

    // Get gauge data for a specific item
    const getGaugeData = (item, gaugeData) => {
        const gaugeKey = `${item.m_gauge_device_id}${item.gauge_id}${item.get_datetime}`;
        const gaugeEntries = gaugeData[gaugeKey] || [];
    
        return gaugeEntries.map(gauge => {
            let measureVal = gauge?.measure_val;
            if (measureVal === null) {
                measureVal = ""; 
            } else if (measureVal !== null && measureVal !== undefined) {
                measureVal = formatValue(parseFloat(measureVal), gauge.decimal_value);
            }
                
            return {
                unit: gauge ? gauge.unit : '',
                diffFlg: gauge ? gauge.diff_flg : 0,
                measureVal, 
                diffVal: gauge && gauge.diff_val !== null ? gauge.diff_val : '--',
                displayDiffVal: gauge && gauge.diff_val === 0 ? '0' : gauge.diff_val,
                hasMeasureVal: gauge && gauge.measure_val !== '',
                measure_id: gauge ? gauge.measure_id : '',
                decimal_value: gauge ? gauge.decimal_value : '',
                min_limit: gauge ? gauge.min_limit : '',
                min_limit_flg: gauge ? gauge.min_limit_flg : '',
                max_limit: gauge ? gauge.max_limit : '',
                max_limit_flg: gauge ? gauge.max_limit_flg : '',
                diff_type: gauge ? gauge.diff_type : '',
                diff_min_limit: gauge ? gauge.diff_min_limit : '',
                diff_min_limit_flg: gauge ? gauge.diff_min_limit_flg : '',
                diff_max_limit: gauge ? gauge.diff_max_limit : '',
                diff_max_limit_flg: gauge ? gauge.diff_max_limit_flg : '',
                abnormal_flg: gauge ? gauge.abnormal_flg : 0,
                prev_measure_val: gauge ? gauge.prev_measure_val : '',
                threshold_min_flg: gauge ? gauge.threshold_min_flg : '',
                threshold_max_flg: gauge ? gauge.threshold_max_flg : '',
                diff_threshold_min_flg: gauge ? gauge.diff_threshold_min_flg : '',
                diff_threshold_max_flg: gauge ? gauge.diff_threshold_max_flg : '',
            };
        });
    };

    // Format the value according to decimal_value
    const formatValue = (value, decimalValue) => {
        if (value === null || value === undefined || isNaN(value)) {
            return '';
        }
        if (decimalValue === null || decimalValue === undefined || isNaN(decimalValue)) {
            decimalValue = 0; 
        }
    
        const numValue = parseFloat(value);
    
        if (decimalValue >= 0) {
            const factor = Math.pow(10, decimalValue);
            return (Math.trunc(numValue * factor) / factor).toFixed(decimalValue);
        }

        return value.toString();
    };
    
    
    // Function to handle diffVal
    const formatDiffVal = (diffVal, decimalValue) => {
        if (diffVal === null || diffVal === undefined || isNaN(diffVal)) {
            return "--"; 
        }
        if (diffVal < 0) {
            return "--"; 
        }
        return formatValue(diffVal, decimalValue); 
    };  

    // Handle edit button click
    const [editStatus, setEditStatus] = useState({});

    const fnEditData = (num, item, gaugeDataList) => {
        setEditStatus((prevStatus) => ({
            ...prevStatus,
            [num]: true,
        }));
        const hasMeasureVal = gaugeDataList.some(gauge => gauge.measureVal);

        if (hasMeasureVal) {
            setMeasureValues((prevValues) => ({
                ...prevValues,
                [num]: gaugeDataList.reduce((acc, gauge, index) => {
                    acc[index] = gauge.measureVal || ''; 
                    return acc;
                }, {}),
            }));
        }
        
        document.querySelectorAll(`.edit_data_lbl${num}`).forEach(element => {
            element.style.display = "none";
        });
        document.querySelectorAll(`.edit_data${num}`).forEach(element => {
            element.style.display = "block";
        });
        
        document.querySelectorAll(`#edit_comment_lbl${num}`).forEach(element => {
            element.style.display = "none";
        });
        document.querySelectorAll(`#edit_comment${num}`).forEach(element => {
            element.style.display = "block";
        });
        document.getElementById(`edit_btn${num}`).style.display = "none";
        document.getElementById(`edit_update_btn${num}`).style.display = "";

        document.getElementById(`size_edit${num}`).style.display = "";
        document.getElementById(`leftLabel${num}`).style.display = "";
        document.getElementById(`rightLabel${num}`).style.display = "";

        const checkbox = document.getElementById(`disuse_flg${num}`);
        if (checkbox) {
            checkbox.disabled = false;
        }
    };

    // Handle comment change
    const [comments, setComments] = useState({});
    const handleCommentChange = (index, event) => {
        const newComment = event.target.value;
        setComments(prevComments => ({
            ...prevComments,
            [index]: newComment
        }));
    };

    // Handle font size change
    const [fontSizes, setFontSizes] = useState({});
    const handleFontSizeChange = (index, newSize) => {
        setFontSizes(prevFontSizes => ({
            ...prevFontSizes,
            [index]: newSize
        }));
    };

    const [checkboxStates, setCheckboxStates] = useState({});
    const handleCheckboxChange = (relativeIndex, checked) => {
        setCheckboxStates(prevState => ({
            ...prevState,
            [relativeIndex]: checked,
        }));
    };    

    useEffect(() => {
        const updatedCheckboxStates = displayedData.reduce((acc, item, index) => {
            acc[index] = item.disuse_flg === 1; 
            return acc;
        }, {});
        
        setCheckboxStates(updatedCheckboxStates); 
    }, [displayedData]);

    // Handle search action
    const handleSearch = (startDate, endDate, selectedFacility, selectedCamera, selectedGauge) => {
        const isTokyoWaterworks = selectedItem.company_id === "1970853" && selectedItem.company_name === "東京都水道局";
        const daysDifference = differenceInDays(endDate, startDate);
        if (isTokyoWaterworks && daysDifference > 30) {
            setModalMessage('1か月以内に期間を入力してください。');
            setIsModalOpen(true);
            return;
        } else if (!isTokyoWaterworks && daysDifference > 365) {
            setModalMessage('1年以内に期間を入力してください。');
            setIsModalOpen(true);
            return;
        }

        if (!startDate || !endDate) {
            setModalMessage('期間を入力してください。');
            setIsModalOpen(true);
            return;
        }
    
        if (isBefore(new Date(endDate.toDateString()), new Date(startDate.toDateString()))) {
            setModalMessage('正しい期間を入力してください。');
            setIsModalOpen(true);
            return;
        }

        const selectedFacilityName = facilities.find(facility => facility.facility_id === parseInt(selectedFacility))?.facility_name || '';
        const selectedCameraName = cameras.find(camera => camera.device_id === selectedCamera)?.display_camera_name || '';
        const selectedGaugeName = gauges.find(gauge => gauge.gauge_id === selectedGauge)?.display_gauge_name || '';
        const processedGaugeName = selectedGaugeName.replace(/^［初回名称］\s*/, '');
        const selectedComment = document.getElementById('search_comment').value || '';
        const selectedThreshold = document.getElementById('threshold').value || '';
        const isHistory = selectedItem?.isHistory || false;
        const { selectedStatus } = getSearchParameters();

        const activeItem = {
            company_id: selectedItem.company_id,
            facility_id: selectedFacility || null,
            camera_id: selectedCamera || null,
            gauge_id: selectedGauge || null,
            isHistory: isHistory,
            type: isHistory
                ? 'history'
                : selectedGauge
                ? 'gauge'
                : selectedCamera
                ? 'camera'
                : selectedFacility
                ? 'facility'
                : 'company',
        };

        if (!selectedCamera) {
            delete activeItem.camera_id;
            delete activeItem.camera_name;
        }
        if (!selectedGauge) {
            delete activeItem.gauge_id;
            delete activeItem.gauge_name;
        }

        // let currentActiveItem = JSON.parse(localStorage.getItem('activeItem'));
        // if (currentActiveItem && currentActiveItem.isHistory && !selectedFacility && !selectedCamera && !selectedGauge) {
        //     console.log('handleSearch - cameras:', cameras);
        //     console.log('handleSearch - currentActiveItem:', currentActiveItem);
            
        //     setSelectedItem(currentActiveItem);
        //     setSearchConditions({
        //         facilityName: selectedFacilityName,
        //         cameraName: selectedCameraName,
        //         gaugeName: processedGaugeName,
        //         status: selectedStatus,
        //         searchComment: selectedComment,
        //         threshold: selectedThreshold
        //     });
        //     setIsSearching(true);
        //     return;
        // }

        let url = `/data-manage?company_id=${activeItem.company_id}`;
        if (activeItem.facility_id) {
            url += `&facility_id=${activeItem.facility_id}`;
        }
        if (activeItem.camera_id) {
            url += `&device_id=${activeItem.camera_id}`;
        }
        if (activeItem.gauge_id) {
            url += `&gauge_id=${activeItem.gauge_id}`;
        }
        if (activeItem.isHistory) {
            url += `&history=true`; // Add history flag to URL
        }
        navigate(url);

        console.log('############ handleSearch - localStorage.setItem - activeItem:', activeItem);
        
        // localStorage.setItem('activeItem', JSON.stringify(activeItem));
        setSelectedItem(activeItem);

        if (!activeItem.isHistory) {
            updateActiveMenuItem(activeItem);
        }

        setSearchConditions({
            facilityName: selectedFacilityName,
            cameraName: selectedCameraName,
            gaugeName: processedGaugeName,
            status: selectedStatus,
            searchComment: selectedComment,
            threshold: selectedThreshold
        });

        setSearchPerformed(true);
        setIsSearching(true);
    };

    const getSearchConditions = () => {
        const selectedComment = document.getElementById('search_comment').value || '';
        const selectedThreshold = document.getElementById('threshold').value || '';

        const { selectedStatusIndex } = getSearchParameters();

        return {
            companyId: selectedItem.company_id,
            startDate: formatDate(startDate),
            endDate: formatDate(endDate),
            facilityId: selectedFacility,
            deviceId: selectedCamera,
            gaugeId: selectedGauge,
            failureId: selectedItem.isHistory,
            status: selectedStatusIndex,
            searchComment: selectedComment,
            threshold: selectedThreshold,
            searchCount: searchCount,
            deviceType: deviceType
        };

    }

    // Update active menu item
    const updateActiveMenuItem = (activeItem) => {
        if (activeItem.isHistory) return;
        window.dispatchEvent(new CustomEvent('updateActiveItem', { detail: activeItem }));
    };

    // Perform search on state change
    useEffect(() => {
        const performSearch = async () => {       
            if (isSearching) {
                const currentApiCallId = apiCallId + 1;
                setApiCallId(currentApiCallId);
                setLoading(true);
                setIsFetchingCount(true);
                setIsCountVisible(false);
                setIsPaginationVisible(false);
                clearCameraInfo();
                try {
                    if (selectedItem.isHistory) {
                        const selectedStatusIndex = parseInt(document.getElementById('status').value, 10);
                        const selectedComment = parseInt(document.getElementById('search_comment').value, 10);
                        const selectedThreshold = parseInt(document.getElementById('threshold').value, 10);
    
                        const imageListPromise = fetchImageList(
                            selectedItem.company_id,
                            selectedItem.company_name,
                            startDate,
                            endDate,
                            1,
                            selectedItem.facility_id,
                            selectedItem.camera_id,
                            selectedItem.gauge_id,
                            isNaN(selectedStatusIndex) ? null : selectedStatusIndex,
                            isNaN(selectedComment) ? null : selectedComment,
                            isNaN(selectedThreshold) ? null : selectedThreshold,
                            1,
                            null
                        ).then(() => {
                            setLoading(false);
                            setLatestApiCallId(currentApiCallId);
                        });
    
                        const countPromise = fetchCount(
                            selectedItem.company_id,
                            selectedItem.company_name,
                            startDate,
                            endDate,
                            selectedItem.facility_id,
                            selectedItem.camera_id,
                            selectedItem.gauge_id,
                            isNaN(selectedStatusIndex) ? null : selectedStatusIndex,
                            isNaN(selectedComment) ? null : selectedComment,
                            isNaN(selectedThreshold) ? null : selectedThreshold,
                            1
                        ).then(() => {
                            setLoading(false);
                            setIsFetchingCount(false); 
                            setIsCountVisible(true);
                            setIsPaginationVisible(true);
                        });

                        if (isGetCameraInfo(selectedItem, failure_id, user)) {
                            const cameraInfoPromise = fetchCameraInfo(
                                selectedItem.company_id,
                                selectedItem.camera_id                                    
                            ).then(() => {
                                // setLoading(false);
                            });
        
                            await Promise.all([imageListPromise, cameraInfoPromise, countPromise]);
                        }
                        else{
                            await Promise.all([imageListPromise, countPromise]);
                        }
                        setLatestApiCallId(currentApiCallId);
                        
                    } else {
                        await fetchDataWithSearch();
                    }
                } catch (error) {
                    console.error("Error during search:", error);
                    setIsFetchingCount(false);
                } finally {
                    setIsSearching(false);
                    apiCalled.current = false;
                }
                setCurrentGroup(1);
                setCurrentPage(1);
                setSelectedPage(1);
                previousGroupRef.current = 1;
            }
        };
        performSearch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSearching]);

    // Handle data display based on search count
    useEffect(() => {
        if (!loading && !isFetchingCount) {
            if (searchCount > 10000) {
                setDisplayedData(imageListData.slice(0, 10000));
            } else {
                setDisplayedData(imageListData);
            }
            setIsFetchingCount(false);
        } else {
            setDisplayedData(imageListData);
        }
    }, [imageListData, searchCount, loading, error, isFetchingCount]);

    //Handling popup screen display
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [currentPopupItem, setCurrentPopupItem] = useState(null);
    const [currentPopupIndex, setCurrentPopupIndex] = useState(null);
    const [currentPopupPage, setCurrentPopupPage] = useState(null);

    const openPopup = (item, index, page) => {
        setCurrentPopupItem(item);
        setCurrentPopupIndex(index);
        setCurrentPopupPage(page);
        setIsPopupOpen(true);
    };

    // Handle Photo Shoot Confirm
    const handlePhotoShootConfirm = () => {
        
        setIsConfirmPopup(true);
    };

    // Handle Photo Shoot Deny
    const handlePhotoShootDeny = () => {
        
        setIsPhotoShootDenyPopup(true);
    };

    // Handle Photo Shoot
    const handlePhotoShoot = () => {
        setIsConfirmPopup(false);     
        onPhotoShoot(selectedItem.company_id, cameraInfo.id, cameraInfo.device_id);
    };

    const handleTopClick = () => {
        navigate('/');
    };

    return (
        <div id="Main" className={`main ${screenType}`}>
            <div id="Contents">
                <Breadcrumb
                    userName = {showUserName}
                    pageName = "データ管理"
                    onTopClick = {handleTopClick}
                />
                <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} message={modalMessage} />
                <div className={`container-datamanage ${screenType}`}>
                    <div className="lay-search">
                        <SearchForm
                            facilities={facilities}
                            cameras={cameras}
                            gauges={gauges}
                            statuses={statuses}
                            onSearch={handleSearch}
                            setFilteredGauges={setFilteredGauges}
                            setFilteredCamera={setFilteredCamera}
                            selectedFacility={selectedFacility}
                            selectedCamera={selectedCamera}
                            selectedGauge={selectedGauge}
                            setSelectedFacility={setSelectedFacility}
                            setSelectedCamera={setSelectedCamera}
                            setSelectedGauge={setSelectedGauge}
                            filteredGauges={filteredGauges}
                            filteredCamera={filteredCamera}
                            startDate={startDate}
                            endDate={endDate}
                            setStartDate={setStartDate}
                            setEndDate={setEndDate}
                            screenType={screenType}
                        />
                    </div>
                    <div className={`lay-search_terms ${screenType}`}>
                        <div className="lay-search_terms-wrap">
                            <div className={`lay-search_terms__list ${screenType}`}>
                                <div className="lay-search_terms__list__title">
                                    <img src='img/common/icon-terms.png' alt="検索条件" />
                                </div>
                                <div className="lay-search_terms__list__text" id="searchtext">
                                    <span>
                                        期間：{formatDate(startDate)} ～ {formatDate(endDate)}　
                                        {searchPerformed && (
                                            <>
                                                {searchConditions.facilityName && (
                                                    <>
                                                        施設名：{searchConditions.facilityName}　
                                                        {searchConditions.cameraName && <br />}
                                                    </>
                                                )}
                                                {searchConditions.cameraName && (
                                                    <>
                                                        カメラ名：{searchConditions.cameraName}　
                                                    </>
                                                )}
                                                {searchConditions.gaugeName && (
                                                    <>
                                                        計器名：{searchConditions.gaugeName}　
                                                        {(searchConditions.status || searchConditions.searchComment || searchConditions.threshold) && <br />}
                                                    </>
                                                )}
                                                {searchConditions.status && (
                                                    <>
                                                        状態：{searchConditions.status}　
                                                    </>
                                                )}
                                                {searchConditions.searchComment && (
                                                    <>
                                                        コメント：{searchConditions.searchComment === "1" ? "有" : "無"}　
                                                    </>
                                                )}
                                                {searchConditions.threshold && (
                                                    <>
                                                        しきい値：{searchConditions.threshold === "1" ? "以上" : "以下"}　
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </span>
                                </div>

                            </div>
                            <div className={`lay-search_terms__btn ${screenType}`}>
                                <ul>
                                    <li className={photoDisplay ? "current" : ""}>
                                        <PhotoDownload
                                            getSearchConditions={getSearchConditions}
                                            setIsDownloading={setIsDownloading}
                                        />
                                    </li>
                                    <li className={photoDisplay ? "" : "current"}>
                                        <CsvDownload
                                            getSearchConditions={getSearchConditions}
                                            setIsDownloading={setIsDownloading}
                                        />
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {isDisplayCameraInfo(user, cameraInfo ,deviceType) && (
                            <div className={`camera_info_photoshoot ${screenType}`}>
                                <div className="lay-search_terms-wrap camera_info_div">
                                    <CameraInfo cameraInfo={cameraInfo} />
                                </div>                                
                                <div className="lay-search_terms-wrap camera_info_div">
                                    <PhotoShoot
                                        cameraInfo={cameraInfo}
                                        companyId={cameraInfo?.company_id || null}  
                                        cameraId={cameraInfo?.id || null} 
                                        deviceId={cameraInfo?.device_id || null}   
                                        gaugeId={selectedItem?.gauge_id || null}  
                                        isActive={cameraInfo?.is_active || null}   
                                        photoShootInfo={cameraInfo?.photoshoot_info || null} 
                                        onPhotoShootConfirm={handlePhotoShootConfirm} 
                                        onPhotoShootDeny={handlePhotoShootDeny}                                 
                                        fetchPhotoShootStatus={fetchPhotoShootStatus} 
                                        onPhotoShootUpdate={onPhotoShootUpdate} 
                                    />
                                </div>
                                {isConfirmPopup && (
                                    <ConfirmPopup   
                                        handlePhotoShoot={handlePhotoShoot}                                     
                                        onClose={() => setIsConfirmPopup(false)}                               
                                    />
                                )}

                                {isPhotoShootDenyPopup && (
                                    <PhotoShootDenyPopup 
                                        onClose={() => setIsPhotoShootDenyPopup(false)}                               
                                    />
                                )}                                                         
                            </div>
                        )}                        
                    </div>
                    {(latestApiCallId === apiCallId && displayedData.length > 0 && isFetched === true && is_null === false ) ? (
                        <div className={`data ${screenType}`} id='dispDataMnageData'>
                            <div className={`lay-tab ${screenType}`}>
                                <div className={`lay-tab__content ${screenType}`}>
                                    <ul>
                                        <li>
                                            <div className={`pager-frame ${screenType}`}>
                                                <div className={`flex-container ${screenType}`}>
                                                    <div className="result_num">
                                                        <span>検索件数：</span>
                                                        {error ? ( 
                                                            <span style={{ color: 'red' }}>{error}</span>
                                                        ) : isCountVisible && !isFetchingCount ? (
                                                            <span>{Math.min(searchCount, 10000)}</span> 
                                                        ) : (
                                                            <div className="spinner-count"></div>
                                                        )}
                                                    </div>
                                                    <div className={`lay-tab__head ${screenType}`}>
                                                        <ul>
                                                            <li className={photoDisplay? "current": ""} onClick={() => {setPhotoDisplay(true)}}>
                                                                画像表示
                                                            </li>
                                                            <li className={photoDisplay? "": "current"} onClick={() => {setPhotoDisplay(false)}}>
                                                                表型表示
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className={`pager ${screenType}`}>
                                                    <input type="hidden" id="current_group" name="current_group" value={currentGroup || ''} />
                                                    <input type="hidden" id="current_page" name="current_page" value={currentPage || ''} />
                                                    {isPaginationVisible && (
                                                        <ul className="pagination">
                                                            {currentGroup > 1 && (
                                                                <>
                                                                    <li className="pagepre" onClick={() => goToGroup(currentGroup - 1)}>
                                                                        <div>
                                                                            <span>前の10件</span>
                                                                        </div>
                                                                    </li>

                                                                    <li className="pagepre_icon" onClick={() => goToGroup(currentGroup - 1)}>
                                                                        <div>
                                                                            <span>&lt;</span>
                                                                        </div>
                                                                    </li>
                                                                </>
                                                            )}
                                                            {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => {
                                                                const pageLength = page.toString().length;
                                                                let pageClass = '';
                                                                if (pageLength === 3) {
                                                                pageClass = 'page-number-3';
                                                                } else if (pageLength === 4) {
                                                                pageClass = 'page-number-4';
                                                                }

                                                                return (
                                                                <li
                                                                    key={page}
                                                                    className={`pagegroup ${Math.ceil(page / buttonsPerGroup)} ${pageClass}`}
                                                                    style={{
                                                                    display:
                                                                        currentGroup === Math.ceil(page / buttonsPerGroup)
                                                                        ? 'inline-block'
                                                                        : 'none',
                                                                    }}
                                                                >
                                                                    <div
                                                                    className={page === currentPage ? 'active' : ''}
                                                                    onClick={() => goToPage(page)}
                                                                    >
                                                                    <span>{page}</span>
                                                                    </div>
                                                                </li>
                                                                );
                                                            })}
                                                            {currentGroup < totalGroups && (
                                                                <>
                                                                    <li className="pagenext" onClick={() => goToGroup(currentGroup + 1)}>
                                                                        <div>
                                                                            <span>次の10件</span>
                                                                        </div>
                                                                    </li>

                                                                    <li className="pagenext_icon" onClick={() => goToGroup(currentGroup + 1)}>
                                                                        <div>
                                                                            <span>&gt;</span>
                                                                        </div>
                                                                    </li>
                                                                </>
                                                            )}
                                                        </ul>
                                                        

                                                    )}
                                                </div>
                                            </div>
                                        </li>
                                        <li className={`lay-li ${photoDisplay ? "current" : ""}`} id='image_list'>
                                            <ImageList
                                                pagesInCurrentGroup={pagesInCurrentGroup}
                                                displayedData={displayedData}
                                                currentPage={currentPage}
                                                setCurrentPage={setCurrentPage}
                                                buttonsPerGroup={buttonsPerGroup}
                                                itemsPerPage={itemsPerPage}
                                                gaugeData={gaugeData}
                                                getGaugeData={getGaugeData}
                                                selectedItem={selectedItem}
                                                startDate={startDate}
                                                endDate={endDate}
                                                selectedFacility={selectedFacility}
                                                selectedCamera={selectedCamera}
                                                selectedGauge={selectedGauge}
                                                comments={comments}
                                                setComments={setComments}
                                                fontSizes={fontSizes}
                                                setFontSizes={setFontSizes}
                                                fetchImageList={fetchImageList}
                                                setLoading={setLoading}
                                                fnEditData={fnEditData}
                                                handleCommentChange={handleCommentChange}
                                                handleFontSizeChange={handleFontSizeChange}
                                                openPopup={openPopup}
                                                checkboxStates={checkboxStates} 
                                                handleCheckboxChange={handleCheckboxChange}  
                                                failure_id={failure_id}
                                                calculateDiff={(measureVal, prevMeasureVal) => measureVal - prevMeasureVal}
                                                formatValue={formatValue}
                                                formatDiffVal={formatDiffVal}
                                                setModalMessage={setModalMessage}
                                                setIsModalOpen={setIsModalOpen}
                                                measureValues={measureValues}
                                                setMeasureValues={setMeasureValues}
                                                screenType={screenType}
                                                mainImage={mainImage}
                                                setMainImage={setMainImage}
                                                editStatus={editStatus}
                                                setEditStatus={setEditStatus}
                                                relativeActive={relativeActive}
                                                setRelativeActive={setRelativeActive}
                                            />
                                        </li>

                                        <li className={`lay-li ${photoDisplay ? "" : "current"}`} id='data_list'>
                                            <DataList
                                                pagesInCurrentGroup={pagesInCurrentGroup}
                                                displayedData={displayedData}
                                                currentPage={currentPage}
                                                buttonsPerGroup={buttonsPerGroup}
                                                itemsPerPage={itemsPerPage}
                                                gaugeData={gaugeData}
                                                getGaugeData={getGaugeData}
                                                fetchImageList={fetchImageList}
                                                selectedItem={selectedItem}
                                                startDate={startDate}
                                                endDate={endDate}
                                                selectedFacility={selectedFacility}
                                                selectedCamera={selectedCamera}
                                                selectedGauge={selectedGauge}
                                                comments={comments}
                                                setComments={setComments}
                                                fontSizes={fontSizes}
                                                setFontSizes={setFontSizes}
                                                setLoading={setLoading}
                                                openPopup={openPopup}
                                                failure_id={failure_id}
                                                formatValue={formatValue}
                                                formatDiffVal={formatDiffVal}
                                            />
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="mod-no-data" style={{display: isFetched && latestApiCallId === apiCallId ? '' : 'none'}}>表示データが存在しません。</div>                      
                    )}

                    <div className="data" id='dispDataMnagePopup'>
                        {isPopupOpen && (
                            <DataManagePopup
                                item={currentPopupItem}
                                displayedData={displayedData}
                                relativeIndex={currentPopupIndex}
                                currentPage={currentPopupPage}
                                itemsPerPage={itemsPerPage}
                                getGaugeData={(item) => getGaugeData(item, gaugeData)}
                                checkboxStates={checkboxStates}  
                                handleCheckboxChange={handleCheckboxChange}  
                                comments={comments} 
                                fontSizes={fontSizes} 
                                handleCommentChange={handleCommentChange} 
                                handleFontSizeChange={handleFontSizeChange} 
                                fnEditData={fnEditData} 
                                imageListData={imageListData}
                                setIsPopupOpen={setIsPopupOpen}
                                setLoading={setLoading}
                                fetchImageList={fetchImageList} 
                                selectedItem={selectedItem} 
                                startDate={startDate} 
                                endDate={endDate} 
                                selectedFacility={selectedFacility} 
                                selectedCamera={selectedCamera} 
                                selectedGauge={selectedGauge} 
                                setComments={setComments} 
                                setFontSizes={setFontSizes} 
                                formatValue={formatValue}
                                formatDiffVal={formatDiffVal}
                                screenType={screenType}
                                setRelativeActive={setRelativeActive}
                            />
                        )}
                    </div>
                </div>
            </div>
            <div className={`overlay ${loading || isDownloading ? 'active' : ''}`}>
                <div className={`spinner ${loading || isDownloading ? 'active' : ''}`}></div>
            </div>
        </div>
    );
};

DataManage.propTypes = {
    user: PropTypes.object,
    isFetching: PropTypes.object.isRequired,
    facilities: PropTypes.array.isRequired,
    cameras: PropTypes.array.isRequired,
    cameraInfo: PropTypes.object,
    gauges: PropTypes.array.isRequired,
    statuses: PropTypes.array.isRequired,
    searchCount: PropTypes.number,
    selectedItem: PropTypes.object,
    error: PropTypes.string,
    fetchFacilities: PropTypes.func.isRequired,
    fetchImageList: PropTypes.func.isRequired,
    fetchCount: PropTypes.func.isRequired,    
    setSelectedItem: PropTypes.func.isRequired,
    fetchCameraInfo: PropTypes.func.isRequired,
    clearCameraInfo: PropTypes.func.isRequired,
    onPhotoShoot: PropTypes.func.isRequired,
    fetchPhotoShootStatus: PropTypes.func.isRequired,
    onPhotoShootUpdate: PropTypes.func.isRequired,
    is_null: PropTypes.bool.isRequired, 
    isFetched: PropTypes.bool.isRequired, 
};

export default React.memo(DataManage);