const initialState = {
    item: null,
};

const selectedItemReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_SELECTED_ITEM':
            return {
                ...state,
                item: action.payload,
            };
        default:
            return state;
    }
};

export default selectedItemReducer;
