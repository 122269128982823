import ActionTypes from "../constants/ActionTypes";

const initialState = {
    maintenance: 0,
    screen_message: "",
};

const maintenanceReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.DOING_MAINTENANCE:
            return {
              ...state,
              maintenance: action.payload.maintenance,
              screen_message: action.payload.screen_message,
        
            };
            default:
              return state;
    };
};

export default maintenanceReducer;
