import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import UserNameChange from '../components/UserNameChange';
import { updateUserName } from '../actions/usernamechange';
import { fetchUserData } from '../actions/user';

const mapStateToProps = (state) => ({
    loading: state.userName.loading,
    error: state.userName.error,
    userName: state.userName.userName,
    userId: state.userName.userId,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateUserName,
    fetchUserData
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UserNameChange);