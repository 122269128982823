import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
// import './CameraEdit.css';
import Modal from '../Modal/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMenuData } from '../../actions/menuActions';
import Breadcrumb from '../Breadcrumb';

const GaugeEdit = ({ user, measuredata = {}, loading, error, fetchGaugeEdit, checkMeasureData, editGaugeData}) => {
    const location = useLocation();
    const navigate = useNavigate ();
    const dispatch = useDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [isConfirmationModal, setIsConfirmationModal] = useState(false);
    const [companyId, setCompanyId] = useState('');
    /* eslint-disable no-unused-vars */
    const [facilityId, setFacilityId] = useState('');
    const [deviceId, setDeviceId] = useState('');
    const [gaugeId, setGaugeId] = useState('');
    const [gauge, setGauge] = useState({}); 
    const [measureList, setMeasureList] = useState([]); 
    const [checkedMeasures, setCheckedMeasures] = useState([]);
    const [isFetching, setIsFetching] = useState(false);
    const [showUserName, setUserName] = useState('');
    const screenType = useSelector((state) => state.device.screenType);
    const authorityClass = useSelector((state) => state.login?.user?.authority_class);
    const shouldShowDeleteColumn = authorityClass === 9;

    // Fetch data when component mounts or when location changes
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const company_id = Number(params.get('company_id'))
        const facility_id = params.get('facility_id');
        const device_id = params.get('device_id');
        const gauge_id = params.get('gauge_id');
        
        if (!isNaN(company_id) && facility_id && device_id && gauge_id) {
            setCompanyId(company_id);
            setFacilityId(facility_id);
            setDeviceId(device_id);
            setGaugeId(gauge_id);
            fetchGaugeEdit(company_id, facility_id, device_id, gauge_id); 
        }
    }, [fetchGaugeEdit, location]);

    useEffect(() => {
        if (measuredata && measuredata.measure) {
            setGauge(measuredata.gauge);
            setMeasureList(measuredata.measure || []);
        }
    }, [measuredata]);

    useEffect(() => {
        if (user) {
          setUserName(user.user_name);
        };
    }, [user]);

    const handleInputChange = (e) => {
        const { value } = e.target;
        setGauge((prevGauge) => ({
            ...prevGauge,
            display_gauge_name: value,
        }));
    };
    
    const handleSortPrev = (index) => {
        if (index === 0) return; 
        const newList = [...measureList];
        [newList[index], newList[index - 1]] = [newList[index - 1], newList[index]]; 
        setMeasureList(newList);
    };

    const handleSortNext = (index) => {
        if (index === measureList.length - 1) return;
        const newList = [...measureList];
        [newList[index], newList[index + 1]] = [newList[index + 1], newList[index]];
        setMeasureList(newList); 
    };

    const handleCheckboxChange = (measureId) => {
        setCheckedMeasures((prevChecked) => {
            if (prevChecked.includes(measureId)) {
                return prevChecked.filter(id => id !== measureId);
            } else {
                return [...prevChecked, measureId];
            }
        });
    };

    const handleSave = async () => {
        setIsFetching(true);
        try {
            const gauge_name = gauge.display_gauge_name;
            const measure_id = measureList.map((measure) => measure.measure_id);
            const is_delete = checkedMeasures;
            const cnt = measureList.length > 0 ? measureList.length - 1 : 0;

            const checkResult = await checkMeasureData(gauge_name, companyId, deviceId, gaugeId, measure_id, is_delete, cnt);

            if (checkResult && checkResult.length > 0) { 
                const selectedMeasures = measureList
                    .filter((measure) => checkedMeasures.includes(measure.measure_id))
                    .map((measure) => `${measure.measure_name}[${measure.unit}]`);

                let message = `${selectedMeasures.join('\n')}\n計測データが登録されています。\n削除しますか？`;

                setModalMessage(message);
                setIsConfirmationModal(true);
                setIsModalOpen(true);
            } else {
                await handleConfirmSave();
            }
        } catch (error) {
            setModalMessage('エラーが発生しました。再試行してください。');
            setIsConfirmationModal(false); 
            setIsModalOpen(true);
        } finally {
            setIsFetching(false);
        }
    };

    const handleConfirmSave = async () => {
        setIsConfirmationModal(false); 
        setIsFetching(true); 

        try {
            const gauge_name = gauge.display_gauge_name;
            const measure_id = measureList.map((measure) => measure.measure_id);
            const is_delete = checkedMeasures;
            const cnt = measureList.length;

            const response = await editGaugeData(gauge_name, companyId, deviceId, gaugeId, measure_id, is_delete, cnt);
            const message = response.data?.respons?.msg;
            await fetchUpdatedMenu(); 

            setModalMessage(message);
            setIsConfirmationModal(false);
            setIsModalOpen(true);
        } catch (error) {
            setModalMessage(error.message || 'エラーが発生しました。再試行してください。');
            setIsConfirmationModal(false);
            setIsModalOpen(true);
        } finally {
            setIsFetching(false);
        }
    };

    const handleTopClick = () => {
        navigate('/');
    };

    // Fetch updated menu after save or update
    const fetchUpdatedMenu = async () => {
        try {
            await dispatch(fetchMenuData());
        } catch (error) {
            console.error('Error fetching updated menu:', error);
        }
    };

    return (
        <div id='Main' className={`main ${screenType}`}>
            <div id='Contents'>
                <Breadcrumb
                    userName = {showUserName}
                    pageName = "カメラ・計器設定"
                    onTopClick = {handleTopClick}
                />
                {isModalOpen && (
                    <Modal
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    message={modalMessage}
                    onConfirm={handleConfirmSave} 
                    showCancelButton={isConfirmationModal}
                />
                )}
                <div id="ajax-view-disp">
                    <div className="display-main" id="set_company_list">
                        <div className="lay-master-set">
                            <div className="lay-master-set-wrap">
                                <div className="lay-master-set__title">
                                    <span>計器編集</span>
                                </div>
                                <form id="gaugeEditData">
                                    <div className="lay-master-set__form">
                                        <div className="wrapper">
                                            <div>
                                                <span>名称を入力してください。</span>
                                            </div>
                                            <div className="set_master_name" style={{marginBottom: '40px'}}>
                                                <input type="text" id="gauge_name_view" value={gauge.display_gauge_name || ''} onChange={handleInputChange}/>
                                                <input type="hidden" id="gauge_name" name="gauge_name"/>
                                                <input type="hidden" name="company_id" value={measuredata.company_id || ''}/>
                                                <input type="hidden" name="device_id" value={measuredata.device_id || ''}/>
                                                <input type="hidden" name="gauge_id" value={gauge.gauge_id || ''}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="lay-master-set__frame">
                                        <div className="lay-master__table">
                                            {measureList.length > 0 && (
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <th></th>
                                                            <th>計測項目</th>
                                                            {shouldShowDeleteColumn && <th>削除</th>}
                                                        </tr>
                                                        {measureList.map((measure, index) => (
                                                            <tr key={measure.measure_id} className="lay-tr">
                                                                <td className="lay-td sort">
                                                                    <div
                                                                        className="sortPrev"
                                                                        style={{ display: index === 0 || measureList.length === 1 ? 'none' : '' }}
                                                                        onClick={() => handleSortPrev(index)}
                                                                    >
                                                                        △
                                                                    </div>
                                                                    <div
                                                                        className="sortNext"
                                                                        style={{ display: index === measureList.length - 1 || measureList.length === 1 ? 'none' : '' }}
                                                                        onClick={() => handleSortNext(index)}
                                                                    >
                                                                        ▽
                                                                    </div>
                                                                </td>
                                                                <td className="lay-td">
                                                                    <input type="hidden" name="measure_id" value={measure.measure_id || ''} />
                                                                    <span id={`measure_id${measure.measure_id}`}>{measure.measure_name}[{measure.unit}]</span>
                                                                </td>
                                                                {shouldShowDeleteColumn && 
                                                                    <td className="lay-td">
                                                                        <label>
                                                                            <input
                                                                                type="checkbox"
                                                                                className="checkbox-input"
                                                                                name="is_delete"
                                                                                value={measure.measure_id || ''}
                                                                                defaultChecked={measure.is_delete === 1}
                                                                                onChange={() => handleCheckboxChange(measure.measure_id)}
                                                                            />
                                                                            <span className="checkbox-parts"></span>
                                                                        </label>
                                                                    </td>
                                                                }
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            )}
                                                <input type="hidden" name="cnt" value={measureList.length > 0 ? measureList.length - 1 : 0} />
                                        </div>
                                    </div>
                                </form>
                                <div className="trun-btn" style={{marginTop: '50px'}}>
                                    <button type="button" className="master_long_btn" id="edit" onClick={handleSave}>
                                        登録
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`overlay ${(loading || isFetching) ? 'active' : ''}`}>
                <div className={`spinner ${(loading || isFetching) ? 'active' : ''}`}></div>
            </div>
        </div>
    );
};

GaugeEdit.propTypes = {
    measuredata: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    fetchGaugeEdit: PropTypes.func.isRequired,
    checkMeasureData: PropTypes.func.isRequired,
    editGaugeData: PropTypes.func.isRequired,
};

export default React.memo(GaugeEdit);
