import React from 'react'
import './TransitionGraphCameraInfo.css'

const TransitionGraphCameraInfo = React.memo(
    ({id, display_facility_name, display_camera_name, display_gauge_name, measure_name, unit, onShowGraph}) => {
        const handleShowGraph = (event) => {
            const { tabIndex } = event.target;
            onShowGraph(tabIndex);
        };
        return (
            <div className="lay-search_terms-transgraph">
                <div className="lay-search_terms-transgraph-wrap">
                    <div className="lay-search_terms-transgraph__list">
                        <div className="lay-search_terms-transgraph__list__title">
                            <img src="img/common/icon-terms-result.png" alt="検索結果" />
                        </div>
                        <div className="lay-search_terms-transgraph__list__text" id="searchtext">
                        {display_facility_name} ／ {display_camera_name} ／ {display_gauge_name} ／ {measure_name}[{unit}]
                            <div className="lay-list-transgraph__table__comment">
                                <button 
                                className='mod-btn-transgraph__roundborder moresmall'
                                id={`graph_show${id}`}
                                tabIndex={id}
                                onClick={handleShowGraph}>
                                グラフ表示
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
);

export default TransitionGraphCameraInfo;