import ActionTypes from '../constants/ActionTypes';

const initialState = {
    isFetching: {},
    facilities: [],
    cameras: [],
    gauges: [],
    statuses: [],
    imageListData: [],
    gaugeData: {},
    failure_id: null,
    searchCount: null,
    new_start_date: null,
    selectedItem: null,
    cameraInfo: null,
    error: null,
    isFetched: false,
    is_null: false,
    relativeActive: null,
};

const findPrevMeasureVal = (gaugeDataArray, currentIndex, state, gauge, updatedItem) => {
    for (let i = currentIndex - 1; i >= 0; i--) {
        const previousGaugeKey = gaugeDataArray[i];
        const previousGaugeList = state.gaugeData[previousGaugeKey] || [];
        const previousGauge = previousGaugeList.find(g => g.measure_id === gauge.measure_id);

        if (previousGauge) {
            const previousImage = state.imageListData.find(item =>
                item.device_id === updatedItem.device_id &&
                item.gauge_id === updatedItem.gauge_id &&
                item.get_datetime === previousGaugeKey.slice(-19)
            );

            if (!previousImage || previousImage.disuse_flg !== 1) {
                return previousGauge.measure_val;
            }
        }
    }
    return null;
};

//Update data register and data edit
const updateData = (state, updatedItem) => {
    const updatedGaugeKey = `${updatedItem.device_id}${updatedItem.gauge_id}${updatedItem.get_datetime}`;
    const gaugeDataArray = Object.keys(state.gaugeData)
        .filter(key => key.startsWith(`${updatedItem.device_id}${updatedItem.gauge_id}`))
        .sort((a, b) => new Date(a.slice(-19)) - new Date(b.slice(-19)));

    const currentIndex = gaugeDataArray.findIndex(key => key === updatedGaugeKey);

    let updatedGaugeData = {
        ...state.gaugeData,
        [updatedGaugeKey]: (state.gaugeData[updatedGaugeKey] || []).map(gauge => {
            const updatedGauge = updatedItem.gauge_data.find(g => g.measure_id === gauge.measure_id);
            const prevMeasureVal = findPrevMeasureVal(
                gaugeDataArray,
                currentIndex,
                state,
                gauge,
                updatedItem
            );
    
            return updatedGauge
                ? {
                    ...gauge,
                    ...updatedGauge,
                    prev_measure_val: prevMeasureVal,
                    diff_val: updatedGauge.diff_val,
                    abnormal_flg: updatedGauge.abnormal_flg,
                    threshold_min_flg: updatedGauge.threshold_min_flg,
                    threshold_max_flg: updatedGauge.threshold_max_flg,
                    diff_threshold_min_flg: updatedGauge.diff_threshold_min_flg,
                    diff_threshold_max_flg: updatedGauge.diff_threshold_max_flg,
                }
                : gauge;
        }),
    };

    // Handle nextGaugeKey if available
    if (currentIndex !== -1 && currentIndex < gaugeDataArray.length - 1) {
        const nextValidKey = gaugeDataArray.slice(currentIndex + 1).find(nextGaugeKey => {
            const get_datetime = nextGaugeKey.slice(-19);
            const nextImage = state.imageListData.find(item =>
                item.device_id === updatedItem.device_id &&
                item.gauge_id === updatedItem.gauge_id &&
                item.get_datetime === get_datetime
            );

            return nextImage && nextImage.disuse_flg !== 1; // Only find valid entries
        });

        if (nextValidKey) {
            console.log('Processing next valid gauge key:', nextValidKey);

            updatedGaugeData = {
                ...updatedGaugeData,
                [nextValidKey]: (state.gaugeData[nextValidKey] || []).map(gauge => {
                    const nextGaugeData = updatedItem.next_gauge_data?.find(g => g.measure_id === gauge.measure_id);
                    const currentGaugeData = updatedItem.gauge_data?.find(g => g.measure_id === gauge.measure_id);
                    const existDisuseFlag = updatedItem.disuse_flg;
                    const prevMeasureVal = existDisuseFlag
                    ? findPrevMeasureVal(gaugeDataArray, currentIndex, state, gauge, updatedItem)
                    : currentGaugeData?.measure_val;

                    if (nextGaugeData) {
                        return {
                            ...gauge,
                            prev_measure_val: prevMeasureVal,
                            diff_val: nextGaugeData.diff_val,
                            abnormal_flg: nextGaugeData.abnormal_flg,
                            diff_threshold_min_flg: nextGaugeData.diff_threshold_min_flg,
                            diff_threshold_max_flg: nextGaugeData.diff_threshold_max_flg,
                        };
                    }
                    return gauge;
                }),
            };

            console.log('Next Valid Gauge Data After Update:', updatedGaugeData[nextValidKey]);
        } else {
            console.log('No valid next gauge data available.');
        }
    }

    // Update imageListData
    const updatedImageListData = state.imageListData.map(item => {
        if (
            item.device_id === updatedItem.device_id &&
            item.gauge_id === updatedItem.gauge_id &&
            item.get_datetime === updatedItem.get_datetime
        ) {
            return {
                ...item,
                comment: updatedItem.comment,
                comment_font_size: updatedItem.comment_font_size,
                disuse_flg: updatedItem.disuse_flg,
                status_class: updatedItem.status_class,
                user_name: updatedItem.record_user_name,
            };
        }

        const nextDataItem = updatedItem.next_data?.find(
            nextItem =>
                nextItem.device_id === item.device_id &&
                nextItem.gauge_id === item.gauge_id &&
                nextItem.get_datetime === item.get_datetime
        );

        if (nextDataItem) {
            return {
                ...item,
                status_class: nextDataItem.status_class,
                status: nextDataItem.status,
            };
        }

        return item;
    });

    return {
        ...state,
        imageListData: updatedImageListData,
        gaugeData: updatedGaugeData,
    };
};

//Delete data and updated data
const clearData = (state, updatedItem) => {
    const updatedGaugeKey = `${updatedItem.device_id}${updatedItem.gauge_id}${updatedItem.get_datetime}`;

    const gaugeDataArray = Object.keys(state.gaugeData)
        .filter(key => key.startsWith(`${updatedItem.device_id}${updatedItem.gauge_id}`))
        .sort((a, b) => new Date(a.slice(-19)) - new Date(b.slice(-19)));

    const currentIndex = gaugeDataArray.findIndex(key => key === updatedGaugeKey);

    const updatedImageListData = state.imageListData.map(item => {
        if (
            item.device_id === updatedItem.device_id &&
            item.gauge_id === updatedItem.gauge_id &&
            item.get_datetime === updatedItem.get_datetime
        ) {
            return {
                ...item,
                comment: null,
                comment_font_size: null,
                disuse_flg: 0,
                status_class: 0,
                diff_threshold_min_flg: 0,
                diff_threshold_max_flg: 0,
                user_name: updatedItem.record_user_name,
            };
        }

        const nextDataItem = updatedItem.next_data?.find(
            nextItem =>
                nextItem.device_id === item.device_id &&
                nextItem.gauge_id === item.gauge_id &&
                nextItem.get_datetime === item.get_datetime
        );

        if (nextDataItem) {
            return {
                ...item,
                status_class: nextDataItem.status_class,
                status: nextDataItem.status,
            };
        }

        return item;
    });

    let updatedGaugeData = {
        ...state.gaugeData,
        [updatedGaugeKey]: (state.gaugeData[updatedGaugeKey] || []).map(gauge => {
            const { measure_val, ...remainingProperties } = gauge;
            const updatedGauge = { ...remainingProperties };
            delete updatedGauge.measure_val;
            return {
                ...updatedGauge,
                diff_val: null ,
                abnormal_flg: 0,
                diff_threshold_min_flg: 0,
                diff_threshold_max_flg: 0,
            };
        })
    };

    if (currentIndex !== -1 && currentIndex < gaugeDataArray.length - 1) {
        const nextValidKey = gaugeDataArray.slice(currentIndex + 1).find(nextGaugeKey => {
            const get_datetime = nextGaugeKey.slice(-19);
            const nextImage = state.imageListData.find(item =>
                item.device_id === updatedItem.device_id &&
                item.gauge_id === updatedItem.gauge_id &&
                item.get_datetime === get_datetime
            );

            return nextImage && nextImage.disuse_flg !== 1; // Only find valid entries
        });

        if (nextValidKey) { 
            updatedGaugeData = {
                ...updatedGaugeData,
                [nextValidKey]: (state.gaugeData[nextValidKey] || []).map(gauge => {
                    const nextGaugeData = updatedItem.next_gauge_data?.find(
                        g => g.measure_id === gauge.measure_id
                    );
                    const currentGaugeData = updatedItem.gauge_data?.find(g => g.measure_id === gauge.measure_id);
                        const existDisuseFlag = updatedItem.disuse_flg;
                        const prevMeasureVal = existDisuseFlag
                        ? findPrevMeasureVal(gaugeDataArray, currentIndex, state, gauge, updatedItem)
                        : currentGaugeData?.measure_val;
    
                    return nextGaugeData
                        ? {
                            ...gauge,
                            prev_measure_val: prevMeasureVal,
                            diff_val: nextGaugeData.diff_val,
                            abnormal_flg: nextGaugeData.abnormal_flg,
                            diff_threshold_min_flg: nextGaugeData.diff_threshold_min_flg,
                            diff_threshold_max_flg: nextGaugeData.diff_threshold_max_flg,
                        }
                        : {
                            ...gauge,
                            diff_val: null,
                        };
                }),
            };
        }
    }

    return {
        ...state,
        imageListData: updatedImageListData,
        gaugeData: updatedGaugeData,
    };
};

const dataManageReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.FETCH_FACILITIES_REQUEST:
        case ActionTypes.FETCH_IMAGE_LIST_REQUEST:
            return {
                ...state,
                isFetching: {
                    ...state.isFetching,
                    [action.type]: true,
                },
                isFetched: false,
            }
        case ActionTypes.FETCH_COUNT_REQUEST:
        case ActionTypes.FETCH_CAMERA_INFO_REQUEST:
        case ActionTypes.PHOTO_SHOOT_REQUEST:
        case ActionTypes.FETCH_PHOTO_SHOOT_STATUS_REQUEST:
        case ActionTypes.PHOTO_SHOOT_UPDATE_REQUEST:
        case ActionTypes.DATA_DELETE_REQUEST:
        case ActionTypes.DATA_EDIT_REQUEST:
        case ActionTypes.DATA_REGISTER_REQUEST:
            return {
                ...state,
                isFetching: {
                    ...state.isFetching,
                    [action.type]: true,
                },
            };
        case ActionTypes.FETCH_FACILITIES_SUCCESS:
            return {
                ...state,
                isFetching: {
                    ...state.isFetching,
                    [ActionTypes.FETCH_FACILITIES_REQUEST]: false,
                },
                facilities: action.payload.facility,
                cameras: action.payload.camera,
                gauges: action.payload.gauge,
                statuses: action.payload.status,
                error: null,
            };
        case ActionTypes.FETCH_IMAGE_LIST_SUCCESS:
            return {
                ...state,
                isFetching: {
                    ...state.isFetching,
                    [ActionTypes.FETCH_IMAGE_LIST_REQUEST]: false,
                },
                isFetched: true,
                imageListData: action.payload.data,
                gaugeData: action.payload.gaugeData,
                failure_id: action.payload.failure_id,
                new_start_date: action.payload.new_start_date || null,
                is_null: action.payload.data.length === 0,
                error: null,
            };
        case ActionTypes.FETCH_COUNT_SUCCESS:
            return {
                ...state,
                isFetching: {
                    ...state.isFetching,
                    [ActionTypes.FETCH_COUNT_REQUEST]: false,
                },
                searchCount: action.payload,
                error: null,
            };
        case ActionTypes.FETCH_CAMERA_INFO_SUCCESS:
            return {
                ...state,
                isFetching: {
                    ...state.isFetching,
                    [ActionTypes.FETCH_CAMERA_INFO_REQUEST]: false,
                },
                cameraInfo: action.payload,
                error: null,
            };
        case ActionTypes.CLEAR_CAMERA_INFO:    // Reset cameraInfo
            return {
                ...state,  
                cameraInfo: null,              
                error: null,
            };
        case ActionTypes.PHOTO_SHOOT_SUCCESS:    // 遠隔撮影    
            return {
                ...state,
                isFetching: {
                    ...state.isFetching,
                    [ActionTypes.PHOTO_SHOOT_REQUEST]: false,
                },
                cameraInfo: {
                    ...state.cameraInfo,
                    photoshoot_info: action.payload,
                },            
                error: null,
            };
        case ActionTypes.FETCH_PHOTO_SHOOT_STATUS_SUCCESS:    // 遠隔撮影ステータス取得
                return {
                    ...state,
                    isFetching: {
                        ...state.isFetching,
                        [ActionTypes.FETCH_PHOTO_SHOOT_STATUS_REQUEST]: false,
                    },
                    cameraInfo: {
                        ...state.cameraInfo,
                        photoshoot_info: {
                            ...state.cameraInfo?.photoshoot_info,
                            photoshoot_status: action.payload,     // Add or update photoshoot_status
                        },
                    },
                    error: null,
                };
        case ActionTypes.PHOTO_SHOOT_UPDATE_SUCCESS:    // 遠隔撮影画像データをシステムへ更新    
                return {
                    ...state,
                    isFetching: {
                        ...state.isFetching,
                        [ActionTypes.PHOTO_SHOOT_UPDATE_REQUEST]: false,
                    },
                    cameraInfo: {
                        ...state.cameraInfo,
                        photoshoot_info: {
                            ...state.cameraInfo?.photoshoot_info,
                            photoshoot_result: action.payload,     // Add or update photoshoot_result
                        },
                    },           
                    error: null,
                };
        case ActionTypes.DATA_REGISTER_SUCCESS:
            return {
                ...state,
                ...updateData(state, action.payload.respons),
                isFetching: {
                    ...state.isFetching,
                    [ActionTypes.DATA_REGISTER_REQUEST]: false,
                },
                error: null,
            };
        case ActionTypes.DATA_EDIT_SUCCESS:
            return {
                ...state,
                ...updateData(state, action.payload.respons),
                isFetching: {
                    ...state.isFetching,
                    [ActionTypes.DATA_EDIT_REQUEST]: false,
                },
                error: null,
            };
        case ActionTypes.DATA_DELETE_SUCCESS:
            return {
                ...state,
                ...clearData(state, action.payload.respons),
                isFetching: {
                    ...state.isFetching,
                    [ActionTypes.DATA_DELETE_REQUEST]: false,
                },
                error: null,
            };
        case ActionTypes.FETCH_FACILITIES_FAILURE:
        case ActionTypes.FETCH_IMAGE_LIST_FAILURE:
            return {
                ...state,
                isFetching: {
                    ...state.isFetching,
                    [action.type.replace('_FAILURE', '_REQUEST')]: false,
                },
                isFetched: false,
            }
        case ActionTypes.FETCH_COUNT_FAILURE:
        case ActionTypes.FETCH_CAMERA_INFO_FAILURE:
        case ActionTypes.PHOTO_SHOOT_FAILURE:
        case ActionTypes.FETCH_PHOTO_SHOOT_STATUS_FAILURE:
        case ActionTypes.PHOTO_SHOOT_UPDATE_FAILURE:
        case ActionTypes.DATA_REGISTER_FAILURE:
        case ActionTypes.DATA_EDIT_FAILURE:
        case ActionTypes.DATA_DELETE_FAILURE:
            return {
                ...state,
                isFetching: {
                    ...state.isFetching,
                    [action.type.replace('_FAILURE', '_REQUEST')]: false,
                },
                error: action.payload,
            };
        case ActionTypes.SET_SELECTED_ITEM:
            return {
                ...state,
                selectedItem: action.payload,
                relativeActive: null
            };
        case ActionTypes.SET_RELATIVE_ACTIVE:
            return {
                ...state,
                relativeActive: action.payload,
            };
        default:
            return state;
    }
};

export default dataManageReducer;
