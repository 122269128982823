// ユーザ権限
export const AUTHORITY_CLASS = {
    GENERAL:       { name: 'general'      ,  value: 0, text: '一般' },
    GENERAL_ADMIN: { name: 'general_admin',  value: 1, text: '管理者（一般）' },
    SYSTEM_ADMIN:  { name: 'system_admin',   value: 9, text: '管理者（システム）' },    
};

// 「遠隔撮影」機能専用
export const PhotoShootStatus = {
    CHECKING:  { name: 'checking',  color: 'pumpkin-orange', hex: '#f0982d', text: '操作内容確認中' },
    ACCEPTED:  { name: 'accepted',  color: 'lagoon-blue',    hex: '#109dc0', text: '操作内容受付済' },
    RUNNING:   { name: 'running',   color: 'light-mud', hex: '#c7a57b', text: '実行中' },
    COMPLETED: { name: 'completed', color: 'blue ', hex: '#0000FF', text: '完了' },   
};

export const PhotoShootResult = {
    QUEUED:    { name: 'queued',    color: 'pumpkin-orange', hex: '#f0982d', text: '実行待ち' },
    PENDING:   { name: 'pending',   color: 'lagoon-blue',    hex: '#109dc0', text: '遠隔操作送信中' },
    REJECTED:  { name: 'rejected',  color: 'red', hex: '#FF0000', text: '失敗' },
    PROCESSED: { name: 'processed', color: 'tank-green', hex: '#8aa177', text: '成功' },  
    PROCESSED_DOWNLINK: { name: 'processed_downlink_stage', color: 'blue ', hex: '#0000FF', text: '成功 (コマンド送信完了)' }, 
    POTENTIALLY_PROCESSED_UPLINK: { name: 'potentially_processed_uplink_stage', color: 'blue ', hex: '#0000FF', text: '成功 (データ受信済み)' }, 
};

export const PhotoShootMessage = {
    ROUTER_NOT_FOUND: { name: 'router not found', color: 'red ', hex: '#FF0000', text: 'ルーターが見つかりません。' }, 
    ROUTER_IS_BUSY: { name: 'router is busy', color: 'red ', hex: '#FF0000', text: 'ルーターが混雑しています。' }, 
    CAMERA_RECEIVED_THE_COMMAND: { name: 'Camera received the command',   color: 'lagoon-blue',    hex: '#c9662c', text: 'カメラはコマンドを受信しました。' },
    DEVICE_UNREACHABLE: { name: 'device unreachable', color: 'red ', hex: '#FF0000', text: 'デバイスにアクセスできません。' }, 
    DEVICE_TIMEOUT: { name: 'device timeout', color: 'red ', hex: '#FF0000', text: 'デバイスのタイムアウト。' }, 
    PHOTO_SHOOT_EXPIRE: { name: 'photoshoot expired', color: 'red ', hex: '#FF0000', text: '遠隔撮影の有効期限が切れました。' }, 
    TASK_RESULT_POTENTIALLY_RECEIVED: { name: 'task result potentially received at', color: 'blue ', hex: '#0000FF', text: '新しい画像がカメラで自動撮影されました。' }, 
    PHOTO_SHOOT_COMPLETED: { name: 'photoshoot completed', color: 'blue ', hex: '#0000FF', text: '撮影が完了しました。' },     
};