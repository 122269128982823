import ActionTypes from "../constants/ActionTypes";
import BASE_URL from "../constants/Api";
import axiosInstance from "../helpers/refreshTokenMiddleware";

export const fetchFacilitiesRequest = () => ({
    type: ActionTypes.FETCH_FACILITY_REQUEST,
});

export const fetchFacilitiesSuccess = (facilities) => ({
    type: ActionTypes.FETCH_FACILITY_SUCCESS,
    payload: facilities,
});

export const fetchFacilitiesFailure = (error) => ({
    type: ActionTypes.FETCH_FACILITY_FAILURE,
    payload: error,
});

// Thunk to fetch data from API
export const fetchFacilities = (company_id) => {
    return async (dispatch) => {
        dispatch(fetchFacilitiesRequest());
        try {
            const accessToken = localStorage.getItem('accessToken');
            const response = await axiosInstance.post(BASE_URL + '/top/facility-list', {
                company_id: parseInt(company_id, 10),
            }, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            const data = response.data.respons || [];
            dispatch(fetchFacilitiesSuccess(data));

        } catch (error) {
            dispatch(fetchFacilitiesFailure(error.message));
        }
    };
};

export const replaceFacilitiesRequest = () => ({
    type: ActionTypes.REPLACE_FACILITY_REQUEST,
});

export const replaceFacilitiesSuccess = (message) => ({
    type: ActionTypes.REPLACE_FACILITY_SUCCESS,
    payload: message,
});

export const replaceFacilitiesFailure = (error) => ({
    type: ActionTypes.REPLACE_FACILITY_FAILURE,
    payload: error,
});

export const replaceFacilities = (company_id, facilityIds, is_delete = undefined) => {
    return async (dispatch) => {
        dispatch(replaceFacilitiesRequest());
        try {
            const accessToken = localStorage.getItem('accessToken');
            const body = {
                company_id: parseInt(company_id, 10),      
                facility_id: facilityIds        
            };

            if (is_delete) {
                body.is_delete = is_delete;
            }

            const response = await axiosInstance.post(BASE_URL + '/top/replace-facility-data', body, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            dispatch(replaceFacilitiesSuccess(response.data.respons.msg));
            return response;
        } catch (error) {
            dispatch(replaceFacilitiesFailure(error.message));
        }
    };
};

export const checkGaugeDataRequest = () => ({
    type: ActionTypes.CHECK_GAUGE_DATA_REQUEST,
});

export const checkGaugeDataSuccess = (data) => ({
    type: ActionTypes.CHECK_GAUGE_DATA_SUCCESS,
    payload: data,
});

export const checkGaugeDataFailure = (error) => ({
    type: ActionTypes.CHECK_GAUGE_DATA_FAILURE,
    payload: error,
});

export const checkGaugeData = (company_id, facilityIds, is_delete = undefined) => {
    return async (dispatch) => {
        dispatch(checkGaugeDataRequest());
        try {
            const accessToken = localStorage.getItem('accessToken');
            const body = {
                company_id: parseInt(company_id, 10),
                facility_id: facilityIds 
            };

            if (is_delete) {
                body.is_delete = is_delete;
            }

            const response = await axiosInstance.post(BASE_URL + '/top/check-gauge-list-data', body, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            dispatch(checkGaugeDataSuccess(response.data.respons.data));
            return response.data.respons.data; 
        } catch (error) {
            dispatch(checkGaugeDataFailure(error.message));
            throw error; 
        }
    };
};
