import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedItem } from '../../actions/datamanage';

const DataManagementSection = ({
    menuData,
    activeItem,
    setActiveItem,
    toggleMenu,
    adminData,
    activeSet,
    setActiveSet,
    setIsMenuOpen,
    screenType,
    updateMenu,
    authorityClass
}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const pageLoading = useSelector(state => 
        Object.values(state.dataManage?.isFetching || {}).some(fetching => fetching)
    );

    useEffect(() => {
        if (isInitialLoad && activeItem && activeSet === 'dataManagement') {
            const { company_id, facility_id, camera_id, gauge_id } = activeItem;

            menuData.forEach((company, companyIndex) => {
                if (String(company.company_id) === String(company_id)) {
                    if (!company.isOpen) {
                        toggleMenu(companyIndex, 'company', 'dataManagement');
                    }

                    company.facility.forEach((facility, facilityIndex) => {
                        if (String(facility.facility_id) === String(facility_id)) {
                            if (!facility.isOpen) {
                                toggleMenu(facilityIndex, 'facility', 'dataManagement', companyIndex);
                            }

                            facility.camera.forEach((camera, cameraIndex) => {
                                if (String(camera.device_id) === String(camera_id)) {
                                    if (!camera.isOpen) {
                                        toggleMenu(cameraIndex, 'camera', 'dataManagement', facilityIndex, companyIndex);
                                    }

                                    camera.gauge.forEach((gauge, gaugeIndex) => {
                                        if (String(gauge.gauge_id) === String(gauge_id) && !gauge.isOpen) {
                                            toggleMenu(gaugeIndex, 'gauge', 'dataManagement', cameraIndex, facilityIndex, companyIndex);
                                        }
                                    });
                                }
                            });
                        }
                    });
                }
            });

            setIsInitialLoad(false);
        }
    }, [isInitialLoad, activeItem, activeSet, menuData, toggleMenu]);
    
    const navigateToDataManage = (
        event,
        company_name,
        facility_name = null,
        camera_name = null,
        gauge_name = null,
        isHistory = false
    ) => {
        if (pageLoading === true) {
            return; 
        }
        const value = event.target.value.split(',');
        const companyId = value[0] ? value[0].split(':')[1]?.trim() : null;
        const facilityId = facility_name ? value[1].split(':')[1]?.trim() : null;
        const cameraId = value[2] ? value[2].split(':')[1]?.trim() : null; // Ensure device_id is used
        const gaugeId = value[3] ? value[3].split(':')[1]?.trim() : null;
    
        const activeItem = {
            company_id: companyId,
            facility_id: facilityId,
            camera_id: cameraId,
            gauge_id: gaugeId,
            isHistory: isHistory,
            type: isHistory
                ? 'history'
                : gaugeId
                ? 'gauge'
                : cameraId
                ? 'camera'
                : facilityId
                ? 'facility'
                : 'company',
        };
    
        setActiveItem(activeItem);
        setActiveSet('dataManagement');
        dispatch(setSelectedItem(activeItem));
    
        // Construct navigation URL
        const url = isHistory
            ? `/data-manage?company_id=${companyId}&history=true`
            : gaugeId
            ? `/data-manage?company_id=${companyId}&facility_id=${facilityId}&device_id=${cameraId}&gauge_id=${gaugeId}`
            : cameraId
            ? `/data-manage?company_id=${companyId}&facility_id=${facilityId}&device_id=${cameraId}`
            : facilityId
            ? `/data-manage?company_id=${companyId}&facility_id=${facilityId}`
            : `/data-manage?company_id=${companyId}`;
    
        navigate(url);

        if (screenType === 'mobile' || screenType === 'tablet') {
            setIsMenuOpen(false);
        }
    };
    
    return (
        <div id="dataManagement" className={`lay-sidemenu__set ${activeSet === 'dataManagement' ? 'active-sidemenu' : ''}`}>
            <div className="menu-master">
                <img src="img/common/navi/icon-data.png" alt="Data Management" className="menu-master-icon" />
                データ管理
            </div>
            {adminData && (
                <div className="admin">
                    <span className="circle"></span>
                    <span className="name" style={{ marginLeft: '3px' }}>{adminData.admin_name}</span>
                </div>
            )}
            {menuData.length > 0 && (
                menuData.map((company, cIndex) => (
                    <ul key={cIndex} className='menu-item'>
                        {/* Company Menu */}
                        <li id={`li_dm_company${cIndex}`} className="menu-item__company" value={`company_${company.company_id}`} >
                            <div className={
                                    company.facility.length > 0
                                        ? `ac-link ${company.isOpen ? 'clicked' : ''}`
                                        : 'non-ac-link'
                                }
                                onClick={() => toggleMenu(cIndex, 'company', 'dataManagement')}>
                                {company.facility.length === 0 && <div style={{ width: '15px' }}></div>}
                            </div>
                            <button
                                className={`name ${
                                    activeItem &&
                                    activeItem.type === 'company' &&
                                    String(activeItem.company_id) === String(company.company_id) &&
                                    !activeItem.facility_id &&
                                    !activeItem.camera_id &&
                                    !activeItem.gauge_id &&
                                    activeSet === 'dataManagement'
                                        ? 'active'
                                        : ''
                                } ${pageLoading ? 'disabled' : ''}`}
                                disabled={pageLoading}
                                onClick={(event) => navigateToDataManage(event, company.company_name)}
                                value={`company_id: ${company.company_id}`}>
                                {company.company_name}
                            </button>
                        </li>
                        <AnimatePresence>
                            {company.isOpen && (
                                <motion.ul
                                    initial={{ height: 0, opacity: 0 }}
                                    animate={{ height: 'auto', opacity: 1 }}
                                    exit={{ height: 0, opacity: 0 }}
                                    transition={{ duration: 0.3 }}
                                >
                                    {company.facility.map((facility, fIndex) => (
                                        <ul key={fIndex} className="facility-list">
                                            {/* Facility Menu */}
                                            <li id={`li_dm_facility${cIndex}${fIndex}`} className="menu-item__facility" value={`facility_${facility.facility_id}`}>
                                                <div className={facility.camera.length > 0 ? `ac-link ${facility.isOpen ? 'clicked' : ''}` : 'non-ac-link'} 
                                                    onClick={() => toggleMenu(fIndex, 'facility', 'dataManagement', cIndex)}>
                                                    {facility.camera.length === 0 && (
                                                        <img src="img/common/navi/icon-data.png" alt="Data Management"/>
                                                    )}
                                                </div>
                                                <button
                                                    className={`name ${
                                                        activeItem &&
                                                        activeItem.type === 'facility' &&
                                                        String(activeItem.company_id) === String(company.company_id) &&
                                                        String(activeItem.facility_id) === String(facility.facility_id) &&
                                                        !activeItem.camera_id &&
                                                        !activeItem.gauge_id &&
                                                        activeSet === 'dataManagement'
                                                            ? 'active'
                                                            : ''
                                                    } ${pageLoading ? 'disabled' : ''}`}
                                                    disabled={pageLoading}
                                                    onClick={(event) => navigateToDataManage(event, company.company_name, facility.facility_name)}
                                                    value={`company_id: ${company.company_id}, facility_id: ${facility.facility_id}`}>
                                                    {facility.facility_name}
                                                </button>
                                            </li>
                                            <AnimatePresence>
                                                {facility.isOpen && (
                                                    <motion.ul
                                                        initial={{ height: 0, opacity: 0 }}
                                                        animate={{ height: 'auto', opacity: 1 }}
                                                        exit={{ height: 0, opacity: 0 }}
                                                        transition={{ duration: 0.3 }}
                                                    >
                                                        {facility.camera.map((camera, caIndex) => (
                                                            <ul key={caIndex} className="camera-list">
                                                                {/* Camera Menu */}
                                                                <li id={`li_dm_camera${cIndex}${fIndex}${caIndex}`} className="menu-item__camera" value={`camera_${camera.device_id}`}>
                                                                    <div className={camera.gauge.length > 0 ? `ac-link ${camera.isOpen ? 'clicked' : ''}` : 'non-ac-link'} 
                                                                        onClick={() => toggleMenu(caIndex, 'camera', 'dataManagement', fIndex, cIndex)}>
                                                                        {camera.gauge.length === 0 && (
                                                                            <img src="img/common/navi/icon-data.png" alt="Data Management"/>
                                                                        )}
                                                                    </div>
                                                                    <button
                                                                        className={`name ${
                                                                            activeItem &&
                                                                            activeItem.type === 'camera' &&
                                                                            String(activeItem.company_id) === String(company.company_id) &&
                                                                            String(activeItem.facility_id) === String(facility.facility_id) &&
                                                                            String(activeItem.camera_id) === String(camera.device_id) &&
                                                                            !activeItem.gauge_id &&
                                                                            activeSet === 'dataManagement'
                                                                                ? 'active'
                                                                                : ''
                                                                        } ${pageLoading ? 'disabled' : ''}`}
                                                                        disabled={pageLoading}
                                                                        onClick={(event) => navigateToDataManage(event, company.company_name, facility.facility_name, camera.display_camera_name)}
                                                                        value={`company_id: ${company.company_id}, facility_id: ${facility.facility_id}, device_id: ${camera.device_id}`}>
                                                                        {camera.display_camera_name} ({camera.battery_percentage}%)
                                                                    </button>
                                                                </li>
                                                                <AnimatePresence>
                                                                    {camera.isOpen && (
                                                                        <motion.ul
                                                                            initial={{ height: 0, opacity: 0 }}
                                                                            animate={{ height: 'auto', opacity: 1 }}
                                                                            exit={{ height: 0, opacity: 0 }}
                                                                            transition={{ duration: 0.3 }}
                                                                        >
                                                                            {camera.gauge.map((gauge, gIndex) => (
                                                                                <ul key={gIndex} className="gauge-list">
                                                                                    {/* Gauge Menu */}
                                                                                    <li id={`li_dm_gauge${cIndex}${fIndex}${caIndex}${gIndex}`} className="menu-item__gauge" onClick={() => toggleMenu(gIndex, 'gauge', 'dataManagement', caIndex, fIndex, cIndex)} value={`gauge_${gauge.gauge_id}`}>
                                                                                        <img src={gauge.measure.length > 0 ? 'img/common/navi/icon-data.png' : 'img/common/navi/icon-photo.png'} alt="Data Management" className="menu-item-icon" />
                                                                                        <button
                                                                                            className={`name ${
                                                                                                activeItem &&
                                                                                                activeItem.type === 'gauge' &&
                                                                                                String(activeItem.company_id) === String(company.company_id) &&
                                                                                                String(activeItem.facility_id) === String(facility.facility_id) &&
                                                                                                String(activeItem.camera_id) === String(camera.device_id) &&
                                                                                                String(activeItem.gauge_id) === String(gauge.gauge_id) &&
                                                                                                activeSet === 'dataManagement'
                                                                                                    ? 'active'
                                                                                                    : ''
                                                                                            } ${pageLoading ? 'disabled' : ''}`}
                                                                                            disabled={pageLoading}
                                                                                            onClick={(event) => navigateToDataManage(event, company.company_name, facility.facility_name, camera.display_camera_name, gauge.display_gauge_name)}
                                                                                            value={`company_id: ${company.company_id}, facility_id: ${facility.facility_id}, device_id: ${camera.device_id}, gauge_id: ${gauge.gauge_id}`}>
                                                                                            {gauge.display_gauge_name}
                                                                                        </button>
                                                                                    </li>
                                                                                </ul>
                                                                            ))}
                                                                        </motion.ul>
                                                                    )}
                                                                </AnimatePresence>
                                                            </ul>
                                                        ))}
                                                    </motion.ul>
                                                )}
                                            </AnimatePresence>
                                        </ul>
                                    ))}
                                </motion.ul>
                            )}
                        </AnimatePresence>
                        {/* History Menu */}
                        {authorityClass !== 0 && company.isOpen && (
                            <li id={`li_dm_gauge_deleted${cIndex}`} className="menu-item__history">
                                <div>
                                    <img src="img/common/navi/icon-data.png" alt="Data History Icon" />
                                </div>
                                <div className="ac-btn" style={{ paddingLeft: '10px', fontSize: '14px' }}>
                                    <button
                                        className={`name ${
                                            activeItem &&
                                            activeItem.type === 'history' &&
                                            String(activeItem.company_id) === String(company.company_id)
                                                ? 'active'
                                                : ''
                                        } ${pageLoading ? 'disabled' : ''}`}
                                        disabled={pageLoading}
                                        value={`company_id: ${company.company_id}`}
                                        onClick={(event) => navigateToDataManage(event, company.company_name, null, null, null, true)}
                                    >
                                        データ履歴 <br />(削除・変更等)
                                    </button>
                                </div>
                            </li>
                        )}
                    </ul>
                ))
            )}
        </div>
    );
};

export default DataManagementSection;
