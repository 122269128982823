import ActionTypes from "../constants/ActionTypes";
import BASE_URL from "../constants/Api";
import axiosInstance from "../helpers/refreshTokenMiddleware";

export const fetchCameraEditRequest = () => ({
    type: ActionTypes.CAMERA_EDIT_REQUEST,
});

export const fetchCameraEditSuccess = (gauges) => ({
    type: ActionTypes.CAMERA_EDIT_SUCCESS,
    payload: gauges,
});

export const fetchCameraEditFailure = (error) => ({
    type: ActionTypes.CAMERA_EDIT_FAILURE,
    payload: error,
});

// Thunk to fetch data from API  
export const fetchCameraEdit = (company_id, facility_id, device_id) => {
    return async (dispatch) => {
        dispatch(fetchCameraEditRequest());
        try {
            const accessToken = localStorage.getItem('accessToken');
            const response = await axiosInstance.post(BASE_URL + '/top/camera-edit', {
                company_id: company_id, 
                facility_id: facility_id,
                device_id: device_id
            }, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            // Check response here
            const data = response.data.respons || {};
            dispatch(fetchCameraEditSuccess(data));

        } catch (error) {
            dispatch(fetchCameraEditFailure(error.message));
        }
    };
};

// New actions for submitting camera edit data
export const submitCameraEditRequest = () => ({
    type: ActionTypes.CAMERA_EDIT_SUBMIT_REQUEST,
});

export const submitCameraEditSuccess = (message) => ({
    type: ActionTypes.CAMERA_EDIT_SUBMIT_SUCCESS,
    payload: message,
});

export const submitCameraEditFailure = (error) => ({
    type: ActionTypes.CAMERA_EDIT_SUBMIT_FAILURE,
    payload: error,
});

// Thunk action to submit camera edit form data
export const submitCameraEdit = (camera_name, company_id, facility_id, device_id, display_name_id, gaugeIds, cnt) => {
    return async (dispatch) => {
        dispatch(submitCameraEditRequest());
        try {
            const accessToken = localStorage.getItem('accessToken');
            const body = {
                company_id: company_id,
                facility_id: facility_id,
                camera_name: camera_name,
                device_id: device_id,           
                display_name_id: display_name_id,
                gauge_id: gaugeIds,
                cnt: cnt
            };
            const response = await axiosInstance.post(BASE_URL + '/top/edit-camera-data', body, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            dispatch(submitCameraEditSuccess(response.data.respons.msg));
            return response;
        } catch (error) {
            // Dispatch failure action if the API call fails
            dispatch(submitCameraEditFailure(error.message));
        }
    };
};