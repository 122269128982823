import React, {useEffect, useState, useRef} from 'react';
import PropTypes from 'prop-types';
import DataRegister from './DataRegister';
import DataEdit from './DataEdit';
import DataDelete from './DataDelete';
import LandscapeView from './LandscapeView';

const ImageList = ({
    pagesInCurrentGroup,
    displayedData,
    currentPage,
    buttonsPerGroup,
    itemsPerPage,
    gaugeData,
    getGaugeData,
    selectedItem,
    startDate,
    endDate,
    selectedFacility,
    selectedCamera,
    selectedGauge,
    comments,
    setComments,
    fontSizes,
    setFontSizes,
    fetchImageList,
    setLoading,
    fnEditData,
    handleCommentChange,
    handleFontSizeChange,
    isSearching,
    openPopup,
    checkboxStates,
    handleCheckboxChange,
    failure_id,
    setCurrentPage,
    calculateDiff,
    formatValue,
    formatDiffVal,
    setModalMessage,
    setIsModalOpen,
    measureValues,
    setMeasureValues,
    screenType, 
    mainImage,
    setMainImage,
    editStatus,
    setEditStatus,
    relativeActive,
    setRelativeActive,
}) => {
    let overallIndex = 0;
    const [isLandscapeSmallScreen, setIsLandscapeSmallScreen] = useState(false); 
    const [images, setImages] = useState([]);
    const isSliderAction = useRef(false);
    const isUserNavigating = useRef(false);

    // Monitor screen size changes
    useEffect(() => {
        const handleMediaQueryChange = (event) => {
            setIsLandscapeSmallScreen(event.matches && document.body.classList.contains('mobile'));
        };
    
        const mediaQuery = window.matchMedia("(max-height: 576px) and (orientation: landscape)");
        mediaQuery.addEventListener("change", handleMediaQueryChange);
    
        handleMediaQueryChange(mediaQuery);
    
        return () => {
            mediaQuery.removeEventListener("change", handleMediaQueryChange);
        };
    }, []);
    

    // Determine the images for the current page
    useEffect(() => {
        if (displayedData.length > 0) {
            const startIndex = ((currentPage - 1) % buttonsPerGroup) * itemsPerPage;
            const endIndex = startIndex + itemsPerPage;
            const pageData = displayedData.slice(startIndex, endIndex);
    
            if (isSliderAction.current || isUserNavigating.current) {
                isSliderAction.current = false;
                isUserNavigating.current = false;
                return;
            }
    
            if (relativeActive !== null) {
                const activeItem = displayedData[relativeActive];
                if (activeItem) {
                    setMainImage({
                        ...activeItem,
                        gaugeDataList: getGaugeData(activeItem, gaugeData) || [],
                        comment_font_size: activeItem.comment_font_size || 12,
                        relativeIndex: relativeActive,
                    });
                    return;
                }
            } else {
                if (selectedItem) {
                    setRelativeActive(null);
                }
                if (pageData.length > 0) {
                    setMainImage({
                        ...pageData[0],
                        gaugeDataList: getGaugeData(pageData[0], gaugeData) || [],
                        comment_font_size: pageData[0].comment_font_size || 12,
                        relativeIndex: displayedData.findIndex(item => item === pageData[0]),
                    });
                }
            }
        }
    // eslint-disable-next-line
    }, [currentPage, displayedData, itemsPerPage, buttonsPerGroup, gaugeData, relativeActive]);      
    
    useEffect(() => {
        const updatedComments = {};
        displayedData.forEach((item, index) => {
            if (comments[index] === undefined || isSearching) {
                updatedComments[index] = item.comment || ''; 
            }
        });
        setComments(updatedComments);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [displayedData, isSearching, setComments]);

    useEffect(() => {
        if (displayedData) {
            const updatedMeasureValues = displayedData.reduce((acc, item, index) => {
                acc[index] = {};
                return acc;
            }, {});
            setMeasureValues(updatedMeasureValues);
        }
    }, [displayedData, setMeasureValues]);
    
    // Handle selecting an image from the slider
    const handleImageClick = (image, relativeIndex) => {
        isSliderAction.current = true;
        isUserNavigating.current = true;
        
        const gaugeDataList = getGaugeData(image.info, gaugeData);
        setMainImage({
          ...image.info,
          gaugeDataList,
          relativeIndex, 
          comment_font_size: image.info.comment_font_size || 12,
        });
      
        const page = getPageFromMainImage(image.info);
        if (page !== currentPage) {
            setCurrentPage(page); 
        }
    };
      
    // Map displayedData to image array for the slider
    useEffect(() => {
        const newImages = displayedData.map((item, index) => ({
            url: item.image_url,
            name: item.display_camera_name || "Unnamed",
            info: { ...item, relativeIndex: index },
        }));
        setImages(newImages);

        if (!isSliderAction && newImages.length > 0) {
            const startIndex = ((currentPage - 1) % buttonsPerGroup) * itemsPerPage; 
            const endIndex = startIndex + itemsPerPage;
            const currentPageImages = newImages.slice(startIndex, endIndex);

            if (currentPageImages.length > 0) {
                setMainImage({
                    ...currentPageImages[0].info,
                    gaugeDataList: getGaugeData(currentPageImages[0].info, gaugeData),
                    comment_font_size: currentPageImages[0].info.comment_font_size || 12,
                    relativeIndex: currentPageImages[0].info.relativeIndex,
                });
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [displayedData, gaugeData, currentPage, buttonsPerGroup, itemsPerPage, isSliderAction]);

    const calculateGroupOffset = () => {
        return Math.floor((currentPage - 1) / buttonsPerGroup) * buttonsPerGroup;
    };
      
    const getPageFromMainImage = (mainImage) => {
        if (!mainImage || !displayedData.length) return currentPage;
      
        const index = mainImage.relativeIndex !== undefined 
            ? mainImage.relativeIndex 
            : displayedData.findIndex((item) => item.image_url === mainImage.image_url);
      
        const groupOffset = calculateGroupOffset(); 
        const page = Math.floor(index / itemsPerPage) + 1 + groupOffset; 
        return page;
    };

    const validateInput = (inputValue) => {
        const intNumLimit = 10;
        const decNumLimit = 5;
    
        const isNumeric = /^[+-]?\d*\.?\d*$/.test(inputValue);
        const dotCount = (inputValue.match(/\./g) || []).length;
        const hyphenCount = (inputValue.match(/-/g) || []).length;
    
        if (
            !isNumeric ||
            dotCount > 1 ||
            hyphenCount > 1 ||
            (hyphenCount === 1 && inputValue.indexOf('-') !== 0)
        ) {
            setModalMessage('数値のみ入力可能です。');
            setIsModalOpen(true);
            return false;
        }
    
        const [intPart, decPart] = inputValue.split('.');
    
        if (intPart.replace('-', '').length > intNumLimit) {
            setModalMessage(`整数の数値は、${intNumLimit}桁までの入力です。`);
            setIsModalOpen(true);
            return false;
        }
    
        if (dotCount === 1 && decPart && decPart.length > decNumLimit) {
            setModalMessage(`小数の数値は、${decNumLimit}桁までの入力です。`);
            setIsModalOpen(true);
            return false;
        }
    
        return true;
    };
    
    const handleInputChange = (event, relativeIndex, index) => {
        const newValue = event.target.value.replace(',', '.');
        if (newValue === '-' || !isNaN(parseFloat(newValue)) || newValue === '') {
            setMeasureValues((prevValues) => ({
                ...prevValues,
                [relativeIndex]: {
                    ...prevValues[relativeIndex],
                    [index]: newValue,
                },
            }));
        }
    };
    
    const handleMeasureChange = (event, gauge, index, relativeIndex) => {
        let inputValue = event.target.value.trim().replace(',', '.');;
    
        if (inputValue === "") {
            event.target.value = ""; 
            updateDiffValue("--", relativeIndex, index, "black"); 
            setMeasureValues((prevValues) => ({
                ...prevValues,
                [relativeIndex]: {
                    ...prevValues[relativeIndex],
                    [index]: "",
                },
            }));
            return;
        }

        if (!validateInput(inputValue)) {
            event.target.value = ''; 
            updateDiffValue("--", relativeIndex, index, "black");
            setMeasureValues((prevValues) => ({
                ...prevValues,
                [relativeIndex]: {
                    ...prevValues[relativeIndex],
                    [index]: "",
                },
            }));
            return;
        }
    
        const decimalValue = gauge.decimal_value;
        let formattedValue;

        if (decimalValue === -1) {
            formattedValue = inputValue;
        } else if (decimalValue === 0) {
            formattedValue = Math.trunc(parseFloat(inputValue)).toString();
        } else if (decimalValue >= 1 && decimalValue <= 5) {
            const factor = Math.pow(10, decimalValue);
            formattedValue = (Math.trunc(parseFloat(inputValue) * factor) / factor).toFixed(decimalValue);
        }
    
        event.target.value = formattedValue;
        setMeasureValues((prevValues) => ({
            ...prevValues,
            [relativeIndex]: {
                ...prevValues[relativeIndex],
                [index]: formattedValue,
            },
        }));
    
        if (gauge.prev_measure_val !== null && gauge.prev_measure_val !== undefined) {
            const calculatedDiff = parseFloat(formattedValue) - gauge.prev_measure_val;
            const roundedDiff = Math.round(calculatedDiff * Math.pow(10, 5)) / Math.pow(10, 5);
    
            let formattedDiff;
            if (calculatedDiff < 0 || isNaN(roundedDiff)) {
                formattedDiff = '--';
            } else if (roundedDiff === 0) {
                formattedDiff = '0';
            } else {
                formattedDiff = decimalValue >= 0
                    ? roundedDiff.toFixed(decimalValue)
                    : roundedDiff.toString().replace(/\.?0+$/, '');
            }
    
            const diffElement = document.getElementById(`dv_${relativeIndex}_${index}`);
            if (diffElement) {
                diffElement.textContent = formattedDiff;
                diffElement.style.color = getValueColor(
                    gauge.diffFlg,
                    gauge.threshold_min_flg,
                    gauge.threshold_max_flg,
                    gauge.diff_threshold_min_flg,
                    gauge.diff_threshold_max_flg
                );
            }
        } else {
            updateDiffValue('--', relativeIndex, index, 'black');
        }
    };
    
    const updateDiffValue = (value, relativeIndex, index, color) => {
        const diffElement = document.getElementById(`dv_${relativeIndex}_${index}`);
        if (diffElement) {
            diffElement.textContent = value;
            diffElement.style.color = color;
        }
    };

    const getValueColor = (diffFlg, thresholdMinFlg, thresholdMaxFlg, diffThresholdMinFlg, diffThresholdMaxFlg) => {
        const isMeasureAbnormal = thresholdMinFlg || thresholdMaxFlg;
        const isMeasureAbnormalDiff = diffThresholdMinFlg || diffThresholdMaxFlg;
        const isAbnormal = diffFlg ? isMeasureAbnormalDiff : isMeasureAbnormal;
        return isAbnormal ? "red" : "black";
    };
    
    // Render landscape view for small screens
    if (isLandscapeSmallScreen) {
        return (
            <LandscapeView
                mainImage={mainImage}
                images={images}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                fontSizes={fontSizes}
                comments={comments}
                handleCommentChange={handleCommentChange}
                handleFontSizeChange={handleFontSizeChange}
                handleImageClick={handleImageClick}
                fetchImageList={fetchImageList}
                selectedItem={selectedItem}
                startDate={startDate}
                endDate={endDate}
                selectedFacility={selectedFacility}
                selectedCamera={selectedCamera}
                selectedGauge={selectedGauge}
                setLoading={setLoading}
                setComments={setComments}
                setFontSizes={setFontSizes}
                fnEditData={fnEditData}
                failure_id={failure_id}
                getPageFromMainImage={getPageFromMainImage}
                isSliderAction={isSliderAction}
                formatDiffVal={formatDiffVal}
                screenType={screenType}
                setModalMessage={setModalMessage}
                setIsModalOpen={setIsModalOpen}
                setMainImage={setMainImage}
                setMeasureValues={setMeasureValues}
                editStatus={editStatus}
                setEditStatus={setEditStatus}
                setRelativeActive={setRelativeActive}
            />
        );
    }
    
    return (
        <div className="lay-list">
            {pagesInCurrentGroup.map((page, pageIndex) => {
                const startIndex = ((page - 1) % buttonsPerGroup) * itemsPerPage;
                const endIndex = startIndex + itemsPerPage;
                const pageData = displayedData.slice(startIndex, endIndex);

                return (
                    <div
                        key={page}
                        className={`selection page-list${page}`}
                        style={{ display: currentPage === page ? 'block' : 'none' }}
                    >
                        {pageData.length === 0 ? (
                            <div className="mod-no-data">表示データが存在しません。</div>
                        ) : (
                            pageData.reduce((result, item, idx) => {
                                const blockIndex = Math.floor(idx / 5);
                                if (!result[blockIndex]) {
                                    result[blockIndex] = [];
                                }
                                result[blockIndex].push(item);
                                return result;
                            }, []).map((block, blockIdx) => (
                                <div key={blockIdx} className={`lay-list__horizontal ${screenType}`}>
                                    <ul>
                                        {block.map((item, itemIdx) => {
                                            const relativeIndex = overallIndex++;
                                            const gaugeDataList = getGaugeData(item, gaugeData);

                                            return (
                                                <li 
                                                    key={relativeIndex} 
                                                    id={`li${relativeIndex}`} 
                                                    className={(item.status_class === 2 && item.disuse_flg !== 1) ? 'lay-list__horizontal-error' : ''}
                                                >
                                                    <form id={`dataform${relativeIndex}`} onSubmit={(e) => e.preventDefault()}>
                                                        <input type="hidden" name="diff_flg" value={item.diff_flg || ''} />
                                                        <div className={`lay-list__horizontal__info ${screenType}`}>
                                                            <div className={`lay-list__horizontal__date ${screenType}`}>
                                                                {item.disp_datetime}
                                                            </div>
                                                            <div className={`lay-list__horizontal__name ${screenType}`}>
                                                                {item.display_camera_name}<br />
                                                                {item.display_gauge_name}
                                                            </div>

                                                            <div className={`lay-list__horizontal__checkbox ${screenType}`}>
                                                                <label> 
                                                                    <input
                                                                        type="checkbox"
                                                                        id={`disuse_flg${relativeIndex}`}
                                                                        name="disuse_flg"
                                                                        className="checkbox-input"
                                                                        tabIndex={relativeIndex}
                                                                        disabled={(gaugeDataList.some(gauge => gauge.measureVal) || item.status_class !== 0 || failure_id === 1)}
                                                                        checked={checkboxStates[relativeIndex] !== undefined ? checkboxStates[relativeIndex] : item.disuse_flg === 1}
                                                                        onChange={(e) => handleCheckboxChange(relativeIndex, e.target.checked)}
                                                                    />
                                                                    <span className="checkbox-parts"></span>
                                                                    <span>不使用</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        
                                                        <div className={`lay-list__horizontal__img ${screenType}`}>
                                                            <div className={`lay-list__horizontal__pct ${screenType}`}>
                                                                {window.innerWidth >= 768 && failure_id !== 1 ? (
                                                                    <img
                                                                        src={item.image_url}
                                                                        alt=""
                                                                        onClick={() => openPopup(item, relativeIndex, page)}
                                                                    />
                                                                    
                                                                ) : (
                                                                    <a href={item.image_url} target="_blank" rel="noopener noreferrer">
                                                                        <img src={item.image_url} alt="" />
                                                                    </a>
                                                                )}
                                                            </div>
                                                        </div>
                                                        
                                                        <div className={`lay-list__horizontal__data ${screenType}`}>
                                                            {gaugeDataList.length > 0 && (
                                                                <div className={`lay-list__horizontal__para ${screenType}`}>
                                                                    {gaugeDataList.map((gauge, index) => (
                                                                        <React.Fragment key={index}>
                                                                            {gauge.unit && (
                                                                                <>
                                                                                    {gauge.measureVal !== undefined || item.status_class !== 0 ? (
                                                                                        <>
                                                                                            <div className={`lay-list__horizontal__para__num ${screenType} edit_data_lbl${relativeIndex}`} style={{ display: 'block' }}>
                                                                                                <span
                                                                                                    style={{
                                                                                                        color: (item.disuse_flg === 0 && gauge.abnormal_flg === 1) ? '#f00' : 'inherit'
                                                                                                    }}
                                                                                                >
                                                                                                    {isNaN(gauge.measureVal) ? '' : gauge.measureVal}
                                                                                                </span>
                                                                                                <span className={`lay-list__horizontal__para__num-unit ${screenType}`}>
                                                                                                    {gauge.unit}
                                                                                                </span>
                                                                                            </div>
                                                                                            <div className={`mod-form__num ${screenType} edit_data${relativeIndex}`} style={{ display: 'none' }}>
                                                                                                <input
                                                                                                    type="text"
                                                                                                    id={`mv_${relativeIndex}_${index}`}
                                                                                                    name={`measure_val${index}`}
                                                                                                    value={measureValues[relativeIndex]?.[index] || ''}
                                                                                                    onChange={(e) => handleInputChange(e, relativeIndex, index)}
                                                                                                    inputMode="decimal"
                                                                                                    pattern="^-?[0-9]*[.,]?[0-9]+$"
                                                                                                    onKeyDown={(e) => {
                                                                                                        if (e.key === 'Enter') {
                                                                                                            e.preventDefault(); 
                                                                                                            handleMeasureChange(e, gauge, index, relativeIndex); 
                                                                                                        }
                                                                                                    }}
                                                                                                    onBlur={(e) => handleMeasureChange(e, gauge, index, relativeIndex)} 
                                                                                                />
                                                                                                <span className={`mod-form__num-unit ${screenType}`}>
                                                                                                    {gauge.unit}
                                                                                                </span>
                                                                                                <input type="hidden" name={`diff_flg${index}`} value={gauge.diffFlg || ''} />
                                                                                                <input type="hidden" name={`measure_id${index}`} value={gauge.measure_id || ''} />
                                                                                                <input type="hidden" name={`decimal_value${index}`} value={gauge.decimal_value || ''} />
                                                                                                <input type="hidden" name={`min_limit${index}`} value={gauge.min_limit || ''} />
                                                                                                <input type="hidden" name={`min_limit_flg${index}`} value={gauge.min_limit_flg || ''} />
                                                                                                <input type="hidden" name={`max_limit${index}`} value={gauge.max_limit || ''} />
                                                                                                <input type="hidden" name={`max_limit_flg${index}`} value={gauge.max_limit_flg || ''} />
                                                                                                <input type="hidden" name={`diff_type${index}`} value={gauge.diff_type || ''} />
                                                                                                <input type="hidden" name={`diff_val${index}`} id={`newdv_0_${index}`} value={gauge.diff_val || ''} />
                                                                                                <input type="hidden" name={`diff_min_limit${index}`} value={gauge.diff_min_limit || ''} />
                                                                                                <input type="hidden" name={`diff_min_limit_flg${index}`} value={gauge.diff_min_limit_flg || ''} />
                                                                                                <input type="hidden" name={`diff_max_limit${index}`} value={gauge.diff_max_limit || ''} />
                                                                                                <input type="hidden" name={`diff_max_limit_flg${index}`} value={gauge.diff_max_limit_flg || ''} />
                                                                                            </div>
                                                                                        </>
                                                                                    ) : (
                                                                                        <>
                                                                                            <div className={`lay-list__horizontal__para__num ${screenType} edit_data_lbl${relativeIndex}`} style={{ display: 'none' }}>
                                                                                                {isNaN(gauge.measureVal) ? '' : gauge.measureVal}
                                                                                                <span className={`lay-list__horizontal__para__num-unit ${screenType}`}>
                                                                                                    {gauge.unit}
                                                                                                </span>
                                                                                            </div>
                                                                                            <div className={`mod-form__num ${screenType} edit_data${relativeIndex}`} style={{ display: 'block' }}>
                                                                                                <input
                                                                                                    type="text"
                                                                                                    id={`mv_${relativeIndex}_${index}`}
                                                                                                    name={`measure_val${index}`}
                                                                                                    value={measureValues[relativeIndex]?.[index] || ''}
                                                                                                    onChange={(e) => handleInputChange(e, relativeIndex, index)}
                                                                                                    inputMode="decimal"
                                                                                                    pattern="^-?[0-9]*[.,]?[0-9]+$"
                                                                                                    onKeyDown={(e) => {
                                                                                                        if (e.key === 'Enter') {
                                                                                                            e.preventDefault(); 
                                                                                                            handleMeasureChange(e, gauge, index, relativeIndex); 
                                                                                                        }
                                                                                                    }}
                                                                                                    onBlur={(e) => handleMeasureChange(e, gauge, index, relativeIndex)} 
                                                                                                />
                                                                                                <span className={`mod-form__num-unit ${screenType}`}>
                                                                                                    {gauge.unit}
                                                                                                </span>

                                                                                                <input type="hidden" name={`diff_flg${index}`} value={gauge.diffFlg || ''} />
                                                                                                <input type="hidden" name={`measure_id${index}`} value={gauge.measure_id || ''} />
                                                                                                <input type="hidden" name={`decimal_value${index}`} value={gauge.decimal_value || ''} />
                                                                                                <input type="hidden" name={`min_limit${index}`} value={gauge.min_limit || ''} />
                                                                                                <input type="hidden" name={`min_limit_flg${index}`} value={gauge.min_limit_flg || ''} />
                                                                                                <input type="hidden" name={`max_limit${index}`} value={gauge.max_limit || ''} />
                                                                                                <input type="hidden" name={`max_limit_flg${index}`} value={gauge.max_limit_flg || ''} />
                                                                                                <input type="hidden" name={`diff_type${index}`} value={gauge.diff_type || ''} />
                                                                                                <input type="hidden" name={`diff_val${index}`} id={`newdv_0_${index}`} value={gauge.diff_val || ''} />
                                                                                                <input type="hidden" name={`diff_min_limit${index}`} value={gauge.diff_min_limit || ''} />
                                                                                                <input type="hidden" name={`diff_min_limit_flg${index}`} value={gauge.diff_min_limit_flg || ''} />
                                                                                                <input type="hidden" name={`diff_max_limit${index}`} value={gauge.diff_max_limit || ''} />
                                                                                                <input type="hidden" name={`diff_max_limit_flg${index}`} value={gauge.diff_max_limit_flg || ''} />
                                                                                            </div>
                                                                                        </>
                                                                                    )}
                                                                                    <div className={`lay-list__horizontal__para__num ${screenType} diff`}  style={{
                                                                                        display: item.disuse_flg === 0 && gauge.diffFlg === 1 ? 'block' : 'none',
                                                                                        color: getValueColor(
                                                                                            gauge.diffFlg,
                                                                                            gauge.threshold_min_flg,
                                                                                            gauge.threshold_max_flg,
                                                                                            gauge.diff_threshold_min_flg,
                                                                                            gauge.diff_threshold_max_flg
                                                                                        ),
                                                                                    }}title={`${gauge.diffVal}`}>
                                                                                        <span className={`lay-list__horizontal__para__text ${screenType}`}>差分値：</span>
                                                                                        <span id={`dv_${relativeIndex}_${index}`}
                                                                                            style={{
                                                                                                color: getValueColor(
                                                                                                    gauge.diffFlg,
                                                                                                    gauge.threshold_min_flg,
                                                                                                    gauge.threshold_max_flg,
                                                                                                    gauge.diff_threshold_min_flg,
                                                                                                    gauge.diff_threshold_max_flg
                                                                                                ),
                                                                                        }}>
                                                                                            {formatDiffVal(gauge.diffVal, gauge.decimal_value) || gauge.displayDiffVal || '--'}
                                                                                        </span>
                                                                                        <span className={`lay-list__horizontal__para__num-unit ${screenType}`}>{gauge.unit}</span>
                                                                                    </div>
                                                                                </>
                                                                            )}
                                                                        </React.Fragment>
                                                                    ))}
                                                                </div>
                                                            )}
                                                            <div className={`lay-list__horizontal__comment ${screenType}`}>
                                                                <div className={`lay-list__horizontal__comment__title ${screenType}`}>コメント入力欄</div>
                                                                <div className={`mod-form__textarea ${screenType}`}>
                                                                    {(gaugeDataList.some(gauge => gauge.measureVal) || item.status_class === 1 || failure_id === 1) ? (
                                                                        <>
                                                                            <textarea
                                                                                id={`edit_comment_lbl${relativeIndex}`}
                                                                                name="comment"
                                                                                style={{ fontSize: `${fontSizes[relativeIndex] || item.comment_font_size}pt`, display: 'block' }}
                                                                                value={comments[relativeIndex] !== undefined ? comments[relativeIndex] : item.comment || ''}
                                                                                onChange={(e) => handleCommentChange(relativeIndex, e)}
                                                                                readOnly={true}
                                                                            ></textarea>
                                                                            <textarea
                                                                                id={`edit_comment${relativeIndex}`}
                                                                                name="comment"
                                                                                style={{ fontSize: `${fontSizes[relativeIndex] || item.comment_font_size}pt`, display: 'none' }}
                                                                                value={comments[relativeIndex] !== undefined ? comments[relativeIndex] : item.comment || ''}
                                                                                onChange={(e) => handleCommentChange(relativeIndex, e)}
                                                                            ></textarea>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <textarea
                                                                                id={`edit_comment_lbl${relativeIndex}`}
                                                                                name="comment"
                                                                                style={{ fontSize: `${fontSizes[relativeIndex] || item.comment_font_size}pt`, display: 'none' }}
                                                                                value={comments[relativeIndex] !== undefined ? comments[relativeIndex] : item.comment || ''}
                                                                                onChange={(e) => handleCommentChange(relativeIndex, e)}
                                                                                readOnly={true}
                                                                            ></textarea>
                                                                            <textarea
                                                                                id={`edit_comment${relativeIndex}`}
                                                                                name="comment"
                                                                                style={{ fontSize: `${fontSizes[relativeIndex] || item.comment_font_size}pt`, display: 'block' }}
                                                                                value={comments[relativeIndex] !== undefined ? comments[relativeIndex] : item.comment || ''}
                                                                                onChange={(e) => handleCommentChange(relativeIndex, e)}
                                                                            ></textarea>
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            {(gaugeDataList.some(gauge => gauge.measureVal) || item.status_class === 1) && (
                                                                <div className={`lay-list__horizontal_slider ${screenType}`}>
                                                                    <label>
                                                                        <span id={`leftLabel${relativeIndex}`} style={{ fontSize: '12pt', display: 'none' }}>A</span>
                                                                        <input
                                                                            type="range"
                                                                            className="slider"
                                                                            id={`size_edit${relativeIndex}`}
                                                                            name="comment_font_size"
                                                                            min="6"
                                                                            max="18"
                                                                            style={{ display: 'none' }}
                                                                            value={fontSizes[relativeIndex] || item.comment_font_size || ''}
                                                                            step="1"
                                                                            onChange={(e) => handleFontSizeChange(relativeIndex, e.target.value)}
                                                                        />
                                                                        <span id={`rightLabel${relativeIndex}`} style={{ fontSize: '18pt', display: 'none' }}>A</span>
                                                                    </label>
                                                                </div>
                                                            )}
                                                            {failure_id !== 1 && (
                                                                (gaugeDataList.some(gauge => gauge.measureVal) || item.status_class === 1) ? (
                                                                    <div className={`lay-list__horizontal__btn ${screenType}`}>
                                                                        <button
                                                                            type="button"
                                                                            className="mod-btn__roundborder small"
                                                                            id={`edit_btn${relativeIndex}`}
                                                                            onClick={() => fnEditData(relativeIndex, item, gaugeDataList)}
                                                                        >
                                                                            編集
                                                                        </button>
                                                                        <DataEdit
                                                                            relativeIndex={relativeIndex}
                                                                            fetchImageList={fetchImageList}
                                                                            selectedItem={selectedItem}
                                                                            startDate={startDate}
                                                                            endDate={endDate}
                                                                            selectedFacility={selectedFacility}
                                                                            selectedCamera={selectedCamera}
                                                                            selectedGauge={selectedGauge}
                                                                            comments={comments}
                                                                            fontSizes={fontSizes}
                                                                            setComments={setComments}
                                                                            setFontSizes={setFontSizes}
                                                                            setLoading={setLoading}
                                                                            isPopup={false}
                                                                            screenType={screenType}
                                                                            editStatus={editStatus}
                                                                            setEditStatus={setEditStatus}
                                                                            setRelativeActive={setRelativeActive}
                                                                        />
                                                                        <DataDelete
                                                                            relativeIndex={relativeIndex}
                                                                            fetchImageList={fetchImageList}
                                                                            selectedItem={selectedItem}
                                                                            startDate={startDate}
                                                                            endDate={endDate}
                                                                            selectedFacility={selectedFacility}
                                                                            selectedCamera={selectedCamera}
                                                                            selectedGauge={selectedGauge}
                                                                            comments={comments}
                                                                            setComments={setComments}
                                                                            setLoading={setLoading}
                                                                            setMeasureValues={setMeasureValues}
                                                                            screenType={screenType}
                                                                            editStatus={editStatus}
                                                                            setEditStatus={setEditStatus}
                                                                            setRelativeActive={setRelativeActive}
                                                                        />
                                                                    </div>
                                                                ) : (
                                                                    <div className={`lay-list__horizontal__btn ${screenType}`}>
                                                                        <DataRegister
                                                                            relativeIndex={relativeIndex}
                                                                            fetchImageList={fetchImageList}
                                                                            selectedItem={selectedItem}
                                                                            startDate={startDate}
                                                                            endDate={endDate}
                                                                            selectedFacility={selectedFacility}
                                                                            selectedCamera={selectedCamera}
                                                                            selectedGauge={selectedGauge}
                                                                            comments={comments}
                                                                            setComments={setComments}
                                                                            setLoading={setLoading}
                                                                            isPopup={false}
                                                                            screenType={screenType}
                                                                            editStatus={editStatus}
                                                                            setEditStatus={setEditStatus}
                                                                            setRelativeActive={setRelativeActive}
                                                                        />
                                                                    </div>
                                                                )
                                                            )}
                                                        </div>
                                                        
                                                        <input type="hidden" name="company_id" value={item.company_id || ''} />
                                                        <input type="hidden" name="gauge_id" value={item.gauge_id || ''} />
                                                        <input type="hidden" name="device_id" value={item.device_id || ''} />
                                                        <input type="hidden" name="get_datetime" value={item.get_datetime || ''} />
                                                        <input type="hidden" name="this_count" value={relativeIndex || ''} />
                                                        <input type="hidden" name="tot_count" value={pageData.length || ''} />
                                                        <input type="hidden" name="page_cnt" value={page || ''} />
                                                        <input type="hidden" name="cnt" value={gaugeDataList.length || ''} />
                                                    </form>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            ))
                        )}
                    </div>
                );
            })}
        </div>
    );
};

ImageList.propTypes = {
    pagesInCurrentGroup: PropTypes.array.isRequired,
    displayedData: PropTypes.array.isRequired,
    currentPage: PropTypes.number.isRequired,
    buttonsPerGroup: PropTypes.number.isRequired,
    itemsPerPage: PropTypes.number.isRequired,
    gaugeData: PropTypes.object.isRequired,
    getGaugeData: PropTypes.func.isRequired,
    selectedItem: PropTypes.object.isRequired,
    startDate: PropTypes.instanceOf(Date).isRequired,
    endDate: PropTypes.instanceOf(Date).isRequired,
    selectedFacility: PropTypes.string.isRequired,
    selectedCamera: PropTypes.string.isRequired,
    selectedGauge: PropTypes.string.isRequired,
    comments: PropTypes.object.isRequired,
    setComments: PropTypes.func.isRequired,
    fontSizes: PropTypes.object.isRequired,
    setFontSizes: PropTypes.func.isRequired,
    fetchImageList: PropTypes.func.isRequired,
    setLoading: PropTypes.func.isRequired,
    fnEditData: PropTypes.func.isRequired,
    handleCommentChange: PropTypes.func.isRequired,
    handleFontSizeChange: PropTypes.func.isRequired,
    openPopup: PropTypes.func.isRequired,
    checkboxStates: PropTypes.object.isRequired, 
    handleCheckboxChange: PropTypes.func.isRequired, 
};

export default ImageList;