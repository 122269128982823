import React from 'react';
import { useNavigate } from 'react-router-dom';

const GraphSection = ({ activeItem, setActiveItem, activeSet, setActiveSet, setIsMenuOpen, screenType }) => {
    const navigate = useNavigate();

    // Helper function to close menu on mobile devices
    const closeMenuOnMobile = () => {
        if (screenType === 'tablet') {
            setIsMenuOpen(false);
        }
    };

    const handleBatteryGraph = () => {
        setActiveItem({ id: 'battery_graph' });
        setActiveSet('graph');
        navigate('/batterygraphlist');
        closeMenuOnMobile();
    };

    const handleComparisonGraph = () => {
        setActiveItem({ id: 'comparison_graph' });
        setActiveSet('graph');
        navigate('/comparisongraphlist');
        closeMenuOnMobile();
    };

    const handleTransitionGraph = () => {
        setActiveItem({ id: 'transition_graph' });
        setActiveSet('graph');
        navigate('/transitiongraphlist');
        closeMenuOnMobile();
    };


    return (
        <div id="graph" className={`lay-sidemenu__set ${screenType} ${activeSet === 'graph' ? 'active-sidemenu' : ''}`}>
            <div className="lay-sidemenu__set__title graph menu-master">
                <img src="img/common/navi/icon-graph.png" alt="Graph Icon" className="menu-master-icon" />
                グラフ
            </div>
            <ul className="submenu-graph">
                <li id='li_transition_graph'>
                    <button
                        className={`name ${activeItem && activeItem.id === 'transition_graph' ? 'active' : ''}`}
                        onClick={() => {
                            setActiveItem({ id: 'transition_graph' });
                            setActiveSet('graph');
                            handleTransitionGraph();
                        }}>
                        データ推移
                    </button>
                </li>
                <li id='li_comparison_graph'>
                    <button 
                        className={`name ${activeItem && activeItem.id === 'comparison_graph' ? 'active' : ''}`}
                        onClick={() => {
                            setActiveItem({ id: 'comparison_graph' });
                            setActiveSet('graph');
                            handleComparisonGraph();
                        }}>
                        データ比較
                    </button>
                </li>
                <li id='li_battery_graph'>
                    <button className={`name ${activeItem && activeItem.id === 'battery_graph' ? 'active' : ''}`}
                        onClick={() => {
                            setActiveItem({ id: 'battery_graph' });
                            setActiveSet('graph');
                            handleBatteryGraph();
                        }}>
                        カメラバッテリー残量
                    </button>
                </li>
            </ul>
        </div>
    );
};

export default GraphSection;
