import { connect } from 'react-redux';
import CameraNew from '../components/CameraNew';
import { registerCamera, fetchCameraList } from '../actions/new';

const mapStateToProps = (state) => ({
    user: state.login.user,
    cameraList: state.cameraNew.cameraList,
    loading: state.cameraNew.loading,
    error: state.cameraNew.error
});

const mapDispatchToProps = {
    registerCamera,
    fetchCameraList  
};

export default connect(mapStateToProps, mapDispatchToProps)(CameraNew);
