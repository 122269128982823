import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ConfirmFirstMonth from '../components/ConfirmFirstMonth';
import { fetchConfirmFirstMonth } from '../actions/confirmfirstmonth';

const mapStateToProps = (state) => {
    const { confirmFirstMonth } = state;
    return {
        data: confirmFirstMonth.data,
        loading: confirmFirstMonth.loading,
        error: confirmFirstMonth.error,
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchConfirmFirstMonth,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmFirstMonth);