import ActionTypes from '../constants/ActionTypes'; 

const initialState = {
    loading: false,
    data: {},
    error: null,
    dataCount: null
};

const cameraChangeReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.FETCH_CAMERA_CHANGE_DATA_REQUEST:
            return { ...state, loading: true };
        case ActionTypes.FETCH_CAMERA_CHANGE_DATA_SUCCESS:
            return { ...state, loading: false, data: action.payload};
        case ActionTypes.FETCH_CAMERA_CHANGE_DATA_FAILURE:
            return { ...state, loading: false, error: action.payload };
        case ActionTypes.FETCH_GET_GAUGE_DATA_SUCCESS:
            return { ...state, loading: false, dataCount: action.payload};
        case ActionTypes.FETCH_GET_GAUGE_DATA_FAILURE:
            return { ...state, loading: false, error: action.payload };
        case ActionTypes.CAMERA_CHANGE_MAIL_SEND_REQUEST:
            return { ...state, loading: true };
        case ActionTypes.CAMERA_CHANGE_MAIL_SEND_SUCCESS:
            return { ...state, loading: false, message: action.payload};
        case ActionTypes.CAMERA_CHANGE_MAIL_SEND_FAILURE:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};

export default cameraChangeReducer;
