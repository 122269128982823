import ActionTypes from '../constants/ActionTypes';
import axiosInstance from '../helpers/refreshTokenMiddleware';
import BASE_URL from '../constants/Api';
import { format } from 'date-fns';

//Fetch data filter searchlist
export const fetchFacilitiesRequest = () => ({
    type: ActionTypes.FETCH_FACILITIES_REQUEST,
});

export const fetchFacilitiesSuccess = (data) => ({
    type: ActionTypes.FETCH_FACILITIES_SUCCESS,
    payload: data,
});

export const fetchFacilitiesFailure = (error) => ({
    type: ActionTypes.FETCH_FACILITIES_FAILURE,
    payload: error,
});

export const fetchFacilities = (companyId, failureId = 0) => async (dispatch) => {
    dispatch(fetchFacilitiesRequest());
    try {
        const response = await axiosInstance.post(BASE_URL + '/top/searchlist', { company_id: Number(companyId), failure_id: failureId });
        dispatch(fetchFacilitiesSuccess(response.data.respons));
    } catch (error) {
        const errorMessage = error.response && error.response.status === 504 
            ? '504 ゲートウェイタイムアウト。\n後でもう一度お試しください。' 
            : error.message;
        dispatch(fetchFacilitiesFailure(errorMessage));
    }
};

//Fetch ImageList and DataList
export const fetchImageListRequest = () => ({
    type: ActionTypes.FETCH_IMAGE_LIST_REQUEST,
});

export const fetchImageListSuccess = (data, gaugeData, failure_id, isEmpty, new_start_date) => ({
    type: ActionTypes.FETCH_IMAGE_LIST_SUCCESS,
    payload: { data, gaugeData , failure_id, isEmpty, new_start_date},
});


export const fetchImageListFailure = (error) => ({
    type: ActionTypes.FETCH_IMAGE_LIST_FAILURE,
    payload: error,
});

export const fetchImageList = (
    companyId,
    companyName,
    startDate,
    endDate,
    pageGroupNumber,
    facilityId = null,
    deviceId = null,
    gaugeId = null,
    status = null,
    searchComment = null,
    threshold = null,
    failureId = 0,
    newStartDate = null
) => async (dispatch) => {
    dispatch(fetchImageListRequest());
    try {
        const payload = {
            company_id: Number(companyId),
            company_name: companyName,
            failure_id: failureId,
            start_date: format(startDate, 'yyyy/MM/dd'),
            end_date: format(endDate, 'yyyy/MM/dd'),
            page_group_number: pageGroupNumber
        };

        if (facilityId) payload.facility_id = Number(facilityId);
        if (deviceId) payload.device_id = deviceId;
        if (gaugeId) payload.gauge_id = gaugeId;
        if (status !== null && status !== '') payload.status = status;
        if (searchComment !== null && searchComment !== '') payload.search_comment = searchComment;
        if (threshold !== null && threshold !== '') payload.threshold = threshold;
        if (pageGroupNumber > 1 && newStartDate) {
            payload.new_start_date = format(newStartDate, 'yyyy/MM/dd');
        }
        const response = await axiosInstance.post(BASE_URL + '/top/datamanage', payload);
        const data = response?.data?.respons?.data || [];
        const gaugeData = response?.data?.respons?.gauge_data || {};
        const failure_id = response?.data?.respons?.failure_id;
        const new_start_date = response?.data?.respons?.new_start_date;
        const isEmpty = Array.isArray(data) && data.length === 0;
        
        dispatch(fetchImageListSuccess(data, gaugeData, failure_id, isEmpty, new_start_date));
    } catch (error) {
        const errorMessage = error.response && error.response.status === 504 
            ? '504 ゲートウェイタイムアウト。\n後でもう一度お試しください。' 
            : error.message;
        dispatch(fetchImageListFailure(errorMessage));
    }
};

//Fetch count data
export const fetchCountRequest = () => ({
    type: ActionTypes.FETCH_COUNT_REQUEST,
});

export const fetchCountSuccess = (count) => ({
    type: ActionTypes.FETCH_COUNT_SUCCESS,
    payload: count,
});

export const fetchCountFailure = (error) => ({
    type: ActionTypes.FETCH_COUNT_FAILURE,
    payload: error,
});

export const fetchCount = (
    companyId,
    companyName,
    startDate,
    endDate,
    facilityId = null,
    deviceId = null,
    gaugeId = null,
    status = null,
    searchComment = null,
    threshold = null,
    failureId = 0
) => async (dispatch) => {
    dispatch(fetchCountRequest());
    try {
        const payload = {
            company_id: Number(companyId),
            company_name: companyName,
            failure_id: failureId,
            start_date: format(startDate, 'yyyy/MM/dd'),
            end_date: format(endDate, 'yyyy/MM/dd')
        };

        if (facilityId) payload.facility_id = Number(facilityId);
        if (deviceId) payload.device_id = deviceId;
        if (gaugeId) payload.gauge_id = gaugeId;
        if (status !== null && status !== '') payload.status = status;
        if (searchComment !== null && searchComment !== '') payload.search_comment = searchComment;
        if (threshold !== null && threshold !== '') payload.threshold = threshold;

        const response = await axiosInstance.post(BASE_URL + '/top/datamanage-count', payload);
        const count = response.data.respons.count;

        dispatch(fetchCountSuccess(count));
        return count;
    } catch (error) {
        const errorMessage = error.response && error.response.status === 504 
            ? '504 ゲートウェイタイムアウト。\n後でもう一度お試しください。' 
            : error.message;
        dispatch(fetchCountFailure(errorMessage));
    }
};

export const setSelectedItem = (item) => ({
    type: ActionTypes.SET_SELECTED_ITEM,
    payload: item,
});

//Data register
export const dataRegisterRequest = () => ({
    type: ActionTypes.DATA_REGISTER_REQUEST,
});

export const dataRegisterSuccess = (data) => ({
    type: ActionTypes.DATA_REGISTER_SUCCESS,
    payload: data
});

export const dataRegisterFailure = (error) => ({
    type: ActionTypes.DATA_REGISTER_FAILURE,
    payload: error,
});

export const dataRegister = (data) => async (dispatch) => {
    dispatch(dataRegisterRequest());
    try {
        const response = await axiosInstance.post(`${BASE_URL}/top/data-regist`, data);
        if (response.status === 200) {
            dispatch(dataRegisterSuccess(response.data));
            return response.data;
        } else {
            dispatch(dataRegisterFailure('Failed to register data'));
            return response.data;
        }
    } catch (error) {
        const errorMessage = error.response && error.response.status === 504
            ? '504 ゲートウェイタイムアウト。\n後でもう一度お試しください。'
            : error.message;
        dispatch(dataRegisterFailure(errorMessage));
    }
};

//Data edit
export const dataEditRequest = () => ({
    type: ActionTypes.DATA_EDIT_REQUEST,
});

export const dataEditSuccess = (data) => ({
    type: ActionTypes.DATA_EDIT_SUCCESS,
    payload: data,
});

export const dataEditFailure = (error) => ({
    type: ActionTypes.DATA_EDIT_FAILURE,
    payload: error,
});

export const dataEdit = (data) => async (dispatch) => {
    dispatch(dataEditRequest());
    try {
        const response = await axiosInstance.post(`${BASE_URL}/top/data-edit`, data);
        if (response.status === 200) {
            dispatch(dataEditSuccess(response.data));
            return response.data;
        } else {
            dispatch(dataEditFailure('Failed to edit data'));
        }
    } catch (error) {
        const errorMessage = error.response && error.response.status === 504
            ? '504 ゲートウェイタイムアウト。\n後でもう一度お試しください。'
            : error.message;
        dispatch(dataEditFailure(errorMessage));
    }
};

// Data delete
export const dataDeleteRequest = () => ({
    type: ActionTypes.DATA_DELETE_REQUEST,
});

export const dataDeleteSuccess = (data) => ({
    type: ActionTypes.DATA_DELETE_SUCCESS,
    payload: data,
});

export const dataDeleteFailure = (error) => ({
    type: ActionTypes.DATA_DELETE_FAILURE,
    payload: error,
});

export const dataDelete = (data) => async (dispatch) => {
    dispatch(dataDeleteRequest());
    try {
        const response = await axiosInstance.post(`${BASE_URL}/top/data-delete`, data);
        if (response.status === 200) {
            dispatch(dataDeleteSuccess(response.data));
            return response.data;
        } else {
            dispatch(dataDeleteFailure('Failed to delete data'));
            return null;
        }
    } catch (error) {
        const errorMessage = error.response && error.response.status === 504
            ? '504 ゲートウェイタイムアウト。\n後でもう一度お試しください。'
            : error.message;
        dispatch(dataDeleteFailure(errorMessage));
        return null;
    }
};

//Fetch Camera Info
export const fetchCameraInfoRequest = () => ({
    type: ActionTypes.FETCH_CAMERA_INFO_REQUEST,
});

export const fetchCameraInfoSuccess = (camera_info) => ({
    type: ActionTypes.FETCH_CAMERA_INFO_SUCCESS,
    payload: camera_info,
});

export const fetchCameraInfoFailure = (error) => ({
    type: ActionTypes.FETCH_CAMERA_INFO_FAILURE,
    payload: error,
});

export const clearCameraInfo = () => ({
    type: ActionTypes.CLEAR_CAMERA_INFO,
});

export const fetchCameraInfo = (
    companyId,
    deviceId
) => async (dispatch) => {
    dispatch(fetchCameraInfoRequest());
    try {      
        if( !companyId || !deviceId) return;
                    
        const payload = {
            company_id: Number(companyId),
            device_id:  deviceId
        };

        const response = await axiosInstance.post(BASE_URL + '/top/camera-info', payload);
          
        const cameraInfo = response?.data?.respons;
        dispatch(fetchCameraInfoSuccess(cameraInfo));
        
    } catch (error) {
        const errorMessage = error.response && error.response.status === 504 
            ? '504 ゲートウェイタイムアウト。\n後でもう一度お試しください。' 
            : error.message;
        dispatch(fetchCameraInfoFailure(errorMessage));
    }
};

//遠隔撮影
export const photoShootRequest = () => ({
    type: ActionTypes.PHOTO_SHOOT_REQUEST,
});

export const photoShootSuccess = (data) => ({
    type: ActionTypes.PHOTO_SHOOT_SUCCESS,
    payload: data,
});

export const photoShootFailure = (error) => ({
    type: ActionTypes.PHOTO_SHOOT_FAILURE,
    payload: error,
});

export const onPhotoShoot = (
    companyId,    
    cameraId,
    deviceId
) => async (dispatch) => {
    dispatch(photoShootRequest());
    try { 
        const payload = { 
            company_id: Number(companyId),     
            camera_id: cameraId,
            device_id: deviceId
        };

        const response = await axiosInstance.post(`${BASE_URL}/top/photo-shoot`, payload);
        if (response.status === 200) {
            const photoshoot_info = response?.data?.respons.photoshoot_info;
            dispatch(photoShootSuccess(photoshoot_info));
            return response.data;
        } else {
            dispatch(photoShootFailure('Failed to execute photo shoot'));
        }
    } catch (error) {
        const errorMessage = error.response && error.response.status === 504
            ? '504 ゲートウェイタイムアウト。\n後でもう一度お試しください。'
            : error.message;
        dispatch(photoShootFailure(errorMessage));
    }
};

//遠隔撮影ステータスを取得
export const fetchPhotoShootStatusRequest = () => ({
    type: ActionTypes.FETCH_PHOTO_SHOOT_STATUS_REQUEST,
});

export const fetchPhotoShootStatusSuccess = (status) => ({
    type: ActionTypes.FETCH_PHOTO_SHOOT_STATUS_SUCCESS,
    payload: status,
});

export const fetchPhotoShootStatusFailure = (error) => ({
    type: ActionTypes.FETCH_PHOTO_SHOOT_STATUS_FAILURE,
    payload: error,
});

export const fetchPhotoShootStatus = (
    cameraId,
    taskId
) => async (dispatch) => {
    dispatch(fetchPhotoShootStatusRequest());
    try {      
        if( !cameraId || !taskId) return;
                    
        const payload = {
            camera_id: cameraId,
            task_id:  taskId
        };

        const response = await axiosInstance.post(
            BASE_URL + '/top/photo-shoot-status', 
            payload
        );

        console.log('action - fetchPhotoShootStatus() - response:', response);

        const status = response?.data?.respons;        
        dispatch(fetchPhotoShootStatusSuccess(status));
        
    } catch (error) {
        const errorMessage = error.response && error.response.status === 504 
            ? '504 ゲートウェイタイムアウト。\n後でもう一度お試しください。' 
            : error.message;
        dispatch(fetchCameraInfoFailure(errorMessage));
    }
};

//遠隔撮影結果をシステムへ更新
export const photoShootUpdateRequest = () => ({
    type: ActionTypes.PHOTO_SHOOT_UPDATE_REQUEST,
});

export const photoShootUpdateSuccess = (data) => ({
    type: ActionTypes.PHOTO_SHOOT_UPDATE_SUCCESS,
    payload: data,
});

export const photoShootUpdateFailure = (error) => ({
    type: ActionTypes.PHOTO_SHOOT_UPDATE_FAILURE,
    payload: error,
});

export const onPhotoShootUpdate = (
    companyId,    
    cameraId,
    deviceId,
    gaugeId
) => async (dispatch) => {
    dispatch(photoShootUpdateRequest());
    try { 
        const payload = { 
            company_id: companyId,     
            camera_id: cameraId,
            device_id: deviceId,
            gauge_id: gaugeId
        };

        const response = await axiosInstance.post(`${BASE_URL}/top/photo-shoot-update`, payload);
        if (response.status === 200) {
            const result = response?.data?.respons;
            dispatch(photoShootUpdateSuccess(result));
            return response.data;
        } else {
            dispatch(photoShootUpdateFailure('Failed to update photo shoot'));
        }
    } catch (error) {
        const errorMessage = error.response && error.response.status === 504
            ? '504 ゲートウェイタイムアウト。\n後でもう一度お試しください。'
            : error.message;
        dispatch(photoShootUpdateFailure(errorMessage));
    }
};

export const setRelativeActive = (index) => ({
    type: ActionTypes.SET_RELATIVE_ACTIVE,
    payload: index,
});
