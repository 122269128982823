import ActionTypes from '../constants/ActionTypes'; 

const initialState = {
    loading: false,
    facilities: [],
    error: null,
};

const facilityReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.FETCH_FACILITY_REQUEST:
            return { ...state, loading: true };
        case ActionTypes.FETCH_FACILITY_SUCCESS:
            return { ...state, loading: false, facilities: action.payload.data};
        case ActionTypes.FETCH_FACILITY_FAILURE:
            return { ...state, loading: false, error: action.payload };

        case ActionTypes.REPLACE_FACILITY_REQUEST:
            return { ...state, loading: true};
        case ActionTypes.REPLACE_FACILITY_SUCCESS:
            return { ...state, loading: false, message: action.payload }; 
        case ActionTypes.REPLACE_FACILITY_FAILURE:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};

export default facilityReducer;
