import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Setting2FA from '../components/Setting2FA';
import { updateSetting2FA } from '../actions/setting2fa';
import { fetchUserData } from '../actions/user';

const mapStateToProps = (state) => ({
    loading: state.setting2FA.loading,
    error: state.setting2FA.error,
    mail2FA: state.setting2FA.mail2FA,
    using2FAflg: state.setting2FA.using2FAflg,
    user: state.login.user,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateSetting2FA,
    fetchUserData
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Setting2FA);