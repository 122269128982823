import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

const SettingsSection = ({ logout = () => {}, activeItem, setActiveItem, isUserInfoOpen, toggleSubmenu, activeSet, setActiveSet, setIsMenuOpen, screenType, isManagementCompany, setIsUserInfoOpen }) => {
    const navigate = useNavigate();

    const navigateToSetting = (screen) => {
        let path = '';
        let activeItem = null;

        switch (screen) {
            case 'mailSendSetting':
                path = '/mailSendSetting';
                activeItem = { id: 'mailSendSetting', name: 'メール送信設定' };
                break;
            case 'confirmFirstMonth':
                path = '/confirmFirstMonth';
                activeItem = { id: 'confirmFirstMonth', name: '利用開始月の確認' };
                break;
            case 'confirmCameraCount':
                path = '/confirmCameraCount';
                activeItem = { id: 'confirmCameraCount', name: '利用カメラ台数の確認' };
                break;
            case 'userNameChange':
                path = '/userNameChange';
                activeItem = { id: 'userNameChange', name: 'ユーザ名の変更' };
                break;
            case 'idPasswordChange':
                path = '/idPasswordChange';
                activeItem = { id: 'idPasswordChange', name: 'ID/PWの変更' };
                break;
            case 'setting2FA':
                path = '/setting2FA';
                activeItem = { id: 'setting2FA', name: '二段階認証設定' };
                break;
            case 'cameraChange':
                path = '/cameraChange';
                activeItem = { id: 'cameraChange', name: 'カメラ移動連絡' };
                break;
            case 'loginHistory':
                path = '/loginHistory';
                activeItem = { id: 'loginHistory', name: 'ログイン履歴' };
                break;
            default:
                return;
        }
        
        if (activeItem) {
            setActiveItem(activeItem);
        }
        setActiveSet('settings');
        navigate(path);

        if (screenType === 'tablet') {
            setIsMenuOpen(false);
        }
    };

    useEffect(() => {
        if (
            activeItem &&
            [
                'mailSendSetting',
                'confirmFirstMonth',
                'confirmCameraCount',
                'userNameChange',
                'idPasswordChange',
                'setting2FA',
                'cameraChange',
                'loginHistory',
            ].includes(activeItem.id)
        ) {
            // Ensure the submenu is open when an active item exists
            setIsUserInfoOpen(true);
        }
    }, [activeItem, setIsUserInfoOpen]);

    return (
        <div id="settings" className={`lay-sidemenu__set ${screenType} ${activeSet === 'settings' ? 'active-sidemenu' : ''}`}>
            <div className="lay-sidemenu__set__title setting menu-master">
                <img src="img/common/navi/icon-setting.png" alt="Settings Icon" className="menu-master-icon" />
                設定
            </div>
            <ul className="menu-item">
                <li id="li_set_user_information" className='menu-item__setting'>
                    <div className={`ac-link ${isUserInfoOpen ? 'clicked' : ''}`} onClick={toggleSubmenu}></div>
                    <span>ユーザ情報</span>
                </li>
                <AnimatePresence>
                    {isUserInfoOpen && (
                        <motion.ul
                            className='menu-item__setting-sub'
                            initial={{ height: 0, opacity: 0 }}
                            animate={{ height: 'auto', opacity: 1 }}
                            exit={{ height: 0, opacity: 0 }}
                            transition={{ duration: 0.3 }}
                        >
                            <li id="li_setting_mail_send">
                                <img src="img/common/navi/icon-data.png" alt="Mail Icon" className="menu-item-icon" />
                                <button 
                                    className={`name ${activeItem && activeItem.id === 'mailSendSetting' ? 'active' : ''}`} 
                                    onClick={() => navigateToSetting('mailSendSetting')}>メール送信設定
                                </button>
                            </li>
                            <li id="li_setting_first_month">
                                <img src="img/common/navi/icon-data.png" alt="Start Date Icon" className="menu-item-icon" />
                                <button 
                                    className={`name ${activeItem && activeItem.id === 'confirmFirstMonth' ? 'active' : ''}`} 
                                    onClick={() => navigateToSetting('confirmFirstMonth')}>利用開始月の確認
                                </button>
                            </li>
                            <li id="li_setting_camera_count">
                                <img src="img/common/navi/icon-data.png" alt="Camera Count Icon" className="menu-item-icon" />
                                <button 
                                    className={`name ${activeItem && activeItem.id === 'confirmCameraCount' ? 'active' : ''}`} 
                                    onClick={() => navigateToSetting('confirmCameraCount')}>利用カメラ台数の確認
                                </button>
                            </li>
                            <li id="li_setting_user_name">
                                <img src="img/common/navi/icon-data.png" alt="User Change Icon" className="menu-item-icon" />
                                <button 
                                    className={`name ${activeItem && activeItem.id === 'userNameChange' ? 'active' : ''}`} 
                                    onClick={() => navigateToSetting('userNameChange')}>ユーザ名の変更
                                </button>
                            </li>
                            <li id="li_setting_password">
                                <img src="img/common/navi/icon-data.png" alt="ID/PW Icon" className="menu-item-icon" />
                                <button
                                    className={`name ${activeItem && activeItem.id === 'idPasswordChange' ? 'active' : ''}`} 
                                    onClick={() => navigateToSetting('idPasswordChange')}>ID/PWの変更
                                </button>
                            </li>
                            <li id="li_setting_2fa">
                                <img src="img/common/navi/icon-login.png" alt="Two Factor Auth Icon" className="menu-item-icon" />
                                <button 
                                    className={`name ${activeItem && activeItem.id === 'setting2FA' ? 'active' : ''}`} 
                                    onClick={() => navigateToSetting('setting2FA')}>二段階認証設定
                                </button>
                            </li>
                            <li id="li_setting_camera">
                                <img src="img/common/navi/icon-photo.png" alt="Camera Movement Icon" className="menu-item-icon" />
                                <button 
                                    className={`name ${activeItem && activeItem.id === 'cameraChange' ? 'active' : ''}`} 
                                    onClick={() => navigateToSetting('cameraChange')}>カメラ移動連絡
                                </button>
                            </li>
                            {isManagementCompany && (
                                <li id="li_setting_login_history">
                                    <img src="img/common/navi/icon-login.png" alt="Login History Icon" className="menu-item-icon" />
                                    <button 
                                        className={`name ${activeItem?.id === 'loginHistory' ? 'active' : ''}`} 
                                        onClick={() => navigateToSetting('loginHistory')}>
                                        ログイン履歴
                                    </button>
                                </li>
                            )}
                        </motion.ul>
                    )}
                </AnimatePresence>
            </ul>

            <ul className="logout">
                <li>
                    <button onClick={logout} className="logout-button">ログアウト</button>
                </li>
            </ul>
        </div>
    );
};

export default SettingsSection;
