// eslint-disable-next-line import/no-anonymous-default-export
export default {
    REQUEST_ACCESS_TOKEN: 'REQUEST_ACCESS_TOKEN',
    RECEIVE_ACCESS_TOKEN_SUCCESS: 'RECEIVE_ACCESS_TOKEN_SUCCESS',
    RECEIVE_ACCESS_TOKEN_FAILURE: 'RECEIVE_ACCESS_TOKEN_FAILURE',
    RECEIVE_OTP_TOKEN: 'RECEIVE_OTP_TOKEN',

    VERIFY_OTP_REQUEST: 'VERIFY_OTP_REQUEST',
    VERIFY_OTP_SUCCESS: 'VERIFY_OTP_SUCCESS',
    VERIFY_OTP_FAILURE: 'VERIFY_OTP_FAILURE',

    RESEND_OTP_REQUEST: 'RESEND_OTP_REQUEST',
    RESEND_OTP_SUCCESS: 'RESEND_OTP_SUCCESS',
    RESEND_OTP_FAILURE: 'RESEND_OTP_FAILURE',

    RESET_OTP_ATTEMPT_COUNT: 'RESET_OTP_ATTEMPT_COUNT',
    CLEAR_ERROR: 'CLEAR_ERROR',

    REFRESH_ACCESS_TOKEN_REQUEST: 'REFRESH_ACCESS_TOKEN_REQUEST',
    REFRESH_ACCESS_TOKEN_SUCCESS: 'REFRESH_ACCESS_TOKEN_SUCCESS',
    REFRESH_ACCESS_TOKEN_FAILURE: 'REFRESH_ACCESS_TOKEN_FAILURE',

    FETCH_USER_SUCCESS: 'FETCH_USER_SUCCESS',
    FETCH_USER_FAILURE: 'FETCH_USER_FAILURE',

    LOGOUT_REQUEST: 'LOGOUT_REQUEST',
    LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
    LOGOUT_FAILURE: 'LOGOUT_FAILURE',
    LOGOUT_USER: 'LOGOUT_USER',

    FETCH_SYSTEM_INFO_REQUEST: 'FETCH_SYSTEM_INFO_REQUEST',
    FETCH_SYSTEM_INFO_SUCCESS: 'FETCH_SYSTEM_INFO_SUCCESS',
    FETCH_SYSTEM_INFO_FAILURE: 'FETCH_SYSTEM_INFO_FAILURE',

    FETCH_FACILITIES_REQUEST: 'FETCH_FACILITIES_REQUEST',
    FETCH_FACILITIES_SUCCESS: 'FETCH_FACILITIES_SUCCESS',
    FETCH_FACILITIES_FAILURE: 'FETCH_FACILITIES_FAILURE',
    FETCH_IMAGE_LIST_REQUEST: 'FETCH_IMAGE_LIST_REQUEST',
    FETCH_IMAGE_LIST_SUCCESS: 'FETCH_IMAGE_LIST_SUCCESS',
    FETCH_IMAGE_LIST_FAILURE: 'FETCH_IMAGE_LIST_FAILURE',
    
    FETCH_COUNT_REQUEST: 'FETCH_COUNT_REQUEST',
    FETCH_COUNT_SUCCESS: 'FETCH_COUNT_SUCCESS',
    FETCH_COUNT_FAILURE: 'FETCH_COUNT_FAILURE',
    SET_SELECTED_ITEM: 'SET_SELECTED_ITEM',

    FETCH_COMPANY_SUCCESS: 'FETCH_COMPANY_SUCCESS',

    FETCH_BATTERY_GRAPH_LIST_REQUEST: 'FETCH_BATTERY_GRAPH_LIST_REQUEST',
    FETCH_BATTERY_GRAPH_LIST_SUCCESS: 'FETCH_BATTERY_GRAPH_LIST_SUCCESS',
    FETCH_BATTERY_GRAPH_LIST_FAILURE: 'FETCH_BATTERY_GRAPH_LIST_FAILURE',

    FETCH_BATTERY_GRAPH_DATA_REQUEST: 'FETCH_BATTERY_GRAPH_DATA_REQUEST',
    FETCH_BATTERY_GRAPH_DATA_SUCCESS: 'FETCH_BATTERY_GRAPH_DATA_SUCCESS',
    FETCH_BATTERY_GRAPH_DATA_FAILURE: 'FETCH_BATTERY_GRAPH_DATA_FAILURE',
    SET_SELECTED_CAMERA: 'SET_SELECTED_CAMERA',

    FETCH_SEARCHLIST_REQUEST: 'FETCH_SEARCHLIST_REQUEST',
    FETCH_SEARCHLIST_SUCCESS: 'FETCH_SEARCHLIST_SUCCESS',
    FETCH_SEARCHLIST_FAILURE: 'FETCH_SEARCHLIST_FAILURE',

    DATA_REGISTER_REQUEST: 'DATA_REGISTER_REQUEST',
    DATA_REGISTER_SUCCESS: 'DATA_REGISTER_SUCCESS',
    DATA_REGISTER_FAILURE: 'DATA_REGISTER_FAILURE',

    DATA_EDIT_REQUEST: 'DATA_EDIT_REQUEST',
    DATA_EDIT_SUCCESS: 'DATA_EDIT_SUCCESS',
    DATA_EDIT_FAILURE: 'DATA_EDIT_FAILURE',

    DATA_DELETE_REQUEST: 'DATA_DELETE_REQUEST',
    DATA_DELETE_SUCCESS: 'DATA_DELETE_SUCCESS',
    DATA_DELETE_FAILURE: 'DATA_DELETE_FAILURE',

    FETCH_COMPARISON_GRAPH_LIST_REQUEST: 'FETCH_COMPARISON_GRAPH_LIST_REQUEST',
    FETCH_COMPARISON_GRAPH_LIST_SUCCESS: 'FETCH_COMPARISON_GRAPH_LIST_SUCCESS',
    FETCH_COMPARISON_GRAPH_LIST_FAILURE: 'FETCH_COMPARISON_GRAPH_LIST_FAILURE',

    FETCH_COMPARISON_GRAPH_REQUEST: 'FETCH_COMPARISON_GRAPH_REQUEST',
    FETCH_COMPARISON_GRAPH_SUCCESS: 'FETCH_COMPARISON_GRAPH_SUCCESS',
    FETCH_COMPARISON_GRAPH_FAILURE: 'FETCH_COMPARISON_GRAPH_FAILURE',

    FETCH_COMPANIES_REQUEST : 'FETCH_COMPANIES_REQUEST',
    FETCH_COMPANIES_SUCCESS : 'FETCH_COMPANIES_SUCCESS',
    FETCH_COMPANIES_FAILURE : 'FETCH_COMPANIES_FAILURE',

    FETCH_MANAGEMENT_UNIT_REQUEST : 'FETCH_MANAGEMENT_UNIT_REQUEST',
    FETCH_MANAGEMENT_UNIT_SUCCESS : 'FETCH_MANAGEMENT_UNIT_SUCCESS',
    FETCH_MANAGEMENT_UNIT_FAILURE : 'FETCH_MANAGEMENT_UNIT_FAILURE',

    REGISTER_COMPANY_REQUEST : 'REGISTER_COMPANY_REQUEST',
    REGISTER_COMPANY_SUCCESS : 'REGISTER_COMPANY_SUCCESS',
    REGISTER_COMPANY_FAILURE : 'REGISTER_COMPANY_FAILURE',

    REPLACE_COMPANY_REQUEST : 'REPLACE_COMPANY_REQUEST',
    REPLACE_COMPANY_SUCCESS : 'REPLACE_COMPANY_SUCCESS',
    REPLACE_COMPANY_FAILURE : 'REPLACE_COMPANY_FAILURE',

    REGISTER_FACILITY_REQUEST : 'REGISTER_FACILITY_REQUEST',
    REGISTER_FACILITY_SUCCESS : 'REGISTER_FACILITY_SUCCESS',
    REGISTER_FACILITY_FAILURE : 'REGISTER_FACILITY_FAILURE',

    FETCH_FACILITY_REQUEST : 'FETCH_FACILITY_REQUEST',
    FETCH_FACILITY_SUCCESS : 'FETCH_FACILITY_SUCCESS',
    FETCH_FACILITY_FAILURE : 'FETCH_FACILITY_FAILURE',

    REPLACE_FACILITY_REQUEST : 'REPLACE_FACILITY_REQUEST',
    REPLACE_FACILITY_SUCCESS : 'REPLACE_FACILITY_SUCCESS',
    REPLACE_FACILITY_FAILURE : 'REPLACE_FACILITY_FAILURE',

    FACILITY_EDIT_REQUEST : 'FACILITY_EDIT_REQUEST',
    FACILITY_EDIT_SUCCESS : 'FACILITY_EDIT_SUCCESS',
    FACILITY_EDIT_FAILURE : 'FACILITY_EDIT_FAILURE',

    FACILITY_UPDATE_REQUEST : 'FACILITY_UPDATE_REQUEST',
    FACILITY_UPDATE_SUCCESS : 'FACILITY_UPDATE_SUCCESS',
    FACILITY_UPDATE_FAILURE : 'FACILITY_UPDATE_FAILURE',

    CHECK_GAUGE_DATA_REQUEST : 'CHECK_GAUGE_DATA_REQUEST',
    CHECK_GAUGE_DATA_SUCCESS : 'CHECK_GAUGE_DATA_SUCCESS',
    CHECK_GAUGE_DATA_FAILURE : 'CHECK_GAUGE_DATA_FAILURE',

    SET_MENU_DATA: 'SET_MENU_DATA',
    UPDATE_MENU: 'UPDATE_MENU',
    UPDATE_COMPANY_IN_MENU: 'UPDATE_COMPANY_IN_MENU',

    FETCH_TRANSITION_GRAPH_LIST_REQUEST: 'FETCH_TRANSITION_GRAPH_LIST_REQUEST',
    FETCH_TRANSITION_GRAPH_LIST_SUCCESS: 'FETCH_TRANSITION_GRAPH_LIST_SUCCESS',
    FETCH_TRANSITION_GRAPH_LIST_FAILURE: 'FETCH_TRANSITION_GRAPH_LIST_FAILURE',

    FETCH_CAMERA_LIST_REQUEST : 'FETCH_CAMERA_LIST_REQUEST',
    FETCH_CAMERA_LIST_SUCCESS : 'FETCH_CAMERA_LIST_SUCCESS',
    FETCH_CAMERA_LIST_FAILURE : 'FETCH_CAMERA_LIST_FAILURE',

    REGISTER_CAMERA_REQUEST : 'REGISTER_CAMERA_REQUEST',
    REGISTER_CAMERA_SUCCESS : 'REGISTER_CAMERA_SUCCESS',
    REGISTER_CAMERA_FAILURE : 'REGISTER_CAMERA_FAILURE',

    CAMERA_EDIT_REQUEST : 'CAMERA_EDIT_REQUEST',
    CAMERA_EDIT_SUCCESS : 'CAMERA_EDIT_SUCCESS',
    CAMERA_EDIT_FAILURE : 'CAMERA_EDIT_FAILURE',

    CAMERA_EDIT_SUBMIT_REQUEST : 'CAMERA_EDIT_SUBMIT_REQUEST',
    CAMERA_EDIT_SUBMIT_SUCCESS : 'CAMERA_EDIT_SUBMIT_SUCCESS',
    CAMERA_EDIT_SUBMIT_FAILURE : 'CAMERA_EDIT_SUBMIT_FAILURE',

    GAUGE_EDIT_REQUEST : 'GAUGE_EDIT_REQUEST',
    GAUGE_EDIT_SUCCESS : 'GAUGE_EDIT_SUCCESS',
    GAUGE_EDIT_FAILURE : 'GAUGE_EDIT_FAILURE',
    CHECK_MEASURE_DATA_REQUEST : 'CHECK_MEASURE_DATA_REQUEST',
    CHECK_MEASURE_DATA_SUCCESS : 'CHECK_MEASURE_DATA_SUCCESS',
    CHECK_MEASURE_DATA_FAILURE : 'CHECK_MEASURE_DATA_FAILURE',
    EDIT_GAUGE_DATA_REQUEST : 'EDIT_GAUGE_DATA_REQUEST',
    EDIT_GAUGE_DATA_SUCCESS : 'EDIT_GAUGE_DATA_SUCCESS',
    EDIT_GAUGE_DATA_FAILURE: 'EDIT_GAUGE_DATA_FAILURE',

    FETCH_CAMERA_INFO_REQUEST: 'FETCH_CAMERA_INFO_REQUEST',
    FETCH_CAMERA_INFO_SUCCESS: 'FETCH_CAMERA_INFO_SUCCESS',
    FETCH_CAMERA_INFO_FAILURE: 'FETCH_CAMERA_INFO_FAILURE',
    CLEAR_CAMERA_INFO: 'CLEAR_CAMERA_INFO',

    PHOTO_SHOOT_REQUEST : 'PHOTO_SHOOT_REQUEST',
    PHOTO_SHOOT_SUCCESS : 'PHOTO_SHOOT_SUCCESS',
    PHOTO_SHOOT_FAILURE : 'PHOTO_SHOOT_FAILURE',

    FETCH_PHOTO_SHOOT_STATUS_REQUEST: 'FETCH_PHOTO_SHOOT_STATUS_REQUEST',
    FETCH_PHOTO_SHOOT_STATUS_SUCCESS: 'FETCH_PHOTO_SHOOT_STATUS_SUCCESS',
    FETCH_PHOTO_SHOOT_STATUS_FAILURE: 'FETCH_PHOTO_SHOOT_STATUS_FAILURE',

    PHOTO_SHOOT_UPDATE_REQUEST : 'PHOTO_SHOOT_UPDATE_REQUEST',
    PHOTO_SHOOT_UPDATE_SUCCESS : 'PHOTO_SHOOT_UPDATE_SUCCESS',
    PHOTO_SHOOT_UPDATE_FAILURE : 'PHOTO_SHOOT_UPDATE_FAILURE',

    FETCH_TRANSITION_GRAPH_REQUEST: 'FETCH_TRANSITION_GRAPH_REQUEST',
    FETCH_TRANSITION_GRAPH_SUCCESS: 'FETCH_TRANSITION_GRAPH_SUCCESS',
    FETCH_TRANSITION_GRAPH_FAILURE: 'FETCH_TRANSITION_GRAPH_FAILURE',

    FETCH_MEASURE_NEW_REQUEST : 'FETCH_MEASURE_NEW_REQUEST',
    FETCH_MEASURE_NEW_SUCCESS : 'FETCH_MEASURE_NEW_SUCCESS',
    FETCH_MEASURE_NEW_FAILURE : 'FETCH_MEASURE_NEW_FAILURE',
    REGISTER_MEASURE_REQUEST : 'REGISTER_MEASURE_REQUEST',
    REGISTER_MEASURE_SUCCESS : 'REGISTER_MEASURE_SUCCESS',
    REGISTER_MEASURE_FAILURE : 'REGISTER_MEASURE_FAILURE',
    MEASURE_EDIT_REQUEST : 'MEASURE_EDIT_REQUEST',
    MEASURE_EDIT_SUCCESS : 'MEASURE_EDIT_SUCCESS',
    MEASURE_EDIT_FAILURE : 'MEASURE_EDIT_FAILURE',
    REGISTER_EDIT_MEASURE_REQUEST : 'REGISTER_EDIT_MEASURE_REQUEST',
    REGISTER_EDIT_MEASURE_SUCCESS : 'REGISTER_EDIT_MEASURE_SUCCESS',
    REGISTER_EDIT_MEASURE_FAILURE : 'REGISTER_EDIT_MEASURE_FAILURE',

    GET_MAIL_SEND_SETTING_REQUEST : 'GET_MAIL_SEND_SETTING_REQUEST',
    GET_MAIL_SEND_SETTING_SUCCESS : 'GET_MAIL_SEND_SETTING_SUCCESS',
    GET_MAIL_SEND_SETTING_FAILURE : 'GET_MAIL_SEND_SETTING_FAILURE',
    TEST_MAIL_SEND_REQUEST : 'TEST_MAIL_SEND_REQUEST',
    TEST_MAIL_SEND_SUCCESS  : 'TEST_MAIL_SEND_SUCCESS',
    TEST_MAIL_SEND_FAILURE  : 'TEST_MAIL_SEND_FAILURE',
    SAVE_MAIL_SEND_REQUEST : 'SAVE_MAIL_SEND_REQUEST',
    SAVE_MAIL_SEND_SUCCESS  : 'SAVE_MAIL_SEND_SUCCESS',
    SAVE_MAIL_SEND_FAILURE  : 'SAVE_MAIL_SEND_FAILURE',

    FETCH_CONFIRM_FIRST_MONTH_REQUEST : 'FETCH_CONFIRM_FIRST_MONTH_REQUEST',
    FETCH_CONFIRM_FIRST_MONTH_SUCCESS : 'FETCH_CONFIRM_FIRST_MONTH_SUCCESS',
    FETCH_CONFIRM_FIRST_MONTH_FAILURE : 'FETCH_CONFIRM_FIRST_MONTH_FAILURE',

    FETCH_CONFIRM_CAMERA_COUNT_REQUEST : 'FETCH_CONFIRM_CAMERA_COUNT_REQUEST',
    FETCH_CONFIRM_CAMERA_COUNT_SUCCESS : 'FETCH_CONFIRM_CAMERA_COUNT_SUCCESS',
    FETCH_CONFIRM_CAMERA_COUNT_FAILURE : 'FETCH_CONFIRM_CAMERA_COUNT_FAILURE',

    UPDATE_USER_NAME_REQUEST : 'UPDATE_USER_NAME_REQUEST',
    UPDATE_USER_NAME_SUCCESS : 'UPDATE_USER_NAME_SUCCESS',
    UPDATE_USER_NAME_FAILURE : 'UPDATE_USER_NAME_FAILURE',

    UPDATE_ID_PASSWORD_REQUEST : 'UPDATE_ID_PASSWORD_REQUEST',
    UPDATE_ID_PASSWORD_SUCCESS : 'UPDATE_ID_PASSWORD_SUCCESS',
    UPDATE_ID_PASSWORD_FAILURE : 'UPDATE_ID_PASSWORD_FAILURE',

    UPDATE_SETTING_2FA_REQUEST : 'UPDATE_SETTING_2FA_REQUEST',
    UPDATE_SETTING_2FA_SUCCESS : 'UPDATE_SETTING_2FA_SUCCESS',
    UPDATE_SETTING_2FA_FAILURE : 'UPDATE_SETTING_2FA_FAILURE',

    FETCH_CAMERA_CHANGE_DATA_REQUEST : 'FETCH_CAMERA_CHANGE_DATA_REQUEST',
    FETCH_CAMERA_CHANGE_DATA_SUCCESS : 'FETCH_CAMERA_CHANGE_DATA_SUCCESS',
    FETCH_CAMERA_CHANGE_DATA_FAILURE : 'FETCH_CAMERA_CHANGE_DATA_FAILURE',
    FETCH_GET_GAUGE_DATA_SUCCESS : 'FETCH_GET_GAUGE_DATA_SUCCESS',
    FETCH_GET_GAUGE_DATA_FAILURE : 'FETCH_GET_GAUGE_DATA_FAILURE',
    CAMERA_CHANGE_MAIL_SEND_REQUEST : 'CAMERA_CHANGE_MAIL_SEND_REQUEST',
    CAMERA_CHANGE_MAIL_SEND_SUCCESS : 'CAMERA_CHANGE_MAIL_SEND_SUCCESS',
    CAMERA_CHANGE_MAIL_SEND_FAILURE : 'CAMERA_CHANGE_MAIL_SEND_FAILURE',

    FETCH_LOGIN_HISTORY_REQUEST : 'FETCH_LOGIN_HISTORY_REQUEST',
    FETCH_LOGIN_HISTORY_SUCCESS : 'FETCH_LOGIN_HISTORY_SUCCESS',
    FETCH_LOGIN_HISTORY_FAILURE : 'FETCH_LOGIN_HISTORY_FAILURE',
    FETCH_GET_LOGIN_HISTORY_REQUEST : 'FETCH_GET_LOGIN_HISTORY_REQUEST',
    FETCH_GET_LOGIN_HISTORY_SUCCESS : 'FETCH_GET_LOGIN_HISTORY_SUCCESS',
    FETCH_GET_LOGIN_HISTORY_FAILURE : 'FETCH_GET_LOGIN_HISTORY_FAILURE',

    DOING_MAINTENANCE: 'DOING_MAINTENANCE',

    SET_RELATIVE_ACTIVE: 'SET_RELATIVE_ACTIVE'
};
