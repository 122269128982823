import axiosInstance from '../helpers/refreshTokenMiddleware';
import BASE_URL from '../constants/Api';
import ActionTypes from '../constants/ActionTypes';

export const setMenuData = (dataManagementMenu, cameraSettingsMenu, adminData) => ({
    type: ActionTypes.SET_MENU_DATA,
    payload: { dataManagementMenu, cameraSettingsMenu, adminData },
});

export const updateMenu = (menuType, updatedMenu) => ({
    type: ActionTypes.UPDATE_MENU,
    payload: { menuType, updatedMenu },
});

export const updateCompanyInMenu = (companyId, companyCode, companyName) => ({
    type: ActionTypes.UPDATE_COMPANY_IN_MENU,
    payload: { companyId, companyCode, companyName }
});

// Fetch menu data from API
export const fetchMenuData = () => async (dispatch) => {
    try {
        const response = await axiosInstance.get(`${BASE_URL}/top/sidemenu`);
        const formattedData = formatMenuData(response.data.respons.side_data_menu);
        dispatch(setMenuData(
            JSON.parse(JSON.stringify(formattedData)), 
            JSON.parse(JSON.stringify(formattedData)), 
            response.data.respons.data_admin
        ));
    } catch (error) {
        console.error('Error fetching menu data:', error);
    }
};

const formatMenuData = (menuData) => {
    return menuData.map(company => ({
        ...company,
        isOpen: false,
        facility: company.facility.map(facility => ({
            ...facility,
            isOpen: false,
            camera: facility.camera.map(camera => ({
                ...camera,
                isOpen: false,
                gauge: camera.gauge.map(gauge => ({
                    ...gauge,
                    isOpen: false,
                })),
            })),
        })),
    }));
};