import ActionTypes from '../constants/ActionTypes';
import axiosInstance from '../helpers/refreshTokenMiddleware';
import BASE_URL from '../constants/Api';

export const fetchSearchlistRequest = () => ({
    type: ActionTypes.FETCH_SEARCHLIST_REQUEST,
});

export const fetchSearchlistSuccess = (data) => ({
    type: ActionTypes.FETCH_SEARCHLIST_SUCCESS,
    payload: data,
});

export const fetchSearchlistFailure = (error) => ({
    type: ActionTypes.FETCH_SEARCHLIST_FAILURE,
    payload: error,
});

export const fetchTransitionGraphListRequest = () => ({
    type: ActionTypes.FETCH_TRANSITION_GRAPH_LIST_REQUEST,
});

export const fetchTransitionGraphListSuccess = (data) => ({
    type: ActionTypes.FETCH_TRANSITION_GRAPH_LIST_SUCCESS,
    payload: data,
});

export const fetchTransitionGraphListFailure = (error) => ({
    type: ActionTypes.FETCH_TRANSITION_GRAPH_LIST_FAILURE,
    payload: error,
});

export const fetchSearchlist = () => async (dispatch) => {
    dispatch(fetchSearchlistRequest());
    try {
        const response = await axiosInstance.post(BASE_URL + '/top/searchlist', {failure_id: 0});
        dispatch(fetchSearchlistSuccess(response.data.respons));
    } catch (error) {
        const errorMessage = error.response && error.response.status === 504 
            ? '504 ゲートウェイタイムアウト。\n後でもう一度お試しください。' 
            : error.message;
        dispatch(fetchSearchlistFailure(errorMessage));
    }
};

export const fetchTransitionGraphList = (startDate, endDate, facilityId, deviceId, gaugeId, measureId, viewType) => async (dispatch) => {
    dispatch(fetchTransitionGraphListRequest());
    try {
        const response = await axiosInstance.post(BASE_URL + '/graph/transition-graph-list', {
            start_date: startDate,
            end_date: endDate,
            facility_id: facilityId,
            device_id: deviceId,
            gauge_id: gaugeId,
            measure_id: measureId,
            view_type: viewType});
    dispatch(fetchTransitionGraphListSuccess(response.data.respons));
} catch (error) {
        const errorMessage = error.response && error.response.status === 504 
            ? '504 ゲートウェイタイムアウト。\n後でもう一度お試しください。' 
            : error.message;
        dispatch(fetchTransitionGraphListFailure(errorMessage));
    }

};