import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import './CompanyList.css';
import Modal from '../Modal/Modal';
import Draggable from 'react-draggable';
import { useDispatch, useSelector } from 'react-redux';
import { updateCompanyInMenu, fetchMenuData } from '../../actions/menuActions';
import Breadcrumb from '../Breadcrumb';

const CompanyList = ({ user, companies = [], loading, error, fetchCompanies, fetchManagementUnitData, registerCompany, replaceCompanies }) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate ();
    const [selectedCompanyIndex, setSelectedCompanyIndex] = useState(null); 
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [adminId, setAdminId] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [isDragging, setIsDragging] = useState(false);
    const [companyCode, setCompanyCode] = useState('');
    const [companyNameView, setCompanyNameView] = useState('');
    const [companyList, setCompanyList] = useState(companies); 
    const [showUserName, setUserName] = useState('');
    const [errors, setErrors] = useState({});
    const screenType = useSelector((state) => state.device.screenType);

    // Fetch companies on component mount or when location changes
    useEffect(() => {
        fetchCompanies();
    }, [fetchCompanies, location]);

    // When companies data changes, set the first company's admin_id by default (or handle it as per your logic)
    useEffect(() => {
        if (companies.length > 0) {
            // Assuming you want to use the `admin_id` of the first company in the list
            setAdminId(companies[0].admin_id || '');
        }
        setCompanyList(companies);
    }, [companies]);

    useEffect(() => {
        if (user) {
          setUserName(user.user_name);
        };
    }, [user]);

    // Handle Edit button click
    const handleEdit = (index) => {
        setSelectedCompanyIndex(index); 
        setIsPopupOpen(true); 
        setCompanyCode(companyList[index].company_code || ''); 
        setCompanyNameView(companyList[index].company_name || '');
        setErrors({});
    };

    const closePopup = () => {
        if (!isDragging && !isModalOpen) {
            setIsPopupOpen(false);
            setErrors({});
        }
    };

    const handleWrapperClick = (e) => {
        if (e.target.classList.contains('popup-wrapper')) {
            closePopup();
        }
    };

    // Move to the previous company in the list
    const handlePrev = () => {
        setSelectedCompanyIndex((prevIndex) => {
            const newIndex = Math.max(prevIndex - 1, 0);
            setCompanyCode(companyList[newIndex].company_code || ''); 
            setCompanyNameView(companyList[newIndex].company_name || ''); 
            return newIndex;
        });
    };

    // Move to the next company in the list
    const handleNext = () => {
        setSelectedCompanyIndex((prevIndex) => {
            const newIndex = Math.min(prevIndex + 1, companyList.length - 1);
            setCompanyCode(companyList[newIndex].company_code || ''); 
            setCompanyNameView(companyList[newIndex].company_name || ''); 
            return newIndex;
        });
    };

    if (error) {
        return <div>Error: {error}</div>;
    }

    // Hàm fetch data và mở modal khi thành công
    const handleFetchManagementData = async () => {
        try {
            await fetchManagementUnitData();
            setModalMessage('取得しました。');
            setIsModalOpen(true);
        } catch (error) {
            console.error('Error fetching management unit data:', error);
        }
    };

    const handleRegister = async () => {
        const errorMessage = validateCompanyCode(companyCode);

        if (errorMessage) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                company_code: errorMessage,
            }));
            return; 
        }

        if (selectedCompanyIndex !== null) {
            const selectedCompany = companyList[selectedCompanyIndex];
            try {
                const response = await registerCompany(
                    adminId,
                    selectedCompany.company_id,
                    companyCode,
                    companyNameView
                );
    
                const successMessage = response.data.respons?.msg;
    
                // Update the corresponding input and span in the form without re-fetching the data
                document.querySelector(`#company_info_${selectedCompanyIndex} input[name="company_code[]"]`).value = companyCode;
                document.querySelector(`#company_name${selectedCompany.company_id}`).textContent = companyNameView;
                companyList[selectedCompanyIndex].company_code = companyCode;
                companyList[selectedCompanyIndex].company_name = companyNameView;
    
                // Update the state to reflect the new changes after registration
                dispatch(updateCompanyInMenu(selectedCompany.company_id, companyCode, companyNameView));
    
                // Show success modal
                setErrors({});
                setModalMessage(successMessage);
                setIsModalOpen(true);
    
            } catch (error) {
                setModalMessage('登録に失敗しました。エラーが発生しました。');
                setIsModalOpen(true);
            }
        }
    };
    
    const handleSortPrev = (index) => {
        if (index === 0) return; 
        const newList = [...companyList];
        [newList[index], newList[index - 1]] = [newList[index - 1], newList[index]]; 
        setCompanyList(newList);
    };

    const handleSortNext = (index) => {
        if (index === companyList.length - 1) return;
        const newList = [...companyList];
        [newList[index], newList[index + 1]] = [newList[index + 1], newList[index]];
        setCompanyList(newList); 
    };

    const getCompanyIdArray = () => {
        return companyList.map(company => company.company_id);
    };

    const handleSave = async () => {
        try {
            const companyIds = getCompanyIdArray();
            const response = await replaceCompanies(companyIds);
            const message = response.data?.respons?.msg;
            await fetchCompanies();
            await dispatch(fetchMenuData());

            setModalMessage(message);
            setIsModalOpen(true);
        } catch (error) {
            setModalMessage(error.message || 'エラーが発生しました。再試行してください。');
            setIsModalOpen(true);
        } finally {
        }
    };

    const handleTopClick = () => {
        navigate('/');
    };

    const validateCompanyCode = (code) => {
        const forbiddenCharacters = new RegExp("[/\\\\:*?\"<>|]");
        if (forbiddenCharacters.test(code)) {
            return "組織IDには次の文字は使えません： \\ / : * ? \" < > |";
        }
        return "";
    };

    return (
        <div id='Main' className={`main ${screenType}`}>
            <div id='Contents'>
                <Breadcrumb
                    userName = {showUserName}
                    pageName = "カメラ・計器設定"
                    onTopClick = {handleTopClick}
                />
                {isModalOpen && (
                    <Modal
                        isOpen={isModalOpen}
                        onClose={() => setIsModalOpen(false)}
                        message={modalMessage}
                    />
                )}
                {isPopupOpen && selectedCompanyIndex !== null && (
                    <div className="data" id="companyPopup">
                        <div className="popup-wrapper preNextPopup pt10" onClick={handleWrapperClick}>
                            <Draggable
                                handle=".popup"
                                positionOffset={{ x: '-50%', y: '-50%' }}
                                onStart={() => setIsDragging(true)}
                                onDrag={() => setIsDragging(true)}
                                onStop={() => setIsDragging(false)}
                                cancel="textarea, input, .no-drag, button"
                                disabled={isModalOpen}
                            >
                                <div className="popup" id="companyPopup" style={{ maxWidth: '900px', minWidth: '900px' }}>
                                    <div className="popup-content" style={{ background: '#dcf1fa'}}>
                                        <div style={{ width: '100%', textAlign: 'center', color: 'red' }} id="success-message"></div>
                                        
                                        <button className="prev" onClick={handlePrev} style={{ display: selectedCompanyIndex === 0 ? 'none' : 'inline' }}>❮</button>
                                        <button className="next" onClick={handleNext} style={{ display: selectedCompanyIndex === companyList.length - 1 ? 'none' : 'inline' }}>❯</button>

                                        <form id="companyEditpopup" onSubmit={(e) => e.preventDefault()}>
                                            <div style={{ width: '300px', float: 'left', marginLeft: '20px', marginTop: '25px' }}>
                                                <div className="mod-master-form__set measure gauge_name">
                                                    <div style={{ display: 'flex' }}>
                                                        <div className="set_master_name_measure">
                                                            <dl>
                                                                <dt>組織ID</dt>
                                                                <dd style={{ width: '350px' }}>
                                                                    <input type="hidden" id="admin_id" name="admin_id" value={adminId || ''}/>
                                                                    <input type="hidden" id="company_id" name="company_id" value={companyList[selectedCompanyIndex].company_id || ''}/>
                                                                    <input
                                                                        type="text"
                                                                        id="company_code"
                                                                        name="company_code"
                                                                        value={companyCode}
                                                                        onChange={(e) => setCompanyCode(e.target.value)}
                                                                    />
                                                                    <div className="error_company_code">
                                                                        {errors.company_code && <span style={{ color: 'red' }}>{errors.company_code}</span>}
                                                                    </div>
                                                                </dd>
                                                            </dl>
                                                            <dl style={{ marginTop: '20px', marginBottom: '0' }}>
                                                                <dt>組織名</dt>
                                                                <dd style={{ width: '350px', marginBottom: '0' }}>
                                                                    <input
                                                                        type="text"
                                                                        id="company_name_view"
                                                                        value={companyNameView}
                                                                        onChange={(e) => setCompanyNameView(e.target.value)}
                                                                    />
                                                                </dd>   
                                                            </dl>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="lay-list__horizontal__btn">
                                                    <button
                                                        type="button"
                                                        className="mod-btn__roundborder small"
                                                        style={{ width: '45%', float: 'left' }}
                                                        onClick={closePopup}
                                                    >
                                                        キャンセル
                                                    </button>
                                                    <button
                                                        type="button"
                                                        id="registerbtn"
                                                        className="mod-btn__round small"
                                                        style={{ width: '45%', float: 'right', marginRight: '10px' }}
                                                        onClick={handleRegister}
                                                    >
                                                        登録
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </Draggable>

                        </div>
                    </div>
                )}

                {/* Main Company List Table */}
                <div className="display-main" id="set_company_list">
                    <div className="lay-master-set">
                        <div className="lay-master-set-wrap">
                            <div className="lay-master-set__title">
                                <span>組織一覧</span>
                            </div>
                            <div className="lay-master-set__frame">
                                <div className="lay-master__table">
                                    <div className="get_api_management" style={{ float: 'left' }}>
                                        <button type="button" id="get_management_units" onClick={handleFetchManagementData} style={{outline: 'none'}}>
                                            データ取得
                                        </button>
                                    </div>
                                    <form id="companyListData">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>組織名</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {companyList.map((company, index) => (
                                                    <tr key={company.company_id} className="lay-tr">
                                                        <td className="lay-td sort">
                                                            <div
                                                                className="sortPrev"
                                                                style={{ display: index === 0 || companyList.length === 1 ? 'none' : '' }}
                                                                onClick={() => handleSortPrev(index)}
                                                            >
                                                                △
                                                            </div>
                                                            <div
                                                                className="sortNext"
                                                                style={{ display: index === companyList.length - 1 || companyList.length === 1 ? 'none' : '' }}
                                                                onClick={() => handleSortNext(index)}
                                                            >
                                                                ▽
                                                            </div>
                                                        </td>
                                                        <td className="lay-td" id={`company_info_${index}`}>
                                                            <input
                                                                type="hidden"
                                                                name="company_id[]"
                                                                value={company.company_id || ''}
                                                            />
                                                            <input
                                                                type="hidden"
                                                                name="company_code[]"
                                                                value={company.company_code || ''}
                                                            />
                                                            <span
                                                                name="company_name[]"
                                                                id={`company_name${company.company_id}`}
                                                            >
                                                                {company.company_name}
                                                            </span>
                                                        </td>
                                                        <td style={{ paddingLeft: '30px' }}>
                                                            <button
                                                                type="button"
                                                                id={`company_info_edit_${index}`}
                                                                className="mod-btn__roundborder"
                                                                style={{ width: '100px', height: '30px', fontSize: '12px', lineHeight: '27px' }}
                                                                onClick={() => handleEdit(index)}
                                                            >
                                                                編集
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        <input type="hidden" name="admin_id" value={adminId || ''}/>
                                        <input type="hidden" name="this_count" value={selectedCompanyIndex !== null ? selectedCompanyIndex : 0}/>
                                        <input type="hidden" name="tot_count" value={companyList.length - 1 || ''}/>
                                    </form>
                                    <div className="trun-btn" style={{ marginTop: '50px', marginRight: '10%' }}>
                                        <button type="button" className="master_long_btn" id="edit" onClick={handleSave} style={{outline: 'none'}}>
                                            登録
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`overlay ${loading ? 'active' : ''}`}>
                <div className={`spinner ${loading ? 'active' : ''}`}></div>
            </div>
        </div>
    );
};

CompanyList.propTypes = {
    companies: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    fetchCompanies: PropTypes.func.isRequired,
    fetchManagementUnitData: PropTypes.func.isRequired,
    registerCompany: PropTypes.func.isRequired,
    replaceCompanies: PropTypes.func.isRequired,
};

export default React.memo(CompanyList);