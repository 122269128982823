/* eslint-disable array-callback-return */
/* eslint-disable no-loop-func */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, useRef } from 'react'
import './TransitionGraph.css'
import { Chart, LineElement, CategoryScale, LinearScale, PointElement, Title, Tooltip, Legend } from 'chart.js/auto';
import { addDays, addMonths, addYears } from 'date-fns';
import html2canvas from 'html2canvas';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Modal from '../Modal/Modal';
import Breadcrumb from '../Breadcrumb';

Chart.register(LineElement, CategoryScale, LinearScale, PointElement, Title, Tooltip, Legend);

const TransitionGraph = React.memo(
    ({user,
      standard_time,
      diff_flg,
      diff_type,
      graph_data = [],
      gauge_id,
      measure_id,
      is_loaded,
      start_date,
      end_date,
      start_date_real,
      end_date_real,
      view_type,
      unit,
      max_limit,
      min_limit,
      error,
      isFetched,
      isLoading,
      fetchTransitionGraphData
    }) => {
        const canvasRef = useRef(null);
        const chartInstance = useRef(null);
        const navigate = useNavigate ();
        const location = useLocation();
        let queryParams = new URLSearchParams(location.search);
        let dataInfo = JSON.parse(queryParams.get('data_info'));
        const [graphDate, setGraphDate]= useState([]);
        const [showData, setShowData] = useState([]); 
        const [slideIndex, setSlideIndex] = useState(dataInfo.slideIndex);
        const [graphType, setGraphType] = useState(dataInfo.graphType);
        const [isModalOpen, setIsModalOpen] = useState(false);
        const [modalMessage, setModalMessage] = useState('');
        const [pastViewData, setPastViewData] = useState(dataInfo.pastDataView);
        const [info, setInfo] = useState('');
        const [showUserName, setUserName] = useState('');
        const [deviceType, setDeviceType] = useState("desktop");
        const screenType = useSelector((state) => state.device.screenType);
        const viewType = dataInfo.viewType;
        var maximumValue = dataInfo.maximumValue;
        var minimumValue = dataInfo.minimumValue;
        var decimalValue = dataInfo.decimalValue;
        var stepSizeValue = dataInfo.stepSizeValue;
        let facility_name = '';
        let display_camera_name = '';
        let display_gauge_name = '';
        let measure_name = '';

        var clickTime = 0;

        const fnGetDateFormat = (date) => {
            if (parseInt(viewType) === 2) {
                return date.getFullYear()+"/"+("0" + (date.getMonth()+1)).slice(-2);
            } else {
                return date.getFullYear()+"/"+("0" + (date.getMonth()+1)).slice(-2)+"/"+("0"+date.getDate()).slice(-2);
            };
        };

        function fnGetDateFormatJp(date) {
            return date.getFullYear()+"年"+("0" + (date.getMonth()+1)).slice(-2)+"月"+("0"+date.getDate()).slice(-2)+"日";
        };

        const handleContextMenu = (event) => {
            event.preventDefault();
        };

        const handleGraphTypeChange = (event) => {
            setGraphType(event.target.value);
            
        };

        const handleChangeSlide = (key, dateInfo) => {
            changeSlideIndex(key);
        };

        const handlePrevMove = (event) => {
            var tmpIndex;
            if (parseInt(slideIndex) !== 0) {
                tmpIndex = slideIndex - 1;
                changeSlideIndex(tmpIndex);
            };
        };

        const handleNextMove = (event) => {
            var tmpIndex;
            if (parseInt(slideIndex) !== (graphDate.length - 1)) {
                tmpIndex = slideIndex + 1;
                changeSlideIndex(tmpIndex);
            };        
        };

        const changeSlideIndex = (key) => {
            if (graphDate.length > 0) {
                for (let index = 0; index < graphDate.length; index++) {
                    graphDate[index].className = "transdot";               
                };
                graphDate[key].className = "transdot active";               
                setSlideIndex(key);
                
            };
        };

        const handleGoBack = (event)  => {
            navigate(-1);
        };

        const handleDownloadImage = (event) => {
            if (deviceType !== 'desktop') {
                setModalMessage('グラフ画像ダウンロードはPCからご利用ください。');
                setIsModalOpen(true);
            } else {
    
                if (!clickTime) {
                  clickTime = new Date();
                } else {
                  //ダブルクリック防止
                  var diffTime = new Date() - clickTime;
                  clickTime = new Date();
                  if (diffTime < 900) {
                    return false;
                  }
                }
    
                var element = document.getElementById('screenshot');
                var imgWidth = element.offsetWidth + 15;
                var imgHeight = element.offsetHeight + 15;
  
                console.log("window.scrollX:", window.scrollX);
                console.log("window.scrollY:", window.scrollY);
                console.log("width:", imgWidth);
                console.log("height:", imgHeight);
          
                html2canvas(element, {scrollY: -window.scrollY + 25, scrollX: -window.scrollX + 25, width: imgWidth, height: imgHeight}).then(function (canvas) {
                  // ファイル名作成開始
                  const tmpItems = dataInfo.selectedItem;
                  var graph_date = graphDate[slideIndex].date.replace(/\//g,'');
                  var regSpecChar = RegExp(/["<>:|\\*\\/?]/g);
  
                  if (tmpItems.facility_name != null) {
                    facility_name = tmpItems.facility_name;
                  } else {
                    facility_name = '';
                  };

                  if (tmpItems.display_camera_name != null) {
                    display_camera_name = tmpItems.display_camera_name;
                  } else {
                    display_camera_name = '';
                  };

                  if (tmpItems.display_gauge_name != null) {
                    display_gauge_name = tmpItems.display_gauge_name;
                  } else {
                    display_gauge_name = '';
                  };

                  if (tmpItems.measure_name != null) {
                    measure_name = tmpItems.measure_name + "[" + tmpItems.unit + "]";
                  } else {
                    measure_name = '';
                  };
                  
                  //施設名チェック
                  facility_name = facility_name.replace(regSpecChar, '');
                  if (facility_name.length > 50) {
                    facility_name = facility_name.slice(0, 50) + "...";
                  };
                  //カメラ名チェック
                  display_camera_name = display_camera_name.replace(regSpecChar, '');
                  if (display_camera_name.length > 50) {
                      display_camera_name = display_camera_name.slice(0, 50) + "...";
                  };
                  //計器名チェック
                  display_gauge_name = display_gauge_name.replace(regSpecChar, '');
                  if (display_gauge_name.length > 50) {
                    display_gauge_name = display_gauge_name.slice(0, 50) + "...";
                  };
                  //計測項目名チェック
                  measure_name = measure_name.replace(regSpecChar, '');
                  if (measure_name.length > 50) {
                    measure_name = measure_name.slice(0, 50) + "...";
                  };
                  console.log("measure_name:", measure_name);
                  var fileName = graph_date + facility_name + display_camera_name + display_gauge_name + measure_name + ".png";
                  // ファイル名作成終了
                  
                  if (canvas.msToBlob) { // IEブラウザ
                    window.navigator.msSaveBlob(canvas.msToBlob(), fileName);
                  } else { // 他のブラウザ
                    var imgageData = canvas.toDataURL("image/png");
                    var newData = imgageData.replace(/^data:image\/png/, "data:application/octet-stream");
      
                    var link = document.createElement('a');
                    link.href = newData;
                    link.download = fileName;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                  }
                }).catch(function(err){
                  console.log(err);
                });
    
                setModalMessage('');
                setIsModalOpen(false);
      
            };

        };

        const handleChangeGraphMode = (event) => {
            
            if (pastViewData === true) {
                setPastViewData(false);
                dataInfo.pastDataView = false;
            } else {
                setPastViewData(true);
                dataInfo.pastDataView = true;
            };
            
        };

        const updateQueryParams = () => {
            dataInfo.slideIndex = slideIndex;
            dataInfo.graphType = graphType;
            dataInfo.pastDataView = pastViewData;
            queryParams.set('data_info', JSON.stringify(dataInfo));
            let newUrl = window.location.pathname + '?' + queryParams.toString();
            window.history.replaceState({}, '', newUrl);

        };

        useEffect(() => {
            const detectDevice = () => {
                const userAgent = navigator.userAgent || navigator.vendor || window.opera;
                const isTouchDevice = "ontouchstart" in window || navigator.maxTouchPoints > 0;
    
                if (/iPhone|Android.+Mobile/.test(userAgent)) {
                    setDeviceType("mobile");
        
                } else if (
                    /iPad|Android/.test(userAgent) || // iPad and Android tablets
                    (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)  || // iPad Pro in desktop mode
                    (isTouchDevice)            // Handle simulation in Chrome DevTools
                ) {
                    setDeviceType("tablet");
                 } else {
                    setDeviceType("desktop");        
                }
            };
    
            detectDevice();
            window.addEventListener('resize', detectDevice);
    
            return () => {
                window.removeEventListener('resize', detectDevice);
            };
        }, []);
        console.log("device Type:", deviceType);

        useEffect(() => {
            console.log("----------------初期化--------------------");
            const tmpItems = dataInfo.selectedItem;
            console.log("tmpItems:", tmpItems);
            console.log("viewType:", viewType);
            var tmpStartDate = new Date(dataInfo.startDate);
            var tmpEndDate = new Date(dataInfo.endDate);
            const tmpGraphDate = [];
            var tmpShowStartDate = "";
            var tmpShowEndDate = "";

            if (parseInt(viewType) === 2) {
                tmpShowStartDate = fnGetDateFormat(tmpStartDate);
                tmpShowEndDate = fnGetDateFormat(tmpEndDate);
            } else {
                tmpShowStartDate = dataInfo.startDate;
                tmpShowEndDate = dataInfo.endDate;
            };

            var tmpString = "期間：" + tmpShowStartDate + " ～ " + tmpShowEndDate;
            tmpString += "\n";
            if (tmpItems.facility_name != null) tmpString += "施設名：" + tmpItems.facility_name;
            if (tmpItems.display_camera_name != null) tmpString += "　カメラ名：" + tmpItems.display_camera_name;
            tmpString += "\n";
            if (tmpItems.display_gauge_name != null) tmpString += "計器名：" + tmpItems.display_gauge_name;
            if (tmpItems.measure_name != null) tmpString += "　計測項目：" + tmpItems.measure_name;
            if (tmpItems.unit != null) tmpString += "[" + tmpItems.unit + "]";
            console.log("tmpString:", tmpString);
            setInfo(tmpString);
    
            if (parseInt(viewType) === 2) {
                const yearDifference = tmpEndDate.getFullYear() - tmpStartDate.getFullYear();
                const monthDifference = tmpEndDate.getMonth() - tmpStartDate.getMonth();
                const diffInMonths = yearDifference * 12 + monthDifference;

                for (let index = 0; index <= diffInMonths; index++) {
                    if (index === parseInt(slideIndex)) {
                        tmpGraphDate.push({className: "transdot active", date:fnGetDateFormat(addMonths(tmpStartDate, index))});
                    } else {
                        tmpGraphDate.push({className: "transdot", date:fnGetDateFormat(addMonths(tmpStartDate, index))});
                    };
                }
            } else {
                const diffInMs = Math.abs(tmpEndDate - tmpStartDate);
                const diffInDays  = Math.ceil(diffInMs / (1000 * 60 * 60 * 24));
    
                for (let index = 0; index <= diffInDays; index++) {
                    if (index === parseInt(slideIndex)) {
                        tmpGraphDate.push({className: "transdot active", date:fnGetDateFormat(addDays(tmpStartDate, index))});
                    } else {
                        tmpGraphDate.push({className: "transdot", date:fnGetDateFormat(addDays(tmpStartDate, index))});
                    };
    
                };
            };

            console.log("tmpGraphDate:", tmpGraphDate);


            setGraphDate(tmpGraphDate);
            console.log("graphDate:", graphDate);

        },[]);

        useEffect(() => {

            console.log("----------------fetchData--------------------");
            
            const fetchData = async () => {
                if (graphDate.length > 0) {
                    fetchTransitionGraphData(dataInfo.selectedItem, graphDate[slideIndex].date, graphDate[slideIndex].date, parseInt(dataInfo.viewType), pastViewData);
                };
            };

            fetchData();

            updateQueryParams();

        }, [graphDate, slideIndex, pastViewData]);

        useEffect(() => {
            console.log("----------------setShowData--------------------");

            console.log("graphDate:", graphDate);
            if (graph_data) {
                console.log("graphDate.length:", graphDate.length);
                
                if (graphDate.length > 0) {
                    var tmpShowData = [];
                    tmpShowData.push(graph_data[1]);
                    if (pastViewData === true) {
                        tmpShowData.push(graph_data[2]);
                    };
        
                    console.log("tmpShowData:", tmpShowData);
                    setShowData(tmpShowData);
                };
    
            };
            
        }, [graph_data]);

        useEffect(() => {
            if (user) {
              setUserName(user.user_name);
            };
        }, [user]);

        useEffect(() => {
            console.log("----------------setGraph--------------------");
            console.log("setGraph-isFetched:", isFetched);
            console.log("setGraph-showData:", showData);
            let toolValue = [];
            let toolTitle = [];
            let toolCurDate = [];
            let toolCurHour = [];
            let toolPrevDate = [];
            let toolPrevHour = [];
            let toolPrevValue = [];
            let toolDiffValue = [];
            let toolDiffFlg = [];
            let toolComment = [];
            let toolUnit = [];
            let toolMin = [];
            let toolMax = [];
            const limitUnderColor = '#f6aa00';
            const limitOverColor = '#ff1300';
            const bar1Color = '#00dff3';
            const bar2Color = '#068eaa';
            var tooltipBorder = [];
            var DataList1 = [];
            var DataList2 = [];
            var filteredDataList1 = [];
            var filteredDataList2 = [];
            var BackGroundColorList1 = [];
            var BackGroundColorList2 = []; 
            var hasErrorData1 = false;		//1日異常データがあるか
            var hasErrorData2 = false;		//1日異常データがあるか
            var datasetArray = [];
            let toolIdx = "";
            let tmpTime = "";
            let tmpObj = [];
            var minFlg,maxFlg;
            let minValue = 0;
            let maxValue = 0;
            let stepSize = 0;
            let arrDiffFlg = [];
            let arrDiffType = [];
            let arrStandardTime = [];
            var digitFloor;
            var baseValue;
            var minTemp;
            var maxTemp;
            var minData = 0;
            var maxData = 0;
            const STEPNUM = 10;            
            var stepCount = 0;
            var isMaxNull = false;	//最大値は空白で検索
            var isMinNull = false;	//最小値は空白で検索
            var isStepNull = false;	//目盛り間隔は空白で検索
            var isAuto = false;
            var maxLimit = max_limit;
            var clipValue = 0;
            var clipTopValue = 0;
            var clipBottomValue = 0;

            if (pastViewData === true) {
                tooltipBorder.push(bar2Color);
                tooltipBorder.push(bar1Color);
            } else {
                tooltipBorder.push(bar1Color);
                tooltipBorder.push(bar2Color);
            };

            const customTooltip = (context) => {
                const { tooltip } = context;
                var status_class;
                // const index = tooltip.dataPoints[0].dataIndex;
                var tooltopDate = tooltip.dataPoints[0].label;
                var tooltopIndex = tooltip.dataPoints[0].datasetIndex;
                var toolMinFlg = toolMin[tooltopIndex+"_"+tooltopDate];
                var toolMaxFlg = toolMax[tooltopIndex+"_"+tooltopDate];
                var tool_diff_flg = arrDiffFlg[tooltopIndex];

                if ( parseInt(toolMinFlg) === 1 || parseInt(toolMaxFlg) === 1 ) {
                    status_class = 2;
                };
                console.log("tooltip dataset:", tooltip.dataPoints[0].dataset);

                let tooltipEl = document.getElementById('chartjs-tooltip');

                if (!tooltipEl) {
                    tooltipEl = document.createElement('div');
                    tooltipEl.id = 'chartjs-tooltip';
                    tooltipEl.classList.add('trans_modal_view');
                    tooltipEl.innerHTML = '<div class="trans_text_view" id="trans_text_view1"></div>';
                    document.getElementById('tchatArea').appendChild(tooltipEl);
                };

                if (parseInt(status_class) === 2) {
                    tooltipEl.style.backgroundImage = 'url("/img/common/icon-caution.png")';
                    tooltipEl.style.border = `solid 3px ${limitOverColor}`;     
                } else {
                    tooltipEl.style.backgroundImage = 'none';
                    tooltipEl.style.border = `solid 3px ${tooltipBorder[tooltopIndex]}`;     
                };

                if (tooltip.opacity === 0) {
                    tooltipEl.style.opacity = 0;
                    document.getElementById('chartjs-tooltip').style.display = 'none';
                    return;
                };

                if (tooltip.body) {
                    const bodyLines = tooltip.body.map(b => b.lines);
                    let innerHtml = '';
                    var tooltip_height = 220;
                    var tooltip_width = (tool_diff_flg ? 600 : 400);
                    var trans_modal_view = document.getElementsByClassName('trans_modal_view');

                    if (bodyLines.length > 1) {
                        tooltip_height += (bodyLines.length - 1) * (tool_diff_flg ? 100 : 50);
                    };

                    for (let k = 0; k < trans_modal_view.length; k++) {
                        trans_modal_view[k].style.height = tooltip_height + 'px';
                        trans_modal_view[k].style.width = tooltip_width + 'px';
                    };

                    bodyLines.forEach(function(body, i) {
                        if (graphType && bodyLines.length > 1) {
                            tooltopIndex = tooltip.dataPoints[i].datasetIndex;
                            tool_diff_flg = toolDiffFlg[tooltopIndex + "_" + tooltopDate];
                            console.log("tool_diff_flg2:", tool_diff_flg);
                        };

                        var toolIndex = tooltopIndex + "_" + tooltopDate;
                        console.log("toolIndex:", toolIndex);
                        var maxLenNoDiff = 55;
                        var maxLenDiff = 7;
                        var maxLenOverLapNoDiff = 28;
                        var maxLen = maxLenNoDiff;
                        var dispComment = '';



                        if (graphType) {
                            maxLen = tool_diff_flg ? maxLenDiff : maxLenOverLapNoDiff;
                        } else {
                            maxLen = tool_diff_flg ? maxLenDiff : maxLenNoDiff;
                        };

                        if (toolComment[toolIndex]) {
                            dispComment = toolComment[toolIndex];
                        };
                
                        if (dispComment.length > maxLen) {
                          dispComment = dispComment.substring(0, maxLen) + "..."
                        };

                        console.log("tool_diff_flg3:", tool_diff_flg);
                        if (tool_diff_flg === true) {	//差分値ポップアップ
                            innerHtml += '<div class="trans_diff_view">'
                              +'<div class="trans_measure_view">'
                              +'<p>' + toolCurDate[toolIndex] + '<br>'
                              + toolCurHour[toolIndex] + '<br>'
                              + parseFloat(toolValue[toolIndex]) +" "+toolUnit[toolIndex]+
                              '</p></div>';
                            innerHtml += '<div>－</div>';
                            innerHtml += '<div class="trans_measure_view">'
                              +'<p>' + toolPrevDate[toolIndex] + '<br>'
                              + toolPrevHour[toolIndex] + '<br>'
                              + parseFloat(toolPrevValue[toolIndex]) +" "+toolUnit[toolIndex]+
                              '</p></div>';
                            innerHtml += '<div>=</div>';
                            innerHtml += '<div class="trans_measure_view" style="border: solid red 3px;">'
                              +'<p>' + toolCurDate[toolIndex] + '<br>'
                              + toolCurHour[toolIndex] + '<br>'
                              + parseFloat(toolDiffValue[toolIndex]) +" "+toolUnit[toolIndex]+'<br>' 
                              + dispComment + 
                              '</p></div>';
                            innerHtml += '</div>';
                        } else {	//計測値ポップアップ
                            var style = 'margin-top:15px;';
                            if (bodyLines.length > 1) style += ' border:solid 1px;';
                            innerHtml += '<p style="' + style + '">' + toolTitle[toolIndex] + '<br>'
                              + "計測値：" + parseFloat(toolValue[toolIndex]) +"["+toolUnit[toolIndex]+']<br>' 
                              + dispComment + 
                              '</p>';
                        };
                    });

                    document.getElementById('trans_text_view1').innerHTML = innerHtml;

                };
                tooltipEl.style.opacity = 1;
                tooltipEl.style.display = 'block';
            };

            var countDecimals = function (value) {
                if (!value) return 0;
                console.log("value:", value);
                console.log("Math.floor(value):", Math.floor(value));
                if(Math.floor(value) === value) return 0;
                return value.toString().split(".")[1].length || 0; 
            };

            var truncateDecimal = function (value) {
                return Math.round(parseFloat((value * Math.pow(10, 5)).toFixed(5))) / Math.pow(10, 5);
            };

            if (minimumValue === "") {
                isMinNull = true;
            } else {
                minValue = parseFloat(minimumValue);
            };

            if (maximumValue === "") {
                isMaxNull = true;
            } else {
                maxValue = parseFloat(maximumValue);
            };

            if (stepSizeValue === "") {
                isStepNull = true;
            } else {
                stepSize = parseFloat(stepSizeValue);
                stepSizeValue = stepSize;
                if (stepSizeValue === 0) {
                    stepSizeValue = 1;
                }
            };

            if (decimalValue === "auto") {
                isAuto = true;
            } else {
                decimalValue = parseFloat(decimalValue);
            };

            if ((isFetched === true) && (showData.length > 0)) {
                const ctx = canvasRef.current.getContext('2d');
                const xLabels = [];
                canvasRef.current.style.display = 'none';
                canvasRef.current.addEventListener('contextmenu', handleContextMenu);

                let chartStatus = Chart.getChart("tchart");
                if (chartStatus !== undefined) {
                    chartStatus.destroy();
                };

                let meterName = [];
                let minLoopIndex;
                let maxLoopIndex;
                let id01;
                let id02;
                let tmpDate01;
                let legendColor01;
                let legendColor02;
                let tmpData;

                if (pastViewData === true) {
                    meterName.push(fnGetDateFormat(addYears(new Date(graphDate[slideIndex].date), -1)));
                    meterName.push(graphDate[slideIndex].date);
                    id01 = 1;
                    id02 = 0;
                } else {
                    meterName.push(graphDate[slideIndex].date);
                    id01 = 0;
                };
                
                if (parseInt(viewType) === 2) {
                    var calYear = graphDate[slideIndex].date.slice(0,4);
                    var calMonth = graphDate[slideIndex].date.slice(-2);
                    console.log("calYear:", calYear);
                    console.log("calMonth:", calMonth);
                    minLoopIndex = 1;
                    maxLoopIndex = new Date(calYear, calMonth, 0).getDate();
                    console.log("maxLoopIndex:", maxLoopIndex);
                } else {
                    minLoopIndex = 0
                    maxLoopIndex = 23;
                };

                for (var t = minLoopIndex; t <= maxLoopIndex; t++) {
                    if (parseInt(viewType) === 2) {
                        var xMonth = parseInt(graphDate[slideIndex].date.split("/")[1]).toString();
                        xLabels.push(xMonth+"月" + t +"日");
                    } else {
                        xLabels.push(("0" + (t)).slice(-2)+"時");
                    };

                    arrDiffFlg[0] = diff_flg;
                    arrDiffType[0] = diff_type;
                    arrStandardTime[0] = standard_time;
                    tmpObj = [];

                    if (pastViewData === true) {
                        tmpDate01 = fnGetDateFormat(addYears(new Date(graphDate[slideIndex].date), -1));
                    } else {
                        tmpDate01 = graphDate[slideIndex].date;
                    };

                    if (parseInt(viewType) === 2) {
                        tmpTime = tmpDate01 + "/" + ("0" + (t)).slice(-2) + "_" + ("0" + (standard_time)).slice(-2);
                    } else {
                        tmpTime = tmpDate01 + "_" + ("0" + (t)).slice(-2);
                    };
                    console.log("tmpTime:", tmpTime);
                    console.log("showData[id01]:", showData[id01]);

                    if (showData[id01]) {
                        showData[id01].forEach(item => {
                            if (item.measure_date_time == tmpTime) {
                                tmpObj = item;
                            };
                        });
                    };
                    
                    if (tmpObj) {
                        if (diff_flg === true) {
                            if (tmpObj.diff_val) {
                                tmpData = parseFloat(tmpObj.diff_val);
                            } else {
                                tmpData = tmpObj.diff_val;
                            };
                           

                        } else {
                            if (tmpObj.measure_val) {
                                tmpData = parseFloat(tmpObj.measure_val);
                            } else {
                                tmpData = tmpObj.measure_val;
                            };
                            
                        };
                        DataList1.push(tmpData);

                        if (diff_flg === true) {
                            minFlg = tmpObj.diff_threshold_min_flg;
                            maxFlg = tmpObj.diff_threshold_max_flg;
                        } else {
                            minFlg = tmpObj.threshold_min_flg;
                            maxFlg = tmpObj.threshold_max_flg;
                        };

                        if (diff_flg && diff_type) {
                            if (maxFlg == 1) {
                                BackGroundColorList1.push(limitOverColor);
                                hasErrorData1 = true;
                            } else if (minFlg == 1) {
                                BackGroundColorList1.push(limitUnderColor);
                                hasErrorData1 = true;
                            } else {
                                if (pastViewData === true) {
                                    BackGroundColorList1.push(bar2Color);
                                } else {
                                    BackGroundColorList1.push(bar1Color);
                                };
                            };
                        } else {
                            if (pastViewData === true) {
                                BackGroundColorList1.push(bar2Color);
                            } else {
                                BackGroundColorList1.push(bar1Color);
                            };
                        };

                        if (viewType == 2) {
                            toolIdx = "0_"+ parseInt(tmpDate01.split("/")[1]).toString() +"月" + t +"日";

                        } else {
                            toolIdx = "0_"+ ("0" + (t)).slice(-2) + "時";
                        };
                        toolValue[toolIdx] = tmpObj.measure_val;
                        toolMin[toolIdx] = minFlg;
                        toolMax[toolIdx] = maxFlg;
                        toolTitle[toolIdx] = tmpObj.get_datetime;
                        toolCurHour[toolIdx] = ('0' + new Date(tmpObj.get_datetime).getHours()).slice(-2) + '時';
                        toolCurDate[toolIdx] = fnGetDateFormatJp(new Date(tmpObj.get_datetime));
                        toolPrevHour[toolIdx] = ('0' + new Date(tmpObj.prev_datetime).getHours()).slice(-2) + '時';
                        if (new Date(tmpObj.prev_datetime).getMinutes() !== 0) {
                            toolPrevHour[toolIdx] += ('0' + new Date(tmpObj.prev_datetime).getMinutes()).slice(-2) + '分';
                        };
                        toolPrevDate[toolIdx] = fnGetDateFormatJp(new Date(tmpObj.prev_datetime));
                        toolPrevValue[toolIdx] = tmpObj.prev_measure_val;
                        toolDiffValue[toolIdx] = tmpObj.diff_val;
                        toolDiffFlg[toolIdx] = diff_flg;
                        toolComment[toolIdx] = tmpObj.comment;
                        toolUnit[toolIdx] = unit;
                        
                    } else {
                        DataList1.push(null);
                        if (pastViewData === true) {
                            BackGroundColorList1.push(bar2Color);
                        } else {
                            BackGroundColorList1.push(bar1Color);
                        };
                    };

                    if (pastViewData === true) {
                        arrDiffFlg[1] = diff_flg;
                        arrDiffType[1] = diff_type;
                        arrStandardTime[1] = standard_time;
                        tmpObj = [];

                        console.log("showData[id02]:", showData[id02]);
    
                        if (viewType == 2) {
                            tmpTime = graphDate[slideIndex].date + "/" + ("0" + (t)).slice(-2) + "_" + ("0" + (standard_time)).slice(-2);
                        } else {
                            tmpTime = graphDate[slideIndex].date + "_" + ("0" + (t)).slice(-2);
                        };
                        console.log("tmpTime:", tmpTime);

                        if (showData[id02]) {
                            showData[id02].forEach(item => {
                                if (item.measure_date_time == tmpTime) {
                                    tmpObj = item;
                                };
                            });
                        };
                        
                        if (tmpObj) {
                            if (diff_flg === true) {
                                if (tmpObj.diff_val) {
                                    tmpData = parseFloat(tmpObj.diff_val);
                                } else {
                                    tmpData = tmpObj.diff_val;
                                };
                                
    
                            } else {
                                if (tmpObj.measure_val) {
                                    tmpData = parseFloat(tmpObj.measure_val);
                                } else {
                                    tmpData = tmpObj.measure_val;
                                };
                                
                            };
                            DataList2.push(tmpData);

                            if (diff_flg === true) {
                                // DataList2.push(tmpObj.diff_val);
                                minFlg = tmpObj.diff_threshold_min_flg;
                                maxFlg = tmpObj.diff_threshold_max_flg;
                            } else {
                                // DataList2.push(tmpObj.measure_val);
                                minFlg = tmpObj.threshold_min_flg;
                                maxFlg = tmpObj.threshold_max_flg;
                            };
    
                            if (diff_flg && diff_type) {
                                if (maxFlg == 1) {
                                    BackGroundColorList2.push(limitOverColor);
                                    hasErrorData2 = true;
                                } else if (minFlg == 1) {
                                    BackGroundColorList2.push(limitUnderColor);
                                    hasErrorData2 = true;
                                } else {
                                    BackGroundColorList2.push(bar1Color);
                                };
                            } else {
                                BackGroundColorList2.push(bar1Color);
                            };
    
                            if (viewType == 2) {
                                toolIdx = "1_"+ parseInt(graphDate[slideIndex].date.split("/")[1]).toString() +"月" + t +"日";
    
                            } else {
                                toolIdx = "1_"+ ("0" + (t)).slice(-2) + "時";
                            };
                            toolValue[toolIdx] = tmpObj.measure_val;
                            toolMin[toolIdx] = minFlg;
                            toolMax[toolIdx] = maxFlg;
                            toolTitle[toolIdx] = tmpObj.get_datetime;
                            toolCurHour[toolIdx] = ('0' + new Date(tmpObj.get_datetime).getHours()).slice(-2) + '時';
                            toolCurDate[toolIdx] = fnGetDateFormatJp(new Date(tmpObj.get_datetime));
                            toolPrevHour[toolIdx] = ('0' + new Date(tmpObj.prev_datetime).getHours()).slice(-2) + '時';
                            if (new Date(tmpObj.prev_datetime).getMinutes() !== 0) {
                                toolPrevHour[toolIdx] += ('0' + new Date(tmpObj.prev_datetime).getMinutes()).slice(-2) + '分';
                            };
                            toolPrevDate[toolIdx] = fnGetDateFormatJp(new Date(tmpObj.prev_datetime));
                            toolPrevValue[toolIdx] = tmpObj.prev_measure_val;
                            toolDiffValue[toolIdx] = tmpObj.diff_val;
                            toolDiffFlg[toolIdx] = diff_flg;
                            toolComment[toolIdx] = tmpObj.comment;
                            toolUnit[toolIdx] = unit;
                            
                        } else {
                            DataList2.push(null);
                            BackGroundColorList2.push(bar1Color);
                        };
                    };
                };

                if (pastViewData === true) {
                    legendColor01 = bar2Color;
                    legendColor02 = bar1Color;
                } else {
                    legendColor01 = bar1Color;
                };

                console.log("DataList1:", DataList1);
                console.log("DataList2:", DataList2);

                filteredDataList1 = DataList1.filter(num => num != null);
                filteredDataList2 = DataList2.filter(num => num != null);

                maxData = Math.max(...filteredDataList1, ...filteredDataList2);
                minData = Math.min(...filteredDataList1, ...filteredDataList2);

                //最大値設定
                if (isMaxNull) {
                    if (maxData > 0) {
                        maxTemp = truncateDecimal(maxData * 12 / 10);
                        digitFloor = Math.floor(Math.log10(maxTemp)) - 1;
                        baseValue = Math.pow(10, digitFloor);
                        maxTemp = Math.ceil(maxTemp / baseValue) * baseValue;
                        stepSize = truncateDecimal(maxTemp / STEPNUM);
                    } else {
                        maxTemp = 0;
                    };
                
                    maxValue = truncateDecimal(maxTemp);
                    if (isStepNull) {
                        stepSizeValue = stepSize;
                    };
                };

                //検索の最小値は空白の場合、最小値を計算するを処理 
                if (isMinNull) {
                    if (minData >= 0) {
                        minTemp = 0;
                    } else {
                        stepSize = truncateDecimal((maxData - minData) / 10);
              
                        if (maxData > 0) {
                            maxTemp = 0;
                            while (maxTemp < maxData) {
                                maxTemp += stepSize;
                                stepCount ++;
                            };
                            maxValue = truncateDecimal(maxTemp + stepSize);
                            if (isStepNull) {
                                stepSizeValue = stepSize;
                            };
                            minTemp = 0;
                            while (minTemp >= minData) {
                                minTemp -= stepSize;
                                stepCount ++;
                            };
                            minTemp -= stepSize;
                            stepCount += 2;
                        } else {
                            if (isStepNull) {
                                stepSizeValue = stepSize;
                            };
                            minTemp = 0;
                            while (minTemp >= minData) {
                                minTemp -= stepSize;
                                stepCount ++;
                            };
                            minTemp -= stepSize;
                            stepCount += 2;			
                        };
                    };
                    minValue = truncateDecimal(minTemp);
                };

                //目盛り間隔は空白場合
                if (isStepNull) {
                    if (stepCount === 0) stepCount = STEPNUM;
                  
                    if (isMaxNull && !isMinNull) {
                        maxTemp = truncateDecimal(maxData * 12 / 10);
                        digitFloor = Math.floor(Math.log10(maxTemp)) - 1;
                        baseValue = Math.pow(10, digitFloor);
                        maxValue = truncateDecimal(Math.ceil(maxTemp / baseValue) * baseValue);		  
                        stepSizeValue = truncateDecimal((maxData - minValue) / stepCount);
              
                    } else if (!isMaxNull && isMinNull) {
                        stepSizeValue = truncateDecimal((maxValue - minValue) / STEPNUM);
                    } else if (!isMaxNull && !isMinNull) {
                        stepSizeValue = truncateDecimal((maxValue - minValue) / STEPNUM);
                    }; 
                } else {	//目盛り間隔入力した場合
                    //ﾒﾓﾘ間隔により、最大値を調整
                    if (isMaxNull) {
                        maxValue = truncateDecimal(minValue + stepSizeValue * STEPNUM);
                    };
                    if (isMinNull) {
                        if ((maxValue - minValue) / stepSizeValue > STEPNUM) {
                            minValue = truncateDecimal(maxValue - stepSizeValue * STEPNUM);		
                        } else {
                            minValue = 0;
                        };
                    };
                };

                if (isAuto) {
                    var decimalArr = [countDecimals(maxValue), countDecimals(minValue), countDecimals(stepSizeValue)];
                    decimalValue = Math.max.apply(null, decimalArr);
                    if (decimalValue > 5) decimalValue = 5;
                };
                  
                if(parseInt(maxLimit) === 0) {
                    maxLimit = maxValue + 1;
                };

                if ((isMinNull === true && isMaxNull === true && isStepNull === true) && ((parseInt(maxData) === 0 && parseInt(minData) === 0) || !(isFinite(maxData) && isFinite(minData)))) {
                    maxValue = 10;
                    minValue = 0;
                    stepSizeValue = 1;
                    decimalValue = 0;
                };

                if (parseInt(graphType) === 1) {
                    if (isMinNull === false) {
                        clipBottomValue = -minValue;
                    } else {
                        if (isStepNull === false) {
                            clipBottomValue = -minValue;
                        };
                    };
                    if (isMaxNull === false) {
                        clipTopValue = -maxValue;
                    } else {
                        if (isStepNull === false) {
                            clipTopValue = -maxValue;
                        };
                    };
                    
                } else {
                    clipValue = 5;
                    clipBottomValue = 5;
                    clipTopValue = 5;
                };

                for (let i = 0; i < DataList1.length; i++) {
                    if (DataList1[i] !== null) {
                        if (DataList1[i] > maxValue) {
                            DataList1[i] = maxValue;
                        };
    
                        if (DataList1[i] < minValue) {
                            DataList1[i] = minValue;
                        };
                    };
                    
                };

                for (let j = 0; j < DataList2.length; j++) {
                    if (DataList2[j] !== null) {
                        if (DataList2[j] > maxValue) {
                            DataList2[j] = maxValue;
                        };
    
                        if (DataList2[j] < minValue) {
                            DataList2[j] = minValue;
                        };
                    };
                    
                };

                if (pastViewData === false) {
                    datasetArray = [{
                        label: meterName[0] ? meterName[0] : '',
                        data: DataList1,
                        backgroundColor: BackGroundColorList1,
                        priorDisplay: hasErrorData1,
                        clip: {left: clipValue, right: clipValue, top: maxValue + clipTopValue, bottom: minValue + clipBottomValue}
                    }];    
                } else {
                    datasetArray = [{
                        label: meterName[0] ? meterName[0] : '',
                        data: DataList1,
                        backgroundColor: BackGroundColorList1,
                        priorDisplay: hasErrorData1,
                        clip: {left: clipValue, right: clipValue, top: maxValue + clipTopValue, bottom: minValue + clipBottomValue}
                    },
                    {
                        label: meterName[1] ? meterName[1] : '',
                        data: DataList2,
                        backgroundColor: BackGroundColorList2,
                        priorDisplay: hasErrorData2,
                        clip: {left: clipValue, right: clipValue, top: maxValue + clipTopValue, bottom: minValue + clipBottomValue}
                    }];
                };

                
                datasetArray.map(function(arr) {
                    arr.lineTension = 0;
                    arr.showLine = true;
                    arr.fill = false;
                    arr.pointRadius = 5;
                    arr.pointHoverRadius = 8;
                    arr.priorColor = limitOverColor;
                    arr.spanGaps = true;
                });

                chartInstance.current = new Chart(ctx, {
                    type: graphType == 1 ? 'bar' : 'line',
                    data: {
                        labels: xLabels,
                        datasets: datasetArray
                    },

                    options: {
                        responsive: true,
                        elements: {
                            line: {
                              borderWidth: 1.5,
                              borderColor: '#000'
                            }
                        },
                        hover: {
                            mode: 'nearest'	//複数点
                        },
                        layout: {
                            padding: {
                              top: 0,
                              bottom: 0,
                              left: 0,
                              right: 0
                            }
                        },
                        scales: {
                            y: {
                              beginAtZero: true,
                              min: minValue,
                              max: minValue > maxValue ? undefined : maxValue,
                              ticks: {
                                stepSize: stepSizeValue,
                                callback: function(value) {
                                    return value.toFixed(decimalValue);
                                }
                              },
                              grid: {
                                color: '#CCC',
                                lineWidth: 1,
                              },
                              
                            },
                            x: {
                              ticks: {
                                maxRotation: 90,
                                minRotation: 90,
                              }
                            }
                        },
                        plugins: {
                            legend: {
                              display: true,
                              labels: {
                                generateLabels: (chart) => {
                                    const datasets = chart.data.datasets;
                                    return datasets.map((dataset, i) => ({
                                      text: dataset.label,
                                      fillStyle: i == 0 ? legendColor01 : legendColor02,
                                      strokeStyle: '#FFF',
                                      lineWidth: 2,
                                      hidden: !chart.isDatasetVisible(i),
                                      datasetIndex: i,
                                    }));
                                }

                              },
                              
                            },
                            title: {
                              display: false 
                            },
                            tooltip: {
                              enabled: false,
                              external: customTooltip,
                            },    
                        },
                    },

                });

                canvasRef.current.style.display = '';

                return () => {

                    if (canvasRef.current) {
                      canvasRef.current.removeEventListener('contextmenu', handleContextMenu);
                    };
      
                    if (chartInstance.current) {
                      chartInstance.current.destroy();
                    };
        
                };

            };

            updateQueryParams();

        }, [showData, graphType]);

        const handleTopClick = () => {
            navigate('/');
        };

        return (
            <div id='Main' className={`main ${screenType}`}>
                <div id='Contents'>
                    <Breadcrumb
                        userName = {showUserName}
                        pageName = "データ推移"
                        onTopClick = {handleTopClick}
                    />
                    {(info) ? (
                        <div className='lay-search_terms_transitionGraph' id="dispCameraInfo">
                            <div className='lay-search_terms_transitionGraph-wrap'>
                                <div className='lay-search_terms_transitionGraph__list'> 
                                    <div className='lay-search_terms_transitionGraph__list__title'>
                                        <img src='img/common/icon-terms.png' alt="検索条件" />
                                    </div>
                                    <div className='lay-search_terms_transitionGraph__list__text'>
                                        {info.split('\n').map((line, index) => (
                                            <React.Fragment key={index}>
                                                {line}
                                                <br/>
                                            </React.Fragment>
                                        ))}
                                    </div>
                                </div>
                                <div className='lay-search__btn'>
                                    <label id="lbl-back" className="mod-btn__roundborder" onClick={handleGoBack}>戻る</label>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className='lay-search_terms_transitionGraph' id="dispCameraInfo" style={{display: 'none'}}></div>
                    )}
                    
                    <div className='transtabs'>
                        <input type='radio' id='trans_graph_type1' name='trans_graph_type' value={1} checked={graphType == 1} onChange={handleGraphTypeChange}></input>
                        <label className='trans_graph_item' htmlFor='trans_graph_type1'>棒型表示</label>
                        <input type='radio' id='trans_graph_type2' name='trans_graph_type' value={2} checked={graphType == 2} onChange={handleGraphTypeChange}></input>
                        <label className='trans_graph_item' htmlFor='trans_graph_type2'>折れ線表示</label>
                    </div>
                    <div className='lay-transitiongraph'>
                        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} message={modalMessage} />
                        <div className='lay-transitiongraph__fig'>
                            <div className='trans_slideshow-container' id='screenshot'>
                                <div className='trans_measure_unit'>
                                    <span>[{unit}]</span>
                                </div>
                                <div className='trans_chart_area' id='tchatArea'>
                                    <span></span>
                                    <canvas id='tchart' height="500" width="830" ref={canvasRef}></canvas>
                                    <span></span>
                                </div>
                                <div className='trans_graph_prev' style={{display: slideIndex == 0 ? 'none' : ''}} onClick={handlePrevMove}>&#10094;</div>
                                <div className='trans_graph_next' style={{display: slideIndex == (graphDate.length - 1) ? 'none' : ''}} onClick={handleNextMove}>&#10095;</div>
                            </div>
                            <div className='transdots'>
                                {graphDate.map((dateInfo, key) => (
                                    <span className={dateInfo.className} key={key} id={`currentSlide${key}`} value={dateInfo.date} onClick={() => handleChangeSlide(key, dateInfo)}></span>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className='lay-trans_graph__btn'>
                        <ul>
                            <li>
                            <button type='button' className='mod-btn__round' id='btn-past' style={{display: pastViewData == false ? '' : 'none'}} onClick={handleChangeGraphMode}>昨対表示ON</button>
                            <button type='button' className='mod-btn__round' id='btn-now' style={{display: pastViewData == true ? '' : 'none'}} onClick={handleChangeGraphMode}>昨対表示OFF</button>
                            </li>
                            <li>
                                <button type='button' className='mod-btn__round' id='graphImageDownload' onClick={handleDownloadImage}>グラフ画像ダウンロード</button>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={`overlay ${isLoading ? 'active' : ''}`}>
                  <div className={`spinner ${isLoading ? 'active' : ''}`}>
                  </div>
                </div>


            </div>

        );

    }


);

export default TransitionGraph;
