import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual  } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { updateMenu } from '../../actions/menuActions';
import { setSelectedItem } from '../../actions/datamanage';
import { fetchMenuData } from '../../actions/menuActions';
import './Menu.css';
import DataManagementSection from './DataManagementSection';
import CameraSettingsSection from './CameraSettingsSection';
import GraphSection from './GraphSection';
import SettingsSection from './SettingsSection';
import Breadcrumb from '../Breadcrumb';
import { setScreenType } from '../../actions/deviceActions';

const Menu = ({ logout = () => {}, refreshAccessToken = () => {}, fetchUserData, user }) => {
    const [loading, setLoading] = useState(true);
    const [isUserInfoOpen, setIsUserInfoOpen] = useState(false);
    const [isUserInfoVisible, setIsUserInfoVisible] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    // const [isLandscape, setIsLandscape] = useState(window.matchMedia('(orientation: landscape)').matches);
    const [activeItem, setActiveItem] = useState(null);
    const [activeSet, setActiveSet] = useState(null);
    const [showUserName, setUserName] = useState('');
    const screenType = useSelector((state) => state.device.screenType, shallowEqual);
    const authorityClass = useSelector((state) => state.login.user?.authority_class);
    const MANAGEMENT_COMPANY_ID_HAKARU = useSelector((state) => state.login.user?.management, shallowEqual);
    const isManagementCompany = MANAGEMENT_COMPANY_ID_HAKARU === 1 && authorityClass === 9;
    const [manualToggle, setManualToggle] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const { dataManagementMenu, cameraSettingsMenu, adminData } = useSelector(
        (state) => state.menu,
        shallowEqual
    );

    useEffect(() => {
        fetchUserData();
    }, [fetchUserData]);

    const handleNavigationState = (pathname, searchParams) => {
        // Ensure `searchParams` is an instance of URLSearchParams
        if (!(searchParams instanceof URLSearchParams)) {
            searchParams = new URLSearchParams(pathname.split('?')[1] || '');
        }
    
        const routesMapping = [
            {
                patterns: ['/data-manage'],
                activeSet: 'dataManagement',
                activeItem: () => {
                    const companyId = searchParams.get('company_id');
                    const facilityId = searchParams.get('facility_id');
                    const cameraId = searchParams.get('device_id');
                    const gaugeId = searchParams.get('gauge_id');
                    const isHistory = searchParams.get('history') === 'true';
    
                    return {
                        company_id: companyId,
                        facility_id: isHistory ? null : facilityId,
                        camera_id: isHistory ? null : cameraId,
                        gauge_id: isHistory ? null : gaugeId,
                        isHistory,
                        type: isHistory
                            ? 'history'
                            : gaugeId
                            ? 'gauge'
                            : cameraId
                            ? 'camera'
                            : facilityId
                            ? 'facility'
                            : 'company',
                    };
                },
            },
            {
                patterns: ['/companyList','/facilityList', '/facilityEdit', '/cameraEdit', '/gaugeEdit', '/measureEdit', '/facilityNew', '/cameraNew', '/measureNew'],
                activeSet: 'cameraSettings',
                activeItem: () => {
                    const companyId = searchParams.get('company_id');
                    const facilityId = searchParams.get('facility_id');
                    const cameraId = searchParams.get('device_id');
                    const gaugeId = searchParams.get('gauge_id');
                    const measureId = searchParams.get('measure_id');
    
                    return {
                        company_id: companyId,
                        facility_id: facilityId,
                        camera_id: cameraId,
                        gauge_id: gaugeId,
                        measure_id: measureId,
                        type: pathname.split('/')[1],
                    };
                },
            },
            {
                patterns: ['/batterygraphlist', '/batterygraph'],
                activeSet: 'graph',
                activeItem: () => ({ id: 'battery_graph' }),
            },
            {
                patterns: ['/transitiongraphlist', '/transitiongraph'],
                activeSet: 'graph',
                activeItem: () => ({ id: 'transition_graph' }),
            },
            {
                patterns: ['/comparisongraphlist', '/comparisongraph'],
                activeSet: 'graph',
                activeItem: () => ({ id: 'comparison_graph' }),
            },
            {
                patterns: [
                    '/mailSendSetting',
                    '/confirmFirstMonth',
                    '/confirmCameraCount',
                    '/userNameChange',
                    '/idPasswordChange',
                    '/setting2FA',
                    '/cameraChange',
                    '/loginHistory',
                ],
                activeSet: 'settings',
                activeItem: () => ({
                    id: pathname.split('/').pop(),
                }),
            },
        ];
    
        let activeSet = null;
        let activeItem = null;
    
        for (const route of routesMapping) {
            if (route.patterns.some((pattern) => pathname.includes(pattern))) {
                activeSet = route.activeSet;
                activeItem = route.activeItem();
                break;
            }
        }
    
        setActiveSet(activeSet);
        setActiveItem(activeItem);
    
        if (dispatch && typeof dispatch === 'function') {
            dispatch(setSelectedItem(activeItem));
        }
    };
    
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        handleNavigationState(location.pathname, searchParams);
    
        const handlePopState = () => {
            const newSearchParams = new URLSearchParams(window.location.search);
            handleNavigationState(window.location.pathname, newSearchParams);
        };
    
        window.addEventListener('popstate', handlePopState);
        return () => window.removeEventListener('popstate', handlePopState);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname, location.search]);
    
    useEffect(() => {
        if (location.pathname === '/') {
            setActiveItem(null);
            setActiveSet(null);
        }
    }, [location.pathname]);

    // Add a state or use redux to manage authentication/token
    const token = localStorage.getItem('accessToken');

    useEffect(() => {
        if (!token) return;  
        
        const fetchData = async () => {
            setLoading(true);
            try {
                await dispatch(fetchMenuData()); 
            } catch (error) {
                console.error('Error fetching menu data:', error);
            } finally {
                setLoading(false);
            }
        };
    
        fetchData();
    }, [token, dispatch]); 
    
    // Handle orientation changes
    // useEffect(() => {
    //     const handleOrientationChange = (event) => {
    //         setIsLandscape(event.matches);
    //     };

    //     const landscapeMatcher = window.matchMedia('(orientation: landscape)');
    //     landscapeMatcher.addEventListener('change', handleOrientationChange);

    //     return () => {
    //         landscapeMatcher.removeEventListener('change', handleOrientationChange);
    //     };
    // }, []);

    // Toggle menu visibility based on screen size
    // useEffect(() => {
    //     const handleResize = () => {
    //         const isSmallDevice = window.innerWidth < 1024;
    //         setIsMenuOpen(!isSmallDevice);
    //     };

    //     window.addEventListener('resize', handleResize);
    //     handleResize();

    //     return () => {
    //         window.removeEventListener('resize', handleResize);
    //     };
    // }, []);

    useEffect(() => {
        const detectDevice = () => {
            const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        
            const isTablet = /Macintosh|iPad/.test(userAgent) || 
                           ((navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1) || 
                           (navigator.platform === 'Win32' && navigator.maxTouchPoints > 0));
        
            const isMobile = /iPhone|Android.+Mobile/.test(userAgent) || (window.screen.width < 768);
    
            const isIpadProDesktop = isTablet && 
                                     window.devicePixelRatio === 2 &&
                                     window.screen.width === 1024; 
                                     
            const isLargeScreen = window.screen.width >= 1024;
            let newScreenType = 'desktop';
    
            if (isMobile || window.screen.width < 768) {
                newScreenType = 'mobile';
                setIsMenuOpen(false);
            } else if (isTablet && window.screen.width < 1024) {
                newScreenType = 'tablet';
                setIsMenuOpen(false);
            } else if (isIpadProDesktop) {
                newScreenType = 'ipadpro';
                setIsMenuOpen(true);
            } else if (isLargeScreen) {
                newScreenType = 'desktop';
                setIsMenuOpen(true);
            }
            dispatch(setScreenType(newScreenType));
        
            document.body.className = '';
            document.body.classList.add(newScreenType);
        };
        
        detectDevice();
        window.addEventListener('resize', detectDevice);
        
        return () => {
            window.removeEventListener('resize', detectDevice);
        };
    }, [dispatch]);
    
    useEffect(() => {
        if (user) {
          setUserName(user.user_name);
        };
    }, [user]);

    useEffect(() => {
        if (authorityClass == null || MANAGEMENT_COMPANY_ID_HAKARU == null) {
            // If the data is not ready, close all menus
            const closedDataManagementMenu = dataManagementMenu.map((company) => ({
                ...company,
                isOpen: false,
            }));
            const closedCameraSettingsMenu = cameraSettingsMenu.map((company) => ({
                ...company,
                isOpen: false,
            }));

            if (
                JSON.stringify(dataManagementMenu) !== JSON.stringify(closedDataManagementMenu) ||
                JSON.stringify(cameraSettingsMenu) !== JSON.stringify(closedCameraSettingsMenu)
            ) {
                dispatch(updateMenu('dataManagement', closedDataManagementMenu));
                dispatch(updateMenu('cameraSettings', closedCameraSettingsMenu));
            }
            return; 
        }

        // When the data is ready, check conditions to automatically open menus
        if (!manualToggle && !isManagementCompany) {
            const dataMenuNeedsUpdate = dataManagementMenu.some((company) => !company.isOpen);
            if (dataMenuNeedsUpdate) {
                const updatedDataMenu = dataManagementMenu.map((company) => ({
                    ...company,
                    isOpen: true,
                }));
                dispatch(updateMenu('dataManagement', updatedDataMenu));
            }
            
            const cameraMenuNeedsUpdate = cameraSettingsMenu.some((company) => !company.isOpen);
            if (cameraMenuNeedsUpdate) {
                const updatedCameraMenu = cameraSettingsMenu.map((company) => ({
                    ...company,
                    isOpen: true,
                }));
                dispatch(updateMenu('cameraSettings', updatedCameraMenu));
            }
        }
        // eslint-disable-next-line
    }, [isManagementCompany, dataManagementMenu, cameraSettingsMenu, dispatch, manualToggle]);
    
    const toggleMenu = (index, level, menuType, parentIndex = null, grandParentIndex = null, greatGrandParentIndex = null) => {
        setManualToggle(true);
    
        let targetMenu;
        if (menuType === 'dataManagement') {
            targetMenu = [...dataManagementMenu]; 
        } else {
            targetMenu = [...cameraSettingsMenu]; 
        }
    
        if (level === 'company') {
            targetMenu[index] = {
                ...targetMenu[index],
                isOpen: !targetMenu[index].isOpen, 
            };
        } else if (level === 'facility') {
            targetMenu[parentIndex].facility[index].isOpen = !targetMenu[parentIndex].facility[index].isOpen;
        } else if (level === 'camera') {
            targetMenu[grandParentIndex].facility[parentIndex].camera[index].isOpen = !targetMenu[grandParentIndex].facility[parentIndex].camera[index].isOpen;
        } else if (level === 'gauge') {
            targetMenu[greatGrandParentIndex].facility[grandParentIndex].camera[parentIndex].gauge[index].isOpen = !targetMenu[greatGrandParentIndex].facility[grandParentIndex].camera[parentIndex].gauge[index].isOpen;
        }
    
        if (menuType === 'dataManagement') {
            dispatch(updateMenu('dataManagement', targetMenu)); 
        } else {
            dispatch(updateMenu('cameraSettings', targetMenu));
        }
    };

    const handleLogoClickBack = () => {
        const closeAllSections = (menu) => {
            return menu.map(company => {
                if (isManagementCompany && !manualToggle) {
                    return {
                        ...company,
                        facility: company.facility.map(facility => ({
                            ...facility,
                            isOpen: false,
                            camera: facility.camera.map(camera => ({
                                ...camera,
                                isOpen: false,
                                gauge: camera.gauge.map(gauge => ({
                                    ...gauge,
                                    isOpen: false
                                }))
                            }))
                        }))
                    };
                }
                return {
                    ...company,
                    isOpen: false,
                    facility: company.facility.map(facility => ({
                        ...facility,
                        isOpen: false,
                        camera: facility.camera.map(camera => ({
                            ...camera,
                            isOpen: false,
                            gauge: camera.gauge.map(gauge => ({
                                ...gauge,
                                isOpen: false
                            }))
                        }))
                    }))
                };
            });
        };
    
        const closedDataManagementMenu = closeAllSections(dataManagementMenu);
        const closedCameraSettingsMenu = closeAllSections(cameraSettingsMenu);
    
        dispatch(updateMenu('dataManagement', closedDataManagementMenu));
        dispatch(updateMenu('cameraSettings', closedCameraSettingsMenu));
    
        setActiveItem(null);
        setActiveSet(null); // Reset active menu
        navigate('/');

        if (screenType === 'mobile' || screenType === 'tablet') {
            setIsMenuOpen(false);
        }
    };
    

    const toggleSubmenu = () => {
        setIsUserInfoOpen(!isUserInfoOpen);
    };

    const toggleMenuVisibility = () => {
        const openMenu = !isMenuOpen;
        setIsMenuOpen(openMenu);

        // if (openMenu && isLandscape) {
        //     document.body.classList.add('force-portrait');
        // } else {
        //     document.body.classList.remove('force-portrait');
        // } 
    };

    const toggleUserInfoVisibility = () => {
        setIsUserInfoVisible(!isUserInfoVisible); 
    };

    const handleLogoClick = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        const handleUpdateActiveItem = (event) => {
            const activeItem = event.detail;
            if (activeItem) {
                // Set the active item
                setActiveItem(activeItem);
                setActiveSet('dataManagement');
                dispatch(setSelectedItem(activeItem));
                // Dynamically open parent levels
                const { company_id, facility_id, camera_id } = activeItem;
    
                // Update menu in Redux
                const updatedMenu = dataManagementMenu.map((company) => {
                    if (String(company.company_id) === String(company_id)) {
                        return {
                            ...company,
                            isOpen: true, // Open company level
                            facility: company.facility.map((facility) => {
                                if (facility_id && String(facility.facility_id) === String(facility_id)) {
                                    return {
                                        ...facility,
                                        isOpen: true, // Open facility level
                                        camera: facility.camera.map((camera) => {
                                            if (camera_id && String(camera.device_id) === String(camera_id)) {
                                                return {
                                                    ...camera,
                                                    isOpen: true, // Open camera level
                                                };
                                            }
                                            return camera;
                                        }),
                                    };
                                }
                                return facility;
                            }),
                        };
                    }
                    return company;
                });
    
                // Dispatch the updateMenu action
                dispatch(updateMenu('dataManagement', updatedMenu));
            }
        };
    
        window.addEventListener('updateActiveItem', handleUpdateActiveItem);
    
        return () => {
            window.removeEventListener('updateActiveItem', handleUpdateActiveItem);
        };
    }, [dataManagementMenu, dispatch]);
    
    if (loading) return (
        <div className={`menu-load ${screenType}`}>
            <div className="spinner"></div>
        </div>
    );

    return (
        <>
            <div className={`menu-background-layer ${screenType}`}>
                <div className={`menu-header ${screenType}`}>
                    <img className={`logo-header ${screenType}`} src="img/common/navi/logo.png" alt="Logo" onClick={handleLogoClick} />
                    <img
                        className={`logo-user ${screenType}`}
                        src="img/common/user.svg"
                        alt=""
                        style={{
                            width: '24px',
                            height: '24px',
                            color: 'rgb(0,145,167)',
                            position: 'fixed',
                            zIndex: '8',
                            right: '10px',
                            top: '14px',
                            cursor: 'pointer', 
                        }}
                        onClick={toggleUserInfoVisibility}
                    />
                    <div className={`user-info ${screenType} ${isUserInfoVisible ? 'visible' : ''}`}>
                        <Breadcrumb
                            userName={showUserName}
                            pageName=""
                            isTopPage={true}
                        />
                    </div>
                </div>
            </div>
            <button className={`menu-toggle-button ${screenType}`} onClick={toggleMenuVisibility}>
                <div className={`menu-toggle-button-inner ${screenType} ${isMenuOpen ? 'is-open' : ''}`}>
                    <div className={`menu-icon ${screenType}`}></div>
                    <div className={`close-icon ${screenType}`}></div>
                </div>
            </button>
            <div className="ajax-sidemenu">
                <div className={`lay-sidemenu ${screenType} ${isMenuOpen ? 'open' : 'hidden'}`}>
                    <div className="lay-sidemenu-wrap">
                        <div className="lay-sidemenu__logo">
                            <img className="menuIcon" src="img/common/navi/logo.png" alt="Logo" onClick={handleLogoClickBack} style={{cursor: 'pointer'}}/>
                        </div>
                        {/* Data Management Menu */}
                        <DataManagementSection
                            menuData={dataManagementMenu}
                            activeItem={activeItem}
                            setActiveItem={setActiveItem}
                            toggleMenu={toggleMenu}
                            adminData={adminData}
                            activeSet={activeSet}
                            setActiveSet={setActiveSet}
                            setIsMenuOpen={setIsMenuOpen}
                            screenType={screenType}
                            updateMenu={updateMenu}
                            authorityClass={authorityClass}
                        />

                        {/* Camera Settings Menu */}
                        {authorityClass !== 0 &&
                            <CameraSettingsSection
                                menuData={cameraSettingsMenu}
                                activeItem={activeItem}
                                setActiveItem={setActiveItem}
                                toggleMenu={toggleMenu}
                                adminData={adminData}
                                activeSet={activeSet}
                                setActiveSet={setActiveSet}
                                setIsMenuOpen={setIsMenuOpen}
                                screenType={screenType}
                                isManagementCompany={isManagementCompany}
                            />
                        }

                        {/* Graph Menu */}
                        <GraphSection
                            activeItem={activeItem}
                            setActiveItem={setActiveItem}
                            activeSet={activeSet}
                            setActiveSet={setActiveSet}
                            setIsMenuOpen={setIsMenuOpen}
                            screenType={screenType}
                        />

                        {/* Settings Menu */}
                        <SettingsSection
                            logout={logout}
                            activeItem={activeItem}
                            setActiveItem={setActiveItem}
                            setIsUserInfoOpen={setIsUserInfoOpen}
                            isUserInfoOpen={isUserInfoOpen}
                            toggleSubmenu={toggleSubmenu}
                            activeSet={activeSet}
                            setActiveSet={setActiveSet}
                            setIsMenuOpen={setIsMenuOpen}
                            screenType={screenType}
                            isManagementCompany={isManagementCompany}
                        />

                        {/* Footer Menu */}
                        <div className="lay-sidemenu__copyright">
                            <img src="img/common/navi/copyright.png" alt="Hakaru+ Copyright(C) HAKARU PLUS Corporation. All Rights Reserved." />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default React.memo(Menu);
