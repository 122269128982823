// index.js in the 'reducers' directory

import { combineReducers } from 'redux';
import loginReducer from './login';
import systemInfoReducer from './systemInfoReducer';
import selectedItemReducer from './selectedItemReducer';
import dataManageReducer from './datamanageReducer';
import batteryGraphListReducer from './batteryGraphListReducer';
import batteryGraphReducer from './batteryGraphReducer';
import comparisonGraphListReducer from './comparisonGraphListReducer';
import comparisonGraphReducer from './comparisonGraphReducer';
import companyListReducer from './companyListReducer';
import facilityNewReducer from './facilityNewReducer';
import facilityListReducer from './facilityListReducer';
import facilityEditReducer from './facilityEditReducer';
import cameraNewReducer from './cameraNewReducer';
import cameraEditReducer from './cameraEditReducer';
import gaugeEditReducer from './gaugeEditReducer';
import measureNewReducer from './measureNewReducer';
import measureEditReducer from './measureEditReducer';
import menuReducer from './menuReducer';
import transitionGraphListReducer from './transitionGraphListReducer';
import transitionGraphReducer from './transitionGraphReducer';
import mailSendSettingReducer from './mailSendSettingReducer';
import confirmFirstMonthReducer from './confirmFirstMonthReducer';
import confirmCameraCountReducer from './confirmCameraCountReducer';
import userNameChangeReducer from './userNameChangeReducer';
import idPasswordChangeReducer from './idPasswordChangeReducer';
import setting2FAReducer from './setting2FAReducer';
import cameraChangeReducer from './cameraChangeReducer';
import loginHistoryReducer from './loginHistoryReducer';
import deviceReducer from './deviceReducer';
import maintenanceReducer from './maintenanceReducer';

const rootReducer = combineReducers({
  login: loginReducer,
  systemInfo: systemInfoReducer,
  selectedItem: selectedItemReducer,
  dataManage: dataManageReducer,
  batteryGraphList: batteryGraphListReducer,
  batteryGraph: batteryGraphReducer,
  comparisonGraphList: comparisonGraphListReducer,
  comparisonGraph: comparisonGraphReducer,
  companyList: companyListReducer,
  facilityNew: facilityNewReducer,
  facilityList: facilityListReducer,
  facilityData: facilityEditReducer,
  cameraNew: cameraNewReducer,
  cameraEdit: cameraEditReducer,
  gaugeEdit: gaugeEditReducer,
  measureNew: measureNewReducer,
  measureEdit: measureEditReducer,
  menu: menuReducer,
  transitionGraphList: transitionGraphListReducer,
  transitionGraph: transitionGraphReducer,
  mailSendSetting: mailSendSettingReducer,
  confirmFirstMonth: confirmFirstMonthReducer,
  confirmCameraCount: confirmCameraCountReducer,
  userName: userNameChangeReducer,
  idPassword: idPasswordChangeReducer,
  setting2FA: setting2FAReducer,
  cameraChange: cameraChangeReducer,
  loginHistory: loginHistoryReducer,
  device: deviceReducer,
  maintenance: maintenanceReducer,
});

export default rootReducer;
