import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DatePicker, { registerLocale } from 'react-datepicker';
import { addDays, format } from 'date-fns';
import { ja } from 'date-fns/locale';

registerLocale('ja', ja);

// Custom hook for managing filtered data
const useFilteredData = (facilities, cameras, gauges, selectedFacility, selectedCamera,) => {
    const [filteredCamera, setFilteredCamera] = useState(cameras);
    const [filteredGauges, setFilteredGauges] = useState(gauges);

    useEffect(() => {
        if (selectedFacility) {
            setFilteredCamera(cameras.filter(camera => camera.facility_id === parseInt(selectedFacility)));
        } else {
            setFilteredCamera(cameras);
        }
    }, [selectedFacility, cameras]);

    useEffect(() => {
        if (selectedFacility && selectedCamera) {
            setFilteredGauges(gauges.filter(gauge => {
                const cameraForGauge = cameras.find(camera => camera.device_id === gauge.device_id);
                return (
                    cameraForGauge &&
                    cameraForGauge.facility_id === parseInt(selectedFacility) &&
                    gauge.device_id === selectedCamera
                );
            }));
        } else if (selectedFacility) {
            setFilteredGauges(gauges.filter(gauge => {
                const cameraForGauge = cameras.find(camera => camera.device_id === gauge.device_id);
                return cameraForGauge && cameraForGauge.facility_id === parseInt(selectedFacility);
            }));
        } else if (selectedCamera) {
            setFilteredGauges(gauges.filter(gauge => gauge.device_id === selectedCamera));
        } else {
            setFilteredGauges(gauges);
        }
    }, [selectedFacility, selectedCamera, cameras, gauges]);

    return { filteredCamera, filteredGauges };
};

const SearchForm = ({
    facilities,
    cameras,
    gauges,
    statuses,
    onSearch,
    selectedFacility,
    selectedCamera,
    selectedGauge,
    setSelectedFacility,
    setSelectedCamera,
    setSelectedGauge,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    screenType
}) => {
    // Use the custom hook to manage filtered data
    const { filteredCamera, filteredGauges } = useFilteredData(
        facilities, cameras, gauges, selectedFacility, selectedCamera
    );
    const [shouldDisableFocus, setShouldDisableFocus] = useState(false);

    useEffect(() => {
        const checkMediaQueries = () => {
            const condition1 = window.matchMedia('(min-width: 577px) and (max-width: 767px)').matches;
            const condition2 = window.matchMedia('(max-width: 576px)').matches;
            const condition3 = window.matchMedia('(max-height: 576px) and (orientation: landscape)').matches;
            setShouldDisableFocus(condition1 || condition2 || condition3);
        };

        checkMediaQueries();

        window.addEventListener('resize', checkMediaQueries);

        return () => {
            window.removeEventListener('resize', checkMediaQueries);
        };
    }, []);

    useEffect(() => {
        setEndDate(new Date());
        setStartDate(addDays(new Date(), -1));
    }, [setStartDate, setEndDate]);

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleFacilityChange = (e) => {
        const facilityId = e.target.value;
        setSelectedFacility(facilityId);

        // Reset camera and gauge selections when facility changes
        setSelectedCamera('');
        setSelectedGauge('');
    };

    const handleCameraChange = (e) => {
        const cameraId = e.target.value;
        setSelectedCamera(cameraId);
    
        if (cameraId) {
            const selectedCamera = cameras.find(camera => camera.device_id === cameraId);
    
            if (selectedCamera) {
                const facilityForCamera = facilities.find(facility => facility.facility_id === selectedCamera.facility_id);
    
                if (facilityForCamera) {
                    setSelectedFacility(facilityForCamera.facility_id.toString());
                }
            }
        } else {
            setSelectedFacility('');
        }
        setSelectedGauge('');
    };    

    const handleGaugeChange = (e) => {
        const gaugeId = e.target.value;
        setSelectedGauge(gaugeId);
    
        if (gaugeId) {
            const selectedGauge = gauges.find(gauge => gauge.gauge_id === gaugeId);
            if (selectedGauge) {
                const cameraForGauge = cameras.find(camera => camera.device_id === selectedGauge.device_id);
                
                if (cameraForGauge) {
                    setSelectedCamera(cameraForGauge.device_id);

                    const facilityForCamera = facilities.find(facility => facility.facility_id === cameraForGauge.facility_id);
                    if (facilityForCamera) {
                        setSelectedFacility(facilityForCamera.facility_id.toString());
                    }
                }
            }
        } else {
            setSelectedGauge('');
        }
    };

    const handleSearch = () => {
        onSearch(startDate, endDate, selectedFacility, selectedCamera, selectedGauge);
    };

    const highlightWeekends = (date) => {
        const day = date.getDay();
        return day === 0 ? "highlight-sunday" : day === 6 ? "highlight-saturday" : "";
    };

    return (
        <div className={`lay-search-wrap ${screenType}`}>
            <div className="lay-search__title">
                <span>データ検索</span>
            </div>
            <div className="lay-search__lead">それぞれ条件を入力し「検索」ボタンをクリックしてください。</div>
            <form id="searchNewData" className={`${screenType}`}>
                <div className={`lay-search__form ${screenType}`}>
                    <div className={`lay-search__form__col1 ${screenType}`}>
                        <ul>
                            <li>
                                <div className="mod-form__set">
                                    <dl>
                                        <dt>期間</dt>
                                        <dd>
                                            <div className={`mod-form__text ${screenType} calendar`}>
                                                <DatePicker
                                                    selected={startDate}
                                                    onChange={handleStartDateChange}
                                                    dayClassName={highlightWeekends}
                                                    locale="ja"
                                                    dateFormat="yyyy/MM/dd"
                                                    className={`custom-datepicker ${screenType}`}
                                                    calendarStartDay={1}
                                                    onFocus={shouldDisableFocus ? (e) => e.target.blur() : undefined}
                                                    renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
                                                        <div className="custom-header">
                                                            <button style={{float: 'left'}} onClick={(e) => {
                                                                e.preventDefault();
                                                                decreaseMonth();
                                                            }}>{'<'}</button>
                                                            
                                                            <span>{format(date, 'yyyy年')}</span>
                                                            <span>{format(date, 'MM')}月</span>
                                                            
                                                            <button style={{float: 'right'}} onClick={(e) => {
                                                                e.preventDefault(); 
                                                                increaseMonth();
                                                            }}>{'>'}</button>
                                                        </div>
                                                    )}
                                                    onKeyDown={(e) => e.preventDefault()}
                                                />
                                            </div>
                                        </dd>
                                    </dl>
                                </div>
                            </li>
                            <div className={`mod-form__set-between ${screenType}`}>〜</div>
                            <li>
                                <div className={`mod-form__set ${screenType}`}>
                                    <dl>
                                        <dt className='class_hidden'><div>〜</div></dt>
                                        <dd>
                                            <div className={`mod-form__text ${screenType} second-calendar`}>
                                                <DatePicker
                                                    selected={endDate}
                                                    onChange={handleEndDateChange}
                                                    dayClassName={highlightWeekends}
                                                    locale="ja"
                                                    dateFormat="yyyy/MM/dd"
                                                    className={`custom-datepicker ${screenType}`}
                                                    calendarStartDay={1}
                                                    onFocus={shouldDisableFocus ? (e) => e.target.blur() : undefined}
                                                    renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
                                                        <div className="custom-header">
                                                            <button style={{float: 'left'}} onClick={(e) => {
                                                                e.preventDefault();
                                                                decreaseMonth();
                                                            }}>{'<'}</button>
                                                            
                                                            <span>{format(date, 'yyyy年')}</span>
                                                            <span>{format(date, 'MM')}月</span>
                                                            
                                                            <button style={{float: 'right'}} onClick={(e) => {
                                                                e.preventDefault(); 
                                                                increaseMonth();
                                                            }}>{'>'}</button>
                                                        </div>
                                                    )}
                                                    onKeyDown={(e) => e.preventDefault()}
                                                />
                                            </div>
                                        </dd>
                                    </dl>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className={`lay-search__form__col2 ${screenType}`}>
                        <ul>
                            <li>
                                <div className={`mod-form__set ${screenType}`}>
                                    <dl>
                                        <dt>施設名</dt>
                                        <dd>
                                            <div className="mod-form__select">
                                                <select id="facility" name="facility_id" value={selectedFacility || ''} onChange={handleFacilityChange}>
                                                    <option value=""></option>
                                                    {facilities.map((facility) => (
                                                        <option key={facility.facility_id} value={facility.facility_id}>
                                                            {facility.facility_name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </dd>
                                    </dl>
                                </div>
                            </li>
                            <li>
                                <div className={`mod-form__set ${screenType}`}>
                                    <dl>
                                        <dt>カメラ名</dt>
                                        <dd>
                                            <div className="mod-form__select">
                                                <select id="device_id" name="device_id" value={selectedCamera || ''} onChange={handleCameraChange}>
                                                    <option value=""></option>
                                                    {filteredCamera.map((camera) => (
                                                        <option key={camera.device_id} value={camera.device_id}>
                                                            {camera.display_camera_name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </dd>
                                    </dl>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className={`lay-search__form__col2 ${screenType}`}>
                        <ul>
                            <li>
                                <div className={`mod-form__set ${screenType}`}>
                                    <dl>
                                        <dt>計器名</dt>
                                        <dd>
                                            <div className="mod-form__select">
                                                <select id="gauge_id" name="gauge_id" value={selectedGauge || ''} onChange={handleGaugeChange}>
                                                    <option value=""></option>
                                                    {filteredGauges.map((gauge) => (
                                                        <option key={gauge.gauge_id} value={gauge.gauge_id}>
                                                            {gauge.display_gauge_name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </dd>
                                    </dl>
                                </div>
                            </li>
                            <li>
                                <div className={`mod-form__set ${screenType}`}>
                                    <dl>
                                        <dt>状態</dt>
                                        <dd>
                                            <div className="mod-form__select">
                                                <select id="status" name="status">
                                                    <option value=""></option>
                                                    {statuses.map((status, index) => (
                                                        <option key={index} value={index}>
                                                            {status}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </dd>
                                    </dl>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className={`lay-search__form__col2 ${screenType}`}>
                        <ul>
                            <li>
                                <div className={`mod-form__set ${screenType}`}>
                                    <dl>
                                        <dt>コメント</dt>
                                        <dd>
                                            <div className="mod-form__select">
                                                <select id="search_comment" name="search_comment">
                                                    <option value=""></option>
                                                    <option value="0">無</option>
                                                    <option value="1">有</option>
                                                </select>
                                            </div>
                                        </dd>
                                    </dl>
                                </div>
                            </li>
                            <li>
                                <div className={`mod-form__set ${screenType}`}>
                                    <dl>
                                        <dt>しきい値</dt>
                                        <dd>
                                            <div className="mod-form__select">
                                                <select id="threshold" name="threshold">
                                                    <option value=""></option>
                                                    <option value="0">以下</option>
                                                    <option value="1">以上</option>
                                                </select>
                                            </div>
                                        </dd>
                                    </dl>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </form>
            <div className="button-container">
                <div className="lay-search__btn">
                    <button type="button" className="mod-btn__round" id="search" onClick={handleSearch}>
                        検索
                    </button>
                </div>
            </div>
        </div>
    );
};

SearchForm.propTypes = {
    facilities: PropTypes.array.isRequired,
    cameras: PropTypes.array.isRequired,
    gauges: PropTypes.array.isRequired,
    statuses: PropTypes.array.isRequired,
    onSearch: PropTypes.func.isRequired,
    selectedFacility: PropTypes.string.isRequired,
    selectedCamera: PropTypes.string.isRequired,
    selectedGauge: PropTypes.string.isRequired,
    setSelectedFacility: PropTypes.func.isRequired,
    setSelectedCamera: PropTypes.func.isRequired,
    setSelectedGauge: PropTypes.func.isRequired,
    startDate: PropTypes.instanceOf(Date).isRequired,
    endDate: PropTypes.instanceOf(Date).isRequired,
    setStartDate: PropTypes.func.isRequired,
    setEndDate: PropTypes.func.isRequired
};

export default SearchForm;
