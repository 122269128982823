import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Modal from '../Modal/Modal';
import './CameraNew.css';
import { useDispatch, useSelector } from 'react-redux'; 
import { fetchMenuData  } from '../../actions/menuActions';
import Breadcrumb from '../Breadcrumb';

const CameraNew = ({ user, registerCamera, loading, error, fetchCameraList, cameraList }) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate ();
    const [companyId, setCompanyId] = useState('');
    const [facilityId, setFacilityId] = useState('');
    const [cameraName, setCameraName] = useState('');
    const [deviceId, setDeviceId] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const screenType = useSelector((state) => state.device.screenType);
    const [showUserName, setUserName] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const company_id = Number(params.get('company_id'))
        const facility_id = params.get('facility_id');
        if (!isNaN(company_id) && facility_id) {
            setCompanyId(company_id);
            setFacilityId(facility_id);
            fetchCameraList(company_id, facility_id);
        }

    }, [fetchCameraList, location]);

    useEffect(() => {
        if (user) {
          setUserName(user.user_name);
        };
    }, [user]);

    // useEffect(() => {
    //     if (isLoadingPage) {
    //         setFacilityName(''); 
    //     }
    // }, [isLoadingPage]);

    const fetchUpdatedMenu = async () => {
        try {
            // Instead of calling the API here, dispatch fetchMenuData action
            await dispatch(fetchMenuData());
        } catch (error) {
            console.error('Error fetching updated menu:', error);
        }
    };

    const handleRegister = async () => {
        try {
            setIsLoading(true);
    
            const response = await registerCamera(cameraName, companyId, facilityId, deviceId);
            const message = response.data.respons?.msg;

            await Promise.all([
                fetchUpdatedMenu(),
                fetchCameraList(companyId, facilityId)
            ]);
            setCameraName("");

            setModalMessage(message);
            setIsModalOpen(true);
    
        } catch (error) {
            setModalMessage(error.message);
            setIsModalOpen(true);
        } finally {
            setIsLoading(false);
        }
    };

    const handleTopClick = () => {
        navigate('/');
    };
    
    return (
        <div id="Main" className={`main ${screenType}`}>
            <div id="Contents">
                <Breadcrumb
                    userName = {showUserName}
                    pageName = "カメラ・計器設定"
                    onTopClick = {handleTopClick}
                />
                {isModalOpen && (
                    <Modal
                        isOpen={isModalOpen}
                        onClose={() => setIsModalOpen(false)}
                        message={modalMessage}
                    />
                )}

                    <div id="ajax-view-disp">
                        <div className="display-main">
                            <div className={`lay-master-set ${screenType}`}>
                                <div className="lay-master-set-wrap">
                                    <div className="lay-master-set__title">
                                        <span>カメラ設定</span>
                                    </div>

                                    <div className="lay-master-set__form">
                                        <form action="" id='cameraNewData'>
                                            <div className="mod-master-form__set">
                                                <div className="wrapper">
                                                    <div style={{textAlign: 'left', marginBottom: '15px'}}>
                                                        <span>カメラを選択して、名称を入力してください。</span>
                                                    </div>

                                                    <dl>
                                                        <dt style={{ whiteSpace: 'nowrap', fontWeight: '400'}}>カメラID</dt>
                                                        <dd>
                                                            <div className="mod-form__select">
                                                                <select 
                                                                    id="device_id" 
                                                                    name="device_id"
                                                                    value={deviceId}
                                                                    onChange={(e) => setDeviceId(e.target.value)}
                                                                >
                                                                    <option value=""></option>
                                                                    {cameraList.map((camera) => (
                                                                        <option key={camera.device_id} value={camera.device_id}>
                                                                            {camera.device_id}（{camera.camera_name}）
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </dd>
                                                    </dl>

                                                    <dl className="camera_input">
                                                        <dt  style={{ whiteSpace: 'nowrap', fontWeight: '400'}}>カメラ名</dt>
                                                        <dd>
                                                            <div>
                                                                <input 
                                                                    type="text" 
                                                                    id="camera_name_view" 
                                                                    name="camera_name" 
                                                                    value={cameraName}
                                                                    onChange={(e) => setCameraName(e.target.value)}
                                                                />
                                                                <input type="hidden" id="camera_name" name="camera_name"/>
                                                            </div>
                                                        </dd>
                                                    </dl>
                                                </div>
                                                <input type="hidden" name="company_id" value={companyId}/>
                                                <input type="hidden" name="facility_id" value={facilityId}/>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="trun-btn">
                                        <button
                                            type="button"
                                            className="master_long_btn"
                                            id="register"
                                            onClick={handleRegister} 
                                        >
                                            登録
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

            </div>
            <div className={`overlay ${(loading || isLoading)  ? 'active' : ''}`}>
                <div className={`spinner ${(loading || isLoading) ? 'active' : ''}`}></div>
            </div>
        </div>
    );
};

CameraNew.propTypes = {
    registerCamera: PropTypes.func.isRequired,
    fetchCameraList: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string
};

export default React.memo(CameraNew);
