import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TransitionGraph from '../components/TransitionGraph';
import { fetchTransitionGraphData} from "../actions/transitionGraph";

const mapStateToProps = (state) => {
    const transitionGraphData = state.transitionGraph;
    const user = state.login.user;

    return {
        user,
        standard_time: transitionGraphData.standard_time,
        diff_flg: transitionGraphData.diff_flg,
        diff_type: transitionGraphData.diff_type,
        graph_data: transitionGraphData.graph_data,
        gauge_id: transitionGraphData.gauge_id,
        measure_id: transitionGraphData.measure_id,
        is_loaded: transitionGraphData.is_loaded,
        start_date: transitionGraphData.start_date,
        end_date: transitionGraphData.end_date,
        start_date_real: transitionGraphData.start_date_real,
        end_date_real: transitionGraphData.end_date_real,
        view_type: transitionGraphData.view_type,
        unit: transitionGraphData.unit,
        max_limit: transitionGraphData.max_limit,
        min_limit: transitionGraphData.min_limit,
        error: transitionGraphData.error,
        isFetched: transitionGraphData.isFetched,
        isLoading: transitionGraphData.isLoading,
    };

};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchTransitionGraphData,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TransitionGraph);
