import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ConfirmCameraCount from '../components/ConfirmCameraCount';
import { fetchConfirmCameraCount } from '../actions/confirmcameracount';

const mapStateToProps = (state) => {
    const { confirmCameraCount } = state;
    const user = state.login.user;
    return {
        user,
        data: confirmCameraCount.data,
        loading: confirmCameraCount.loading,
        error: confirmCameraCount.error,
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchConfirmCameraCount,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmCameraCount);