import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PrivateRoute = ({ children, allowedAuthority, disallowedAuthority, requiredCompanyId  }) => {
  const isAuthenticated = localStorage.getItem('accessToken');
  const authorityClass = useSelector((state) => state.login.user?.authority_class);
  const isManagementCompany = useSelector((state) => state.login.user?.management);

  if (authorityClass === undefined) {
    return null;
  }
  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  if ((allowedAuthority && authorityClass !== allowedAuthority) || 
      (disallowedAuthority === authorityClass) || 
      (requiredCompanyId && isManagementCompany !== requiredCompanyId)) {
    alert("アクセス権限がありません。");
    return <Navigate to="/" replace />;
  }

  return children;

};

export default PrivateRoute;
