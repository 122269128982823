import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import './Facility.css';
import Modal from '../Modal/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMenuData  } from '../../actions/menuActions';
import Breadcrumb from '../Breadcrumb';

const FacilityList = ({ user, facilities = [], loading, error, fetchFacilities, replaceFacilities, checkGaugeData }) => {
    const location = useLocation();
    const navigate = useNavigate ();
    const dispatch = useDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [companyId, setCompanyId] = useState('');
    const [facilityList, setFacilityList] = useState(facilities); 
    const [isConfirmationModal, setIsConfirmationModal] = useState(false);
    const [checkedFacilities, setCheckedFacilities] = useState([]); 
    const [isFetching, setIsFetching] = useState(false);
    const [showUserName, setUserName] = useState('');
    const screenType = useSelector((state) => state.device.screenType);
    const authorityClass = useSelector((state) => state.login?.user?.authority_class);
    const shouldShowDeleteColumn = authorityClass === 9;

    // Fetch companies on component mount or when location changes
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const company_id = Number(params.get('company_id'))
        if (!isNaN(company_id)) {
            setCompanyId(company_id);
            fetchFacilities(company_id); 
        }
    }, [fetchFacilities, location]);

    useEffect(() => {
        setFacilityList(facilities);
    }, [facilities]);

    useEffect(() => {
        if (user) {
          setUserName(user.user_name);
        };
    }, [user]);

    const handleSortPrev = (index) => {
        if (index === 0) return; 
        const newList = [...facilityList];
        [newList[index], newList[index - 1]] = [newList[index - 1], newList[index]]; 
        setFacilityList(newList);
    };

    const handleSortNext = (index) => {
        if (index === facilityList.length - 1) return;
        const newList = [...facilityList];
        [newList[index], newList[index + 1]] = [newList[index + 1], newList[index]];
        setFacilityList(newList); 
    };

    // Handle checkbox change for deletion
    const handleCheckboxChange = (facilityId) => {
        setCheckedFacilities((prevChecked) => {
            if (prevChecked.includes(facilityId)) {
                return prevChecked.filter(id => id !== facilityId);
            } else {
                return [...prevChecked, facilityId];
            }
        });
    };

    // Get the array of facility IDs in the current order
    const getFacilityIdArray = () => {
        return facilityList.map(facility => facility.facility_id);
    };

    const handleSave = async () => {
        if (checkedFacilities.length > 0) {
            try {
                const gaugeData = await checkGaugeData(companyId, checkedFacilities, checkedFacilities);
                const facilityNames = facilityList
                    .filter(facility => checkedFacilities.includes(facility.facility_id))
                    .map(facility => facility.facility_name);

                const facilityNamesString = facilityNames.join('\n');
                let message = `${facilityNamesString}\n`;

                if (gaugeData && gaugeData.length > 0) {
                    message += '計測データが登録されています。\n';
                }
    
                message += `削除しますか？\n登録されているカメラも登録解除されます。`;
    
                setModalMessage(message);
                setIsConfirmationModal(true);
                setIsModalOpen(true);
    
            } catch (error) {
                setModalMessage('エラーが発生しました。再試行してください。');
                setIsConfirmationModal(false); 
                setIsModalOpen(true);
            }
        } else {
            setModalMessage("更新しますか？");
            setIsConfirmationModal(true); 
            setIsModalOpen(true);
        }
    };

    const handleTopClick = () => {
        navigate('/');
    };

    const fetchUpdatedMenu = async () => {
        try {
            // Instead of calling the API here, dispatch fetchMenuData action
            await dispatch(fetchMenuData());
        } catch (error) {
            console.error('Error fetching updated menu:', error);
        }
    };
    
    // This function is triggered when the user confirms the action in the modal
    const handleConfirmSave = async () => {
        setIsModalOpen(false);
        setIsFetching(true); 

        try {
            const facilityIds = getFacilityIdArray();
            const response = await replaceFacilities(companyId, facilityIds, checkedFacilities.length > 0 ? checkedFacilities : undefined);
            const message = response.data?.respons?.msg;
            await fetchFacilities(companyId);
            await fetchUpdatedMenu();

            setModalMessage(message);
            setIsConfirmationModal(false);
            setIsModalOpen(true);
        } catch (error) {
            setModalMessage(error.message || 'エラーが発生しました。再試行してください。');
            setIsConfirmationModal(false);
            setIsModalOpen(true);
        } finally {
            setIsFetching(false);  
        }
    };

    return (
        <div id='Main' className={`main ${screenType}`}>
            <div id='Contents'>
                <Breadcrumb
                    userName = {showUserName}
                    pageName = "カメラ・計器設定"
                    onTopClick = {handleTopClick}
                />
                {isModalOpen && (
                    <Modal
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    message={modalMessage}
                    onConfirm={handleConfirmSave} 
                    showCancelButton={isConfirmationModal}
                />
                )}
                <div id="ajax-view-disp">
                    <div className="display-main" id="set_company_list">
                        <div className="lay-master-set">
                            <div className="lay-master-set-wrap">
                                <div className="lay-master-set__title">
                                    <span>施設一覧</span>
                                </div>
                                <div className="lay-master-set__frame">
                                    <div className="lay-master__table">
                                        <form id="facilityListData">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <th></th>
                                                        <th>施設名</th>
                                                        {shouldShowDeleteColumn && <th>削除</th>}
                                                    </tr>
                                                    {facilityList.map((facility, index) => (
                                                        <tr key={facility.facility_id} className="lay-tr">
                                                            <td className="lay-td sort">
                                                                <div
                                                                    className="sortPrev"
                                                                    style={{ display: index === 0 || facilityList.length === 1 ? 'none' : '' }}
                                                                    onClick={() => handleSortPrev(index)}
                                                                >
                                                                    △
                                                                </div>
                                                                <div
                                                                    className="sortNext"
                                                                    style={{ display: index === facilityList.length - 1 || facilityList.length === 1 ? 'none' : '' }}
                                                                    onClick={() => handleSortNext(index)}
                                                                >
                                                                    ▽
                                                                </div>
                                                            </td>
                                                            <td className="lay-td">
                                                                <input type="hidden" name="facility_id" value={facility.facility_id} />
                                                                <span></span>
                                                                <span id={`facilityName${facility.facility_id}`}>{facility.facility_name}</span>
                                                            </td>
                                                            {shouldShowDeleteColumn && (
                                                                <td className="lay-td">
                                                                    <label>
                                                                        <input
                                                                            type="checkbox"
                                                                            className="checkbox-input"
                                                                            name="is_delete"
                                                                            value={facility.facility_id}
                                                                            defaultChecked={facility.is_delete === 1}
                                                                            onChange={() => handleCheckboxChange(facility.facility_id)}
                                                                        />
                                                                        <span className="checkbox-parts"></span>
                                                                    </label>
                                                                </td>
                                                            )}
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </form>
                                        <div 
                                            className="trun-btn" 
                                            style={{ 
                                                marginTop: '50px', 
                                                marginRight: '10%', 
                                                display: facilityList.length > 0 ? 'block' : 'none' 
                                            }}
                                        >
                                            <button type="button" className="master_long_btn" id="edit" onClick={handleSave}>
                                                登録
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`overlay ${(loading || isFetching) ? 'active' : ''}`}>
                <div className={`spinner ${(loading || isFetching) ? 'active' : ''}`}></div>
            </div>
        </div>
    );
};

FacilityList.propTypes = {
    facilities: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    fetchFacilities: PropTypes.func.isRequired,
    replaceFacilities: PropTypes.func.isRequired,
    checkGaugeData: PropTypes.func.isRequired,
};

export default React.memo(FacilityList);
