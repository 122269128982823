import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import './FacilityEdit.css';
import Modal from '../Modal/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMenuData } from '../../actions/menuActions';
import Breadcrumb from '../Breadcrumb';

const FacilityEdit = ({ user, facilitydata = {}, loading, error, fetchFacilityEdit, updateFacility }) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate ();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [isConfirmationModal, setIsConfirmationModal] = useState(false);
    const [companyId, setCompanyId] = useState('');
    const [facilityId, setFacilityId] = useState('');
    const [facility, setFacility] = useState({}); 
    const [deviceList, setDeviceList] = useState([]); 
    const [checkedDevices, setCheckedDevices] = useState([]);
    const [isFetching, setIsFetching] = useState(false);
    const [showUserName, setUserName] = useState('');
    const screenType = useSelector((state) => state.device.screenType);
    const authorityClass = useSelector((state) => state.login?.user?.authority_class);
    const shouldShowDeleteColumn = authorityClass === 9;

    // Fetch data when component mounts or when location changes
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const company_id = Number(params.get('company_id'))
        const facility_id = params.get('facility_id');
        
        if (!isNaN(company_id) && facility_id) {
            setCompanyId(company_id);
            setFacilityId(facility_id);
            fetchFacilityEdit(company_id, facility_id); 
        }
    }, [fetchFacilityEdit, location]);

    useEffect(() => {
        if (facilitydata && facilitydata.facility) {
            console.log('facilitydata:', facilitydata); 
            setFacility(facilitydata.facility);
            setDeviceList(facilitydata.data || []);
        }
    }, [facilitydata]);

    useEffect(() => {
        if (user) {
          setUserName(user.user_name);
        };
    }, [user]);

    const handleInputChange = (e) => {
        const { value } = e.target;
        setFacility((prevFacility) => ({
            ...prevFacility,
            facility_name: value,
        }));
    };
    
    const handleSortPrev = (index) => {
        if (index === 0) return; 
        const newList = [...deviceList];
        [newList[index], newList[index - 1]] = [newList[index - 1], newList[index]]; 
        setDeviceList(newList);
    };

    const handleSortNext = (index) => {
        if (index === deviceList.length - 1) return;
        const newList = [...deviceList];
        [newList[index], newList[index + 1]] = [newList[index + 1], newList[index]];
        setDeviceList(newList); 
    };

    const handleCheckboxChange = (deviceId) => {
        setCheckedDevices((prevChecked) => {
            if (prevChecked.includes(deviceId)) {
                return prevChecked.filter(id => id !== deviceId);
            } else {
                return [...prevChecked, deviceId];
            }
        });
    };
    
    const getDeviceIdArray = () => {
        return deviceList.map(device => device.device_id);
    };

    const getDisplayNameIdArray = () => {
        return deviceList.map(device => device.display_name_id);
    };    
    
    const handleSave = () => {
        if (checkedDevices.length > 0) {
            const deviceNames = deviceList
                .filter(device => checkedDevices.includes(device.device_id))
                .map(device => device.display_camera_name);
    
            const deviceNamesString = deviceNames.join('\n');
            setModalMessage(`${deviceNamesString}\n削除しますか？\n登録されているカメラも登録解除されます。`);
            setIsConfirmationModal(true);
            setIsModalOpen(true);
        } else {
            setModalMessage("更新しますか？");
            setIsConfirmationModal(true); 
            setIsModalOpen(true);
        }
    };

    const handleTopClick = () => {
        navigate('/');
    };

    // Fetch updated menu after save or update
    const fetchUpdatedMenu = async () => {
        try {
            await dispatch(fetchMenuData());
        } catch (error) {
            console.error('Error fetching updated menu:', error);
        }
    };
    
    const handleConfirmSave = async () => {
        setIsModalOpen(false);
        setIsFetching(true);
        try {
            let deviceIds = [];
            let displayNameIds = [];
    
            if (deviceList.length > 0) {
                deviceIds = getDeviceIdArray();
                displayNameIds = getDisplayNameIdArray();
            }
    
            const response = await updateFacility(
                facility.facility_name, 
                companyId, 
                facilityId, 
                deviceIds.length > 0 ? deviceIds : undefined, 
                displayNameIds.length > 0 ? displayNameIds : undefined,
                checkedDevices.length > 0 ? checkedDevices : undefined 
            );
    
            const message = response.data?.respons?.msg;
            await fetchUpdatedMenu();

            setModalMessage(message);
            setIsConfirmationModal(false); 
            setIsModalOpen(true); 
        } catch (error) {
            const errorMessage = error.message;
            setModalMessage(errorMessage);
            setIsConfirmationModal(false); 
            setIsModalOpen(true); 
        } finally {
            setIsFetching(false);  
        }
    };
    
    return (
        <div id='Main' className={`main ${screenType}`}>
            <div id='Contents'>
                <Breadcrumb
                    userName = {showUserName}
                    pageName = "カメラ・計器設定"
                    onTopClick = {handleTopClick}
                />
                {isModalOpen && (
                    <Modal
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    message={modalMessage}
                    onConfirm={handleConfirmSave} 
                    showCancelButton={isConfirmationModal}
                />
                )}
                <div id="ajax-view-disp">
                    <div className="display-main" id="set_company_list">
                        <div className="lay-master-set">
                            <div className="lay-master-set-wrap">
                                <div className="lay-master-set__title">
                                    <span>施設編集</span>
                                </div>
                                <form id="facilityEditData">
                                    <div className="lay-master-set__form">
                                        <div className="wrapper">
                                            <div>
                                                <span>名称を入力してください。</span>
                                            </div>
                                            <div className="set_master_name" style={{marginBottom: '40px'}}>
                                                <input type="text" id="facility_name_view" value={facility.facility_name || ''} onChange={handleInputChange}/>
                                                <input type="hidden" id="facility_name" name="facility_name"/>
                                                <input type="hidden" name="company_id" value={facilitydata.company_id || ''}/>
                                                <input type="hidden" name="facility_id" value={facility.facility_id || ''}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="lay-master-set__frame">
                                        <div className="lay-master__table">
                                            {deviceList.length > 0 && (
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <th></th>
                                                            <th>カメラ名</th>
                                                            {shouldShowDeleteColumn && <th>削除</th>}
                                                        </tr>
                                                        {deviceList.map((device, index) => (
                                                            <tr key={device.device_id} className="lay-tr">
                                                                <td className="lay-td sort">
                                                                    <div
                                                                        className="sortPrev"
                                                                        style={{ display: index === 0 || deviceList.length === 1 ? 'none' : '' }}
                                                                        onClick={() => handleSortPrev(index)}
                                                                    >
                                                                        △
                                                                    </div>
                                                                    <div
                                                                        className="sortNext"
                                                                        style={{ display: index === deviceList.length - 1 || deviceList.length === 1 ? 'none' : '' }}
                                                                        onClick={() => handleSortNext(index)}
                                                                    >
                                                                        ▽
                                                                    </div>
                                                                </td>
                                                                <td className="camera_name">
                                                                    <input type="hidden" name="display_name_id" value={device.display_name_id || ''} />
                                                                    <input type="hidden" name="device_i" value={device.device_id || ''} />
                                                                    <span id={`camera_name${device.device_id}`}>{device.display_camera_name || ''}</span>
                                                                </td>
                                                                {shouldShowDeleteColumn && 
                                                                    <td className="lay-td">
                                                                    <label>
                                                                        <input
                                                                            type="checkbox"
                                                                            className="checkbox-input"
                                                                            name="is_delete"
                                                                            value={device.device_id || ''}
                                                                            defaultChecked={device.is_delete === 1}
                                                                            onChange={() => handleCheckboxChange(device.device_id)}
                                                                        />
                                                                        <span className="checkbox-parts"></span>
                                                                    </label>
                                                                    </td>
                                                                }
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            )}
                                        </div>
                                    </div>
                                </form>
                                <div className="trun-btn" style={{marginTop: '50px', marginRight: '10%'}}>
                                    <button type="button" className="master_long_btn" id="edit" onClick={handleSave}>
                                        登録
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`overlay ${(loading || isFetching) ? 'active' : ''}`}>
                <div className={`spinner ${(loading || isFetching) ? 'active' : ''}`}></div>
            </div>
        </div>
    );
};

FacilityEdit.propTypes = {
    facilitydata: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    fetchFacilityEdit: PropTypes.func.isRequired,
};

export default React.memo(FacilityEdit);
