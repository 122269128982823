import ActionTypes from '../constants/ActionTypes'; 

const initialState = {
    loading: false,
    error: null,
    mail2FA: '', 
    using2FAflg: null
};

const setting2FAReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.UPDATE_SETTING_2FA_REQUEST:
            return { ...state, loading: true, error: null };
        case ActionTypes.UPDATE_SETTING_2FA_SUCCESS:
            return { ...state, loading: false, message: action.payload, error: null };
        case ActionTypes.UPDATE_SETTING_2FA_FAILURE:
            return { ...state, loading: false, error: action.payload };
        case ActionTypes.FETCH_USER_SUCCESS: 
            return { ...state, mail2FA: action.payload.mail_2fa, using2FAflg: action.payload.using_2fa_flg, error: null };
        case ActionTypes.FETCH_USER_FAILURE:
            return { ...state, error: action.payload };
        default:
            return state;
    }
};

export default setting2FAReducer;
