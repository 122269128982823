import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { dataRegister, fetchImageList } from '../../actions/datamanage';

const MAX_COMMENT_LENGTH = 21779;

const DataRegister = ({
    relativeIndex,
    dataRegister,
    fetchImageList,
    selectedItem,
    startDate,
    endDate,
    selectedFacility,
    selectedCamera,
    selectedGauge,
    comments,
    setComments,
    setLoading,
    isPopup,
    setIsRegisterModalOpen, 
    setModalMessage ,
    updateComments,
    screenType,
    editStatus,
    setEditStatus,
    setRelativeActive
}) => {
    const dataRegisterHandler = async () => {
        let form;
        if (isPopup) {
            form = document.getElementById(`dataformpopup0`);
        } else {
            form = document.getElementById(`dataform${relativeIndex}`);
        }
        
        const inputs = form.getElementsByTagName('input');
        let data = {};

        for (let input of inputs) {
            if (input.type === 'hidden' || input.type === 'text') {
                if (input.value !== '') {
                    if (['comment', 'gauge_id', 'device_id', 'get_datetime'].includes(input.name)) {
                        data[input.name] = input.value;
                    } else {
                        const parsedValue = parseFloat(input.value);
                        data[input.name] = isNaN(parsedValue) ? '' : parsedValue;
                    }
                }
            }
        }

        const diffSpans = form.querySelectorAll(`span[id^="dv_"]`);
        diffSpans.forEach((span) => {
            const spanId = span.id;
            const parts = spanId.split('_');
            const cleanKey = `diff_val${parts[parts.length - 1]}`;

            const spanValue = span.textContent.trim();

            if (spanValue === '--') {
                data[cleanKey] = null; 
            } else {
                data[cleanKey] = parseFloat(spanValue);
            }
        });

        const diffSpansPop = form.querySelectorAll(`span[id^="dvpop_"]`);
        diffSpansPop.forEach((span) => {
            const spanId = span.id;
            const parts = spanId.split('_');
            const cleanKey = `diff_val${parts[parts.length - 1]}`;

            const spanValue = span.textContent.trim();

            if (spanValue === '--') {
                data[cleanKey] = null; 
            } else {
                data[cleanKey] = parseFloat(spanValue);
            }
        });

        let comment;
        if (isPopup) {
            const measureInputs = form.querySelectorAll('input[name^="measure_val"]');
            measureInputs.forEach((input, index) => {
                const measureKey = `measure_val${index}`;
                const parsedValue = parseFloat(input.value);
                data[measureKey] = isNaN(parsedValue) ? null : parsedValue;
            });

            comment = form.querySelector('textarea[name="comment"]').value || '';
        } else {
            comment = comments[relativeIndex] || '';
        }

        if (comment.length > MAX_COMMENT_LENGTH) {
            comment = comment.slice(0, MAX_COMMENT_LENGTH);
        }
        data['comment'] = comment;

        const checkbox = form.querySelector('input[name="disuse_flg"]');
        const isCheckboxChecked = checkbox && checkbox.checked;
        if (isCheckboxChecked) {
            data['disuse_flg'] = 'on';
        }

        setLoading(true);

        try {
            await dataRegister(data);
            setRelativeActive(relativeIndex);
            if (isPopup) {
                setModalMessage('登録完了しました。');
                setIsRegisterModalOpen(true);
                updateComments(relativeIndex);
            } else {
                setEditStatus(() => {
                    const newStatus = {};
                    Object.keys(editStatus).forEach((key) => {
                        newStatus[key] = false;
                    });
                    return newStatus;
                });
    
                Object.keys(editStatus).forEach((key) => {
                    const editBtn = document.getElementById(`edit_btn${key}`);
                    const editUpdateBtn = document.getElementById(`edit_update_btn${key}`);
            
                    if (editBtn) {
                        editBtn.style.display = "block";
                    }
                    if (editUpdateBtn) {
                        editUpdateBtn.style.display = "none";
                    }
                    document.querySelectorAll(`.edit_data_lbl${key}`).forEach(element => {
                        element.style.display = "block";
                    });
                    document.querySelectorAll(`.edit_data${key}`).forEach(element => {
                        element.style.display = "none";
                    });
                    document.querySelectorAll(`#edit_comment_lbl${key}`).forEach(element => {
                        element.style.display = "block";
                    });
                    document.querySelectorAll(`#edit_comment${key}`).forEach(element => {
                        element.style.display = "none";
                    });
                    const editCommentLbl = document.getElementById(`edit_comment_lbl${key}`);
                    const editComment = document.getElementById(`edit_comment${key}`);
                    const sizeEdit = document.getElementById(`size_edit${key}`);
                    const leftLabel = document.getElementById(`leftLabel${key}`);
                    const rightLabel = document.getElementById(`rightLabel${key}`);
            
                    if (editCommentLbl) {
                        editCommentLbl.style.display = "block";
                    }
                    if (editComment) {
                        editComment.style.display = "none";
                    }
                    if (sizeEdit) {
                        sizeEdit.style.display = "none";
                    }
                    if (leftLabel) {
                        leftLabel.style.display = "none";
                    }
                    if (rightLabel) {
                        rightLabel.style.display = "none";
                    }
        
                    const checkbox = document.getElementById(`disuse_flg${key}`);
                    if (checkbox) {
                        checkbox.disabled = true;
                    }
                });
            }
            
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <button
            type="button"
            id={`registerbtn${relativeIndex}`}
            className={`mod-btn__round small ${screenType}`}
            style={{
                display: 'block',
                ...(isPopup && {
                    width: '45%',
                    float: 'right',
                    marginLeft: '10px',
                }),
            }}
            onClick={dataRegisterHandler}
        >
            登録
        </button>
    );
};

DataRegister.propTypes = {
    relativeIndex: PropTypes.number.isRequired,
    dataRegister: PropTypes.func.isRequired,
    fetchImageList: PropTypes.func.isRequired,
    selectedItem: PropTypes.object.isRequired,
    startDate: PropTypes.instanceOf(Date).isRequired,
    endDate: PropTypes.instanceOf(Date).isRequired,
    selectedFacility: PropTypes.string.isRequired,
    selectedCamera: PropTypes.string.isRequired,
    selectedGauge: PropTypes.string.isRequired,
    comments: PropTypes.object.isRequired,
    setComments: PropTypes.func.isRequired,
    setLoading: PropTypes.func.isRequired,
    isPopup: PropTypes.bool,
    setIsRegisterModalOpen: PropTypes.func, 
    setModalMessage: PropTypes.func, 
};

const mapDispatchToProps = {
    dataRegister,
    fetchImageList
};

export default connect(null, mapDispatchToProps)(DataRegister);
