const initialState = {
    screenType: 'desktop',
};

const deviceReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_DEVICE_TYPE':
            return {
                ...state,
                screenType: action.payload,
            };
        default:
            return state;
    }
};

export default deviceReducer;
