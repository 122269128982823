import ActionTypes from '../constants/ActionTypes';
import BASE_URL from "../constants/Api";
import axiosInstance from "../helpers/refreshTokenMiddleware";

export const registerFacilityRequest = () => ({
    type: ActionTypes.REGISTER_FACILITY_REQUEST
});

export const registerFacilitySuccess = (message) => ({
    type: ActionTypes.REGISTER_FACILITY_SUCCESS,
    payload: message
});

export const registerFacilityFailure = (error) => ({
    type: ActionTypes.REGISTER_FACILITY_FAILURE,
    payload: error
});

export const registerFacility = (facilityName, companyId) => {
    return async (dispatch) => {
        dispatch(registerFacilityRequest());

        try {
            const accessToken = localStorage.getItem('accessToken');
            const response = await axiosInstance.post(BASE_URL + '/top/reg-facility-data', {
                facility_name: facilityName,
                company_id: companyId,
            }, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            dispatch(registerFacilitySuccess(response.data.respons.msg));
            return response;
        } catch (error) {
            dispatch(registerFacilityFailure(error.message));
        }
    };
};

//camera new
export const fetchCameraListRequest = () => ({
    type: ActionTypes.FETCH_CAMERA_LIST_REQUEST
});

export const fetchCameraListSuccess = (cameraList) => ({
    type: ActionTypes.FETCH_CAMERA_LIST_SUCCESS,
    payload: cameraList
});

export const fetchCameraListFailure = (error) => ({
    type: ActionTypes.FETCH_CAMERA_LIST_FAILURE,
    payload: error
});

export const fetchCameraList = (companyId, facilityId) => {
    return async (dispatch) => {
        dispatch(fetchCameraListRequest());

        try {
            const accessToken = localStorage.getItem('accessToken');
            const response = await axiosInstance.post(BASE_URL + '/top/camera-new', {
                company_id: companyId,
                facility_id: facilityId
            }, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            dispatch(fetchCameraListSuccess(response.data.respons.camera));
        } catch (error) {
            dispatch(fetchCameraListFailure(error.message));
        }
    };
};

export const registerCameraRequest = () => ({
    type: ActionTypes.REGISTER_CAMERA_REQUEST
});

export const registerCameraSuccess = (message) => ({
    type: ActionTypes.REGISTER_CAMERA_SUCCESS,
    payload: message
});

export const registerCameraFailure = (error) => ({
    type: ActionTypes.REGISTER_CAMERA_FAILURE,
    payload: error
});

export const registerCamera = (cameraName, companyId, facilityId, deviceId) => {
    return async (dispatch) => {
        dispatch(registerCameraRequest());

        try {
            const accessToken = localStorage.getItem('accessToken');
            const response = await axiosInstance.post(BASE_URL + '/top/reg-camera-data', {
                camera_name: cameraName,
                company_id: companyId,
                facility_id: facilityId,
                device_id: deviceId
            }, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            dispatch(registerCameraSuccess(response.data.respons.msg));
            return response;
        } catch (error) {
            dispatch(registerCameraFailure(error.message));
            throw error;
        }
    };
};

//measure new
export const fetchMeasureNewRequest = () => ({
    type: ActionTypes.FETCH_MEASURE_NEW_REQUEST
});

export const fetchMeasureNewSuccess = (data) => ({
    type: ActionTypes.FETCH_MEASURE_NEW_SUCCESS,
    payload: data
});

export const fetchMeasureNewFailure = (error) => ({
    type: ActionTypes.FETCH_MEASURE_NEW_FAILURE,
    payload: error
});

export const fetchMeasureNew = (companyId, facilityId, deviceId, gaugeId) => {
    return async (dispatch) => {
        dispatch(fetchMeasureNewRequest());

        try {
            const accessToken = localStorage.getItem('accessToken');
            const response = await axiosInstance.post(BASE_URL + '/top/measure-new', {
                company_id: companyId,
                facility_id: facilityId,
                device_id: deviceId,
                gauge_id: gaugeId
            }, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            dispatch(fetchMeasureNewSuccess(response.data.respons));
        } catch (error) {
            dispatch(fetchMeasureNewFailure(error.message));
        }
    };
};

export const registerMeasureRequest = () => ({
    type: ActionTypes.REGISTER_MEASURE_REQUEST
});

export const registerMeasureSuccess = (message) => ({
    type: ActionTypes.REGISTER_MEASURE_SUCCESS,
    payload: message
});

export const registerMeasureFailure = (error) => ({
    type: ActionTypes.REGISTER_MEASURE_FAILURE,
    payload: error
});

export const registerMeasure = (payload) => {
    return async (dispatch) => {
        dispatch(registerMeasureRequest());

        try {
            const accessToken = localStorage.getItem('accessToken');
            const response = await axiosInstance.post(BASE_URL + '/top/reg-measure-data', payload, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            dispatch(registerMeasureSuccess(response.data.respons.msg));
            return response;
        } catch (error) {
            dispatch(registerMeasureFailure(error.message));
            throw error;
        }
    };
};
