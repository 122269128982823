import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from '../Modal/Modal';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './MailSendSetting.css';
import Breadcrumb from '../Breadcrumb';

const MailSendSetting = ({ user, fetchMailSend, testMailSend, saveMailSend, data = {}, loading, error }) => {
    const navigate = useNavigate ();
    const location = useLocation ();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [abnormalAlertFlag, setAbnormalAlertFlag] = useState(false);
    const [batteryAlertFlag, setBatteryAlertFlag] = useState(false);
    const [batteryAlertAmount, setBatteryAlertAmount] = useState('');
    const [mailToList, setMailToList] = useState(['']);
    const [mailCcList, setMailCcList] = useState(['']);
    const [mailBccList, setMailBccList] = useState(['']);
    const [errors, setErrors] = useState({});
    const [showUserName, setUserName] = useState('');
    const screenType = useSelector((state) => state.device.screenType);

    useEffect(() => {
        fetchMailSend(); 
        setErrors({});
    }, [fetchMailSend, location]);  

    useEffect(() => {
        if (data.setting) {
            setAbnormalAlertFlag(data.setting.abnomal_alert_flg === 1);
            setBatteryAlertFlag(data.setting.battery_alert_flg === 1);
            setBatteryAlertAmount(data.setting.battery_alert_amount); 
        }
    }, [data]);

    useEffect(() => {
        if (data.data) {
            const mailsTo = data.data.mail_to ? data.data.mail_to.split(',') : [''];
            const mailsCc = data.data.mail_cc ? data.data.mail_cc.split(',') : [''];
            const mailsBcc = data.data.mail_bcc ? data.data.mail_bcc.split(',') : [''];
            
            setMailToList(mailsTo); 
            setMailCcList(mailsCc); 
            setMailBccList(mailsBcc); 
        }
    }, [data]);

    useEffect(() => {
        if (user) {
          setUserName(user.user_name);
        };
    }, [user]);

    // Function to handle the "Add" button click to add a new input for mail_to, mail_cc, or mail_bcc
    const handleAddMailTo = () => {
        if (mailToList.length < 10) {
            setMailToList([...mailToList, '']);
        } else {
            setModalMessage("登録は10件まで可能です。");
            setIsModalOpen(true);
        }
    };

    const handleAddMailCc = () => {
        if (mailCcList.length < 10) {
            setMailCcList([...mailCcList, '']);
        } else {
            setModalMessage("登録は10件まで可能です。");
            setIsModalOpen(true);
        }
    };

    const handleAddMailBcc = () => {
        if (mailBccList.length < 10) {
            setMailBccList([...mailBccList, '']);
        } else {
            setModalMessage("登録は10件まで可能です。");
            setIsModalOpen(true);
        }
    };

    // Function to handle when the user changes the value in the inputs
    const handleMailToChange = (index, value) => {
        const newMailToList = [...mailToList];
        newMailToList[index] = value;
        setMailToList(newMailToList);
    };

    const handleMailCcChange = (index, value) => {
        const newMailCcList = [...mailCcList];
        newMailCcList[index] = value;
        setMailCcList(newMailCcList);
    };

    const handleMailBccChange = (index, value) => {
        const newMailBccList = [...mailBccList];
        newMailBccList[index] = value;
        setMailBccList(newMailBccList);
    };

    const handleTopClick = () => {
        navigate('/');
    };

    // const handleErrors = (errorsArray) => {
    //     const errorObj = {};
    //     errorsArray.forEach(error => {
    //         const key = error.field; 
    //         const index = error.index;
    //         errorObj[`${key}_${index}`] = error.message; 
    //     });
    //     setErrors(errorObj); 
    // };

    // Define the validateEmail function
    const validateEmail = (email) => {
        const regex = /^(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x21\x23-\x5b\x5d-\x7f]|\\[\x21-\x7f])*")@[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?)*$/;
        return regex.test(email);
    };

    // Function to validate all emails
    const validateAllEmails = () => {
        let isValid = true;
        const newErrors = {};

        if (mailToList.filter(mail => mail.trim() !== '').length === 0) {
            setModalMessage("TOは一件以上必ず入力してください");
            setIsModalOpen(true);
            return false;
        }
        
        mailToList.forEach((mail, index) => {
            if (mail && !validateEmail(mail)) {
                newErrors[`mail_to_${index}`] = "メールアドレスが正しくありません。";
                isValid = false;
            }
        });

        mailCcList.forEach((mail, index) => {
            if (mail && !validateEmail(mail)) {
                newErrors[`mail_cc_${index}`] = "メールアドレスが正しくありません。";
                isValid = false;
            }
        });

        mailBccList.forEach((mail, index) => {
            if (mail && !validateEmail(mail)) {
                newErrors[`mail_bcc_${index}`] = "メールアドレスが正しくありません。";
                isValid = false;
            }
        });

        const allToEmails = new Set();
        const allCcEmails = new Set();
        const allBccEmails = new Set();

        // Function to check for duplicates within a single group
        const checkDuplicates = (mailList, errorKeyPrefix, emailSet) => {
            mailList.forEach((mail, index) => {
                if (mail) {
                    if (emailSet.has(mail)) {
                        newErrors[`${errorKeyPrefix}_${index}`] = "すでに同じメールアドレスが登録されています";
                        isValid = false;
                    } else {
                        emailSet.add(mail);
                    }
                }
            });
        };

        checkDuplicates(mailToList, "mail_to", allToEmails);
        checkDuplicates(mailCcList, "mail_cc", allCcEmails);
        checkDuplicates(mailBccList, "mail_bcc", allBccEmails);

        setErrors(newErrors);
        return isValid;
    };

    const handleTestMailSend = async () => {
        const isValid = validateAllEmails();

        if (!isValid) {
            return; 
        }

        setIsModalOpen(false);
        try {
            const response = await testMailSend(
                abnormalAlertFlag ? 1 : 0, 
                batteryAlertFlag ? 1 : 0,
                batteryAlertAmount,
                mailToList.filter(mail => mail !== ''),
                mailCcList.filter(mail => mail !== ''),
                mailBccList.filter(mail => mail !== '')
            );

            const message = response.data?.respons?.msg;
            setModalMessage(message);
            setIsModalOpen(true);
        } catch (error) {
            console.error("Error sending test email:", error.message);
        }
    };

    const handleSaveMailSend = async () => {
        const isValid = validateAllEmails();

        if (!isValid) {
            return; 
        }

        setIsModalOpen(false);
        try {
            const response = await saveMailSend(
                abnormalAlertFlag ? 1 : 0, 
                batteryAlertFlag ? 1 : 0,
                batteryAlertAmount,
                mailToList.filter(mail => mail !== ''),
                mailCcList.filter(mail => mail !== ''),
                mailBccList.filter(mail => mail !== '')
            );

            const message = response.data?.respons?.msg;
            setModalMessage(message);
            setIsModalOpen(true);
        } catch (error) {
            console.error("Error sending test email:", error.message);
        }
    };

    const handleBatteryAlertAmountChange = (e) => {
        const value = e.target.value;
    
        if (/^\d*$/.test(value)) {
            setBatteryAlertAmount(value); 
        }
    };
    
    const handleBatteryAlertAmountBlur = () => {
        if (batteryAlertAmount === '' || isNaN(Number(batteryAlertAmount))) {
            setBatteryAlertAmount('0'); 
        } else {
            const numericValue = Math.min(100, Number(batteryAlertAmount));
            setBatteryAlertAmount(String(numericValue));
        }
    };
    
    return (
        <div id='Main' className={`main ${screenType}`}>
            <div id='Contents'>
                <Breadcrumb
                    userName = {showUserName}
                    pageName = "ユーザ情報"
                    onTopClick = {handleTopClick}
                />
                {isModalOpen && (
                    <Modal
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    message={modalMessage}
                />
                )}
                <div id="ajax-view-disp">
                    <div className="display-main" id="set_company_list">
                        <div className={`lay-master-set ${screenType}`}>
                            <div className="lay-master-set-wrap">
                                <div className="lay-master-set__title">
                                    <span>メール送信設定</span>
                                </div>
                                <div className={`lay-master-set__form ${screenType}`}>
                                    <form id="mailEditData">
                                        <div className="wrapper" style={{textAlign: 'left'}}>
                                            <div className="mail-send__set">
                                                {/* Checkbox 1: abnomal_alert_flg */}
                                                <div className="checkbox-frame" style={{ marginBottom: '10px' }}>
                                                    <div style={{ display: 'flex' }}>
                                                        <div>
                                                            <label>
                                                                <input
                                                                    type="checkbox"
                                                                    name="abnomal_alert_flg"
                                                                    className="checkbox-input"
                                                                    checked={abnormalAlertFlag}
                                                                    onChange={(e) => setAbnormalAlertFlag(e.target.checked)} 
                                                                />
                                                                <span className="checkbox-parts"></span>
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <span style={{ fontSize: '18px' }}>
                                                                データが異常値と判定された際にメールを送信する
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* Checkbox 2: battery_alert_flg */}
                                                <div className="checkbox-frame">
                                                    <div style={{ marginBottom: '30px', display: 'flex' }}>
                                                        <div style={{ display: 'flex' }}>
                                                            <div>
                                                                <label>
                                                                    <input
                                                                        type="checkbox"
                                                                        name="battery_alert_flg"
                                                                        className="checkbox-input"
                                                                        checked={batteryAlertFlag}
                                                                        onChange={(e) => setBatteryAlertFlag(e.target.checked)} 
                                                                    />
                                                                    <span className="checkbox-parts"></span>
                                                                </label>
                                                            </div>
                                                            <div>
                                                                <span style={{ fontSize: '18px' }}>
                                                                    電池残量が低下した場合にメールを送信する
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="battery-input">
                                                            <input
                                                                type="text"
                                                                id="battery_alert_amount"
                                                                name="battery_alert_amount"
                                                                value={batteryAlertAmount}
                                                                onChange={(e) => handleBatteryAlertAmountChange(e)}
                                                                onBlur={handleBatteryAlertAmountBlur}
                                                                inputMode="numeric"
                                                                pattern="\d*"
                                                                maxLength="3"
                                                                disabled={!batteryAlertFlag} 
                                                                style={{
                                                                    background: batteryAlertFlag ? "#fff" : "#eee", 
                                                                    pointerEvents: batteryAlertFlag ? 'auto' : 'none',
                                                                }}
                                                            />
                                                            <span>%</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* Mail TO */}
                                                <div className="mail-input">
                                                    <div style={{ width: '80%', marginTop: '10px' }}>
                                                        <div style={{ display: 'flex' }}>
                                                            <div>
                                                                <span>TO</span>
                                                            </div>
                                                            <div className="add-btn">
                                                                <button
                                                                    type="button"
                                                                    className="mod-btn__roundborder add"
                                                                    onClick={handleAddMailTo}
                                                                >
                                                                    追加
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* Mail To */}
                                                    {mailToList.map((mail, index) => (
                                                        <div className="mod-form__set" key={index} id={`mail_to_head_${index}`}>
                                                            <dl style={{ marginTop: '10px' }}>
                                                                <dt>宛先{index + 1}</dt>
                                                                <dd style={{ width: '70%' }}>
                                                                    <div className="mod-form__text">
                                                                        <input
                                                                            type="text"
                                                                            id={`mail_to_${index}`}
                                                                            name="mail_to"
                                                                            value={mail}
                                                                            onChange={(e) => handleMailToChange(index, e.target.value)}
                                                                        />
                                                                    </div>
                                                                    <div className="error_mail_to">
                                                                        {errors[`mail_to_${index}`] && (
                                                                            <span style={{ color: 'red' }}>{errors[`mail_to_${index}`]}</span>
                                                                        )}
                                                                    </div>
                                                                </dd>
                                                            </dl>
                                                        </div>
                                                    ))}
                                                </div>

                                                {/* Mail CC */}
                                                <div className="mail-input">
                                                    <div style={{ width: '80%', marginTop: '10px' }}>
                                                        <div style={{ display: 'flex' }}>
                                                            <div>
                                                                <span>CC</span>
                                                            </div>
                                                            <div className="add-btn">
                                                                <button
                                                                    type="button"
                                                                    className="mod-btn__roundborder add"
                                                                    onClick={handleAddMailCc}
                                                                >
                                                                    追加
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {mailCcList.map((mail, index) => (
                                                        <div className="mod-form__set" key={index} id={`mail_cc_head_${index}`}>
                                                            <dl style={{ marginTop: '10px' }}>
                                                                <dt>宛先{index + 1}</dt>
                                                                <dd style={{ width: '70%' }}>
                                                                    <div className="mod-form__text">
                                                                        <input
                                                                            type="text"
                                                                            id={`mail_cc_${index}`}
                                                                            name="mail_cc"
                                                                            value={mail}
                                                                            onChange={(e) => handleMailCcChange(index, e.target.value)}
                                                                        />
                                                                    </div>
                                                                    <div className="error_mail_cc">
                                                                        {errors[`mail_cc_${index}`] && (
                                                                            <span style={{ color: 'red' }}>{errors[`mail_cc_${index}`]}</span>
                                                                        )}
                                                                    </div>
                                                                </dd>
                                                            </dl>
                                                        </div>
                                                    ))}
                                                </div>

                                                {/* Mail BCC */}
                                                <div className="mail-input">
                                                    <div style={{ width: '80%', marginTop: '10px' }}>
                                                        <div style={{ display: 'flex' }}>
                                                            <div>
                                                                <span>BCC</span>
                                                            </div>
                                                            <div className="add-btn">
                                                                <button
                                                                    type="button"
                                                                    className="mod-btn__roundborder add"
                                                                    onClick={handleAddMailBcc}
                                                                >
                                                                    追加
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {mailBccList.map((mail, index) => (
                                                        <div className="mod-form__set" key={index} id={`mail_bcc_head_${index}`}>
                                                            <dl style={{ marginTop: '10px' }}>
                                                                <dt>宛先{index + 1}</dt>
                                                                <dd style={{ width: '70%' }}>
                                                                    <div className="mod-form__text">
                                                                        <input
                                                                            type="text"
                                                                            id={`mail_bcc_${index}`}
                                                                            name="mail_bcc"
                                                                            value={mail}
                                                                            onChange={(e) => handleMailBccChange(index, e.target.value)}
                                                                        />
                                                                    </div>
                                                                    <div className="error_mail_bcc">
                                                                        {errors[`mail_bcc_${index}`] && (
                                                                            <span style={{ color: 'red' }}>{errors[`mail_bcc_${index}`]}</span>
                                                                        )}
                                                                    </div>
                                                                </dd>
                                                            </dl>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="mail-btn" style={{display: 'flex'}}>
                                    <div>
                                        <button type="button" value="saveMailSendSetting" className="mod-btn__round small" style={{width: '200px'}} onClick={handleSaveMailSend}>
                                        保存
                                        </button>
                                    </div>
                                    <div style={{marginLeft: '140px'}}>
                                        <button type="button" value="testMailSendSetting" className="mod-btn__roundborder editmoresmall" style={{width: '200px'}} onClick={handleTestMailSend}>
                                            テストメール送信
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`overlay ${loading ? 'active' : ''}`}>
                <div className={`spinner ${loading ? 'active' : ''}`}></div>
            </div>
        </div>
    );
};

MailSendSetting.propTypes = {
    data: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    fetchMailSend: PropTypes.func.isRequired,
    testMailSend: PropTypes.func.isRequired,
    saveMailSend: PropTypes.func.isRequired,
};

export default React.memo(MailSendSetting);
