import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CameraEdit from '../components/CameraEdit';
import { fetchCameraEdit, submitCameraEdit } from '../actions/cameraedit';

const mapStateToProps = (state) => {
    const { cameraEdit } = state;
    const user = state.login.user;
    return {
        user,
        gaugedata: cameraEdit.gaugedata, 
        loading: cameraEdit.loading,
        error: cameraEdit.error,
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchCameraEdit,
    submitCameraEdit
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CameraEdit);