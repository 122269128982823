import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation} from 'react-router-dom';
import { useSelector } from 'react-redux';
import './ConfirmFirstMonth.css';
import Breadcrumb from '../Breadcrumb';

const ConfirmFirstMonth = ({ data = {}, loading, error, fetchConfirmFirstMonth}) => {
    const navigate = useNavigate ();
    const location = useLocation ();
    const [firstMonth, setFirstMonth] = useState('');
    const [userName, setUserName] = useState('');
    const [cameraDataCount, setCameraDataCount] = useState(0);
    const screenType = useSelector((state) => state.device.screenType);

    // Fetch data on component mount
    useEffect(() => {
        fetchConfirmFirstMonth();
    }, [fetchConfirmFirstMonth, location]);

    // Update state when data changes
    useEffect(() => {
        if (data) {
            setFirstMonth(data.first_month || '');
            setUserName(data.user_name || '');
            setCameraDataCount(data.camera_data_count || 0);
        }
    }, [data]);

    const handleTopClick = () => {
        navigate('/');
    };

    return (
        <div id='Main' className={`main ${screenType}`}>
            <div id='Contents'>
                <Breadcrumb
                    userName = {userName}
                    pageName = "ユーザ情報"
                    onTopClick = {handleTopClick}
                />
                <div id="ajax-view-disp">
                <div className="display-main">
                    <div className={`lay-master-set ${screenType}`}>
                        <div className={`lay-master-set-wrap ${screenType}`}>
                            <div className="lay-master-user__title" style={{marginRight: '80px'}}>
                                <span>利用開始月</span>
                            </div>
                            <div className="confirm">
                                <div className="cell">
                                    <div>
                                        <span>ユーザ：{userName}</span>
                                    </div>
                                    <div>
                                        <span>利用開始月は、{firstMonth}です。</span>
                                    </div>
                                </div>
                            </div>
                            <div className="lay-master-user__title" style={{marginRight: '70px'}}>
                                <span>利用カメラ台数</span>
                            </div>
                            <div className="confirm">
                                <div className="cell">
                                    <div>
                                        <span>現在登録されているカメラは、{cameraDataCount}台です。</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div className={`overlay ${loading ? 'active' : ''}`}>
                <div className={`spinner ${loading ? 'active' : ''}`}></div>
            </div>
        </div>
    );
};

ConfirmFirstMonth.propTypes = {
    data: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    fetchConfirmFirstMonth: PropTypes.func.isRequired,
};

export default React.memo(ConfirmFirstMonth);