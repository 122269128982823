import ActionTypes from '../constants/ActionTypes';

const initialState = {
    dataManagementMenu: [],
    cameraSettingsMenu: [],
    adminData: null,
    loading: false,
    error: null,
    authority_class: null
};

const menuReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SET_MENU_DATA:
            return {
                ...state,
                dataManagementMenu: action.payload.dataManagementMenu,
                cameraSettingsMenu: action.payload.cameraSettingsMenu,
                adminData: action.payload.adminData,
                loading: false,
            };

        case ActionTypes.UPDATE_MENU:
            if (action.payload.menuType === 'dataManagement') {
                return {
                    ...state,
                    dataManagementMenu: action.payload.updatedMenu,
                };
            } else if (action.payload.menuType === 'cameraSettings') {
                return {
                    ...state,
                    cameraSettingsMenu: action.payload.updatedMenu,
                };
            }
            return state;

        case ActionTypes.UPDATE_COMPANY_IN_MENU:
            const { companyId: updatedCompanyId, companyCode, companyName } = action.payload;
            const updateMenu = (menu) => {
                return menu.map(company => 
                    company.company_id === updatedCompanyId 
                        ? { ...company, company_code: companyCode, company_name: companyName } 
                        : company
                );
            };

            return {
                ...state,
                dataManagementMenu: updateMenu(state.dataManagementMenu),
                cameraSettingsMenu: updateMenu(state.cameraSettingsMenu),
            };

        case ActionTypes.FETCH_USER_SUCCESS: 
            console.log('authority_class:', action.payload.authority_class);
            return { ...state, authority_class: action.payload.authority_class, error: null };
        case ActionTypes.FETCH_USER_FAILURE:
            return { ...state, error: action.payload };
        default:
            return state;
    }
};

export default menuReducer;
