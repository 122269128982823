import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

const CameraSettingsSection = ({ 
    menuData, 
    activeItem, 
    setActiveItem, 
    toggleMenu, 
    adminData, 
    setActiveSet, 
    activeSet ,
    setIsMenuOpen, 
    screenType,
    isManagementCompany
}) => {
    const navigate = useNavigate();
    const [isInitialLoad, setIsInitialLoad] = useState(true);

    useEffect(() => {
        if (isInitialLoad && activeItem && activeSet === 'cameraSettings') {
            const { company_id, facility_id, camera_id, gauge_id } = activeItem;

            menuData.forEach((company, companyIndex) => {
                if (String(company.company_id) === String(company_id)) {
                    if (!company.isOpen) {
                        toggleMenu(companyIndex, 'company', 'cameraSettings');
                    }

                    company.facility.forEach((facility, facilityIndex) => {
                        if (String(facility.facility_id) === String(facility_id)) {
                            if (!facility.isOpen) {
                                toggleMenu(facilityIndex, 'facility', 'cameraSettings', companyIndex);
                            }

                            facility.camera.forEach((camera, cameraIndex) => {
                                if (String(camera.device_id) === String(camera_id)) {
                                    if (!camera.isOpen) {
                                        toggleMenu(cameraIndex, 'camera', 'cameraSettings', facilityIndex, companyIndex);
                                    }

                                    camera.gauge.forEach((gauge, gaugeIndex) => {
                                        if (String(gauge.gauge_id) === String(gauge_id) && !gauge.isOpen) {
                                            toggleMenu(gaugeIndex, 'gauge', 'cameraSettings', cameraIndex, facilityIndex, companyIndex);
                                        }
                                    });
                                }
                            });
                        }
                    });
                }
            });

            setIsInitialLoad(false);
        }
    }, [isInitialLoad, activeItem, activeSet, menuData, toggleMenu]);

    
    const generateActiveItem = (screen, company_id, facility_id, camera_id, gauge_id, measure_id) => {
        const activeItem = { company_id: String(company_id) };
    
        if (facility_id) activeItem.facility_id = String(facility_id);
        if (camera_id) activeItem.camera_id = String(camera_id);
        if (gauge_id) activeItem.gauge_id = String(gauge_id);
        if (measure_id) activeItem.measure_id = String(measure_id);
    
        return activeItem;
    };
    
    const navigateToCameraSetting = (
        event, 
        company_id, 
        facility_id = null, 
        device_id = null, 
        gauge_id = null, 
        measure_id = null, 
        screen = 'list',
    ) => {
        let path = '';

        const activeItem = generateActiveItem(
            screen, 
            company_id, 
            facility_id, 
            device_id, 
            gauge_id, 
            measure_id,
        );
    
        switch (screen) {
            case 'companyList':
                path = `/companyList`;
                setActiveItem(null);
                break;
                case 'facilityList':
                    path = `/facilityList?company_id=${company_id}`;
                    break;
                case 'facilityEdit':
                    path = `/facilityEdit?company_id=${company_id}&facility_id=${facility_id}`;
                    break;
                case 'cameraEdit':
                    path = `/cameraEdit?company_id=${company_id}&facility_id=${facility_id}&device_id=${device_id}`;
                    break;
                case 'gaugeEdit':
                    path = `/gaugeEdit?company_id=${company_id}&facility_id=${facility_id}&device_id=${device_id}&gauge_id=${gauge_id}`;
                    break;
                case 'measureEdit':
                    path = `/measureEdit?company_id=${company_id}&facility_id=${facility_id}&device_id=${device_id}&gauge_id=${gauge_id}&measure_id=${measure_id}`;
                    break;
            case 'facilityNew':
                path = `/facilityNew?company_id=${company_id}`;
                break;
            case 'cameraNew':
                path = `/cameraNew?company_id=${company_id}&facility_id=${facility_id}`;
                break;
            case 'measureNew':
                path = `/measureNew?company_id=${company_id}&facility_id=${facility_id}&device_id=${device_id}&gauge_id=${gauge_id}`;
                break;
            default:
                return;
        }
    
        if (activeItem) {
            setActiveItem(activeItem);
        }
        setActiveSet('cameraSettings');
        navigate(path);

        if (screenType === 'tablet') {
            setIsMenuOpen(false);
        }
    };

    const getLinkClass = (hasData, isOpen) => {
        return hasData ? `ac-link ${isOpen ? 'clicked' : ''}` : 'non-ac-link';
    };

    const getGaugeIcon = measure => {
        return measure.length > 0 ? 'img/common/navi/icon-data.png' : 'img/common/navi/icon-photo.png';
    };

    return (
        <div id="cameraSettings" className={`lay-sidemenu__set ${screenType} ${activeSet === 'cameraSettings' ? 'active-sidemenu' : ''}`}>
            <div className="menu-master">
                <img src="img/common/navi/icon-photo.png" alt="Camera Management" className="menu-master-icon" />
                カメラ・計器設定
            </div>
            {isManagementCompany && adminData && (
                <div className="admin">
                    <span className="circle"></span>
                    <button
                        className="name"
                        onClick={(event) =>
                            navigateToCameraSetting(event, null, null, null, null, null, 'companyList')
                        }
                        style={{ marginLeft: '3px' }}
                    >
                        <span>{adminData.admin_name}</span>
                    </button>
                </div>
            )}
            {menuData.length > 0 && (
                menuData.map((company, cIndex) => (
                    <ul key={cIndex} className='menu-item'>
                        <li id={`li_set_company${cIndex}`} className="menu-item__company" value={`company_${company.company_id}`}>
                            <div className={company.facility.length > 0 ? `ac-link ${company.isOpen ? 'clicked' : ''}` : 'non-ac-link'} onClick={() => toggleMenu(cIndex, 'company', 'cameraSettings')}>
                                {company.facility.length === 0 && <div style={{ width: '15px' }}></div>}
                            </div>
                            <button
                                className={`name ${
                                    activeItem &&
                                    String(activeItem.company_id) === String(company.company_id) &&
                                    !activeItem.facility_id &&
                                    !activeItem.camera_id &&
                                    !activeItem.gauge_id &&
                                    !activeItem.measure_id &&
                                    activeSet === 'cameraSettings'
                                        ? 'active'
                                        : ''
                                }`}
                                onClick={(event) => navigateToCameraSetting(event, company.company_id, null, null, null, null, 'facilityList', company.company_name)}
                            >
                                {company.company_name}
                            </button>
                            <button 
                                className='plus-minus-outer'
                                onClick={(event) => {
                                    setActiveItem({
                                        company_id: String(company.company_id),
                                        facility_id: null,
                                        camera_id: null,
                                        gauge_id: null,
                                        measure_id: null,
                                    });
                                    navigateToCameraSetting(event, company.company_id, null, null, null, null, 'facilityNew', company.company_name);
                                }}>
                                <span className="plus-icon"></span>
                            </button>
                        </li>
                        <AnimatePresence>
                            {company.isOpen && (
                                <motion.ul
                                    initial={{ height: 0, opacity: 0 }}
                                    animate={{ height: 'auto', opacity: 1 }}
                                    exit={{ height: 0, opacity: 0 }}
                                    transition={{ duration: 0.3 }}
                                >
                                    {company.facility.map((facility, fIndex) => (
                                        <ul key={fIndex} className="facility-list">
                                            <li id={`li_set_facility${cIndex}${fIndex}`} className="menu-item__facility" value={`facility_${facility.facility_id}`}>
                                                <div className={getLinkClass(facility.camera.length > 0, facility.isOpen)} onClick={() => toggleMenu(fIndex, 'facility', 'cameraSettings', cIndex)}>
                                                    {facility.camera.length === 0 && <img src="img/common/navi/icon-data.png" alt="Management"/>}
                                                </div>
                                                <button 
                                                    className={`name ${
                                                        activeItem &&
                                                        String(activeItem.company_id) === String(company.company_id) &&
                                                        String(activeItem.facility_id) === String(facility.facility_id) &&
                                                        !activeItem.camera_id &&
                                                        !activeItem.gauge_id &&
                                                        !activeItem.measure_id &&
                                                        activeSet === 'cameraSettings'
                                                            ? 'active'
                                                            : ''
                                                    }`}
                                                    onClick={(event) => navigateToCameraSetting(event, company.company_id, facility.facility_id, null, null, null, 'facilityEdit', company.company_name, facility.facility_name)}>
                                                    <span>{facility.facility_name}</span>
                                                </button>
                                                <button
                                                    className='plus-minus-outer'
                                                    onClick={(event) => {
                                                        setActiveItem({
                                                            company_id: String(company.company_id),
                                                            facility_id: String(facility.facility_id),
                                                            camera_id: null,
                                                            gauge_id: null,
                                                            measure_id: null,
                                                        });
                                                        navigateToCameraSetting(event, company.company_id, facility.facility_id, null, null, null, 'cameraNew', company.company_name, facility.facility_name);
                                                    }}>
                                                    <span className="plus-icon"></span>
                                                </button>
                                            </li>
                                            <AnimatePresence>
                                                {facility.isOpen && (
                                                    <motion.ul
                                                        initial={{ height: 0, opacity: 0 }}
                                                        animate={{ height: 'auto', opacity: 1 }}
                                                        exit={{ height: 0, opacity: 0 }}
                                                        transition={{ duration: 0.3 }}
                                                    >
                                                        {facility.camera.map((camera, caIndex) => (
                                                            <ul key={caIndex} className="camera-list">
                                                                <li id={`li_set_camera${cIndex}${fIndex}${caIndex}`} className="menu-item__camera" value={`camera_${camera.device_id}`}>
                                                                    <div className={getLinkClass(camera.gauge.length > 0, camera.isOpen)} onClick={() => toggleMenu(caIndex, 'camera', 'cameraSettings', fIndex, cIndex)}>
                                                                        {camera.gauge.length === 0 && <img src="img/common/navi/icon-data.png" alt="Management"/>}
                                                                    </div>
                                                                    <button className={`name ${
                                                                                activeItem &&
                                                                                String(activeItem.company_id) === String(company.company_id) &&
                                                                                String(activeItem.facility_id) === String(facility.facility_id) &&
                                                                                String(activeItem.camera_id) === String(camera.device_id) &&
                                                                                !activeItem.gauge_id &&
                                                                                !activeItem.measure_id &&
                                                                                activeSet === 'cameraSettings'
                                                                                    ? 'active'
                                                                                    : ''
                                                                            }`}
                                                                            onClick={(event) => navigateToCameraSetting(event, company.company_id, facility.facility_id, camera.device_id, null, null, 'cameraEdit', company.company_name, facility.facility_name, camera.display_camera_name)}>
                                                                        <span>{camera.display_camera_name}</span>
                                                                    </button>
                                                                </li>
                                                                <AnimatePresence>
                                                                    {camera.isOpen && (
                                                                        <motion.ul
                                                                            initial={{ height: 0, opacity: 0 }}
                                                                            animate={{ height: 'auto', opacity: 1 }}
                                                                            exit={{ height: 0, opacity: 0 }}
                                                                            transition={{ duration: 0.3 }}
                                                                        >
                                                                            {camera.gauge.map((gauge, gIndex) => (
                                                                                <ul key={gIndex} className="gauge-list">
                                                                                    <li id={`li_set_gauge${cIndex}${fIndex}${caIndex}${gIndex}`} className="menu-item__gauge" value={`gauge_${gauge.gauge_id}`}>
                                                                                        <div className={getLinkClass(gauge.measure.length > 0, gauge.isOpen)} onClick={() => toggleMenu(gIndex, 'gauge', 'cameraSettings', caIndex, fIndex, cIndex)}>
                                                                                            {gauge.measure.length === 0 && <img src="img/common/navi/icon-photo.png" alt="Management"/>}
                                                                                        </div>
                                                                                        <button className={`name ${
                                                                                                    activeItem &&
                                                                                                    String(activeItem.company_id) === String(company.company_id) &&
                                                                                                    String(activeItem.facility_id) === String(facility.facility_id) &&
                                                                                                    String(activeItem.camera_id) === String(camera.device_id) &&
                                                                                                    String(activeItem.gauge_id) === String(gauge.gauge_id) &&
                                                                                                    !activeItem.measure_id &&
                                                                                                    activeSet === 'cameraSettings'
                                                                                                        ? 'active'
                                                                                                        : ''
                                                                                                }`}
                                                                                                style={{wordBreak: "break-all"}}
                                                                                                onClick={(event) => navigateToCameraSetting(event, company.company_id, facility.facility_id, camera.device_id, gauge.gauge_id, null, 'gaugeEdit', company.company_name, facility.facility_name, camera.display_camera_name, gauge.display_gauge_name)}>
                                                                                                {gauge.display_gauge_flg === 0 && (
                                                                                                    <>
                                                                                                        <span style={{ fontWeight: 'bold', fontSize: '14px', color: '#666' }}>
                                                                                                            [初回名称] 
                                                                                                        </span> 
                                                                                                        <br />
                                                                                                    </>
                                                                                                )}
                                                                                                <span>{gauge.display_gauge_name}</span>
                                                                                        </button>
                                                                                        <button
                                                                                            className='plus-minus-outer'
                                                                                            onClick={(event) => {
                                                                                                setActiveItem({
                                                                                                    company_id: String(company.company_id),
                                                                                                    facility_id: String(facility.facility_id),
                                                                                                    camera_id: String(camera.device_id),
                                                                                                    gauge_id: String(gauge.gauge_id),
                                                                                                    measure_id: null,
                                                                                                    name: gauge.display_gauge_name,
                                                                                                });
                                                                                                navigateToCameraSetting(event, company.company_id, facility.facility_id, camera.device_id, gauge.gauge_id, null, 'measureNew', company.company_name, facility.facility_name, camera.display_camera_name, gauge.display_gauge_name);
                                                                                            }}>
                                                                                            <span className="plus-icon"></span>
                                                                                        </button>
                                                                                    </li>
                                                                                    {gauge.isOpen && (
                                                                                        <motion.ul
                                                                                            initial={{ height: 0, opacity: 0 }}
                                                                                            animate={{ height: 'auto', opacity: 1 }}
                                                                                            exit={{ height: 0, opacity: 0 }}
                                                                                            transition={{ duration: 0.3 }}
                                                                                        >
                                                                                            {gauge.measure.map((measure, mIndex) => (
                                                                                                <li id={`li_set_measure${cIndex}${fIndex}${caIndex}${gIndex}${measure.measure_id}`} key={mIndex} className="menu-item__measure" value={`measure_${measure.measure_id}`}>
                                                                                                    <img src={getGaugeIcon(gauge.measure)} alt="Gauge" className="menu-item-icon" />
                                                                                                    <button className={`name ${
                                                                                                                activeItem &&
                                                                                                                String(activeItem.company_id) === String(company.company_id) &&
                                                                                                                String(activeItem.facility_id) === String(facility.facility_id) &&
                                                                                                                String(activeItem.camera_id) === String(camera.device_id) &&
                                                                                                                String(activeItem.gauge_id) === String(gauge.gauge_id) &&
                                                                                                                String(activeItem.measure_id) === String(measure.measure_id) &&
                                                                                                                activeSet === 'cameraSettings'
                                                                                                                    ? 'active'
                                                                                                                    : ''
                                                                                                            }`}
                                                                                                            onClick={(event) => navigateToCameraSetting(event, company.company_id, facility.facility_id, camera.device_id, gauge.gauge_id, measure.measure_id, 'measureEdit', company.company_name, facility.facility_name, camera.display_camera_name, gauge.display_gauge_name, measure.measure_name)}>
                                                                                                        <span>{measure.measure_name}</span>
                                                                                                    </button>
                                                                                                </li>
                                                                                            ))}
                                                                                        </motion.ul>
                                                                                    )}
                                                                                </ul>
                                                                            ))}
                                                                        </motion.ul>
                                                                    )}
                                                                </AnimatePresence>
                                                            </ul>
                                                        ))}
                                                    </motion.ul>
                                                )}
                                            </AnimatePresence>
                                        </ul>
                                    ))}
                                </motion.ul>
                            )}
                        </AnimatePresence>
                    </ul>
                ))
            )}
        </div>
    );
};

export default CameraSettingsSection;
