import ActionTypes from "../constants/ActionTypes";
import BASE_URL from "../constants/Api";
import axiosInstance from "../helpers/refreshTokenMiddleware";

export const fetchMailSendRequest = () => ({
  type: ActionTypes.GET_MAIL_SEND_SETTING_REQUEST,
});

export const fetchMailSendSuccess = (data) => ({
    type: ActionTypes.GET_MAIL_SEND_SETTING_SUCCESS,
    payload: data,
});

export const fetchMailSendFailure = (error) => ({
    type: ActionTypes.GET_MAIL_SEND_SETTING_FAILURE,
    payload: error,
});

// Thunk to fetch data from API  
export const fetchMailSend = () => {
  return async (dispatch) => {
    dispatch(fetchMailSendRequest());
    try {
      const accessToken = localStorage.getItem('accessToken');
  
      if (!accessToken) {
        window.location.href = '/login';
        return;
      }
  
      const response = await axiosInstance.get(BASE_URL + '/setting/mail-send-setting', {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });
      const data = response.data.respons || {};
      dispatch(fetchMailSendSuccess(data));
    } catch (error) {
      dispatch(fetchMailSendFailure(error.message));
    }
  };
};

export const testMailSendRequest = () => ({
  type: ActionTypes.TEST_MAIL_SEND_REQUEST,
});

export const testMailSendSuccess = (message) => ({
  type: ActionTypes.TEST_MAIL_SEND_SUCCESS,
  payload: message,
});

export const testMailSendFailure = (error) => ({
  type: ActionTypes.TEST_MAIL_SEND_FAILURE,
  payload: error,
});

export const testMailSend = (abnomal_alert_flg, battery_alert_flg, battery_alert_amount, mail_to, mail_cc, mail_bcc) => {
  return async (dispatch) => {
    dispatch(testMailSendRequest());
    try {
        const accessToken = localStorage.getItem('accessToken');

        if (!accessToken) {
          window.location.href = '/login';
          return;
        }

        const body = {
            abnomal_alert_flg,
            battery_alert_flg,
            battery_alert_amount,
            mail_to,
            mail_cc, 
            mail_bcc,
        };

        const response = await axiosInstance.post(BASE_URL + '/setting/test-mail-send-setting', body, {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        });

        dispatch(testMailSendSuccess(response.data.respons.msg));
        return response;
    } catch (error) {
        dispatch(testMailSendFailure(error.message)); 
    }
  };
};

export const saveMailSendRequest = () => ({
  type: ActionTypes.TEST_MAIL_SEND_REQUEST,
});

export const saveMailSendSuccess = (message) => ({
  type: ActionTypes.TEST_MAIL_SEND_SUCCESS,
  payload: message,
});

export const saveMailSendFailure = (error) => ({
  type: ActionTypes.TEST_MAIL_SEND_FAILURE,
  payload: error,
});

export const saveMailSend = (abnomal_alert_flg, battery_alert_flg, battery_alert_amount, mail_to, mail_cc, mail_bcc) => {
  return async (dispatch) => {
    dispatch(saveMailSendRequest());
    try {
        const accessToken = localStorage.getItem('accessToken');

        if (!accessToken) {
          window.location.href = '/login';
          return;
        }

        const body = {
            abnomal_alert_flg,
            battery_alert_flg,
            battery_alert_amount,
            mail_to,
            mail_cc, 
            mail_bcc,
        };

        const response = await axiosInstance.post(BASE_URL + '/setting/save-mail-send-setting', body, {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        });

        dispatch(saveMailSendSuccess(response.data.respons.msg));
        return response; 
    } catch (error) {
        dispatch(saveMailSendFailure(error.message)); 
    }
  };
};


