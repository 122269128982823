import ActionTypes from '../constants/ActionTypes';
import axiosInstance from '../helpers/refreshTokenMiddleware';
import BASE_URL from '../constants/Api';

export const fetchComparisonGraphDataRequest = () => ({
    type: ActionTypes.FETCH_COMPARISON_GRAPH_REQUEST,
});

export const fetchComparisonGraphDataSuccess = (data, isEmpty) => ({
    type: ActionTypes.FETCH_COMPARISON_GRAPH_SUCCESS,
    payload: { data, isEmpty },
});

export const fetchComparisonGraphDataFailure = (error) => ({
    type: ActionTypes.FETCH_COMPARISON_GRAPH_FAILURE,
    payload: error,
});

export const fetchComparisonGraphData = (items, start_date, end_date, view_type) => async (dispatch) => {
    dispatch(fetchComparisonGraphDataRequest());
    try {
        var inputdata = [];
        const count = items.length;
        console.log("items:", items);
        
        if (count === 2 || count === 3) {
            if (count === 2) {
                inputdata = {
                    graphCount: count,
                    graph_facility_id1: items[0].facility_id,
                    graph_device_id1: items[0].device_id,
                    graph_gauge_id1: items[0].gauge_id,
                    graph_measure_id1: items[0].measure_id,
                    graph_facility_id2: items[1].facility_id,
                    graph_device_id2: items[1].device_id,
                    graph_gauge_id2: items[1].gauge_id,
                    graph_measure_id2: items[1].measure_id,
                    start_date: start_date,
                    end_date: end_date,
                    view_type: view_type
                };
                console.log("inputdata:", inputdata);
            } else if (count === 3) {
                inputdata = {
                    graphCount: count,
                    graph_facility_id1: items[0].facility_id,
                    graph_device_id1: items[0].device_id,
                    graph_gauge_id1: items[0].gauge_id,
                    graph_measure_id1: items[0].measure_id,
                    graph_facility_id2: items[1].facility_id,
                    graph_device_id2: items[1].device_id,
                    graph_gauge_id2: items[1].gauge_id,
                    graph_measure_id2: items[1].measure_id,
                    graph_facility_id3: items[2].facility_id,
                    graph_device_id3: items[2].device_id,
                    graph_gauge_id3: items[2].gauge_id,
                    graph_measure_id3: items[2].measure_id,
                    start_date: start_date,
                    end_date: end_date,
                    view_type: view_type
                };
                console.log("inputdata:", inputdata);
            };  

            const response = await axiosInstance.post(BASE_URL + '/graph/comparison-graph-data', inputdata);
            const data = response.data.respons || [];
            const isEmpty = data.length === 0;
            dispatch(fetchComparisonGraphDataSuccess(data, isEmpty));  
        } else {
            dispatch(fetchComparisonGraphDataSuccess([], 0));  
        };
    
    } catch (error) {
    dispatch(fetchComparisonGraphDataFailure(error.message));       
    }

};