import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CameraChange from '../components/CameraChange';
import { fetchCameraChange, fetchGetGaugeData, sendCameraChange} from '../actions/camerachange';

const mapStateToProps = (state) => {
    const { cameraChange } = state;
    const user = state.login.user;
    return {
        user,
        data: cameraChange.data, 
        loading: cameraChange.loading,
        error: cameraChange.error,
        dataCount: cameraChange.dataCount,
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchCameraChange,
    fetchGetGaugeData,
    sendCameraChange
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CameraChange);