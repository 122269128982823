import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import Modal from '../Modal/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMenuData } from '../../actions/menuActions';
import Breadcrumb from '../Breadcrumb';

const CameraEdit = ({ user, gaugedata = {}, loading, error, fetchCameraEdit, submitCameraEdit }) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate ();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [companyId, setCompanyId] = useState('');
    const [facilityId, setFacilityId] = useState('');
    const [deviceId, setDeviceId] = useState('');
    const [camera, setCamera] = useState({}); 
    const [gaugeList, setGaugeList] = useState([]); 
    const [isFetching, setIsFetching] = useState(false);
    const [showUserName, setUserName] = useState('');
    const screenType = useSelector((state) => state.device.screenType);

    // Fetch data when component mounts or when location changes
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const company_id = Number(params.get('company_id'))
        const facility_id = params.get('facility_id');
        const device_id = params.get('device_id');
        
        if (!isNaN(company_id) && facility_id && device_id) {
            setCompanyId(company_id);
            setFacilityId(facility_id);
            setDeviceId(device_id);
            fetchCameraEdit(company_id, facility_id, device_id); 
        }
    }, [fetchCameraEdit, location]);

    useEffect(() => {
        if (gaugedata && gaugedata.gauge) {
            setCamera(gaugedata.camera);
            setGaugeList(gaugedata.gauge || []);
        }
    }, [gaugedata]);

    useEffect(() => {
        if (user) {
          setUserName(user.user_name);
        };
    }, [user]);

    const handleInputChange = (e) => {
        const { value } = e.target;
        setCamera((prevCamera) => ({
            ...prevCamera,
            display_camera_name: value,
        }));
    };
    
    const handleSortPrev = (index) => {
        if (index === 0) return; 
        const newList = [...gaugeList];
        [newList[index], newList[index - 1]] = [newList[index - 1], newList[index]]; 
        setGaugeList(newList);
    };

    const handleSortNext = (index) => {
        if (index === gaugeList.length - 1) return;
        const newList = [...gaugeList];
        [newList[index], newList[index + 1]] = [newList[index + 1], newList[index]];
        setGaugeList(newList); 
    };

    const handleSave = async () => {
        setIsModalOpen(false);
        setIsFetching(true);
        try {
            const camera_name = camera.display_camera_name;
            const cnt = gaugeList.length;
            const gaugeIds = gaugeList.map((gauge) => gauge.gauge_id);

            const response = await submitCameraEdit(
                camera_name, 
                companyId, 
                facilityId, 
                deviceId, 
                camera.display_name_id, 
                gaugeIds, 
                cnt
            );

            const message = response.data?.respons?.msg;
            await fetchUpdatedMenu();

            setModalMessage(message);
            setIsModalOpen(true);
        } catch (error) {
            const errorMessage = error.message;
            setModalMessage(errorMessage);
            setIsModalOpen(true);
        } finally {
            setIsFetching(false);
        }
    };

    const handleTopClick = () => {
        navigate('/');
    };

    // Fetch updated menu after save or update
    const fetchUpdatedMenu = async () => {
        try {
            await dispatch(fetchMenuData());
        } catch (error) {
            console.error('Error fetching updated menu:', error);
        }
    };

    return (
        <div id='Main' className={`main ${screenType}`}>
            <div id='Contents'>
                <Breadcrumb
                    userName = {showUserName}
                    pageName = "カメラ・計器設定"
                    onTopClick = {handleTopClick}
                />
                {isModalOpen && (
                    <Modal
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    message={modalMessage}
                />
                )}
                <div id="ajax-view-disp">
                    <div className="display-main" id="set_company_list">
                        <div className="lay-master-set">
                            <div className="lay-master-set-wrap">
                                <div className="lay-master-set__title">
                                    <span>カメラ編集</span>
                                </div>
                                <form id="cameraEditData">
                                    <div className="lay-master-set__form">
                                        <div className="wrapper">
                                            <div>
                                                <span>名称を入力してください。</span>
                                            </div>
                                            <div className="set_master_name" style={{marginBottom: '40px'}}>
                                                <input type="text" id="camera_name_view" value={camera.display_camera_name || ''}  onChange={handleInputChange}/>
                                                <input type="hidden" id="camera_name" name="camera_name"/>
                                                <input type="hidden" name="company_id" value={gaugedata.company_id || ''}/>
                                                <input type="hidden" name="facility_id" value={gaugedata.facility_id || ''}/>
                                                <input type="hidden" name="device_id" value={camera.device_id || ''}/>
                                                <input type="hidden" name="display_name_id" value={camera.display_name_id || ''}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="lay-master-set__frame">
                                        <div className="lay-master__table">
                                            {/* {gaugeList.length > 0 && ( */}
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <th></th>
                                                            <th>計器名</th>
                                                            <th>停止</th>
                                                        </tr>
                                                        {gaugeList.map((gauge, index) => (
                                                            <tr key={gauge.gauge_id} className="lay-tr">
                                                                <td className="lay-td sort">
                                                                    <div
                                                                        className="sortPrev"
                                                                        style={{ display: index === 0 || gaugeList.length === 1 ? 'none' : '' }}
                                                                        onClick={() => handleSortPrev(index)}
                                                                    >
                                                                        △
                                                                    </div>
                                                                    <div
                                                                        className="sortNext"
                                                                        style={{ display: index === gaugeList.length - 1 || gaugeList.length === 1 ? 'none' : '' }}
                                                                        onClick={() => handleSortNext(index)}
                                                                    >
                                                                        ▽
                                                                    </div>
                                                                </td>
                                                                <td className="lay-td">
                                                                    <input type="hidden" name="gauge_id" value={gauge.gauge_id || ''} />
                                                                    <span id={`gauge_id${gauge.gauge_id}`}>{gauge.display_gauge_name || ''}</span>
                                                                </td>
                                                                <td className="lay-td">
                                                                    {!gauge.is_active && (
                                                                        <span style={{ color: 'red' }}>停止</span>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                                <input type="hidden" name="cnt" value={gaugeList.length} />
                                            {/* )} */}
                                        </div>
                                    </div>
                                </form>
                                <div className="trun-btn" style={{marginTop: '50px'}}>
                                    <button type="button" className="master_long_btn" id="edit" onClick={handleSave}>
                                        登録
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`overlay ${(loading || isFetching) ? 'active' : ''}`}>
                <div className={`spinner ${(loading || isFetching) ? 'active' : ''}`}></div>
            </div>
        </div>
    );
};

CameraEdit.propTypes = {
    gaugedata: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    fetchCameraEdit: PropTypes.func.isRequired,
    submitCameraEdit: PropTypes.func.isRequired,
};

export default React.memo(CameraEdit);
