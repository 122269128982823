import ActionTypes from "../constants/ActionTypes";
import BASE_URL from "../constants/Api";
import axiosInstance from "../helpers/refreshTokenMiddleware";

export const fetchCameraChangeRequest = () => ({
  type: ActionTypes.FETCH_CAMERA_CHANGE_DATA_REQUEST,
});

export const fetchCameraChangeSuccess = (data) => ({
    type: ActionTypes.FETCH_CAMERA_CHANGE_DATA_SUCCESS,
    payload: data,
});

export const fetchCameraChangeFailure = (error) => ({
    type: ActionTypes.FETCH_CAMERA_CHANGE_DATA_FAILURE,
    payload: error,
});

// Thunk to fetch data from API  
export const fetchCameraChange = () => {
  return async (dispatch) => {
    dispatch(fetchCameraChangeRequest());
    try {
      const accessToken = localStorage.getItem('accessToken');
  
      if (!accessToken) {
        window.location.href = '/login';
        return;
      }
  
      const response = await axiosInstance.get(BASE_URL + '/setting/camera-change', {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });
      const data = response.data.respons || {};
      dispatch(fetchCameraChangeSuccess(data));
    } catch (error) {
      dispatch(fetchCameraChangeFailure(error.message));
    }
  };
};


export const fetchGetGaugeDataSuccess = (dataCount) => ({
  type: ActionTypes.FETCH_GET_GAUGE_DATA_SUCCESS,
  payload: dataCount,
});

export const fetchGetGaugeDataFailure = (error) => ({
  type: ActionTypes.FETCH_GET_GAUGE_DATA_FAILURE,
  payload: error,
});

// Thunk action to submit camera edit form data
export const fetchGetGaugeData = (camera_id) => {
  return async (dispatch) => {
      try {
          const accessToken = localStorage.getItem('accessToken');
          const body = {
            camera_id: camera_id,
          };
          const response = await axiosInstance.post(BASE_URL + '/setting/get-gauge-data', body, {
              headers: {
                  'Authorization': `Bearer ${accessToken}`,
              }
          });

          dispatch(fetchGetGaugeDataSuccess(response.data.respons.dataCount));
          return response;
      } catch (error) {
          // Dispatch failure action if the API call fails
          dispatch(fetchGetGaugeDataFailure(error.message));
      }
  };
};

export const sendCameraChangeRequest = () => ({
  type: ActionTypes.CAMERA_CHANGE_MAIL_SEND_REQUEST,
});

export const sendCameraChangeSuccess = (message) => ({
  type: ActionTypes.CAMERA_CHANGE_MAIL_SEND_SUCCESS,
  payload: message,
});

export const sendCameraChangeFailure = (error) => ({
  type: ActionTypes.CAMERA_CHANGE_MAIL_SEND_FAILURE,
  payload: error,
});

export const sendCameraChange = (mail_to, mail_cc, mail_bcc, facility_id, facility_name, camera_id, camera_object, new_camera_object, camera_index, camera_name, new_camera_name, new_facility_name) => {
  return async (dispatch) => {
      dispatch(sendCameraChangeRequest());
      try {
          const accessToken = localStorage.getItem('accessToken');
          const body = {
            mail_to, 
            mail_cc, 
            mail_bcc, 
            facility_id, 
            facility_name, 
            camera_id, 
            camera_object, 
            new_camera_object, 
            camera_index, 
            camera_name, 
            new_camera_name, 
            new_facility_name
            
          };
          const response = await axiosInstance.post(BASE_URL + '/setting/camera-change-mail-send', body, {
              headers: {
                  'Authorization': `Bearer ${accessToken}`,
              }
          });

          dispatch(sendCameraChangeSuccess(response.data.respons.msg));
          return response;
      } catch (error) {
          // Dispatch failure action if the API call fails
          dispatch(sendCameraChangeFailure(error.message));
      }
  };
};

