import ActionTypes from '../constants/ActionTypes';

const initialState = {
    isFetching: false,
    systemInfo: null,
    error: null,
    isFetched: false,
    is_null: false,
};


const systemInfoReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.FETCH_SYSTEM_INFO_REQUEST:
            return {
                ...state,
                isFetched: false,
            }      
        
        case ActionTypes.FETCH_SYSTEM_INFO_SUCCESS:
            return {
                ...state,
                isFetching: false,
                systemInfo: action.payload,
                error: null,
            };
       
        case ActionTypes.FETCH_SYSTEM_INFO_FAILURE:
            return {
                ...state,
                isFetched: false,
            }
        
        default:
            return state;
    }
};

export default systemInfoReducer;
