import ActionTypes from '../constants/ActionTypes';
import axiosInstance from '../helpers/refreshTokenMiddleware';
import BASE_URL from '../constants/Api';

//Fetch system info
export const fetchSystemInfoRequest = () => ({
    type: ActionTypes.FETCH_SYSTEM_INFO_REQUEST,
});

export const fetchSystemInfoSuccess = (data) => ({
    type: ActionTypes.FETCH_SYSTEM_INFO_SUCCESS,
    payload: data,
});

export const fetchSystemInfoFailure = (error) => ({
    type: ActionTypes.FETCH_SYSTEM_INFO_FAILURE,
    payload: error,
});

export const fetchSystemInfo = () => async (dispatch) => {
    dispatch(fetchSystemInfoRequest());
    try {
        const accessToken = localStorage.getItem('accessToken');

        if (!accessToken) {
            window.location.href = '/login';
            return;
        }

        const response = await axiosInstance.get(BASE_URL + '/top/system-info', { 
            headers: {
                'Authorization': `Bearer ${accessToken}`
                }
        });

        dispatch(fetchSystemInfoSuccess(response.data.respons));
    } catch (error) {
        const errorMessage = error.response && error.response.status === 504 
            ? '504 ゲートウェイタイムアウト。\n後でもう一度お試しください。' 
            : error.message;
        dispatch(fetchSystemInfoFailure(errorMessage));
    }
};