import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import GaugeEdit from '../components/GaugeEdit';
import { fetchGaugeEdit, checkMeasureData, editGaugeData } from '../actions/gaugeedit';

const mapStateToProps = (state) => {
    const { gaugeEdit } = state;
    const user = state.login.user;
    return {
        user,
        measuredata: gaugeEdit.measuredata, 
        loading: gaugeEdit.loading,
        error: gaugeEdit.error,
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchGaugeEdit,
    checkMeasureData,
    editGaugeData
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(GaugeEdit);