import ActionTypes from '../constants/ActionTypes'; 

const initialState = {
    loading: false,
    error: null
};

const facilityReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.REGISTER_FACILITY_REQUEST:
            return { ...state, loading: true, error: null };
        case ActionTypes.REGISTER_FACILITY_SUCCESS:
            return { ...state, loading: false, message: action.payload };
        case ActionTypes.REGISTER_FACILITY_FAILURE:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};

export default facilityReducer;
