import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Modal from '../Modal/Modal';
import './FacilityNew.css';
import { useDispatch, useSelector } from 'react-redux'; 
import { fetchMenuData  } from '../../actions/menuActions'; 
import Breadcrumb from '../Breadcrumb';

const FacilityNew = ({ user, registerFacility, loading: registerLoading, error }) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate ();
    const [companyId, setCompanyId] = useState('');
    const [facilityName, setFacilityName] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [isLoadingPage, setIsLoadingPage] = useState(true);
    const [isFetching, setIsFetching] = useState(false);
    const screenType = useSelector((state) => state.device.screenType);
    const [showUserName, setUserName] = useState('');

    useEffect(() => {
        setIsLoadingPage(true);

        const params = new URLSearchParams(location.search);
        const company_id = Number(params.get('company_id'))
        if (!isNaN(company_id)) {
            setCompanyId(company_id);
        }
        const delay = setTimeout(() => {
            setIsLoadingPage(false);
        }, 1000);

        return () => clearTimeout(delay);
    }, [location]);

    useEffect(() => {
        if (isLoadingPage) {
            setFacilityName(''); 
        }
    }, [isLoadingPage]);

    useEffect(() => {
        if (user) {
          setUserName(user.user_name);
        };
    }, [user]);

    const fetchUpdatedMenu = async () => {
        try {
            // Instead of calling the API here, dispatch fetchMenuData action
            await dispatch(fetchMenuData());
        } catch (error) {
            console.error('Error fetching updated menu:', error);
        }
    };

    const handleRegister = async () => {
        setIsLoadingPage(false); 
        setIsFetching(true);
        try {
            const response = await registerFacility(facilityName, companyId);
            const message = response.data.respons?.msg;
            await fetchUpdatedMenu();

            setModalMessage(message);
            setIsModalOpen(true);
        } catch (message) {
            setModalMessage(message);
            setIsModalOpen(true);
        } finally {
            setIsFetching(false);  
        }
    };

    const handleTopClick = () => {
        navigate('/');
    };
    
    return (
        <div id="Main" className={`main ${screenType}`}>
            <div id="Contents">
                <Breadcrumb
                    userName = {showUserName}
                    pageName = "カメラ・計器設定"
                    onTopClick = {handleTopClick}
                />
                {isModalOpen && (
                    <Modal
                        isOpen={isModalOpen}
                        onClose={() => setIsModalOpen(false)}
                        message={modalMessage}
                    />
                )}

                {isLoadingPage ? (
                    <div className="loader active"></div>
                ) : (
                    <div id="ajax-view-disp">
                        <div className="display-main">
                            <div className="lay-master-set">
                                <div className="lay-master-set-wrap">
                                    <div className="lay-master-set__title">
                                        <span>施設設定</span>
                                    </div>

                                    <div className="lay-master-set__form">
                                        <form action="" id='facilityNewData'>
                                            <div className="wrapper">
                                                <div style={{textAlign: 'left'}}>
                                                    <span>名称を入力してください。</span>
                                                </div>

                                                <div className="set_master_name">
                                                    <input
                                                        type="text"
                                                        id="facility_name_view"
                                                        name="facility_name"
                                                        value={facilityName}
                                                        onChange={(e) => setFacilityName(e.target.value)} 
                                                    />
                                                    <input type="hidden" id="facility_name" name="facility_name"/>
                                                    <input type="hidden" name="company_id" value={companyId}/>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="trun-btn">
                                        <button
                                            type="button"
                                            className="master_long_btn"
                                            id="register"
                                            onClick={handleRegister} 
                                            disabled={registerLoading}
                                        >
                                            登録
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {(registerLoading || isFetching) && (
                <div className="overlay active">
                    <div className="spinner active"></div>
                </div>
            )}
        </div>
    );
};

FacilityNew.propTypes = {
    registerFacility: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string
};

export default React.memo(FacilityNew);
