import React, { useEffect, useRef } from 'react';
import { useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import './ScreenView.css';
import Breadcrumb from '../Breadcrumb';
import Config from '../../constants/Config';

const ScreenView = React.memo(
    ({ 
        isFetching = false, 
        user = {}, 
        systemInfo,
        fetchUserData, 
        refreshAccessToken, 
        fetchSystemInfo, 
        logout 
    }) => {
    const screenType = useSelector((state) => state.device.screenType);

    console.log('ScreenView Render !');

    const isInitialRender = useRef(true);

    useEffect(() => {
        let accessToken = localStorage.getItem('accessToken');
        const refreshToken = localStorage.getItem('refreshToken');
        const isMaintenance = localStorage.getItem('maintenance');

        // const fetchData = async () => {
        //     try {
        //         fetchUserData(accessToken);
        //     } catch (error) {
        //         if (error.response && error.response.status === 401) {
        //             if (refreshToken) {
        //                 const newAccessToken = await refreshAccessToken(refreshToken);
        //                 accessToken = newAccessToken;
        //                 localStorage.setItem('accessToken', accessToken);
        //             } else {
        //                 logout();
        //             }
        //         } else {
        //             console.error(error);
        //         }
        //     }
        // };

        if (accessToken && refreshToken&&(isMaintenance !=="1")) {
            // Call fetchSystemInfo on the first render
            if (isInitialRender.current) {
                fetchSystemInfo();
                isInitialRender.current = false;
            }   

            // fetchData();
        };

    }, [fetchSystemInfo, fetchUserData, logout, refreshAccessToken]);

    return (
        <div id="Main" className={`main ${screenType}`}>
            <div id="Contents">
                {(user) ? (
                    <Breadcrumb
                        userName = {user.user_name}
                        pageName = ""
                        isTopPage = {true}
                    />
                ) : (
                    <Breadcrumb style={{display: 'none'}}></Breadcrumb>
                )}
                <div className={`display-main ${screenType}`}>
                    <div className={`lay-top ${screenType}`}>
                        <div className={`lay-top__title ${screenType}`}>
                            <img src="img/top/logo-main.png" alt="Logo" className="screenview-logo" />
                            <p>左のメニューから操作内容をお選びください。</p>
                        </div>
                    </div>
                    <div className="lay-bottom__title">
                        <div>Version {Config.CLIENT_VERSION}</div>
                        {systemInfo?.version && (
                            <div>API Version {systemInfo.version}</div>
                        )}
                        
                    </div>
                </div>
            </div>
            {/* <div className="screenview">
                <div className="screenview-content">
                    
                    {!isFetching && user && (
                        <>
                            <p className="welcome-message">Welcome {user.user_name}</p>
                        </>
                    )}
                </div>
            </div> */}
        </div>
    );
});

ScreenView.propTypes = {
    isFetching: PropTypes.bool.isRequired,
    user: PropTypes.object,
    systemInfo: PropTypes.object,
    fetchUserData: PropTypes.func.isRequired,
    refreshAccessToken: PropTypes.func.isRequired,
    fetchSystemInfo: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired
};

// ScreenView.defaultProps = {
//     isFetching: false,
//     user: {}
// };

export default ScreenView;
