import { connect } from "react-redux";
import Maintenance from "../components/Maintenance";

const mapStateToProps = (state) => {
  console.log("state:", state);

  return {
    maintenance: state.login.maintenance,
    screen_message: state.login.screen_message,
  };
};


export default connect(mapStateToProps)(Maintenance);
