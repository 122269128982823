import ActionTypes from '../constants/ActionTypes';
import axiosInstance from '../helpers/refreshTokenMiddleware';
import BASE_URL from '../constants/Api';

export const updateSetting2FARequest = () => ({
    type: ActionTypes.UPDATE_SETTING_2FA_REQUEST,
});

export const updateSetting2FASuccess = (message) => ({
    type: ActionTypes.UPDATE_SETTING_2FA_SUCCESS,
    payload: message,
});

export const updateSetting2FAFailure = (error) => ({
    type: ActionTypes.UPDATE_SETTING_2FA_FAILURE,
    payload: error,
});

export const updateSetting2FA = (is2FAEnabled, email) => {
    return async (dispatch) => {
        dispatch(updateSetting2FARequest());
        try {
            const accessToken = localStorage.getItem('accessToken');
            const response = await axiosInstance.post(BASE_URL + '/setting/save-setting-2fa', {
                using_2fa_flg: is2FAEnabled ? 1 : 0,
                mail_2fa: email,
            }, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            dispatch(updateSetting2FASuccess(response.data.respons.msg));
            return response;
        } catch (error) {
            const errorMessage = error.response?.data?.respons?.msg; 
            dispatch(updateSetting2FAFailure(errorMessage));
            throw new Error(errorMessage);
        
        }
    };
};
