/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types';
import './BatteryGraph.css'
import { Chart, LineElement, CategoryScale, LinearScale, PointElement, Title, Tooltip, Legend } from 'chart.js/auto';
import html2canvas from 'html2canvas';
import { useNavigate, useLocation  } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Modal from '../Modal/Modal';
import Breadcrumb from '../Breadcrumb';

Chart.register(LineElement, CategoryScale, LinearScale, PointElement, Title, Tooltip, Legend);


const BatteryGraph = React.memo(
    ({user, selectedCamera, battery_data, estimated_date, is_null, isFetched, isLoading, fetchBatteryGraphData}) => {

        const canvasRef = useRef(null);
        const chartInstance = useRef(null);
        const navigate = useNavigate ();
        const location = useLocation();
        const queryParams = new URLSearchParams(location.search);
        const company_id = queryParams.get('company_id');
        const display_facility_name = queryParams.get('facility');
        const display_camera_name = queryParams.get('camera');
        const device_id = queryParams.get('device_id');
        const [isModalOpen, setIsModalOpen] = useState(false);
        const [modalMessage, setModalMessage] = useState('');
        const [showUserName, setUserName] = useState('');
        const [deviceType, setDeviceType] = useState("desktop");
        const screenType = useSelector((state) => state.device.screenType);

        var clickTime = 0;

        const handleGoBack = (event)  => {
          navigate(-1);
        };

        const handleDownloadImage = (event) => {
          if (deviceType !== 'desktop') {
            setModalMessage('グラフ画像ダウンロードはPCからご利用ください。');
            setIsModalOpen(true);
          } else {

            if (!clickTime) {
              clickTime = new Date();
            } else {
              //ダブルクリック防止
              var diffTime = new Date() - clickTime;
              clickTime = new Date();
              if (diffTime < 900) {
                return false;
              }
            }

            var element = document.getElementById('screenshot');
            var imgWidth = element.offsetWidth + 15;
            var imgHeight = element.offsetHeight + 15;
      
            html2canvas(element, {scrollY: -window.scrollY + 25, scrollX: -window.scrollX + 25, width: imgWidth, height: imgHeight}).then(function (canvas) {
              // ファイル名作成開始
  
              var graph_date = new Date();
      
              graph_date = fnGetDateFormat(graph_date).replace(/\//g,'');
      
              var facility_name = display_facility_name;
              var camera_name = display_camera_name;
              
              var regSpecChar = RegExp(/["<>:|\\*/?]/g);
              
              //施設名チェック
              facility_name = facility_name.replace(regSpecChar, '');
              if (facility_name.length > 50) {
                facility_name = facility_name.slice(0, 50) + "...";
              }
              //カメラ名チェック
              camera_name = camera_name.replace(regSpecChar, '');
              if (camera_name.length > 50) {
                camera_name = camera_name.slice(0, 50) + "...";
              }
              
              var fileName = graph_date + facility_name + camera_name + "バッテリー残量.png";
              // ファイル名作成終了
              
              if (canvas.msToBlob) { // IEブラウザ
                window.navigator.msSaveBlob(canvas.msToBlob(), fileName);
              } else { // 他のブラウザ
                var imgageData = canvas.toDataURL("image/png");
                var newData = imgageData.replace(/^data:image\/png/, "data:application/octet-stream");
  
                var link = document.createElement('a');
                link.href = newData;
                link.download = fileName;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }
            }).catch(function(err){
              console.log(err);
            });

            setModalMessage('');
            setIsModalOpen(false);
  
          };
          
        };

        const handleTopClick = () => {
          navigate('/');
        };

        const fnGetDateFormat = (date) => {
          return date.getFullYear()+"/"+("0" + (date.getMonth()+1)).slice(-2)+"/"+("0"+date.getDate()).slice(-2);
        };

        const handleContextMenu = (event) => {
          event.preventDefault();
        };

        useEffect(() => {
          const detectDevice = () => {
              const userAgent = navigator.userAgent || navigator.vendor || window.opera;
              const isTouchDevice = "ontouchstart" in window || navigator.maxTouchPoints > 0;
  
              if (/iPhone|Android.+Mobile/.test(userAgent)) {
                  setDeviceType("mobile");
      
              } else if (
                  /iPad|Android/.test(userAgent) || // iPad and Android tablets
                  (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)  || // iPad Pro in desktop mode
                  (isTouchDevice)            // Handle simulation in Chrome DevTools
                  // (isTouchDevice)            // Handle simulation in Chrome DevTools
              ) {
                  setDeviceType("tablet");
               } else {
                  setDeviceType("desktop");        
              }
          };
  
          detectDevice();
          window.addEventListener('resize', detectDevice);
  
          return () => {
              window.removeEventListener('resize', detectDevice);
          };
        }, []);

        useEffect(() => {

          const fetchData = async () => {
            if ((company_id) && (device_id)) {
              fetchBatteryGraphData(company_id*1, device_id);
            };
          };

          fetchData();

        }, [company_id, device_id]);

        useEffect(() => {
          if (user) {
            setUserName(user.user_name);
          };
        }, [user]);

        useEffect(() => {

          let toolAlert = [];
          let toolValue = [];
          let toolDate = [];
          let toolHour = [];
          let hasErrorData1 = false;
          let toolIdx = "";
          let BackGroundColorList = [];
          var bar1Color = '#00dff3';
          var limitOverColor = '#ff1300';

          const customTooltip = (context) => {
            const { tooltip } = context;

            const dataLabel = tooltip.dataPoints[0].label;
            const index = tooltip.dataPoints[0].dataIndex;
            const backGroundColor = tooltip.dataPoints[0].dataset.backgroundColor[index];
             
            let tooltipEl = document.getElementById('chartjs-tooltip');
      
            if (!tooltipEl) {
              tooltipEl = document.createElement('div');
              tooltipEl.id = 'chartjs-tooltip';
              tooltipEl.classList.add('batt_modal_view');
              tooltipEl.innerHTML = '<div class="batt_text_view" id="info"></div>';
              document.getElementById('bchatArea').appendChild(tooltipEl);
            };

            tooltipEl.style.border = `solid 3px ${backGroundColor}`;     
            if (toolAlert[dataLabel] === '1') {
              tooltipEl.style.backgroundImage = 'url("/img/common/icon-caution.png")';
            } else {
              tooltipEl.style.backgroundImage = 'none';
            };
      
            if (tooltip.opacity === 0) {
              tooltipEl.style.opacity = 0;
              document.getElementById('chartjs-tooltip').style.display = 'none';
              return;
            };
      
            if (tooltip.body) {
              const bodyLines = tooltip.body.map(b => b.lines);
      
              let innerHtml = '';
      
              bodyLines.forEach(function(body, i) {
                var style = '';

                if (toolAlert[dataLabel] === '1') {
                  style = 'margin-top:30px;'
                } else {
                  style = 'margin-top:15px;'
                };

                innerHtml += '<p style="' + style + '">' + toolDate[dataLabel] + ' ' + toolHour[dataLabel] + '<br>'
                        + toolValue[dataLabel] + '% <br>' 
                        + '</p>';
              });
            
              document.getElementById('info').innerHTML = innerHtml;
            };
      
            tooltipEl.style.opacity = 1;
            tooltipEl.style.display = 'block';
          };

          if (is_null === false && (battery_data) && (isFetched === true)) {
            const ctx = canvasRef.current.getContext('2d');
            const xLabels = [];
            const dataSet = [];
            canvasRef.current.style.display = 'none';
            canvasRef.current.addEventListener('contextmenu', handleContextMenu);

            let chartStatus = Chart.getChart("bchart");
            if (chartStatus !== undefined) {
              chartStatus.destroy();

            };

            for (let i = 0; i < battery_data.length; i++) {
              xLabels.push(battery_data[i].get_date.slice(2,10).replace(/-/g,'/'));
              dataSet.push(battery_data[i].battery_percentage);
              toolIdx = battery_data[i].get_date.slice(2,10).replace(/-/g,'/');
              toolDate[toolIdx] = battery_data[i].get_date;           
              toolHour[toolIdx] = battery_data[i].get_time;           
              toolValue[toolIdx] = battery_data[i].battery_percentage;

              if (battery_data[i].battery_percentage < 20) {
                  BackGroundColorList.push(limitOverColor);
                  toolAlert[toolIdx] = '1';
              } else {
                  BackGroundColorList.push(bar1Color);
                  toolAlert[toolIdx] = '';            
              }
            }

            var datasetArray = [{
                label: '',
                data: dataSet,
                backgroundColor: BackGroundColorList,
                priorDisplay: hasErrorData1,
                clip: {left: 10, right: 10, top: 10, bottom: 10} //バッテリー残量が０％又は１００％のの表示が切れないのため
              }];
    
            datasetArray.map(function(arr) {
              arr.lineTension = 0;
              arr.showLine = true;
              arr.fill = false;
              arr.pointRadius = 3;
              arr.pointHoverRadius = 5;
              arr.priorColor = limitOverColor;
              arr.spanGaps = true;
            });

            chartInstance.current = new Chart(ctx, {
                type: 'line',
                data: {
                  labels: xLabels,
                  datasets: datasetArray
                },
                    
                options: {
                  responsive: true,
                  elements: {
                    line: {
                      borderWidth: 1.5,
                      borderColor: '#000'
                    }
                  },
                  hover: {
                    mode: 'nearest'	//複数点
                  },
                  layout: {
                    padding: {
                      top: 0,
                      bottom: 0,
                      left: 0,
                      right: 0
                    }
                  },
                  scales: {
                    y: {
                      beginAtZero: true,
                      min: 0,
                      max: 100,
                      ticks: {
                        stepSize: 10,
                      },
                      grid: {
                        color: '#CCC',
                        lineWidth: 1,
                      },
                      
                    },
                    x: {
                      ticks: {
                        maxRotation: 55,
                        minRotation: 55,
                      }
                    }
                  },
                  plugins: {
                    legend: {
                      display: false
                    },
                    title: {
                      display: false 
                    },
                    tooltip: {
                      enabled: false,
                      external: customTooltip,
                    },
                  },

                }
            });            
            canvasRef.current.style.display = '';

            return () => {

              if (canvasRef.current) {
                canvasRef.current.removeEventListener('contextmenu', handleContextMenu);
              };

              if (chartInstance.current) {
                chartInstance.current.destroy();
              };
  
            };
          };

        },[isFetched]);

        return (
            <div id='Main' className={`main ${screenType}`}>
                <div id='Contents'>
                    <Breadcrumb
                        userName = {showUserName}
                        pageName = "カメラバッテリー残量"
                        onTopClick = {handleTopClick}
                    />
                    <div className='lay-search_terms_batteryGraph' id="dispCameraInfo">
                        <div className='lay-search_terms-wrap_batteryGraph'>
                            <div className='lay-search_terms__list_batteryGraph'>
                                <div className='lay-search_terms__list__text_batteryGraph'>
                                施設名：{display_facility_name}　　カメラ名：{display_camera_name}
                                </div>
                            </div>
                            <div className='lay-search__btn_batteryGraph'>
                            <label id="lbl-back" className="mod-btn__roundborder_batteryGraph" onClick={handleGoBack}>戻る</label>
                            </div>
                        </div>
                    </div>

                    {(is_null === false && (battery_data) && (isFetched === true))?(
                      <div className="lay-batterygraph">
                        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} message={modalMessage} />
                        <div className="lay-batterygraph__fig">
                            <div className="slideshow-container" id="screenshot">
                                <div className="batt_measure_unit">
                                    <span>［%］</span>
                                </div>
                                <div className="batt_chart_area" id = "bchatArea">
                                    <span></span>
                                    <canvas id="bchart" height="500" width="980" ref={canvasRef}></canvas>
                                    <span></span>
                                </div>
                            </div>
                        </div>
                        <div className="lay-battery-estimate">
                            <span>バッテリー切れ予測日</span>
                            <div className="batt_estimate_date">{estimated_date}</div>
                        </div>
                        <div className="lay-note">※上記予測日はあくまで目安であり、この日までの動作を保証するものではありません。</div>
                        <div className="lay-graph__btn">
                            <button type="button" className="mod-btn__round_batteryGraph" id="graphImageDownload" onClick={handleDownloadImage}>グラフ画像ダウンロード</button>
                        </div>
                        
                      </div>
                    ): (
                      <div className="mod-no-data" style={{display: isFetched ? '' : 'none'}}>表示データが存在しません。</div>                      
                    )
                    }
                    
                </div>
                <div className={`overlay ${isLoading ? 'active' : ''}`}>
                  <div className={`spinner ${isLoading ? 'active' : ''}`}>
                  </div>
                </div>
            </div>
        );
    }

);

BatteryGraph.propTypes = {
    selectedItem: PropTypes.object,
    battery_data: PropTypes.array,
    estimated_date: PropTypes.string,
    is_null: PropTypes.bool.isRequired, 
    isFetched: PropTypes.bool.isRequired, 
    isLoading:  PropTypes.bool.isRequired, 
    fetchBatteryGraphData: PropTypes.func.isRequired,
};

export default BatteryGraph;




