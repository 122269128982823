import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import BatteryGraphList from "../components/BatteryGraphList";
import { refreshAccessToken} from "../actions/user";
import {fetchBatteryGraphList, setSelectedCamera} from '../actions/batteryGraphList';

const mapStateToProps = (state) => {
  const user = state.login.user;

  return {
    user,    
  };

};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchBatteryGraphList,
  setSelectedCamera,
  refreshAccessToken
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BatteryGraphList);
