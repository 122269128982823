import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DataManage from '../components/DataManage';
import { 
    fetchFacilities, 
    fetchImageList, 
    fetchCount, 
    setSelectedItem, 
    fetchCameraInfo, 
    clearCameraInfo, 
    onPhotoShoot, 
    fetchPhotoShootStatus,
    onPhotoShootUpdate,
    dataRegister, 
    dataEdit, 
    dataDelete,
    setRelativeActive 
} from '../actions/datamanage';

const mapStateToProps = (state) => {
    const { dataManage } = state;   
    const user = state.login.user;

    return {
        user,
        isFetching: dataManage.isFetching,
        facilities: dataManage.facilities,
        cameras: dataManage.cameras,
        cameraInfo: dataManage.cameraInfo,
        gauges: dataManage.gauges,
        statuses: dataManage.statuses,
        imageListData: dataManage.imageListData,
        gaugeData: dataManage.gaugeData,
        failure_id: dataManage.failure_id,
        new_start_date: dataManage.new_start_date,
        searchCount: dataManage.searchCount,
        selectedItem: dataManage.selectedItem,
        error: dataManage.error,
        is_null: dataManage.is_null,
        isFetched: dataManage.isFetched,
        relativeActive: dataManage.relativeActive,
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchFacilities,
    fetchImageList,
    fetchCount,
    setSelectedItem,
    fetchCameraInfo,
    clearCameraInfo,
    onPhotoShoot,
    fetchPhotoShootStatus,
    onPhotoShootUpdate,
    dataDelete,
    dataEdit,
    dataRegister,
    setRelativeActive
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DataManage);
