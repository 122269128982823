import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BatteryGraph from '../components/BatteryGraph';
import { fetchBatteryGraphData} from "../actions/batteryGraph";

const mapStateToProps = (state) => {

  const batteryGraphListData = state.batteryGraphList;
  const batteryGraphData = state.batteryGraph;
  console.log("batteryGraphListData", batteryGraphListData.selectedCamera);
  console.log("batteryGraphData", batteryGraphData);
  const user = state.login.user;

  return {
    user,
    selectedCamera: batteryGraphListData.selectedCamera,
    battery_data: batteryGraphData.battery_data,
    estimated_date: batteryGraphData.estimated_date,
    is_null: batteryGraphData.is_null,
    isFetched: batteryGraphData.isFetched,
    isLoading: batteryGraphData.isLoading,
  };

};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchBatteryGraphData,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BatteryGraph);


