import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ScreenView from "../components/ScreenView";
import { fetchUserData, refreshAccessToken, logout } from "../actions/user";
import { fetchSystemInfo } from "../actions/systemInfo";

const mapStateToProps = (state) => {
  const { login, systemInfo } = state;
  const { isFetching, user } = login || {
    isFetching: false,
    user: {},
  };
  return {
    isFetching,
    user,
    systemInfo: systemInfo.systemInfo,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchUserData,
      refreshAccessToken,
      fetchSystemInfo,
      logout,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ScreenView);
