import ActionTypes from '../constants/ActionTypes'; 

const initialState = {
    data: {},
    loading: false,
    error: null
};

const measureReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.FETCH_MEASURE_NEW_REQUEST:
            return { ...state, loading: true, error: null };
        case ActionTypes.FETCH_MEASURE_NEW_SUCCESS:
            return { ...state, loading: false, data: action.payload };
        case ActionTypes.FETCH_MEASURE_NEW_FAILURE:
            return { ...state, loading: false, error: action.payload };

        case ActionTypes.REGISTER_MEASURE_REQUEST:
            return { ...state, loading: true, error: null};
        case ActionTypes.REGISTER_MEASURE_SUCCESS:
            return { ...state, loading: false, message: action.payload, error: null };
        case ActionTypes.REGISTER_MEASURE_FAILURE:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};

export default measureReducer;


