import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Modal from '../Modal/Modal';
import './MeasureNew.css';
import { useDispatch, useSelector } from 'react-redux'; 
import { fetchMenuData  } from '../../actions/menuActions';
import Breadcrumb from '../Breadcrumb';

const MeasureNew = ({user, fetchMeasureNew, loading, error, data, registerMeasure }) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate ();
    const [companyId, setCompanyId] = useState('');
    const [gaugeId, setGaugeId] = useState('');
    const [displayGaugeName, setDisplayGaugeName] = useState(''); 
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [measureName, setMeasureName] = useState('');
    const [unit, setUnit] = useState('');
    const [decimalValue, setDecimalValue] = useState('-1');
    const [standardTime, setStandardTime] = useState('0'); 
    const [diffType, setDiffType] = useState('0');
    const [maxLimit, setMaxLimit] = useState(''); 
    const [minLimit, setMinLimit] = useState('');
    const [diffMaxLimit, setDiffMaxLimit] = useState('');
    const [diffMinLimit, setDiffMinLimit] = useState('');
    const [isDiffChecked, setIsDiffChecked] = useState(false);
    const [isMaxLimitChecked, setIsMaxLimitChecked] = useState(false);
    const [isMinLimitChecked, setIsMinLimitChecked] = useState(false);
    const [isDiffMaxLimitChecked, setIsDiffMaxLimitChecked] = useState(false);
    const [isDiffMinLimitChecked, setIsDiffMinLimitChecked] = useState(false);
    const screenType = useSelector((state) => state.device.screenType);
    const [showUserName, setUserName] = useState('');

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const company_id = Number(params.get('company_id'))
        const facility_id = params.get('facility_id');
        const device_id = params.get('device_id');
        const gauge_id = params.get('gauge_id');
        if (!isNaN(company_id) && gauge_id) {
            setCompanyId(company_id);
            setGaugeId(gauge_id);
            fetchMeasureNew(company_id, facility_id, device_id, gauge_id);
        }

    }, [fetchMeasureNew, location]);

    useEffect(() => {
        if (data && data.gauge) {
            setDisplayGaugeName(data.gauge.display_gauge_name); 
        }
    }, [data]);

    useEffect(() => {
        if (user) {
          setUserName(user.user_name);
        };
    }, [user]);

    // Function to check for full-width characters
    const isFullWidth = (str) => {
        return /^[^\u0020-\u007E]+$/.test(str);
    }; 
    
    // Fetch updated menu after save or update
    const fetchUpdatedMenu = async () => {
        try {
            await dispatch(fetchMenuData());
        } catch (error) {
            console.error('Error fetching updated menu:', error);
        }
    };

    // Function to handle the register action
    const handleRegister = async () => {
        // Check for empty input fields
        if (!measureName || !unit) {
            setModalMessage("空白の登録はできません。");  
            setIsModalOpen(true);
            return;
        }
    
        // Check for full-width characters
        if (!isFullWidth(measureName) || !isFullWidth(unit)) {
            setModalMessage("名称、単位は、全角のみ登録可能です。"); 
            setIsModalOpen(true);
            return;
        }
    
        // Check diff_flg values
        if (isDiffChecked) {
            if (isDiffMaxLimitChecked) {
                if (!diffMaxLimit && diffMaxLimit !== 0) {
                    setModalMessage("上限値を入力してください。"); 
                    setIsModalOpen(true);
                    return;
                }
            }
    
            if (isDiffMinLimitChecked) {
                if (!diffMinLimit && diffMinLimit !== 0) {
                    setModalMessage("下限値を入力してください。"); 
                    setIsModalOpen(true);
                    return;
                }
            }
    
            if (isDiffMaxLimitChecked && isDiffMinLimitChecked) {
                if (Number(diffMinLimit) >= Number(diffMaxLimit)) {
                    setModalMessage("下限値は上限値より小さく入力してください。");  
                    setIsModalOpen(true);
                    return;
                }
            }
        } else {
            // Check max/min limits when diff_flg is not selected
            if (isMaxLimitChecked) {
                if (!maxLimit && maxLimit !== 0) {
                    setModalMessage("上限値を入力してください。");  
                    setIsModalOpen(true);
                    return;
                }
            }
    
            if (isMinLimitChecked) {
                if (!minLimit && minLimit !== 0) {
                    setModalMessage("下限値を入力してください。");  
                    setIsModalOpen(true);
                    return;
                }
            }
    
            if (isMaxLimitChecked && isMinLimitChecked) {
                if (Number(minLimit) >= Number(maxLimit)) {
                    setModalMessage("下限値は上限値より小さく入力してください。");
                    setIsModalOpen(true);
                    return;
                }
            }
        }
    
        // If no errors, proceed with registration
        const payload = {
            measure_name: measureName,
            unit: unit,
            company_id: companyId,
            gauge_id: gaugeId,
            decimal_value: decimalValue,
            standard_time: standardTime,
            diff_type: diffType,
            diff_max_limit: diffMaxLimit,
            diff_min_limit: diffMinLimit,
            max_limit: maxLimit,
            min_limit: minLimit,
            diff_flg: isDiffChecked ? 'on' : '',  
            diff_max_limit_flg: isDiffMaxLimitChecked ? 'on' : '', 
            diff_min_limit_flg: isDiffMinLimitChecked ? 'on' : '',  
            max_limit_flg: isMaxLimitChecked ? 'on' : '',  
            min_limit_flg: isMinLimitChecked ? 'on' : ''  
        };
    
        try {
            const response = await registerMeasure(payload);
            await fetchUpdatedMenu(); 
            setModalMessage(response.data?.respons?.msg || "登録が成功しました。");
            setIsModalOpen(true);
        } catch (error) {
            setModalMessage(error.message || "登録に失敗しました。");
            setIsModalOpen(true);
        }
    };    

    // Handle diff_flg checkbox
    const handleDiffFlgChange = (e) => {
        setIsDiffChecked(e.target.checked);  
    };

    // Handle max_limit_flg checkbox
    const handleMaxLimitChange = (e) => {
        setIsMaxLimitChecked(e.target.checked);  
    };

    // Handle min_limit_flg checkbox
    const handleMinLimitChange = (e) => {
        setIsMinLimitChecked(e.target.checked);
    };

    // Handle diff_max_limit_flg checkbox
    const handleDiffMaxLimitChange = (e) => {
        setIsDiffMaxLimitChecked(e.target.checked); 
    };

    // Handle diff_min_limit_flg checkbox
    const handleDiffMinLimitChange = (e) => {
        setIsDiffMinLimitChecked(e.target.checked);
    };

    const handleTopClick = () => {
        navigate('/');
    };

    // 半角数値入力チェック
    const fncInputNumCheck = (e) => {
        var value = e.target.value;
        var result = value.replace(/[^\d-.]/g,'');
        e.target.value = result;
    };

    //数値変換、桁数チェック 末尾にゼロ入力可能
    const fncNumberZeroEndingCheck = (e, decimal_value, fncSetLimit) => {

        var int_num = 10;
        var dec_num = 5;
        var int_count = int_num;

        //数値変換
        var value = e.target.value;

        if (value === "") {
            e.target.value = null;
            fncSetLimit(e.target.value);
            return;
        } else if (value === "0") {
            if (decimal_value > -1) {
                e.target.value = Number(value).toFixed(decimal_value);
                fncSetLimit(e.target.value);
            } else {
                e.target.value = value;
                fncSetLimit(e.target.value);
            }
            return;
        }

        var result = value;
        
        //桁数チェック
        var int_str = String(result).split(".")[0];
        var dec_str = String(result).split(".")[1];
        var decimalNum = 0;
        //マイナスは含める
        var isMinus = int_str.match("-");
        if(isMinus){
            int_count = int_num + 1;
        }
        if (dec_str){
            if (dec_str.length > dec_num){
                setModalMessage("小数の数値は、" + dec_num + "桁までの入力です。");
                setIsModalOpen(true);
                e.target.value = "";
                fncSetLimit(e.target.value);
                
                return;
            } else if(int_str.length > int_count) {
                setModalMessage("整数の数値は、" + int_num + "桁までの入力です。");
                setIsModalOpen(true);
                e.target.value = "";
                fncSetLimit(e.target.value);
                
                return;
            }
            decimalNum = decimal_value;
            if (decimal_value < 0) {
                decimalNum = dec_str.length;
            }
        } else {
            if (value.length > int_count){
                setModalMessage("整数の数値は、" + int_num + "桁までの入力です。");
                setIsModalOpen(true);
                e.target.value = "";
                fncSetLimit(e.target.value);
                
                return;
            }
            if (decimal_value >= 0) {
                decimalNum = decimal_value;
            }
        }

        if (decimalNum > 0) {
            if (isMinus) {
                result = Math.ceil(parseFloat(value * 10**decimalNum)) / 10**decimalNum;
            } else {
                result = Math.floor(parseFloat(value * 10**decimalNum)) / 10**decimalNum;
            }
        } else {
            result = isMinus ? Math.ceil(value) : Math.floor(value);
        }
        result = result.toFixed(decimalNum);
        
        //NaN確認
        if(isNaN(result)) {
            setModalMessage("数値のみ入力可能です。");
            setIsModalOpen(true);
            e.target.value = "";
            fncSetLimit(e.target.value);
            
            return;
        }
        e.target.value = result;
        fncSetLimit(e.target.value);
    }

    // 上限値・下限値のフォーマット
    const changeDecimalValue = (value) => {

        const decimalVal = Number(value);
        setDecimalValue(value);
        if (decimalVal >= 0) {
            setMaxLimit(maxLimit !== '' ? parseFloat(maxLimit).toFixed(decimalVal) : '');
            setMinLimit(minLimit !== '' ? parseFloat(minLimit).toFixed(decimalVal) : '');
            setDiffMaxLimit(diffMaxLimit !== '' ? parseFloat(diffMaxLimit).toFixed(decimalVal) : '');
            setDiffMinLimit(diffMinLimit !== '' ? parseFloat(diffMinLimit).toFixed(decimalVal) : '');
        } else {
            setMaxLimit(maxLimit !== '' ? (maxLimit).replace(/\.?0+$/, '') : '');
            setMinLimit(minLimit !== '' ? (minLimit).replace(/\.?0+$/, '') : '');
            setDiffMaxLimit(diffMaxLimit !== '' ? (diffMaxLimit).replace(/\.?0+$/, '') : '');
            setDiffMinLimit(diffMinLimit !== '' ? (diffMinLimit).replace(/\.?0+$/, '') : '');
        }
    }

    return (
        <div id="Main" className={`main ${screenType}`}>
            <div id="Contents">
                <Breadcrumb
                    userName = {showUserName}
                    pageName = "カメラ・計器設定"
                    onTopClick = {handleTopClick}
                />
                {isModalOpen && (
                    <Modal
                        isOpen={isModalOpen}
                        onClose={() => setIsModalOpen(false)}
                        message={modalMessage}
                    />
                )}

                    <div id="ajax-view-disp">
                        <div className="display-main">
                            <div className={`lay-master-set ${screenType}`}>
                                <div className="lay-master-set-wrap">
                                    <div className="lay-master-set__title">
                                        <span>計測項目設定</span>
                                    </div>

                                    <div className={`lay-master-set__form ${screenType}`}>
                                        <form action="" id='measureNewData'>
                                            <div className="mod-master-form__set measure">
                                                <div className="set_master_name_measure gauge_name">
                                                    <dl>
                                                        <dd style={{width: '450px'}}>
                                                            <input 
                                                                type="text" 
                                                                id="gauge_name" 
                                                                name="gauge_name" 
                                                                style={{ textAlign: 'center', fontSize: '22px' }} 
                                                                readOnly 
                                                                value={displayGaugeName} 
                                                            />
                                                        </dd>
                                                    </dl>
                                                </div>
                                                <div style={{textAlign: 'left'}}>
                                                    <span>名称を入力してください。(全角のみ)</span>
                                                </div>
                                                <div style={{display: 'flex'}}>
                                                    <div className="set_master_name_measure">
                                                        <dl>
                                                            <dt>計測項目名称</dt>
                                                            <dd>
                                                                <input 
                                                                    type="text" 
                                                                    id="measure_name_view" 
                                                                    name="measure_name" 
                                                                    value={measureName}
                                                                    onChange={(e) => setMeasureName(e.target.value)} 
                                                                />
                                                                <input type="hidden" id="measure_name" name="measure_name"/>
                                                            </dd>
                                                        </dl>
                                                    </div>
                                                    <div className="set_master_name_measure unit" style={{marginLeft: '50px'}}>
                                                        <dl>
                                                            <dt style={{width: '100px'}}>単位</dt>
                                                            <dd style={{width: '120px'}}>
                                                                <input 
                                                                    type="text" 
                                                                    id="unit" 
                                                                    name="unit" 
                                                                    value={unit}
                                                                    onChange={(e) => setUnit(e.target.value)} 
                                                                />
                                                            </dd>
                                                        </dl>
                                                    </div>
                                                </div>
                                                <div style={{display: 'flex'}}>
                                                    <div className="set_master_name_measure" style={{display: 'flex'}}>
                                                        <dl>
                                                            <dt>小数点桁数</dt>
                                                            <dd>
                                                                <div>
                                                                    <select 
                                                                        id="decimal_value" 
                                                                        name="decimal_value" 
                                                                        style={{width: '150px'}}
                                                                        value={decimalValue} 
                                                                        onChange={(e) => changeDecimalValue(e.target.value)}
                                                                    >
                                                                        <option value="-1">未設定</option>
                                                                        <option value="0">0</option>
                                                                        <option value="1">1</option>
                                                                        <option value="2">2</option>
                                                                        <option value="3">3</option>
                                                                        <option value="4">4</option>
                                                                        <option value="5">5</option>
                                                                    </select>
                                                                </div>
                                                            </dd>
                                                        </dl>
                                                    </div>
                                                </div>
                                                <div className="set_master_name_measure" style={{display: 'flex'}}>
                                                    <div>
                                                        <label>
                                                            <input 
                                                                type="checkbox" 
                                                                className="checkbox-input" 
                                                                id="diff_flg" 
                                                                name="diff_flg" 
                                                                onChange={handleDiffFlgChange}
                                                            />
                                                            <span className="checkbox-parts"></span>
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <span style={{marginLeft: '15px', fontSize: '20px'}}>
                                                            差分値を計算する
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className={`base-time-settings ${screenType}`}>
                                                    <div className="set_master_name_measure">
                                                        <dl style={{textAlign: 'center', }}>
                                                            <dt style={{width: '160px'}}>基準時間<br/>（データ表示時間）</dt>
                                                            <dd>
                                                                <div>
                                                                    <select 
                                                                        id="standard_time" 
                                                                        name="standard_time" 
                                                                        style={{width: '150px'}}
                                                                        value={standardTime}
                                                                        onChange={(e) => setStandardTime(e.target.value)}
                                                                    >
                                                                        {[...Array(24).keys()].map(i => (
                                                                            <option key={i} value={i}>{i} 時</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </dd>
                                                        </dl>
                                                    </div>
                                                    <div 
                                                        className="set_master_name_measure" 
                                                        id="diff_type_panel" 
                                                        style={{ display: isDiffChecked ? 'block' : 'none' }}  
                                                    >
                                                        <dl>
                                                            <dt>差分計算設定</dt>
                                                            <dd>
                                                                <div>
                                                                    <select 
                                                                        id="diff_type" 
                                                                        name="diff_type" 
                                                                        value={diffType}
                                                                        onChange={(e) => setDiffType(e.target.value)}
                                                                    >
                                                                        <option value="0">前回データ差分</option>
                                                                        <option value="1">前日データ差分</option>
                                                                    </select>
                                                                </div>
                                                            </dd>
                                                        </dl>
                                                    </div>
                                                </div>
                                                <div style={{textAlign: 'left', marginBottom: '20px'}}>
                                                    <p><span style={{fontWeight: 'bold'}}>差分値計算がオン</span>　...　計算基準時間を設定します。</p>
                                                    <p><span style={{fontWeight: 'bold'}}>差分値計算がオフ</span>　...　グラフ表示設定が「月単位」の場合、</p>
                                                    <p style={{paddingLeft: '161px'}}>グラフ表示データを選択できます。</p>
                                                </div>
                                                {/* measure limit */}
                                                <div id="measure_limit" className="measure_limit" style={{ display: isDiffChecked ? 'none' : 'block' }}>
                                                    <div className="set_master_name_measure" style={{display: 'flex'}}>
                                                        <div>
                                                            <label>
                                                                <input 
                                                                    type="checkbox" 
                                                                    className="checkbox-input" 
                                                                    id="max_limit_flg" 
                                                                    name="max_limit_flg" 
                                                                    onChange={handleMaxLimitChange} 
                                                                />
                                                                <span className="checkbox-parts"></span>
                                                            </label>
                                                        </div>
                                                        <div style={{marginLeft: '15px', fontSize: '20px'}}>
                                                            <span>
                                                                上限異常判定値
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <div style={{marginLeft: '10px', width: '180px'}}>
                                                                <input 
                                                                    type="text" 
                                                                    id="max_limit" 
                                                                    name="max_limit"
                                                                    value={maxLimit}
                                                                    onChange={(e) => setMaxLimit(e.target.value)}
                                                                    onInput={fncInputNumCheck}
                                                                    onBlur={(e) => fncNumberZeroEndingCheck(e, decimalValue, setMaxLimit)}
                                                                    inputMode="decimal"
                                                                    pattern="[0-9]*\.?[0-9]+" 
                                                                    onKeyDown={(e) => {
                                                                        if (e.key === 'Enter') {
                                                                            e.preventDefault(); 
                                                                            fncNumberZeroEndingCheck(e, decimalValue, setMaxLimit)
                                                                        }
                                                                    }}
                                                                    style={{height: '30px' }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div style={{marginLeft: '10px', fontSize: '20px'}}>
                                                            <div>
                                                                <span>
                                                                    以上
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style={{textAlign: 'center', marginBottom: '20px'}}>
                                                        <span>
                                                            チェックを入れると判定値以上の値を認識したときに上限異常と判定します。
                                                        </span>
                                                    </div>
                                                    <div className="set_master_name_measure" style={{display: 'flex'}}>
                                                        <div>
                                                            <label>
                                                                <input 
                                                                    type="checkbox" 
                                                                    className="checkbox-input" 
                                                                    id="min_limit_flg" 
                                                                    name="min_limit_flg" 
                                                                    onChange={handleMinLimitChange} 
                                                                />
                                                                <span className="checkbox-parts"></span>
                                                            </label>
                                                        </div>
                                                        <div style={{marginLeft: '15px', fontSize: '20px'}}>
                                                            <span>
                                                                下限異常判定値
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <div style={{marginLeft: '10px', width: '180px'}}>
                                                                <input 
                                                                    type="text" 
                                                                    id="min_limit" 
                                                                    name="min_limit"
                                                                    value={minLimit}
                                                                    onChange={(e) => setMinLimit(e.target.value)}
                                                                    onInput={fncInputNumCheck}
                                                                    onBlur={(e) => fncNumberZeroEndingCheck(e, decimalValue, setMinLimit)}
                                                                    inputMode="decimal"
                                                                    pattern="[0-9]*\.?[0-9]+" 
                                                                    onKeyDown={(e) => {
                                                                        if (e.key === 'Enter') {
                                                                            e.preventDefault(); 
                                                                            fncNumberZeroEndingCheck(e, decimalValue, setMinLimit)
                                                                        }
                                                                    }}
                                                                    style={{height: '30px' }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div style={{marginLeft: '10px', fontSize: '20px'}}>
                                                            <span>
                                                                以下
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div style={{textAlign: 'center', marginBottom: '20px'}}>
                                                        <span>
                                                            チェックを入れると判定値以下の値を認識したときに下限異常と判定します。
                                                        </span>
                                                    </div>
                                                </div>
                                                {/* diff limit  */}
                                                <div id="diff_limit" className="measure_limit" style={{ display: isDiffChecked ? 'block' : 'none' }}>
                                                    <div className="set_master_name_measure" style={{display: 'flex'}}>
                                                        <div>
                                                            <label>
                                                                <input 
                                                                    type="checkbox" 
                                                                    className="checkbox-input" 
                                                                    id="diff_max_limit_flg" 
                                                                    name="diff_max_limit_flg" 
                                                                    onChange={handleDiffMaxLimitChange} 
                                                                />
                                                                <span className="checkbox-parts"></span>
                                                            </label>
                                                        </div>
                                                        
                                                        <div style={{marginLeft: '15px', fontSize: '20px'}}>
                                                            <span>
                                                                上限異常判定値
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <div style={{marginLeft: '10px', width: '180px'}}>
                                                                <input 
                                                                    type="text" 
                                                                    id="diff_max_limit" 
                                                                    name="diff_max_limit"
                                                                    value={diffMaxLimit}
                                                                    onChange={(e) => setDiffMaxLimit(e.target.value)}
                                                                    onInput={fncInputNumCheck}
                                                                    onBlur={(e) => fncNumberZeroEndingCheck(e, decimalValue, setDiffMaxLimit)}
                                                                    inputMode="decimal"
                                                                    pattern="[0-9]*\.?[0-9]+" 
                                                                    onKeyDown={(e) => {
                                                                        if (e.key === 'Enter') {
                                                                            e.preventDefault(); 
                                                                            fncNumberZeroEndingCheck(e, decimalValue, setDiffMaxLimit)
                                                                        }
                                                                    }}
                                                                    style={{height: '30px' }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div style={{marginLeft: '10px', fontSize: '20px'}}>
                                                            <div>
                                                                <span>
                                                                    以上
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style={{textAlign: 'center', marginBottom: '20px'}}>
                                                        <span>
                                                            チェックを入れると判定値以上の値を認識したときに上限異常と判定します。
                                                        </span>
                                                    </div>
                                                    <div className="set_master_name_measure" style={{display: 'flex'}}>
                                                        <div>
                                                            <label>
                                                                <input 
                                                                    type="checkbox" 
                                                                    className="checkbox-input" 
                                                                    id="diff_min_limit_flg" 
                                                                    name="diff_min_limit_flg" 
                                                                    onChange={handleDiffMinLimitChange} 
                                                                />
                                                                <span className="checkbox-parts"></span>
                                                            </label>
                                                        </div>
                                                        <div style={{marginLeft: '15px', fontSize: '20px'}}>
                                                            <span>
                                                                下限異常判定値
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <div style={{marginLeft: '10px', width: '180px'}}>
                                                                <input 
                                                                    type="text" 
                                                                    id="diff_min_limit" 
                                                                    name="diff_min_limit"
                                                                    value={diffMinLimit}
                                                                    onChange={(e) => setDiffMinLimit(e.target.value)}
                                                                    onInput={fncInputNumCheck}
                                                                    onBlur={(e) => fncNumberZeroEndingCheck(e, decimalValue, setDiffMinLimit)}
                                                                    inputMode="decimal"
                                                                    pattern="[0-9]*\.?[0-9]+" 
                                                                    onKeyDown={(e) => {
                                                                        if (e.key === 'Enter') {
                                                                            e.preventDefault(); 
                                                                            fncNumberZeroEndingCheck(e, decimalValue, setDiffMinLimit)
                                                                        }
                                                                    }}
                                                                    style={{height: '30px' }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div style={{marginLeft: '10px', fontSize: '20px'}}>
                                                            <span>
                                                                以下
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div style={{textAlign: 'center', marginBottom: '20px'}}>
                                                        <span>
                                                            チェックを入れると判定値以下の値を認識したときに下限異常と判定します。
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="trun-btn">
                                        <button
                                            type="button"
                                            className="master_long_btn"
                                            id="register"
                                            onClick={handleRegister} 
                                        >
                                            登録
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

            </div>
            <div className={`overlay ${loading ? 'active' : ''}`}>
                <div className={`spinner ${loading ? 'active' : ''}`}></div>
            </div>
        </div>
    );
};

MeasureNew.propTypes = {
    fetchMeasureNew: PropTypes.func.isRequired,
    registerMeasure: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string
};

export default React.memo(MeasureNew);
