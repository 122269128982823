import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FacilityNew from '../components/FacilityNew';
import { registerFacility } from '../actions/new';

const mapStateToProps = (state) => ({
    user: state.login.user,
    loading: state.facilityNew.loading,
    error: state.facilityNew.error
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    registerFacility
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FacilityNew);
