import ActionTypes from "../constants/ActionTypes";
import BASE_URL from "../constants/Api";
import axiosInstance from "../helpers/refreshTokenMiddleware";

export const fetchMeasureEditRequest = () => ({
    type: ActionTypes.MEASURE_EDIT_REQUEST,
});

export const fetchMeasureEditSuccess = (data) => ({
    type: ActionTypes.MEASURE_EDIT_SUCCESS,
    payload: data,
});

export const fetchMeasureEditFailure = (error) => ({
    type: ActionTypes.MEASURE_EDIT_FAILURE,
    payload: error,
});

// Thunk to fetch data from API  
export const fetchMeasureEdit = (company_id, facility_id, device_id, gauge_id, measure_id) => {
    return async (dispatch) => {
        dispatch(fetchMeasureEditRequest());
        try {
            const accessToken = localStorage.getItem('accessToken');
            const response = await axiosInstance.post(BASE_URL + '/top/measure-edit', {
                company_id: company_id, 
                facility_id: facility_id,
                device_id: device_id,
                gauge_id: gauge_id,
                measure_id: measure_id
            }, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            // Check response here
            const data = response.data.respons || {};
            dispatch(fetchMeasureEditSuccess(data));

        } catch (error) {
            dispatch(fetchMeasureEditFailure(error.message));
        }
    };
};

export const registerEditMeasureRequest = () => ({
    type: ActionTypes.REGISTER_EDIT_MEASURE_REQUEST
});

export const registerEditMeasureSuccess = (message) => ({
    type: ActionTypes.REGISTER_EDIT_MEASURE_SUCCESS,
    payload: message
});

export const registerEditMeasureFailure = (error) => ({
    type: ActionTypes.REGISTER_EDIT_MEASURE_FAILURE,
    payload: error
});

export const registerEditMeasure = (payload) => {
    return async (dispatch) => {
        dispatch(registerEditMeasureRequest());

        try {
            const accessToken = localStorage.getItem('accessToken');
            const response = await axiosInstance.post(BASE_URL + '/top/edit-measure-data', payload, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            dispatch(registerEditMeasureSuccess(response.data.respons.msg));
            return response;
        } catch (error) {
            dispatch(registerEditMeasureFailure(error.message));
            throw error;
        }
    };
};