import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import './PhotoShoot.css';
import { PhotoShootStatus, PhotoShootResult, PhotoShootMessage } from '../../constants/Enums';

const LOCK_INTERVAL = 60 * 10;    //撮影ボタンロック間隔：10分

const formatToJapaneseTime = (isoString) => {
    // Check if isoString is valid
    if (!isoString) return '';

    // Create a new Date object from the ISO string
    const date = new Date(isoString);

    // Check if the date is valid
    if (isNaN(date.getTime())) return '';

    // Format the date to Japan Standard Time using Intl.DateTimeFormat
    const options = {
        timeZone: 'Asia/Tokyo',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        weekday: 'short',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false // Use 24-hour time format
    };

    // Return the formatted date string in Japanese
    return new Intl.DateTimeFormat('ja-JP', options).format(date);
};


const PhotoShoot = React.memo(
    ({  
        cameraInfo,
        companyId,
        cameraId,
        deviceId,
        gaugeId,
        isActive,
        photoShootInfo,  
        onPhotoShootConfirm,
        onPhotoShootDeny,
        fetchPhotoShootStatus,
        onPhotoShootUpdate
    }) => {

    console.log('PhotoShoot Render !');   

    const isInitialRender = useRef(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isDenied, setIsDenied] = useState(false);
    const [isPhotoShootBtnDisable, setIsPhotoShootBtnDisable] = useState(false);
    const [status, setStatus] = useState((''));
    const [result, setResult] = useState((''));
    const [acceptedTime, setAcceptedTime] = useState((''));
    const [updateDatetime, setUpdateDatetime] = useState((''));
    const [message, setMessage] = useState((''));
    
    const getPhotoShootStatus = (status) => {
        const statusEntry = Object.values(PhotoShootStatus).find(item => item.name === status);
        return statusEntry;
    };

    const getPhotoShootMessage = (message) => {
        // Check if the message is a valid string
        if (typeof message !== 'string') {
            return null;
        }

        // Find the message object in PhotoShootMessage based on its name
        let messageObj = Object.values(PhotoShootMessage).find(
            (item) => message.includes(item.name)
        );        
    
        return messageObj || null;
    };

    const getPhotoShootResult = (result) => {
        const resultEntry = Object.values(PhotoShootResult).find(item => item.name === result);
        return resultEntry;
    };
    
    const StatusText = ({ text, color }) => {
        const textColor = color || '#f0982d'; // Default to orange if no color is provided
    
        return (
            <div style={{ color: textColor, fontWeight: 'bold' }}>
                　{text}
            </div>
        );
    };

    const handlePhotoShoot = async () => {
        setIsLoading(true); // Show the loader
        if( isDenied) {
            onPhotoShootDeny();
        }
        else{
            onPhotoShootConfirm();
        }
        
        setIsLoading(false); // Hide the loader after action is complete
    };

    useEffect(() => {  
        
        let isBtnDisable = !isActive ;    // 停止されたカメラは「遠隔撮影」ボタンが無効になる

        if( isActive && photoShootInfo ){
            if( photoShootInfo.photoshoot_expire && photoShootInfo.photoshoot_status ){
                let status = getPhotoShootStatus(photoShootInfo.photoshoot_status.status);
                let result = getPhotoShootResult(photoShootInfo.photoshoot_status.result);
                let acceptedTime = formatToJapaneseTime(photoShootInfo.photoshoot_status.accepted_at_utc);
                let updateDatetime = formatToJapaneseTime(photoShootInfo.photoshoot_status.update_datetime_in_utc);
                let message = getPhotoShootMessage(photoShootInfo.photoshoot_status?.message);
             
                setStatus(status);
                setResult(result);
                setAcceptedTime(acceptedTime);
                setUpdateDatetime(updateDatetime);

                // if (message) {
                //     if( message.name === PhotoShootMessage.TASK_RESULT_POTENTIALLY_RECEIVED.name) {
                //         const extractedDateTime = extractDateTime(photoShootInfo.photoshoot_status.message);
                //         let messageText = message.text + "（" + formatToJapaneseTime(extractedDateTime) + "） " ;  
                //         message.text = messageText;                                                           
                //     }
                // }
                setMessage(message||photoShootInfo.photoshoot_status.message);
            }
            
            // 遠隔撮影ボタンロック状態設定
            const schedule = cameraInfo.status.schedule;
            let photoshootCount = parseInt(schedule.daily_photoshoots_count);
            
            const interval = (24 * 3600) / photoshootCount;
            const lockInterval = LOCK_INTERVAL * 1000;

            const start = new Date();
            start.setHours(0, 0, 0, 0);

            const now = new Date() - start;
            const idx = Math.floor((now + lockInterval) / (interval * 1000));

            let checkTimePrev = start.getTime() + idx * interval * 1000 - lockInterval;
            let checkTimeNext = checkTimePrev + lockInterval * 2;

            checkTimePrev = new Date(checkTimePrev);
            checkTimeNext = new Date(checkTimeNext);

            if (Date.now() > checkTimePrev.getTime() && Date.now() < checkTimeNext.getTime()) {
                // 遠隔撮影ボタンロック状態設定
                setIsDenied(true);           
            }            
        }

        setIsPhotoShootBtnDisable(isBtnDisable);      

    }, [cameraInfo, isActive, photoShootInfo, setIsPhotoShootBtnDisable]);


    useEffect(() => {     
        if (photoShootInfo && photoShootInfo.photoshoot_task_id) {           
            // Call fetchPhotoShootStatus immediately on the first render
            if (isInitialRender.current) {
                fetchPhotoShootStatus(cameraId, photoShootInfo.photoshoot_task_id);
                isInitialRender.current = false;
            }            
          
            const interval = setInterval(() => {
                const expireDate = new Date(photoShootInfo.photoshoot_expire);
                const now = new Date();              
    
                if (now > expireDate) {
                    console.log('# Photo shoot has expired, clearing interval');
                    setIsPhotoShootBtnDisable(false);
                    setIsLoading(false);
                    setMessage(PhotoShootMessage.PHOTO_SHOOT_EXPIRE);
                    clearInterval(interval);
                    return;
                }

                if ( message ) {
                    if ( message.name === PhotoShootMessage.ROUTER_NOT_FOUND.name ||
                         message.name === PhotoShootMessage.ROUTER_IS_BUSY.name ||
                         message.name === PhotoShootMessage.DEVICE_UNREACHABLE.name ||
                         message.name === PhotoShootMessage.DEVICE_TIMEOUT.name
                    ) {
                        setIsPhotoShootBtnDisable(false);
                        setIsLoading(false);
                        clearInterval(interval);
                        return;
                    } 

                    // 新しい画像データはシステムに登録された場合、遠隔撮影が完了
                    if (photoShootInfo.photoshoot_result &&  photoShootInfo.photoshoot_result.result === 'processed' ){
                        setMessage(PhotoShootMessage.PHOTO_SHOOT_COMPLETED);
                        setIsPhotoShootBtnDisable(false);
                        setIsLoading(false);
                        clearInterval(interval);
                        return;
                    }

                    // Lilzのカメラ側、自動撮影が完了した場合、新しい画像データをシステムに登録
                    if ( message.name === PhotoShootMessage.TASK_RESULT_POTENTIALLY_RECEIVED.name) {
                        console.log('# Photo shoot is completed!');
                        console.log('# onPhotoShootUpdate() - companyId:', companyId);
                        console.log('# onPhotoShootUpdate() - cameraId:', cameraId);
                        console.log('# onPhotoShootUpdate() - deviceId:', deviceId);
                        console.log('# onPhotoShootUpdate() - gaugeId:', gaugeId);
                        onPhotoShootUpdate(companyId, cameraId, deviceId, gaugeId)
                    } 
                }               
                
                fetchPhotoShootStatus(cameraId, photoShootInfo.photoshoot_task_id);
                
            }, 3000); 
        
            // Clean up the interval on component unmount or if dependencies change
            return () => clearInterval(interval);
        }
    }, [cameraId, photoShootInfo, fetchPhotoShootStatus, onPhotoShootUpdate, companyId, deviceId, gaugeId, message]);
    
    return (
        <>
            <div className="lay-search_terms__list photo_shoot" style={{ width: "100%" }}>
                <fieldset id="shoot_photo" className="shoot_photo">
                    <legend>　遠隔操作　</legend>
                    <div className="photo_shoot-content">
                        <div className="photo_shoot-info">
                            {(!status) && (
                                <div id="photo_shoot-message">
                                    遠隔操作は、カメラの周辺にBLE-LTEルータが必要になります。
                                </div>
                            )}
                            {(status) && (
                                <>
                                    <div id="photo_shoot-message">撮影を開始しました。画像到着に１０分程かかる場合があります。</div>
                                    <div id="photo_shoot-message">処理ステータス：</div></>
                            )}
                            {(status) && (
                                <>
                                    {/* 実行時刻 */}
                                    <div className="photo_shoot-status-row">
                                        <div className="photo_shoot-status-col">
                                            <div className="status-label">・実行時刻: </div>
                                            <StatusText text={acceptedTime || ' '} color={'#2E0854'} />                                             
                                        </div>
                                    </div>

                                    {/* 更新時刻 */}
                                    <div className="photo_shoot-status-row">
                                        <div className="photo_shoot-status-col">
                                            <div className="status-label">・更新時刻: </div>
                                            <StatusText text={updateDatetime || ' '} color={'#2E0854'} />                                
                                        </div>
                                    </div>

                                    {/* 有効期限 */}
                                    <div className="photo_shoot-status-row">
                                        <div className="photo_shoot-status-col">
                                            <div className="status-label">・有効期限: </div>
                                            <StatusText text={formatToJapaneseTime(photoShootInfo.photoshoot_expire) || ' '} color={'#2E0854'} />                                
                                        </div>
                                    </div>

                                    {/* 実行状態 */}
                                    <div className="photo_shoot-status-row">
                                        <div className="photo_shoot-status-col">
                                            <div className="status-label">・実行状態: </div>
                                            <StatusText text={status?.text || ' '} color={status?.hex} />                                            
                                        </div>
                                    </div>

                                    {/* 実行結果 */}
                                    <div className="photo_shoot-status-row">
                                        <div className="photo_shoot-status-col right-col">
                                            <div className="status-label">・実行結果:</div>
                                            <StatusText text={result?.text || ' '} color={result?.hex} />                                        
                                        </div>
                                    </div>

                                    {/* メッセージ */}
                                    <div className="photo_shoot-status-row">
                                        <div className="photo_shoot-status-col right-col">
                                            <div className="status-label">・メッセージ:</div>
                                            <StatusText text={message?.text || ' '} color={message?.hex || '#584E56'} />                                 
                                        </div>
                                    </div> 
                                </>                                                            
                            )}                            
                        </div>
                        {isLoading &&
                            <div className="waiting-img" id="waiting-img"></div> 
                        }
                        <div className="photo_shoot-button" id="photo_shoot-button">
                            <button
                                type="button"
                                className="mod-btn__round photo-shoot-btn"
                                id="photo-shoot"
                                onClick={handlePhotoShoot}
                                style={{
                                    backgroundColor: isDenied ? "rgb(128, 128, 128)" : "",
                                    cursor: "pointer",
                                    opacity: isDenied ? 0.5 : 1
                                }}
                                disabled={isPhotoShootBtnDisable} // Button is enabled by default
                            >
                                遠隔撮影
                            </button>                           
                        </div>                                               
                    </div>
                </fieldset>
            </div>
        </>
    );
});


PhotoShoot.propTypes = {
    cameraInfo: PropTypes.object,
    companyId: PropTypes.number,
    cameraId: PropTypes.string.isRequired,
    deviceId: PropTypes.string.isRequired,
    gaugeId: PropTypes.string,
    isActive: PropTypes.bool.isRequired,
    photoShootInfo: PropTypes.object,
    onPhotoShootConfirm: PropTypes.func.isRequired,
    onPhotoShootDeny: PropTypes.func.isRequired,
    fetchPhotoShootStatus: PropTypes.func.isRequired,
    onPhotoShootUpdate: PropTypes.func.isRequired,
};

export default PhotoShoot;