import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MeasureEdit from '../components/MeasureEdit';
import { fetchMeasureEdit, registerEditMeasure } from '../actions/measureedit';

const mapStateToProps = (state) => {
    const { measureEdit } = state;
    const user = state.login.user;
    return {
        user,
        data: measureEdit.data, 
        loading: measureEdit.loading,
        error: measureEdit.error,
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchMeasureEdit,
    registerEditMeasure
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MeasureEdit);