import ActionTypes from '../constants/ActionTypes'; 

const initialState = {
    loading: false,
    measuredata: {},
    error: null,
};

const gaugeReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.GAUGE_EDIT_REQUEST:
            return { ...state, loading: true };
        case ActionTypes.GAUGE_EDIT_SUCCESS:
            return { ...state, loading: false, measuredata: action.payload};
        case ActionTypes.GAUGE_EDIT_FAILURE:
            return { ...state, loading: false, error: action.payload };
        case ActionTypes.EDIT_GAUGE_DATA_REQUEST:
            return { ...state, loading: true};
        case ActionTypes.EDIT_GAUGE_DATA_SUCCESS:
            return { ...state, loading: false, message: action.payload }; 
        case ActionTypes.EDIT_GAUGE_DATA_FAILURE:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};

export default gaugeReducer;
