import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CompanyList from '../components/CompanyList';
import { fetchCompanies, fetchManagementUnitData, registerCompany, replaceCompanies } from '../actions/companyList';

const mapStateToProps = (state) => {
    const { companyList } = state;
    const user = state.login.user;
    return {
        user,
        companies: companyList.companies,
        managementUnitData: companyList.managementUnitData,
        loading: companyList.loading,
        error: companyList.error,
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchCompanies,
    fetchManagementUnitData,
    registerCompany,
    replaceCompanies
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CompanyList);