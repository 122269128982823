import React, { useState,  } from 'react';
import PropTypes from 'prop-types';
import Draggable from 'react-draggable';
import purify from "dompurify";
import './ConfirmPopup.css';

const ConfirmPopup = React.memo(
    ({
        handlePhotoShoot,
        onClose,
        message='撮影しますか？',
        confirmButtons = 0
    }) => {

        console.log('ConfirmPopup Render !');
        const MASK_YESNO_BTN = 0
        const MASK_OK_BTN = 1
        const [isDragging, setIsDragging] = useState(false);

        //Handle drag popup
        const [justDragged, setJustDragged] = useState(false);
        const [dragStartPosition, setDragStartPosition] = useState({ x: 0, y: 0 });

        const handleStart = (e) => {
            setIsDragging(false);
            setDragStartPosition({ x: e.clientX, y: e.clientY });
        };

        const handleDrag = (e) => {
            const distance = Math.sqrt(
            Math.pow(e.clientX - dragStartPosition.x, 2) +
            Math.pow(e.clientY - dragStartPosition.y, 2)
            );
        
            if (distance > 5) {
            setIsDragging(true);
            }
        };

        const handleStop = () => {
            if (isDragging) {
              setJustDragged(true);
              setTimeout(() => setJustDragged(false), 200); 
            }
            setIsDragging(false);
        };

        const closePopup = () => {
            if (!isDragging) {
                onClose();
            }
        };
     
        return (
            <div className="popup-wrapper" id="confirm_popup_wrapper" onClick={closePopup}>
                <Draggable
                    handle=".popup"
                    positionOffset={{ x: '-50%', y: '-50%' }}
                    onStart={handleStart}
                    onDrag={handleDrag}
                    onStop={handleStop}
                    cancel="textarea, input, .no-drag, button"
                >
                    <div className="popup" id="confirm_popup">
                        <div className="popup-content" id="photo_shoot_confirm" style={{ background: 'white' }}>
                            <div className="confirm-content-icon">
                                <span className="icon-img"/>
                            </div>
                            <div className="confirm-content-message" >
                                <div className="set_master_name_measure">
                                    <p dangerouslySetInnerHTML={{ __html:purify.sanitize(message) }}></p>
                                </div>
                            </div>
                            <div className="confirm-content-button">
                                {confirmButtons === MASK_YESNO_BTN && <button type="button" id="confirm-no" className="mod-btn__roundborder small" onClick={onClose}>
                                    いいえ
                                </button>}
                                {confirmButtons === MASK_OK_BTN && <button type="button" id="confirm-yes" className="mod-btn__round small" onClick={handlePhotoShoot}>
                                    OK
                                </button>}
                                {confirmButtons === MASK_YESNO_BTN && <button type="button" id="confirm-yes" className="mod-btn__round small" onClick={handlePhotoShoot}>
                                    はい
                                </button>}
                            </div>           
                        </div>
                    </div>
                </Draggable>
            </div>
        );
    },
);


ConfirmPopup.propTypes = {
    handlePhotoShoot: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    message: PropTypes.string,
    confirmButtons: PropTypes.number
};

export default ConfirmPopup;
