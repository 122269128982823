import { createStore, applyMiddleware } from 'redux';
import { thunk as thunkMiddleware } from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from '../reducers';
// import refreshTokenMiddleware from './refreshTokenMiddleware';

const loggerMiddleware = createLogger();

export const store = createStore(
    rootReducer,
    applyMiddleware(
        thunkMiddleware,
        loggerMiddleware,
        // refreshTokenMiddleware() // Thêm middleware refreshAccessTokenMiddleware vào đây
    )
);