import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MeasureNew from '../components/MeasureNew';
import { fetchMeasureNew, registerMeasure } from '../actions/new';

const mapStateToProps = (state) => ({
    user: state.login.user,
    data: state.measureNew.data,
    loading: state.measureNew.loading,
    error: state.measureNew.error
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchMeasureNew,
    registerMeasure
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MeasureNew);