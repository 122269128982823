import ActionTypes from '../constants/ActionTypes';
import axiosInstance from '../helpers/refreshTokenMiddleware';
import BASE_URL from '../constants/Api';

export const updateIdPasswordRequest = () => ({
    type: ActionTypes.UPDATE_ID_PASSWORD_REQUEST,
});

export const updateIdPasswordSuccess = (message) => ({
    type: ActionTypes.UPDATE_ID_PASSWORD_SUCCESS,
    payload: message,
});

export const updateIdPasswordFailure = (error) => ({
    type: ActionTypes.UPDATE_ID_PASSWORD_FAILURE,
    payload: error,
});

export const updateIdPassword = (currentPassword, newPassword, confirmPassword, newLoginId) => {
    return async (dispatch) => {
        dispatch(updateIdPasswordRequest());
        try {
            const accessToken = localStorage.getItem('accessToken');
            const response = await axiosInstance.post(BASE_URL + '/setting/update-id-password', {
                current_password: currentPassword,
                new_password: newPassword,
                confirm_password: confirmPassword,
                login_id: newLoginId,
            }, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            dispatch(updateIdPasswordSuccess(response.data.respons.msg));
            return response;
        } catch (error) {
            const errorMessage = error.response?.data?.respons?.msg || "Failed to update ID/PW."; 
            dispatch(updateIdPasswordFailure(errorMessage));
            throw new Error(errorMessage);
        
        }
    };
};
