import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FacilityEdit from '../components/FacilityEdit';
import { fetchFacilityEdit, updateFacility } from '../actions/facilityedit';

const mapStateToProps = (state) => {
    const { facilityData } = state;
    const user = state.login.user;
    return {
        user,
        facilitydata: facilityData.facilitydata, 
        loading: facilityData.loading,
        error: facilityData.error,
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchFacilityEdit,
    updateFacility,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FacilityEdit);