import ActionTypes from '../constants/ActionTypes';

const initialState = {
    battery_data: null,
    estimated_date: null,
    isFetched: false,
    isLoading: false,
    is_null: false,
};

const batteryGraphReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.FETCH_BATTERY_GRAPH_DATA_FAILURE:
            return {
                ...state,
                isFetched: false,
                isLoading: false,
            };
        case ActionTypes.FETCH_BATTERY_GRAPH_DATA_REQUEST:
            return {
                ...state,
                isFetched: false,
                isLoading: true,
            };
        case ActionTypes.FETCH_BATTERY_GRAPH_DATA_SUCCESS:
            return {
                ...state,
                battery_data: action.payload.data.battery_data,
                estimated_date: action.payload.data.estimated_date,
                is_null: action.payload.data.is_null,
                isFetched: true,
                isLoading: false,
            };
        default:
            return state;
    }

};

export default batteryGraphReducer;
