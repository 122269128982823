import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Modal from '../Modal/Modal';
import './UserNameChange.css';
import Breadcrumb from '../Breadcrumb';

const UserNameChange = ({error, updateUserName, userName, userId, fetchUserData }) => {
    const navigate = useNavigate ();
    const location = useLocation ();
    const [newUserName, setNewUserName] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [showUserName, setUserName] = useState('');
    const [loading, setLoading] = useState(false);
    const screenType = useSelector((state) => state.device.screenType);

    // Fetch user data on component mount
    useEffect(() => {
        const loadData = async () => {
            setLoading(true);
            await fetchUserData(); 
            setLoading(false);
        };
        loadData();
    }, [fetchUserData, location]);

    useEffect(() => {
        if (userName) {
          setUserName(userName);
        };
    }, [userName]);

    // Function to handle username update
    const handleUpdateUserName = async () => {
        if (!newUserName.trim()) { 
            setModalMessage("空白の登録できません。");
            setIsModalOpen(true);
            return;
        }

        try {
            const response = await updateUserName(userId, newUserName);
            const message = response.data?.respons?.msg || "Username updated successfully!";
            fetchUserData();
            setModalMessage(message);
            setIsModalOpen(true); 

            setUserName(newUserName);
            setNewUserName('');
        } catch (error) {
            setModalMessage(error.message || "Failed to update username.");
            setIsModalOpen(true);
        }
    };

    const handleTopClick = () => {
        navigate('/');
    };

    return (
        <div id='Main' className={`main ${screenType}`}>
            <div id='Contents'>
                <Breadcrumb
                    userName = {showUserName}
                    pageName = "ユーザ情報"
                    onTopClick = {handleTopClick}
                />
                {isModalOpen && (
                    <Modal
                        isOpen={isModalOpen}
                        onClose={() => setIsModalOpen(false)}
                        message={modalMessage}
                    />
                )}
                <div id="ajax-view-disp">
                    <div className="display-main">
                        <div className={`lay-master-set ${screenType}`}>
                            <div className="lay-master-set-wrap">
                                <div className="lay-master-set__title">
                                    <span>ユーザ名の変更</span>
                                </div>
                                <div className="lay-master-set__form">
                                    <form id="userData">
                                        <input type="hidden" name="user_id" value={userId || '' } />
                                        <div className="wrapper">
                                            <div className="user_input">
                                                <div>
                                                    <span id="curUserName">現在のユーザ名 : {userName}</span>
                                                </div>
                                            </div>
                                            <div className="user_input" style={{ marginBottom: '20px' }}>
                                                <div>
                                                    <span>新しいユーザ名 : </span>
                                                </div>
                                                <div>
                                                    <input
                                                        type="text"
                                                        id="user_name_view"
                                                        name="user_name_view"
                                                        maxLength="20"
                                                        value={newUserName || ''}
                                                        onChange={(e) => setNewUserName(e.target.value)} 
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="user-btn">
                                    <button type="button" className="master_long_btn" onClick={handleUpdateUserName}>
                                        登録
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`overlay ${loading ? 'active' : ''}`}>
                <div className={`spinner ${loading ? 'active' : ''}`}></div>
            </div>
        </div>
    );
};

UserNameChange.propTypes = {
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    userName: PropTypes.string,
    userId: PropTypes.number,
    updateUserName: PropTypes.func.isRequired,
    fetchUserData: PropTypes.func.isRequired,
};

export default React.memo(UserNameChange);
