import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './ConfirmCameraCount.css';
import Breadcrumb from '../Breadcrumb';

const ConfirmCameraCount = ({ user, data = {}, loading, error, fetchConfirmCameraCount}) => {
    const navigate = useNavigate ();
    const location = useLocation ();
    const [companyList, setCompanyList] = useState([]);
    const [cameraList, setCameraList] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState("0");
    const [showUserName, setUserName] = useState('');
    const screenType = useSelector((state) => state.device.screenType);
    // Check if the user is admin based on API response
    const isAdmin = data.is_admin;
    
    // Fetch data on component mount
    useEffect(() => {
        fetchConfirmCameraCount();
        setSelectedCompany("0");
    }, [fetchConfirmCameraCount, location]);

    // Update companyList when data changes
    useEffect(() => {
        if (data && data.company_data) {
            setCompanyList(data.company_data);
        }
        if (data && data.camera_data) {
            setCameraList(data.camera_data);
        }
    }, [data]);

    useEffect(() => {
        if (user) {
          setUserName(user.user_name);
        };
    }, [user]);

    // Filter camera list based on selected company
    const filteredCameraList = cameraList.filter(camera => {
        if (selectedCompany === "0") {
            return camera.disused_flg === 0; 
        }
        return camera.company_id === parseInt(selectedCompany) && camera.disused_flg === 0;
    });

    // Handle company selection change
    const handleCompanyChange = (event) => {
        setSelectedCompany(event.target.value);
    };

    const handleTopClick = () => {
        navigate('/');
    };

    return (
        <div id='Main' className={`main ${screenType}`}>
            <div id='Contents'>
                <Breadcrumb
                    userName = {showUserName}
                    pageName = "ユーザ情報"
                    onTopClick = {handleTopClick}
                />
                <div id="ajax-view-disp">
                    <div className="display-main">
                        <div className={`lay-master-set ${screenType}`}>
                            <div className={`lay-master-set-wrap ${screenType}`}>
                                <div className="lay-master-user__title" style={{marginRight: '50px'}}>
                                    <span>利用カメラ台数</span>
                                </div>
                                {isAdmin && (
                                    <div className="mod-form__select" style={{ width: '250px', marginBottom: '20px' }}>
                                        <select id="company_list" name="company_list" onChange={handleCompanyChange} value={selectedCompany}>
                                            <option value="0">全表示</option>
                                            {companyList.map((company, index) => (
                                                <option key={index} value={company.company_id} id={`companyName${index}`}>
                                                    {company.company_name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                )}

                                <div className="confirm camera">
                                    <div className="cell">
                                        <div>
                                            <span>現在登録されているカメラは、</span>
                                        </div>
                                        <div>
                                            <span id="camera-count">{filteredCameraList.length}台です。</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="breakdown">
                                    <table id="cameraTable">
                                        <thead>
                                            <tr style={{ borderBottom: '1px solid #ccc' }}>
                                                <td style={{ paddingRight: '50px', paddingTop: '10px' }}>[内訳]</td>
                                                <td>[使用開始月]</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredCameraList.map((camera, index) => (
                                                <tr key={index} style={{ borderBottom: '1px solid #ccc' }}>
                                                    <td className="tdName">
                                                        {camera.display_camera_name} <br />
                                                        ( シリアル番号 : {camera.device_id} )
                                                    </td>
                                                    <td className="tdFirstDate">
                                                        {camera.first_year_month_day} ～( 現在 : {camera.month_count}ヶ月目)
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`overlay ${loading ? 'active' : ''}`}>
                <div className={`spinner ${loading ? 'active' : ''}`}></div>
            </div>
        </div>
    );
};

ConfirmCameraCount.propTypes = {
    data: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    fetchConfirmCameraCount: PropTypes.func.isRequired,
};

export default React.memo(ConfirmCameraCount);