import ActionTypes from '../constants/ActionTypes';
import axiosInstance from '../helpers/refreshTokenMiddleware';
import BASE_URL from '../constants/Api';

export const fetchBatteryGraphDataRequest = () => ({
    type: ActionTypes.FETCH_BATTERY_GRAPH_DATA_REQUEST,
});

export const fetchBatteryGraphDataSuccess = (data, isEmpty) => ({
    type: ActionTypes.FETCH_BATTERY_GRAPH_DATA_SUCCESS,
    payload: { data, isEmpty },
});

export const fetchBatteryGraphDataFailure = (error) => ({
    type: ActionTypes.FETCH_BATTERY_GRAPH_DATA_FAILURE,
    payload: error,
});

export const fetchBatteryGraphData = (company_id, device_id) => async (dispatch) => {
    dispatch(fetchBatteryGraphDataRequest());
    try {
        const response = await axiosInstance.post(BASE_URL + '/graph/battery-graph-data', {
            company_id: company_id,
            device_id: device_id
        });
        const data = response.data.respons || [];
        const isEmpty = data.length === 0;
        dispatch(fetchBatteryGraphDataSuccess(data, isEmpty));       
    } catch (error) {
        dispatch(fetchBatteryGraphDataFailure(error.message));       
    }

};
