import React, { useState, useEffect } from 'react';
import './CameraInfo.css';

const ONE_LINE_SCHEDULE_COUNT = 12;

const CameraInfo = React.memo(
    ({ 
        cameraInfo 
    }) => {   
        
    console.log('CameraInfo Render !');
            
    const [deviceType, setDeviceType] = useState("");
    const [photoShootSchedule, setPhotoShootSchedule] = useState("");
        
    useEffect(() => {
        if (!cameraInfo) {
            return;
        }

        if (cameraInfo.device_type) {
            if (cameraInfo.device_type.includes("ble")) {
                setDeviceType("BLE");
            } else if (cameraInfo.device_type.includes("lte")) {
                setDeviceType("LTE");
            }
        }

        if (!cameraInfo.status || !cameraInfo.status.schedule) {
            return;
        }

        const schedule_span = "<span class='schedule_span'></span>";		       
        const schedule = cameraInfo.status.schedule;
        let daily_photoshoots_count = parseInt(schedule.daily_photoshoots_count);
        let ruleCount = schedule.rrules.length;
        let ruleIdx = 0;
        var ruleText = "";

        // LilzGaugeからのカメラ情報に対して撮影時刻取得	
        const getPhotoShootTime = (time) => {
            const hour = Math.floor(time / 3600);
            const min = Math.floor((time / 60) % 60);
            return `${("0" + hour).slice(-2)}:${("0" + min).slice(-2)}`;
        };

        ruleText += '<div class="schedule-row">';
        ruleText += '<div class="schedule-value">';

        // DTSTART:20211117T000000\nRRULE:FREQ=SECONDLY;INTERVAL=1800
        if (ruleCount === 1 && ruleCount !== daily_photoshoots_count) { 
            const  ruleFormat = schedule.rrules[0].split('\nRRULE:')[1].split(';');
            const  interval = parseInt(ruleFormat[1].split('=')[1]);     
            const midIdx = Math.floor(daily_photoshoots_count / 2);           
           
            while (ruleIdx < daily_photoshoots_count) {
                const time = getPhotoShootTime(ruleIdx * interval);

                if (daily_photoshoots_count > ONE_LINE_SCHEDULE_COUNT * 2) {	//３行以上で表示                     
                    if (ruleIdx > 0 ) {
                        if (ruleIdx === ONE_LINE_SCHEDULE_COUNT) {
                            ruleText += '</div>';
                            ruleText += '</div>';
                            ruleText += '<div class="schedule-row schedule-break">・・・</div>';
                            ruleText += '<div class="schedule-row">';
                            ruleText += '<div class="schedule-value">';
                        } 
                        else {
                            if (ruleIdx < ONE_LINE_SCHEDULE_COUNT || ruleIdx > daily_photoshoots_count - ONE_LINE_SCHEDULE_COUNT) ruleText += schedule_span;
                        }
                    }
                    if (ruleIdx < ONE_LINE_SCHEDULE_COUNT || ruleIdx >= daily_photoshoots_count - ONE_LINE_SCHEDULE_COUNT) ruleText += time;                
                }
                else if (daily_photoshoots_count > ONE_LINE_SCHEDULE_COUNT) {	//2行で表示          
                    if (ruleIdx > 0 ) {
                        if (ruleIdx === midIdx) {
                            ruleText += '</div>';
                            ruleText += '<div class="schedule-row">';
                            ruleText += '<div class="schedule-value">';
                        } else {
                            if (ruleIdx !== midIdx) ruleText += schedule_span;
                        }
                    }
                    ruleText += time;
                } 
                else {	//1行で表示    
                    if (ruleIdx > 0) ruleText += schedule_span;
                    ruleText += time;
                }
                ruleIdx++;                
            }   
            ruleText += '</div>';
            ruleText += '</div>';
        }
        else {    //DTSTART:20211116T000000\nRRULE:FREQ=DAILY;BYHOUR=2;BYMINUTE=0             
            let hour, min, time;     
       
            schedule.rrules.forEach((rule) => {
                let arr = rule.split(';');
                if (arr.length > 0) {
                    hour = ("0" + arr[1].split('=')[1]).slice(-2);
                    min = ("0" + arr[2].split('=')[1]).slice(-2);
                }
                time = `${hour}:${min}`;
            
                if (ruleIdx > 0) {
                    ruleText += schedule_span;                     
                }                    
                ruleText += time;
            
                ruleIdx++;
            });
            ruleText += '</div>';
        }

        setPhotoShootSchedule(ruleText);

    }, [cameraInfo]);

    return (
        <>
            <div className="lay-search_terms__list photo_shoot" style={{ width: "100%" }}>
                <fieldset id="cam_info">
                    <legend>
                        　カメラ情報　<span id="device_type">{deviceType}</span><span>　</span>
                    </legend>
                    <div id="photo_shoot-cam_info">
                        <div className="photo_shoot-cam_info-row">
                            <div className="photo_shoot-cam_info-col">
                                <div className="cam_info-label">・デバイスID: </div>
                                <div className="cam_info-value" id="cam_info-device_id">{cameraInfo?.device_id || ' '}</div>
                            </div>
                            <div className="photo_shoot-cam_info-col right-col">
                                <div className="cam_info-label">・バージョン:</div>
                                <div className="cam_info-value" id="cam_info-firmware_version">{cameraInfo?.status?.firmware_version || ' '}</div>
                            </div>
                        </div>
                        <div className="photo_shoot-cam_info-row">
                            <div className="photo_shoot-cam_info-col">
                                <div className="cam_info-label">・撮影解像度:</div>
                                <div className="cam_info-value" id="cam_info-resolution">{cameraInfo?.status?.resolution || ' '}</div>
                            </div>
                            <div className="photo_shoot-cam_info-col right-col">
                                <div className="cam_info-label">・撮影頻度:</div>
                                <div className="cam_info-value" id="cam_info-daily_photoshoots_count">{cameraInfo?.status?.schedule?.daily_photoshoots_count || ' '} 回/日</div>
                            </div>
                        </div>
                        <div className="photo_shoot-cam_info-row">
                            <div className="photo_shoot-cam_info-col">
                                <div className="cam_info-label">・撮影時刻:</div>
                                <div id="cam_info-schedule">
                                    <div dangerouslySetInnerHTML={{ __html: photoShootSchedule }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
        </>
    );
});

export default CameraInfo;